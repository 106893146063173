import { Error } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, Typography, useTheme } from '@mui/material';

interface VoteResultPostAlertDialogProps {
  open: boolean;
  onInputContinue: () => void;
  onPublish: () => void;
}

const VoteResultPostAlertDialog = ({ open, onInputContinue, onPublish }: VoteResultPostAlertDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: 417 }}>
        <Stack spacing={3} alignItems="center">
          <Error color="error" style={{ fontSize: 80 }} />
          <Typography variant="body1" color={theme.palette.grey[900]} textAlign="center">
            투표 결과가 모두 입력되지 않았습니다. 입력하지 않은 결과는 모바일에서 정상적으로 보여지지 않을 수 있습니다.
            정말 게시하시겠습니까?
          </Typography>
          <Stack direction="row" spacing={2} width="100%">
            <Button variant="outlined" color="primary" sx={{ flex: 1 }} onClick={onInputContinue}>
              계속 작성하기
            </Button>
            <Button variant="contained" color="primary" sx={{ flex: 1 }} onClick={onPublish}>
              게시하기
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VoteResultPostAlertDialog;
