import { Close, HelpOutline, InfoOutlined, SearchOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { ChangeEvent, MutableRefObject, createRef, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { date, number, object, string } from 'yup';
import {
  getDetailGeneralMeeting,
  patchGeneralMeetingInfo,
  requestGeneralMeetingAnnounceFileUpload,
  requestGeneralMeetingCreate,
} from '../../../../../api/general-meeting.api';
import { useCommonSnackbar } from '../../../../../hooks';
import { RequestStatus } from '../../../../../models';
import {
  GeneralMeetingCreateRequestBodyModel,
  GeneralMeetingModel,
  GeneralMeetingQueryStringKeyEnum,
  GeneralMeetingQueryStringValueEnum,
} from '../../../../../models/general-meeting.model';
import PostFileModel from '../../../../../models/post-file.model';
import { GlobalMenuItems } from '../../../../../navigation/global-menu.model';
import SuccessErrorModal from '../../../../common/dialog/success-error-dialog';
import FileItem from '../../../../common/file/file-item';
import MeetingAddressSearchDialog from './meeting-address-search-dialog';
import MeetingMobilePreviewDialog from './meeting-mobile-preview-dialog';
import MeetingRequiredInputAlertDialog from './meeting-required-input-alert-dialog';

dayjs.extend(minMax);

const MeetingPostTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 'none',
    border: `1px solid ${theme.palette.grey[400]}`,
  },
}));

export default function CreateMeetingPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { unionId } = useParams();
  const [searchParam] = useSearchParams();
  const fileSelectRef: MutableRefObject<HTMLInputElement | null> = createRef();

  const [meetingInfoForEdit, setMeetingInfoForEdit] = useState<GeneralMeetingModel>();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [requestResultDescription, setRequestResultDescription] = useState<string>('');
  const [isRequiredAlertVisible, setIsRequiredAlertVisible] = useState<boolean>(false);
  const [isMobilePreviewVisible, setIsMobilePreviewVisible] = useState<boolean>(false);
  const [isMeetingPostTooltipVisible, setIsMeetingPostTooltipVisible] = useState<boolean>(false);
  const [isAddressSearchDialogVisible, setIsAddressSearchDialogVisible] = useState<boolean>(false);
  const [responseMeetingId, setResponseMeetingId] = useState<number>();

  // TODO : 합치기
  const [meetingTitle, setMeetingTitle] = useState<string>();
  const [meetingLocationName, setMeetingLocationName] = useState<string>();
  const [meetingAddress, setMeetingAddress] = useState<string>();
  const [meetingAddressDetail, setMeetingAddressDetail] = useState<string>();
  const [meetingPublishDate, setMeetingPublishDate] = useState<Date>();
  const [meetingHoldDate, setMeetingHoldDate] = useState<Date>();
  const [announcementFile, setAnnouncementFile] = useState<File>();

  const [existingFile, setExistingFile] = useState<PostFileModel>();
  const [wrongTypeFileAlert, setWrongTypeFileAlert] = useState(false);

  const [currentProgress, setCurrentProgress] = useState(0);

  const [snackbar, setFeedback] = useCommonSnackbar();

  const [meetingHoldDatePickerOpen, setMeetingHoldDatePickerOpen] = useState(false);
  const [meetingPublishDatePickerOpen, setMeetingPublishDatePickerOpen] = useState(false);

  const fetchMeetingInfo = useCallback(
    async (meetingId: number) => {
      try {
        const responseData = await getDetailGeneralMeeting(meetingId);
        setMeetingInfoForEdit(responseData);
      } catch (error) {
        setFeedback({ message: '네트워크 오류가 발생했습니다.' });
      }
    },
    [setFeedback],
  );

  useEffect(() => {
    const generalMeetingId = searchParam.get(GeneralMeetingQueryStringKeyEnum.TARGET_ID);
    if (!generalMeetingId) {
      return;
    }

    fetchMeetingInfo(Number(generalMeetingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMeetingInfo]);

  const handleMeetingTooltipClose = () => {
    setIsMeetingPostTooltipVisible(false);
  };

  const handleMeetingTooltipOpen = () => {
    setIsMeetingPostTooltipVisible(true);
  };

  const handleMeetingTitleInputChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMeetingTitle(e.target.value);
  }, []);

  const handleMeetingLocationNameInputChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMeetingLocationName(e.target.value);
  }, []);

  const handleMeetingAddressDetailInputChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMeetingAddressDetail(e.target.value);
  }, []);

  const selectAnnouncementFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // accept이 적용이 안되는 경우를 위한 validation 추가
      const uploadableFileTypes = ['image/', 'pdf'];
      const targetFile = e.target.files[0];

      if (uploadableFileTypes.some((type) => targetFile.type.includes(type))) {
        setAnnouncementFile(targetFile);
      } else {
        setWrongTypeFileAlert(true);
      }
    }
  }, []);

  const deleteSelectedAnnouncementFile = useCallback(() => {
    if (announcementFile) {
      setAnnouncementFile(undefined);
    }
    if (existingFile) {
      setExistingFile(undefined);
    }
  }, [announcementFile, existingFile]);

  const createMeeting = useCallback(async () => {
    if (meetingTitle === undefined || meetingTitle.length === 0) {
      setIsRequiredAlertVisible(true);
      return;
    }

    if (requestStatus === RequestStatus.REQUESTED) {
      return;
    }

    setRequestStatus(RequestStatus.REQUESTED);

    const validatedMeetingCreateRequestBody: GeneralMeetingCreateRequestBodyModel = await object({
      unionId: number().required(),
      title: string().required(),
      locationName: string().optional(),
      address: string().optional(),
      addressDetail: string().optional(),
      publishAt: date().optional(),
      holdAt: date().optional(),
    }).validate({
      unionId,
      title: meetingTitle,
      locationName: meetingLocationName,
      address: meetingAddress,
      addressDetail: meetingAddressDetail,
      publishAt: meetingPublishDate,
      holdAt: meetingHoldDate,
    });

    let meetingId: number | undefined;

    try {
      let responseData: GeneralMeetingModel;
      if (!meetingInfoForEdit) {
        responseData = await requestGeneralMeetingCreate(validatedMeetingCreateRequestBody);
      } else {
        responseData = await patchGeneralMeetingInfo({
          ...validatedMeetingCreateRequestBody,
          generalMeetingId: meetingInfoForEdit.id,
        });
      }
      meetingId = responseData.id;
      setResponseMeetingId(meetingId);
    } catch (error) {
      setRequestResultDescription('총회 저장이 완료되지 않았습니다.');
      setRequestStatus(RequestStatus.FAIL);
    }

    if (announcementFile && meetingId) {
      try {
        await requestGeneralMeetingAnnounceFileUpload(
          meetingId, announcementFile, (progress) => { setCurrentProgress(progress); });
        setRequestResultDescription('총회 저장이 완료되었습니다.');
        setCurrentProgress(0);
        setRequestStatus(RequestStatus.SUCCESS);
      } catch (error) {
        setRequestResultDescription('총회 저장이 완료되었습니다. 하지만, 공고문 파일은 업로드되지 않았습니다.');
        setRequestStatus(RequestStatus.FAIL);
      }
    } else {
      setRequestResultDescription('총회 저장이 완료되었습니다.');
      setRequestStatus(RequestStatus.SUCCESS);
    }
  }, [
    meetingInfoForEdit,
    announcementFile,
    meetingAddress,
    meetingAddressDetail,
    meetingHoldDate,
    meetingLocationName,
    meetingPublishDate,
    meetingTitle,
    requestStatus,
    unionId,
  ]);

  useEffect(() => {
    if (!meetingInfoForEdit) {
      return;
    }
    setMeetingTitle(meetingInfoForEdit.title);
    setMeetingLocationName(meetingInfoForEdit.locationName ?? undefined);
    setMeetingAddress(meetingInfoForEdit.address ?? undefined);
    setMeetingAddressDetail(meetingInfoForEdit.addressDetail ?? undefined);
    setMeetingHoldDate(meetingInfoForEdit.holdAt ?? undefined);
    setMeetingPublishDate(meetingInfoForEdit.publishAt ?? undefined);
    setExistingFile(meetingInfoForEdit.announcementFile);
  }, [meetingInfoForEdit]);

  return (
    <Stack width="100%" spacing={5}>
      <Stack direction="row" columnGap={1}>
        <Typography variant="subtitle2" color={theme.palette.grey[600]}>
          총회
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.grey[600]}>
          {'>'}
        </Typography>
        {meetingInfoForEdit && (
          <Typography variant="subtitle2" color={theme.palette.grey[600]}>
            {meetingInfoForEdit.title}&ensp;{'>'}
          </Typography>
        )}
        <Typography variant="subtitle2" color={theme.palette.grey[800]}>
          총회 {meetingInfoForEdit ? '수정하기' : '만들기'}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h2">총회 정보</Typography>
        <Stack direction="row" alignItems="center" columnGap={0.5}>
          <Typography variant="subtitle2">총회 모바일 화면 예시</Typography>
          <HelpOutline
            sx={{ color: theme.palette.grey[600] }}
            onClick={() => {
              setIsMobilePreviewVisible(true);
            }}
          />
        </Stack>
      </Stack>
      <Stack spacing={5} maxWidth={898} marginTop={5}>
        <Stack direction="row" alignItems="center" spacing={7}>
          <Typography variant="subtitle1" minWidth={150} textAlign="start">
            총회명
            <sup style={{ color: theme.palette.common.red[500] }}>*</sup>
          </Typography>
          <TextField
            fullWidth
            placeholder="예) 2023년 정기총회"
            onChange={handleMeetingTitleInputChanged}
            value={meetingTitle || ''}
          />
        </Stack>
        <Stack direction="row" spacing={7}>
          <Typography variant="subtitle1" minWidth={150} textAlign="start">
            총회 장소
          </Typography>
          <Stack spacing={2} width="100%">
            <Stack spacing={1}>
              <Typography variant="body2" textAlign="start" color={theme.palette.grey[800]}>
                총회 장소명
              </Typography>
              <TextField
                fullWidth
                placeholder="예) 성남시청 1층 온누리홀"
                onChange={handleMeetingLocationNameInputChanged}
                value={meetingLocationName || ''}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" textAlign="start" color={theme.palette.grey[800]}>
                주소
              </Typography>
              <TextField
                fullWidth
                placeholder="여기를 눌러 주소를 검색하세요."
                InputProps={{
                  endAdornment: <SearchOutlined sx={{ color: theme.palette.grey[800] }} />,
                }}
                onClick={() => {
                  setIsAddressSearchDialogVisible(true);
                }}
                value={meetingAddress || ''}
                contentEditable={false}
                focused={false}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2" textAlign="start" color={theme.palette.grey[800]}>
                상세 주소 (선택사항)
              </Typography>
              <TextField
                fullWidth
                placeholder="예) 103호"
                onChange={handleMeetingAddressDetailInputChanged}
                value={meetingAddressDetail || ''}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={7}>
          <Typography variant="subtitle1" minWidth={150} textAlign="start">
            총회 일시
          </Typography>

          <DateTimePicker
            open={meetingHoldDatePickerOpen}
            onOpen={() => setMeetingHoldDatePickerOpen(true)}
            onClose={() => setMeetingHoldDatePickerOpen(false)}
            label="개최일"
            ampm={false}
            format="YYYY-MM-DD HH:mm"
            slotProps={{ textField:
              {
                error: false,
                inputProps: { readOnly: true },
                InputLabelProps: { sx: { color: theme.palette.grey[500] } },
                onClick: () => setMeetingHoldDatePickerOpen(true),
                sx: {
                  '& .MuiOutlinedInput-input':
                  {
                    visibility: meetingHoldDate ? 'visible' : 'hidden',
                  },
                },
              },
            }}
            onChange={(e) => {
              const selectedDate = (e as Dayjs).toDate();
              setMeetingHoldDate(selectedDate);
            }}
              // eslint-disable-next-line no-nested-ternary
            minDateTime={meetingInfoForEdit ?
              (meetingHoldDate ? dayjs.min(dayjs(meetingHoldDate), dayjs()).startOf('m') : dayjs())
              : dayjs().startOf('m')}
            value={meetingHoldDate ? dayjs(meetingHoldDate) : null}
          />

        </Stack>
        <Stack direction="row" alignItems="center" spacing={7}>
          <Stack direction="row" alignItems="center" spacing={1} minWidth={150}>
            <Typography variant="subtitle1" textAlign="start">
              총회 화면 게시일
            </Typography>
            <MeetingPostTooltip
              PopperProps={{ disablePortal: true }}
              open={isMeetingPostTooltipVisible}
              onClose={handleMeetingTooltipClose}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Stack direction="row" spacing={4}>
                  <Typography variant="body2" component="div" color={theme.palette.grey[800]} textAlign="start">
                    <ul>
                      <li>총회 화면은 총회 일시가 종료되고 3일 후에 자동으로 사라집니다.</li>
                      <li>모바일에서 총회 화면 게시를 중단하고자 하면 총회 상세화면에서 게시 중단을 눌러주세요.</li>
                      <li>총회 화면 게시일은 총회 일시보다 늦을 수 없습니다.</li>
                    </ul>
                  </Typography>
                  <Close sx={{ color: theme.palette.grey[800] }} onClick={handleMeetingTooltipClose} />
                </Stack>
              }
            >
              <HelpOutline sx={{ color: theme.palette.grey[700] }} onClick={handleMeetingTooltipOpen} />
            </MeetingPostTooltip>
          </Stack>

          <DateTimePicker
            open={meetingPublishDatePickerOpen}
            onOpen={() => setMeetingPublishDatePickerOpen(true)}
            onClose={() => setMeetingPublishDatePickerOpen(false)}
            label="시작일"
            ampm={false}
            format="YYYY-MM-DD HH:mm"
            slotProps={{ textField:
              {
                error: false,
                inputProps: { readOnly: true },
                InputLabelProps: { sx: { color: theme.palette.grey[500] } },
                onClick: () => setMeetingPublishDatePickerOpen(true),
                sx: {
                  '& .MuiOutlinedInput-input':
                  {
                    visibility: meetingPublishDate ? 'visible' : 'hidden',
                  },
                },
              },
            }}
              // eslint-disable-next-line no-nested-ternary
            minDateTime={meetingInfoForEdit ?
              (meetingPublishDate ? dayjs.min(dayjs(meetingPublishDate), dayjs()).startOf('m') : dayjs())
              : dayjs()}
            maxDateTime={dayjs(meetingHoldDate).startOf('s')}
            onChange={(e) => {
              const selectedDate = (e as Dayjs).toDate();
              setMeetingPublishDate(selectedDate);
            }}
            value={meetingPublishDate ? dayjs(meetingPublishDate) : null}
          />

        </Stack>
        <Stack direction="row" spacing={7}>
          <Typography variant="subtitle1" minWidth={150} textAlign="start">
            총회 공고문
          </Typography>
          <Stack spacing={1}>
            {announcementFile && (
              <FileItem
                fileName={announcementFile.name}
                fileSize={announcementFile.size}
                onDelete={deleteSelectedAnnouncementFile}
              />
            )}
            {existingFile && (
              <FileItem
                fileName={existingFile.originalName}
                fileSize={existingFile.size}
                onDelete={deleteSelectedAnnouncementFile}
              />
            )}
            {!(announcementFile || existingFile) && (
              <>
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ alignSelf: 'flex-start' }}
                  onClick={() => fileSelectRef.current?.click()}
                >
                  파일 선택
                </Button>
                <input
                  ref={fileSelectRef}
                  type="file"
                  multiple={false}
                  accept="image/*,.pdf"
                  style={{ display: 'none' }}
                  onChange={selectAnnouncementFile}
                />
              </>
            )}
            <Stack direction="row" alignItems="center" columnGap={0.5}>
              <InfoOutlined color="primary" />
              <Typography variant="subtitle2" color={theme.palette.primary.main}>
                pdf 또는 이미지 파일 1개만 첨부할 수 있습니다.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="center" spacing={3} marginTop={15}>
        <Button
          variant="contained"
          color="inherit"
          sx={{ minWidth: 112 }}
          onClick={() => {
            navigate(-1);
          }}
        >
          취소
        </Button>
        <Button variant="contained" color="primary" sx={{ minWidth: 112 }} onClick={createMeeting}>
          {currentProgress > 0 ?
            <CircularProgress
              variant="determinate"
              value={currentProgress}
              color="secondary"
              style={{
                width: '24px', height: '24px',
              }}
            />
            : '저장'}
        </Button>
      </Stack>

      <MeetingMobilePreviewDialog
        open={isMobilePreviewVisible}
        onClose={() => {
          setIsMobilePreviewVisible(false);
        }}
      />

      <MeetingRequiredInputAlertDialog
        open={isRequiredAlertVisible}
        onClose={() => {
          setIsRequiredAlertVisible(false);
        }}
      />

      <SuccessErrorModal
        isSuccess={requestStatus === RequestStatus.SUCCESS}
        title="총회 생성"
        description={requestResultDescription}
        open={requestStatus === RequestStatus.SUCCESS || requestStatus === RequestStatus.FAIL}
        onClose={() => {
          if (requestStatus === RequestStatus.SUCCESS) {
            navigate(
              `/unions/${unionId}?tab=${GlobalMenuItems.GENERAL_MEETING.keyName}` +
              `&${GeneralMeetingQueryStringKeyEnum.EVENT_TYPE}=${GeneralMeetingQueryStringValueEnum.MEETING}` +
              `&${GeneralMeetingQueryStringKeyEnum.EVENT_ACTION}=${GeneralMeetingQueryStringValueEnum.DETAIL}` +
              `&${GeneralMeetingQueryStringKeyEnum.TARGET_ID}=${responseMeetingId}`,
              { replace: true },
            );
            return;
          }
          setRequestStatus(RequestStatus.IDLE);
        }}
      />

      <SuccessErrorModal
        isSuccess={false}
        title=""
        description="파일은 pdf 또는 이미지 파일만 첨부할 수 있습니다. 확인 후 다시 시도해 주세요."
        open={wrongTypeFileAlert}
        onClose={() => setWrongTypeFileAlert(false)}
      />

      <MeetingAddressSearchDialog
        open={isAddressSearchDialogVisible}
        onCancel={() => {
          setIsAddressSearchDialogVisible(false);
        }}
        onComplete={(address: string) => {
          setIsAddressSearchDialogVisible(false);
          setMeetingAddress(address);
        }}
      />

      {snackbar}
    </Stack>
  );
}
