import { ArrowBack, FileDownload } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Divider,
  MenuItem,
  Paper,
  Select,
  Stack,
  Theme,
  Typography,
  TypographyOwnProps,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppContext from '../../../../../AppContext';
import { downloadFile } from '../../../../../api/file.api';
import { getImageFromAuthorization, getPost, hideOrShowPost, hideOrShowPostReply } from '../../../../../api/post.api';
import accuseIcon from '../../../../../assets/accuse-icon.svg';
import defaultAvatar from '../../../../../assets/default-avatar.svg';
import pollIcon from '../../../../../assets/poll-icon.svg';
import reReplyIcon from '../../../../../assets/re-reply-indent-icon.svg';
import viewIcon from '../../../../../assets/view-icon.svg';
import { Poll, Post, PostFile, PostReply, ServerError } from '../../../../../models';
import { GlobalMenuItems } from '../../../../../navigation/global-menu.model';
import { getFileIcon, isInternalStaff } from '../../../../../util/functions';
import Splash from '../../../../splash';

const headerTextProps: TypographyOwnProps = {
  variant: 'body2',
  fontWeight: 400,
  color: '#949494',
};

const VoteView = ({ poll, theme }: { poll: Poll; theme: Theme }) => (
  <Paper sx={{ p: 2, width: 420 }}>
    <Stack spacing={1} alignItems="start">
      <Stack direction="row" spacing={1}>
        <img src={pollIcon} alt="" />
        <Typography>투표</Typography>
      </Stack>
      <Typography fontSize={14} fontWeight={600} color={theme.palette.grey[600]}>
        {poll.voterCount}명 투표 {poll.isMultiSelect && ' · 복수 선택 가능'}
      </Typography>
      {poll.pollOptions?.map((option) => (
        <Stack
          sx={{ background: '#F5F5F5', p: 1, width: '100%', borderRadius: '4px' }}
          direction="row"
          key={`poll-${poll.id}-option-${option.id}`}
        >
          <Typography fontWeight={400} fontSize={14} flex={1} textAlign="start">
            {option.content}
          </Typography>
          <Typography fontWeight={400} fontSize={14}>
            {poll.voterCount ? ((option.voteCount / poll.voterCount) * 100).toFixed(0) : '0'}%
          </Typography>
        </Stack>
      ))}
      <Typography fontSize={14} fontWeight={400} color={theme.palette.grey[600]}>
        종료일 {dayjs(poll.endsAt).format('YYYY-MM-DD HH:mm')}
      </Typography>
    </Stack>
  </Paper>
);

const PostReplyView = ({
  reply,
  theme,
  isPostStateEditable,
  onHiddenSelect,
}: {
  reply: PostReply;
  theme: Theme;
  isPostStateEditable: boolean;
  onHiddenSelect: () => void;
}) => {
  const [customerProfileUrl, setCustomerProfileUrl] = useState<Blob>();

  useEffect(() => {
    if (!reply.customer) {
      return;
    }
    if (!reply.customer.customerProfile) {
      return;
    }
    if (reply.customer.customerProfile.url.length === 0) {
      return;
    }
    (async () => {
      try {
        const profileImageUrl = await getImageFromAuthorization(reply.customer?.customerProfile?.url!);
        setCustomerProfileUrl(profileImageUrl);
      } catch (error) {
        // err
      } finally {
        // finally
      }
    })();
  }, [reply.customer, reply.customer?.customerProfile?.url]);

  const authorizationProfileUrl = useMemo(() => {
    if (customerProfileUrl) {
      return window.URL.createObjectURL(customerProfileUrl);
    }
    return defaultAvatar;
  }, [customerProfileUrl]);

  return (
    <Stack direction="row" spacing={2} width={640}>
      {reply.postReplyId && <img src={reReplyIcon} alt="" width={24} height={24} />}
      <Avatar src={authorizationProfileUrl} alt="" sx={{ width: 46, height: 46 }} />
      <Stack alignItems="start" spacing={1} flex={1}>
        <Typography textAlign="left" fontSize={16} fontWeight={600}>
          {reply.customer?.nickname}
        </Typography>
        <Typography textAlign="left" variant="body2" fontWeight={400}>
          {reply.content}
        </Typography>
        <Typography fontSize={14} color={theme.palette.grey[600]}>
          {dayjs(reply.createdAt).format('YYYY-MM-DD HH:mm')}
        </Typography>
      </Stack>
      <Select
        size="small"
        value={reply.isHidden ? '비공개' : '공개'}
        onChange={onHiddenSelect}
        sx={{ width: 100, height: 36 }}
        disabled={isPostStateEditable}
      >
        <MenuItem value="공개">
          <em>공개</em>
        </MenuItem>
        <MenuItem value="비공개">
          <em>비공개</em>
        </MenuItem>
      </Select>
    </Stack>
  );
};

const fetchFile = async (data: PostFile) => {
  const result = await downloadFile(data.url);
  if (result instanceof ServerError) {
    return;
  }
  saveAs(result, data.originalName);
};

export default function PostDetailPage() {
  const navigate = useNavigate();
  const { unionId } = useParams();
  const [params] = useSearchParams();
  const postId = params.get('id');
  const theme = useTheme();
  const { staff } = useContext(AppContext);
  const isInternal = useMemo(() => isInternalStaff(staff), [staff]);

  if (!postId) {
    navigate(-1);
  }

  const [post, setPost] = useState<Post>();
  const isPostStateEditable = useMemo(() => {
    if (isInternal) {
      return false;
    }

    if (!post || post.deletedAt) {
      return false;
    }

    return true;
  }, [isInternal, post]);

  const fetchPost = useCallback(async () => {
    if (Number.isInteger(Number(postId))) {
      const result = await getPost(Number(postId));
      if (result instanceof ServerError) {
        return;
      }
      setPost(result.post);
    }
  }, [postId]);

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  const onPostHiddenStatusChange = useCallback(async () => {
    if (post) {
      const result = await hideOrShowPost(post.id);
      if (result instanceof ServerError) {
        return;
      }
      fetchPost();
    }
  }, [fetchPost, post]);

  const onPostReplyHiddenStatusChange = useCallback(
    async (reply: PostReply) => {
      const result = await hideOrShowPostReply(reply.id);
      if (result instanceof ServerError) {
        return;
      }
      fetchPost();
    },
    [fetchPost],
  );

  return post ? (
    <Stack spacing={4} padding={4} alignItems="start">
      <Button
        variant="text"
        startIcon={<ArrowBack sx={{ color: theme.palette.grey[800] }} />}
        onClick={() => {
          navigate(`/unions/${unionId}?tab=${GlobalMenuItems.POST.keyName}`, { replace: true });
        }}
        sx={{ color: theme.palette.grey[800], alignSelf: 'flex-start' }}
      >
        리스트로 돌아가기
      </Button>
      <Typography variant="h2">게시물 상세</Typography>
      <Typography variant="h3">{post.postWall?.title}</Typography>

      <Stack spacing={2} alignItems="start">
        <Stack direction="row">
          {post.deletedAt && (
            <Typography color="error" variant="h2">
              [삭제]&nbsp;
            </Typography>
          )}
          <Typography variant="h2">{post.title}</Typography>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography {...headerTextProps}>{post.isNotice ? post.staff?.name : post.customer?.nickname}</Typography>
          <Divider orientation="vertical" sx={{ height: '20px' }} />
          <Typography {...headerTextProps}>{dayjs(post.createdAt).format('YYYY.MM.DD HH:mm')}</Typography>
          <Divider orientation="vertical" sx={{ height: '20px' }} />
          <img src={viewIcon} alt="" />
          <Typography {...headerTextProps}>{post.viewCount}</Typography>
          <Divider orientation="vertical" sx={{ height: '20px' }} />
          <img src={accuseIcon} alt="" />
          <Typography {...headerTextProps}>{post.accuseCount}</Typography>
          <Stack paddingLeft={2}>
            <Select
              size="small"
              value={post.isHidden ? '비공개' : '공개'}
              onChange={onPostHiddenStatusChange}
              sx={{ width: 100 }}
              disabled={!isPostStateEditable}
            >
              <MenuItem value="공개">
                <em>공개</em>
              </MenuItem>
              <MenuItem value="비공개">
                <em>비공개</em>
              </MenuItem>
            </Select>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ width: '100%' }} />
      <Typography>{post.content}</Typography>
      {post.polls && post.polls[0] && <VoteView poll={post.polls[0]} theme={theme} />}
      {post.postFiles?.map((file) => (
        <Stack
          direction="row"
          sx={{ background: theme.palette.grey[200], border: `1px solid ${theme.palette.grey[500]}` }}
          p="16px"
          spacing={2}
          alignItems="center"
          key={`post-file-${file.id}`}
        >
          {['.png', '.jpg', '.jpeg', '.bmp'].includes(file.extension) ? (
            <img src={file.url} alt={file.originalName} width="240px" />
          ) : (
            <>
              <img src={getFileIcon(file.originalName)} alt="" />
              <Typography>{file.originalName}</Typography>
              <Typography flex={1} />
            </>
          )}
          <FileDownload sx={{ cursor: 'pointer' }} onClick={() => fetchFile(file)} />
        </Stack>
      ))}
      <Divider sx={{ width: '100%' }} />
      <Stack spacing={2}>
        <Stack direction="row">
          <Typography fontWeight={600}>댓글 {post.replyCount}</Typography>
        </Stack>
        {post.postReplies?.map((reply) => (
          <Stack key={`reply-item-${reply.id}`} spacing={2}>
            <PostReplyView
              reply={reply}
              theme={theme}
              isPostStateEditable={!isPostStateEditable}
              onHiddenSelect={() => onPostReplyHiddenStatusChange(reply)}
            />
            {reply.childReplies?.map((reReply) => (
              <PostReplyView
                key={`reReply-item-${reReply.id}`}
                reply={reReply}
                theme={theme}
                isPostStateEditable={!isPostStateEditable}
                onHiddenSelect={() => onPostReplyHiddenStatusChange(reReply)}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  ) : (
    <Splash />
  );
}
