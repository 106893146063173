import { Chip, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { GeneralMeetingStatus } from '../../../../../models/general-meeting.model';

type GeneralMeetingStatusType = '게시 전' | '게시 중' | '임시저장' | '게시 종료';

interface Props {
  generalMeetingStatus: GeneralMeetingStatus;
}

export default function GeneralMeetingStatusTag({ generalMeetingStatus }: Props) {
  const theme = useTheme();
  const statusType: GeneralMeetingStatusType = useMemo(() => {
    switch (generalMeetingStatus) {
      case GeneralMeetingStatus.CONFIRMED:
        return '게시 전';
      case GeneralMeetingStatus.PUBLISHED:
        return '게시 중';
      case GeneralMeetingStatus.INIT:
        return '임시저장';
      case GeneralMeetingStatus.FINISHED:
        return '게시 종료';
      default:
        return '게시 전';
    }
  }, [generalMeetingStatus]);

  const setBackgroundColor: string = useMemo(() => {
    switch (statusType) {
      case '게시 전':
        return theme.palette.common.yellow[100];
      case '게시 중':
        return theme.palette.common.green[100];
      case '임시저장':
        return theme.palette.grey[200];
      case '게시 종료':
        return theme.palette.common.blue[50];
      default:
        return theme.palette.common.yellow[100];
    }
  }, [statusType, theme]);

  return <Chip label={statusType} style={{ backgroundColor: setBackgroundColor, width: '88px' }} />;
}
