import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getUnionListForInternalStaff } from '../../api/union.api';
import { ServerError, Union } from '../../models';
import UnionHeader from './union-header';
import UnionList from './union-list';

const UnionPage = () => {
  const [unionList, setUnionList] = useState<Union[]>();
  const [searchText, setSearchText] = useState('');
  const [searchedData, setSearchedData] = useState<Union[]>();

  const fetchUnionList = useCallback(async () => {
    const data = await getUnionListForInternalStaff();
    if (data instanceof ServerError) {
      return;
    }
    setUnionList(data.unionList);
  }, [setUnionList]);

  useEffect(() => {
    fetchUnionList();
  }, [fetchUnionList]);

  useEffect(() => {
    if (!unionList) return;
    if (!searchText) {
      setSearchedData(unionList);
    } else {
      const d = unionList.filter(
        ({ name, phone, serialNumber, constructionType, representativeAddress, stage }: Union) =>
          [name, phone, serialNumber, constructionType, representativeAddress, stage].some((v) =>
            v?.toLowerCase().includes(searchText.toLowerCase().trim()),
          ),
      );
      setSearchedData(d);
    }
  }, [searchText, unionList]);

  return (
    <Stack spacing={3}>
      <UnionHeader searchText={searchText} setSearchText={setSearchText} />
      <UnionList data={searchedData || []} />
    </Stack>
  );
};

export default UnionPage;
