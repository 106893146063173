import { Button, Stack, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import { MessageSendingHistoryModel } from '../../../../../models/message-sending-history.model';
import { MessageSendingType } from '../../../../../models/message-sending.model';
import { MessageRegistrationStatus, MessageTargetGrade } from '../../../../../models/message-type';
import { convertDateFormat24HWithoutDay } from '../../../../../util/functions';

interface MessageHistoryTableProps {
  rows: MessageSendingHistoryModel[];
  onDownloadClicked: (messageId: number) => void;
}
export default function MessageHistoryTable(props: MessageHistoryTableProps) {
  const { rows, onDownloadClicked } = props;
  const theme = useTheme();

  const convertType = useCallback((originType: MessageSendingType): string => {
    switch (originType) {
      case MessageSendingType.ALIM_TALK:
        return '카카오톡 알림톡';
      default:
        return originType.toUpperCase();
    }
  }, []);

  const convertTargetGrades = useCallback((originGrades: MessageTargetGrade[]): string[] => {
    const enumLength = Object.keys(MessageTargetGrade).length;
    const converted = originGrades.map((originGrade) => {
      switch (originGrade) {
        case MessageTargetGrade.DELEGATE:
          return '대의원';
        case MessageTargetGrade.EXECUTIVE:
          return '임원';
        case MessageTargetGrade.GENERAL:
          return '일반조합원';
        default:
          return '';
      }
    });
    return enumLength === converted.length ? ['전체 구성원'] : converted;
  }, []);

  const convertRegistrationStatus = useCallback(
    (originStatus: MessageRegistrationStatus): string[] => {
      const converted: string[] = [];
      const registered = '앱 가입자';
      const unRegistered = '앱 미가입자';
      switch (originStatus) {
        case MessageRegistrationStatus.ALL:
          converted.push(registered, unRegistered);
          break;
        case MessageRegistrationStatus.REGISTERED:
          converted.push(registered);
          break;
        case MessageRegistrationStatus.UNREGISTERED:
          converted.push(unRegistered);
          break;
        default:
          break;
      }
      return converted;
    }, []);

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'contents',
      headerName: '내용',
      sortable: false,
      align: 'left',
      flex: 2,
      renderCell: (params) => params.value.message ?? params.row.description,
    },
    {
      field: 'type',
      headerName: '발송 유형',
      sortable: false,
      align: 'left',
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body2" ml="16px">{convertType(params.value)}</Typography>
      ),
    },
    {
      field: 'targetGrades',
      headerName: '받는 사람',
      sortable: false,
      align: 'left',
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="flex-start">
          <Typography variant="body2">{convertTargetGrades(params.value).join(', ')}</Typography>
          <Typography variant="body2" color={theme.palette.grey[700]}>
            {convertRegistrationStatus(params.row.registrationStatus).join(', ')}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'requester',
      headerName: '보낸 사람',
      sortable: false,
      align: 'left',
      flex: 0.5,
      valueFormatter: (params) => (params.value.name),
    },
    {
      field: 'createdAt',
      headerName: '발송일',
      sortable: false,
      align: 'left',
      flex: 1,
      valueFormatter: (params) => (params.value ? convertDateFormat24HWithoutDay(params.value) : '-'),
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            onDownloadClicked(params.row.id);
          }}
        >발송 결과
        </Button>),
    },
  ], [
    convertRegistrationStatus,
    convertTargetGrades,
    convertType,
    onDownloadClicked,
    theme],
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      hideFooterPagination={rows.length === 0}
      rowHeight={80}
      disableColumnMenu
      rowSelection={false}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10]}
      localeText={{ noRowsLabel: '내용이 없습니다.' }}
      sx={{
        [`& .${gridClasses.columnHeader}`]: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.grey[900],
          typography: theme.typography.subtitle2,
        },
        [`& .${gridClasses.cellContent}`]: {
          typography: theme.typography.body2,
        },
        [`& .${gridClasses.cell}:focus`]: {
          outline: 'none',
        },
      }}
    />
  );
}
