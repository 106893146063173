import { Button, Dialog, DialogContent, Typography } from '@mui/material';

interface MeetingRequiredInputAlertDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function MeetingRequiredInputAlertDialog({ open, onClose }: MeetingRequiredInputAlertDialogProps) {
  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: 385 }}>
        <Typography variant="body1" textAlign="center">
          총회명을 입력해주세요.
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: 2 }} fullWidth onClick={onClose}>
          확인
        </Button>
      </DialogContent>
    </Dialog>
  );
}
