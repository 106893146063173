import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { searchResidences } from '../../../../api/residence.api';
import { addResidenceToUnion } from '../../../../api/union.api';
import { RequestStatus, Residence, ServerError } from '../../../../models';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  unionId: number;
  onRefresh: () => void;
}

const searchedResidencesTableColumns: string[] = ['No', '도로명주소', '우편번호', '선택'];

const AddResidenceDialog = ({ open, onClose, unionId, onRefresh }: Props) => {
  const theme = useTheme();
  const [searchAddress, setSearchAddress] = useState('');
  const onSearchAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchAddress(event.target.value);
  };
  const [searchedResidences, setSearchedResidences] = useState<Residence[]>([]);
  const [searchRequestStatus, setSearchRequestStatus] = useState(RequestStatus.IDLE);
  const [addRequestStatus, setAddRequestStatus] = useState(RequestStatus.IDLE);
  const [selectedResidence, setSelectedResidence] = useState<Residence>();

  const exit = useCallback(() => {
    setSearchRequestStatus(RequestStatus.IDLE);
    setSearchAddress('');
    setSelectedResidence(undefined);
    setSearchedResidences([]);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (searchRequestStatus === RequestStatus.FAIL) {
      setSearchRequestStatus(RequestStatus.IDLE);
    } else if (searchRequestStatus === RequestStatus.SUCCESS) {
      setSearchRequestStatus(RequestStatus.IDLE);
    }
  }, [searchRequestStatus]);

  const onSelectResidence = useCallback(
    (residence: Residence) => {
      if (selectedResidence?.id === residence.id) {
        setSelectedResidence(undefined);
      } else {
        setSelectedResidence(residence);
      }
    },
    [selectedResidence?.id],
  );

  const onAddressSearch = useCallback(async () => {
    if (searchAddress) {
      setSearchRequestStatus(RequestStatus.REQUESTED);
      const data = await searchResidences(searchAddress);
      if (data instanceof ServerError) {
        setSearchRequestStatus(RequestStatus.FAIL);
        return;
      }
      setSearchRequestStatus(RequestStatus.SUCCESS);
      setSearchedResidences(data.residences);
    }
  }, [searchAddress]);

  const onAdd = useCallback(async () => {
    setAddRequestStatus(RequestStatus.REQUESTED);
    if (selectedResidence) {
      const result = await addResidenceToUnion({ unionId, residenceId: selectedResidence.id });
      if (result instanceof ServerError) {
        setAddRequestStatus(RequestStatus.FAIL);
        return;
      }
      setAddRequestStatus(RequestStatus.SUCCESS);
    }
  }, [selectedResidence, unionId]);

  return (
    <Dialog open={open} onClose={exit} fullWidth maxWidth="md">
      <DialogTitle variant="h2">
        단지 추가
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={{ position: 'absolute', right: 8, top: 8, color: theme.palette.grey[500] }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            <TextField
              label="주소"
              autoComplete="off"
              value={searchAddress}
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1, minWidth: '260px' }}
              onChange={onSearchAddressChange}
            />
            <Button
              variant="contained"
              onClick={searchRequestStatus === RequestStatus.IDLE ? onAddressSearch : undefined}
            >
              {searchRequestStatus === RequestStatus.REQUESTED ? <CircularProgress size="24px" /> : '검색'}
            </Button>
          </Stack>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ background: '#DDDDDD' }}>
                  <TableCell align="center">
                    <b>{searchedResidencesTableColumns[0]}</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>{searchedResidencesTableColumns[1]}</b>
                  </TableCell>
                  <TableCell align="center" width="120px">
                    <b>{searchedResidencesTableColumns[2]}</b>
                  </TableCell>
                  <TableCell align="center" width="80px">
                    <b>{searchedResidencesTableColumns[3]}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedResidences.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={searchedResidencesTableColumns.length}>
                      <Typography variant="subtitle1" color={theme.palette.grey[600]} textAlign="center">
                        내용이 없습니다.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {searchedResidences.map((item, idx) => (
                  <TableRow key={`sr-item-${item.id}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="center" component="th" scope="row">
                      {idx + 1}
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Typography>{item.fullRoadAddress}</Typography>
                        <Typography>{`(지번) ${item.jibunAddress}`}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="center">{item.zipCode}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        checked={selectedResidence?.id === item.id}
                        onChange={() => onSelectResidence(item)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color={selectedResidence ? 'primary' : 'inherit'} variant="contained" fullWidth onClick={onAdd}>
          {selectedResidence ? '단지 추가하기' : '단지를 선택 해주세요'}
        </Button>
      </DialogActions>
      <SuccessErrorModal
        isSuccess={addRequestStatus === RequestStatus.SUCCESS}
        title="단지 추가"
        description={
          addRequestStatus === RequestStatus.SUCCESS
            ? '단지가 성공적으로 추가 되었습니다.'
            : '단지 추가 중 에러가 발생했습니다.'
        }
        open={addRequestStatus === RequestStatus.SUCCESS || addRequestStatus === RequestStatus.FAIL}
        onClose={() => {
          if (addRequestStatus === RequestStatus.SUCCESS) {
            onRefresh();
            exit();
          }
          setAddRequestStatus(RequestStatus.IDLE);
        }}
      />
    </Dialog>
  );
};

export default AddResidenceDialog;
