/* eslint-disable react/no-array-index-key */
import { Badge, Button, Divider, Stack, Switch, TextField, Typography, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { MutableRefObject, createRef, useCallback, useState } from 'react';
import { array, object, string } from 'yup';
import { PollCreateProps } from '../../../../models';

type OptionType = {
  title: string;
  file?: File;
  fileRef: MutableRefObject<HTMLInputElement | null>;
};
type PollViewProps = {
  setPoll: (props: (PollCreateProps & { pollOptionFiles: File[] }) | undefined) => void;
  onCancel: () => void;
};
const PollView = ({ onCancel, setPoll }: PollViewProps) => {
  const theme = useTheme();
  const [pollDatePickerOpen, setPollDatePickerOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [multiple, setMultiple] = useState(false);
  const [endsAt, setEndsAt] = useState<Date>();
  const [options, setOptions] = useState<OptionType[]>([
    { title: '', fileRef: createRef() },
    { title: '', fileRef: createRef() },
  ]);

  const onTitleChange = (idx: number, newTitle: string) => {
    const newOptions = [...options];
    const option = newOptions[idx];
    if (option) {
      option.title = newTitle;
      setOptions(newOptions);
    }
  };
  const addOption = useCallback(async () => {
    setOptions([...options, { title: '', fileRef: createRef() }]);
  }, [options]);
  const removeFile = useCallback(
    async (idx: number) => {
      const newOptions = [...options];
      const option = newOptions[idx];
      if (option) {
        option.file = undefined;
        setOptions(newOptions);
      }
    },
    [options],
  );
  const deleteOption = async (idx: number) => {
    setOptions(options.filter((_, jdx) => jdx !== idx));
  };
  const onFileChange = useCallback(
    (idx: number, file: File | undefined) => {
      if (file) {
        const newOptions = [...options];
        const option = newOptions[idx];
        if (option) {
          option.file = file;
          setOptions(newOptions);
        }
      }
    },
    [options],
  );

  const onClose = useCallback(() => {
    setOptions([
      { title: '', fileRef: createRef() },
      { title: '', fileRef: createRef() },
    ]);
    setMultiple(false);
    onCancel();
  }, [onCancel]);

  const onSave = useCallback(() => {
    const validate = async () => {
      await array(
        object({
          title: string().required().min(1),
        }),
      )
        .validate(options)
        .then(() => {
          if (!endsAt || !dayjs(endsAt).isBetween(dayjs().add(3, 'hours'), dayjs().add(14, 'days'))) {
            setErrMsg('종료시간을 선택해주세요.');
            return;
          }
          const pollOptionFiles: File[] = [];
          const opts: { content: string; fileIndex?: number }[] = [];
          options.forEach((item) => {
            if (item.file) {
              opts.push({ content: item.title, fileIndex: pollOptionFiles.length });
              pollOptionFiles.push(item.file);
            } else {
              opts.push({ content: item.title });
            }
          });
          setPoll({
            poll: {
              isMultiSelect: multiple,
              endsAt,
            },
            options: opts,
            pollOptionFiles,
          });
          onCancel();
        })
        .catch(() => {
          setErrMsg('각 항목의 텍스트를 입력해주세요');
        });
    };
    validate();
  }, [endsAt, multiple, onCancel, options, setPoll]);

  return (
    <Stack spacing={4}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography>다수항목 선택</Typography>
        <Switch value={multiple} onChange={(e) => setMultiple(Boolean(e.target.value))} />

        <DateTimePicker
          open={pollDatePickerOpen}
          onOpen={() => setPollDatePickerOpen(true)}
          onClose={() => setPollDatePickerOpen(false)}
          label="종료시간"
          ampm={false}
          format="YYYY-MM-DD HH:mm"
          slotProps={{ textField:
            {
              error: false,
              inputProps: { readOnly: true },
              InputLabelProps: { sx: { color: theme.palette.grey[500] } },
              onClick: () => setPollDatePickerOpen(true),
              sx: {
                '& .MuiOutlinedInput-input':
                {
                  visibility: endsAt ? 'visible' : 'hidden',
                },
              },
            },
          }}
          onChange={(e) => setEndsAt((e as Dayjs).toDate())}
          minDateTime={dayjs().add(4, 'hours')}
          maxDateTime={dayjs().add(14, 'days')}
        />

      </Stack>
      <Stack spacing={2}>
        {options.map((option, idx) => (
          <Stack direction="row" key={`poll-option-${idx}`} spacing={2} alignItems="center">
            <TextField
              value={option.title}
              label={`항목 ${idx + 1}`}
              autoComplete="off"
              onChange={(e) => onTitleChange(idx, e.target.value)}
              sx={{ width: 320 }}
            />
            {option.file && (
              <Badge
                badgeContent={
                  <Button
                    variant="outlined"
                    fullWidth={false}
                    sx={{ minWidth: '20px', height: '20px', p: '4px', background: 'white' }}
                    onClick={() => removeFile(idx)}
                  >
                    X
                  </Button>
                }
                color="default"
              >
                <img height={50} width={50} src={URL.createObjectURL(option.file)} alt="" />
              </Badge>
            )}
            {!option.file && (
              <>
                <Button onClick={() => option.fileRef.current?.click()}>이미지</Button>
                <input
                  type="file"
                  onChange={(e) => onFileChange(idx, e.target.files ? e.target.files[0] : undefined)}
                  ref={option.fileRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                />
              </>
            )}

            {idx > 1 && (
              <Button color="error" onClick={() => deleteOption(idx)}>
                삭제
              </Button>
            )}
          </Stack>
        ))}
      </Stack>
      {options.length < 8 && (
        <Button variant="outlined" onClick={addOption} size="small">
          옵션 추가
        </Button>
      )}
      {errMsg && <Typography color="error">{errMsg}</Typography>}
      <Divider />
      <Stack direction="row" spacing={3}>
        <Button variant="outlined" color="error" onClick={onClose}>
          취소
        </Button>
        <Button variant="contained" sx={{ width: 200 }} onClick={onSave}>
          확인
        </Button>
      </Stack>
    </Stack>
  );
};

export default PollView;
