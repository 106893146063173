type InputType = {
  code: number;
  msg: string;
};

export class ServerError extends Error {
  public code: number;

  public msg: string;

  constructor(input: InputType) {
    const { code, msg } = input;
    super(msg);
    this.code = code;
    this.msg = msg;
  }
}

export enum RequestStatus {
  IDLE = 'idle',
  REQUESTED = 'requested',
  FAIL = 'failed',
  SUCCESS = 'success',
}
export enum FileType {
  POST = 'post',
  POST_REPLY = 'postReply',
  NOTICE = 'notice',
  INQUIRY = 'inquiry',
  PROFILE = 'profile',
  POLL_OPTION = 'pollOption',
  CUSTOMER_PROFILE = 'customerProfile',
  LIBRARY = 'library',
}

export type { default as Staff } from './staff.model';
export type { default as StaffRole } from './staff-role.model';
export type { default as Customer } from './customer.model';
export { GenderType } from './customer.model';
export type { default as CustomerSocial } from './customer-social.model';
export { OAuthServiceProvider } from './customer-social.model';
export type { default as CustomerProfile } from './customer-profile.model';
export { StaffRoleType as StaffRoleTitle } from './staff-role.model';

export type { default as Union } from './union.model';
export { UnionConstructionType, UnionStage } from './union.model';
export type { default as UnionOperator } from './union-operator.model';
export { UnionOperatorType } from './union-operator.model';
export type { default as Residence } from './residence.model';
export type { default as UnionMember } from './union-member.model';
export { UnionMemberGrade, UnionMemberType } from './union-member.model';
export type { default as Notice } from './notice.model';
export type { default as PostWall } from './post-wall.model';
export { PostWallType } from './post-wall.model';
export type { default as Post } from './post.model';
export type { default as PostFile } from './post-file.model';
export type { default as PollOptionFile } from './poll-option-file.model';
export type { default as Poll, PollCreateProps } from './poll.model';
export type { default as Library } from './library.model';
export type { default as LibraryCategory } from './library-category.model';
export { LibraryCategoryType } from './library-category.model';
export type { default as LibraryFile } from './library-file.model';
export type { default as PostReply } from './post-reply.model';
export type { default as Community } from './community.model';
export type { default as Terms } from './terms.model';
export { TermsType } from './terms.model';
