import ResidenceModel from './residence.model';
import StaffModel from './staff.model';
import UnionOperatorModel from './union-operator.model';

export default interface UnionModel {
  id: number;
  serialNumber: string;
  name: string;
  phone: string;
  stage: UnionStage;
  constructionType: UnionConstructionType;

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;

  operators?: StaffModel[];
  residences?: ResidenceModel[];
  // unionMembers?: UnionMemberModel[];
  unionOperator?: UnionOperatorModel;
  kevotingRegistrationDate: Date | null;

  phoneForMessage?: string;
  businessRegistrationNumber: string;
  representativePhone: string;
  representativeAddress: string;
  officeAddress: string;
}
export enum UnionStage {
  PROMOTION_COMMITTEE = '추진위원회',
  UNION_CREATION = '조합 설립인가',
  EXECUTION = '사업시행인가',
  DISPOSAL = '관리처분인가',
  DEMOLITON = '철거신고',
  CONSTRUCTION = '착공신고',
  SALES = '일반분양승인',
  CONSTRUCTION_COMPLETED = '준공인가',
  MOVE = '이전고시',
  UNION_DISSOLUTION = '조합해산',
  UNION_LIQUIDATION = '조합청산',
  ETC = '기타',
}

export enum UnionConstructionType {
  REDEVELOPMENT = '재개발',
  RECONSTRUCTION = '재건축',
  REMODELING = '리모델링',
}
