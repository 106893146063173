import { Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { updateUnionMemberGrade } from '../../../../api/union.api';
import { TransitionUp } from '../../../../hooks';
import { RequestStatus, UnionMember, UnionMemberGrade } from '../../../../models';

interface MemberGradeModificationDialogProps {
  open: boolean;
  selectedMember?: UnionMember;
  onClose: () => void;
  onSuccess: () => void;
}

export default function MemberGradeModificationDialog(props: MemberGradeModificationDialogProps) {
  const { open, selectedMember, onClose, onSuccess } = props;
  const theme = useTheme();

  const [grade, setGrade] = useState<UnionMemberGrade | ''>('');
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);

  const isModifyButtonDisabled = useMemo(() => {
    if (requestStatus === RequestStatus.REQUESTED) {
      return true;
    }
    return false;
  }, [requestStatus]);

  const isToastOpen = useMemo(() => {
    if (requestStatus === RequestStatus.FAIL) {
      return true;
    }
    return false;
  }, [requestStatus]);

  const onGradeChange = useCallback((event: SelectChangeEvent) => {
    setGrade(event.target.value as UnionMemberGrade);
  }, []);

  const requestUpdateMemberGrade = useCallback(async () => {
    if (grade === '' || !selectedMember) {
      return;
    }

    setRequestStatus(RequestStatus.REQUESTED);
    try {
      const response = await updateUnionMemberGrade(selectedMember.unionId, selectedMember.id, grade);
      if (response === 200) {
        setRequestStatus(RequestStatus.SUCCESS);
        onSuccess();
      }
    } catch (error) {
      setRequestStatus(RequestStatus.FAIL);
    }
  }, [grade, onSuccess, selectedMember]);

  useEffect(() => {
    if (!selectedMember) {
      return;
    }
    setGrade(selectedMember.grade);
  }, [selectedMember]);

  return (
    <Dialog open={open}>
      <DialogTitle variant="h2">
        구성원 등급 변경
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: '517px' }}>
        <Stack direction="row" spacing={3} alignItems="baseline">
          <Stack alignItems="start" spacing={1} minWidth="140px" sx={{ lineHeight: '24px' }}>
            <Typography variant="subtitle2">고유 번호</Typography>
            <Typography variant="subtitle2">이름</Typography>
            <Typography variant="subtitle2">생년월일/사업자번호</Typography>
            <Typography variant="subtitle2">기존 등급</Typography>
          </Stack>
          <Stack alignItems="start" spacing={1} sx={{ lineHeight: '24px' }}>
            <Typography variant="body2">{selectedMember?.serialNumber}</Typography>
            <Typography variant="body2">{selectedMember?.name}</Typography>
            <Typography variant="body2">
              {selectedMember?.partialRrn || selectedMember?.companyRegistrationNumber}
            </Typography>
            <Typography variant="body2" color={theme.palette.primary.main}>
              {selectedMember?.grade}
            </Typography>
          </Stack>
        </Stack>

        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
        <Typography variant="body2" mb={1}>
          변경할 등급
        </Typography>
        <FormControl required fullWidth>
          <Select value={grade} onChange={onGradeChange} displayEmpty>
            <MenuItem value="">
              <em>선택안함</em>
            </MenuItem>
            {Object.values(UnionMemberGrade).map((val) => (
              <MenuItem key={`member-grade-option-${val}`} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={requestUpdateMemberGrade}
          disabled={isModifyButtonDisabled}
        >
          수정하기
        </Button>
      </DialogActions>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={TransitionUp}
        onClose={() => {
          setRequestStatus(RequestStatus.IDLE);
        }}
      >
        <Alert
          icon={false}
          sx={{
            width: '342px',
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.common.white,
            borderRadius: 1,
          }}
        >
          저장을 시도하는 중 실패했습니다.
        </Alert>
      </Snackbar>
    </Dialog>
  );
}
