import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { ValidationError, mixed, object, string } from 'yup';
import { makeUnion } from '../../api/union.api';
import { RequestStatus, ServerError, UnionConstructionType, UnionStage } from '../../models';
import SuccessErrorModal from '../common/dialog/success-error-dialog';

const UnionCreation = () => {
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [errMsg, setErrMsg] = useState('');

  const [name, setName] = useState('');
  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const [serialNumber, setSerialNumber] = useState('');
  const onSerialNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(e.target.value);
  };
  const [representativePhone, setRepresentativePhone] = useState('');
  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRepresentativePhone(e.target.value);
  };
  const [representativeAddress, setRepresentativeAddress] = useState('');
  const onRepresentativeAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRepresentativeAddress(e.target.value);
  };
  const [constructionType, setConstructionType] = useState<UnionConstructionType | ''>('');
  const onConstructionTypeChange = (event: SelectChangeEvent) => {
    setConstructionType(event.target.value as UnionConstructionType);
  };
  const [stage, setStage] = useState<UnionStage | ''>('');
  const onStageChange = (event: SelectChangeEvent) => {
    setStage(event.target.value as UnionStage);
  };
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('');
  const onRegisterNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBusinessRegistrationNumber(e.target.value);
  };
  const [phoneForMessage, setPhoneForeMessage] = useState('');
  const onMsgPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneForeMessage(e.target.value);
  };
  const [officeAddress, setOfficeAddress] = useState('');
  const onOfficeAddress = (e: ChangeEvent<HTMLInputElement>) => {
    setOfficeAddress(e.target.value);
  };

  const clearFields = () => {
    setName('');
    setRepresentativeAddress('');
    setStage('');
    setConstructionType('');
    setSerialNumber('');
    setRepresentativePhone('');
    setBusinessRegistrationNumber('');
    setPhoneForeMessage('');
    setOfficeAddress('');
  };

  const hyphenRemovedSerialNumber = useMemo(() => serialNumber.replace('/-/g', ''), [serialNumber]);
  const hyphenRemovedBusinessRegistrationNumber = useMemo(() => businessRegistrationNumber.replace('/-/g', ''), [businessRegistrationNumber]);
  const onCreateUnion = async () => {
    try {
      const inputValue = await object()
        .shape({
          name: string().required('사업장명을 입력해주세요').min(1),
          representativePhone: string().required('대표 연락처를 입력해주세요'),
          serialNumber: string().min(10, '고유번호는 10자리여야 합니다').required('고유번호를 입력해주세요'),
          representativeAddress: string().min(1).required('대표단지를 입력해주세요'),
          phoneForMessage: string().max(11).optional(),
          businessRegistrationNumber: string().min(10, '사업자등록번호는 10자리여야 합니다').required('사업자등록번호를 입력해주세요'),
          officeAddress: string().required('사무실 주소를 입력해주세요'),
          constructionType: mixed<UnionConstructionType>()
            .oneOf(Object.values(UnionConstructionType), '사업형태를 선택해주세요')
            .typeError('사업형태를 선택해주세요')
            .required('사업형태를 선택해주세요'),
          stage: mixed<UnionStage>()
            .oneOf(Object.values(UnionStage), '사업단계를 선택해주세요')
            .typeError('사업단계를 선택해주세요')
            .required('사업단계를 선택해주세요'),
        })
        .validate({
          name,
          representativePhone,
          serialNumber: hyphenRemovedSerialNumber,
          representativeAddress,
          phoneForMessage,
          businessRegistrationNumber: hyphenRemovedBusinessRegistrationNumber,
          officeAddress,
          constructionType,
          stage,
        });

      await makeUnion(inputValue);
      setRequestStatus(RequestStatus.SUCCESS);
      navigate('/internal/home', { replace: true });
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrMsg(error.errors[0]);
      } else if (error instanceof ServerError) {
        setRequestStatus(RequestStatus.FAIL);
        setErrMsg('서버에러가 발생했습니다');
      }
    }
  };

  useEffect(() => {
    if (errMsg) {
      setErrMsg('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, serialNumber, representativePhone]);

  useEffect(() => {
    if (requestStatus === RequestStatus.SUCCESS) {
      clearFields();
    }
  }, [requestStatus]);

  return (
    <Paper>
      <Stack p={3} alignItems="center" spacing={4} maxWidth={1200}>
        <Typography variant="h1" alignSelf="start">사업장 추가</Typography>
        <Stack direction="row" spacing={2} width="100%">
          <TextField
            name="orgName"
            label="사업장명"
            value={name}
            placeholder="개포주공6,7단지아파트 재건축정비사업조합"
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            onChange={onNameChange}
            sx={{ flex: 1 }}
          />
          <TextField
            name="orgRepresentativeAddress"
            label="대표단지"
            value={representativeAddress}
            placeholder="개포동 185"
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            onChange={onRepresentativeAddressChange}
            sx={{ flex: 1 }}
          />
        </Stack>
        <Stack direction="row" spacing={2} width="100%">
          <FormControl sx={{ flex: 1 }}>
            <InputLabel id="construction-type-select-label">사업형태</InputLabel>
            <Select
              label="사업형태"
              value={constructionType}
              onChange={onConstructionTypeChange}
              labelId="construction-type-select-label"
            >
              <MenuItem value="">
                <em>선택안함</em>
              </MenuItem>
              {Object.values(UnionConstructionType).map((val) => (
                <MenuItem key={`construction-option-${val}`} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel id="stage-select-label">사업단계</InputLabel>
            <Select label="사업단계" value={stage} onChange={onStageChange} labelId="stage-select-label">
              <MenuItem value="">
                <em>선택안함</em>
              </MenuItem>
              {Object.values(UnionStage).map((val) => (
                <MenuItem key={`stage-option-${val}`} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2} width="100%">
          <PatternFormat
            format="###-##-#####"
            customInput={TextField}
            name="serialNumber"
            label="고유번호"
            value={serialNumber}
            placeholder="고유번호 10자리"
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            onChange={onSerialNumberChange}
            sx={{ flex: 1 }}
          />
          <PatternFormat
            format="###-##-#####"
            customInput={TextField}
            name="businessRegistrationNumber"
            label="사업자등록번호"
            value={businessRegistrationNumber}
            placeholder="사업자등록번호 10자리"
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            onChange={onRegisterNumberChange}
            sx={{ flex: 1 }}
          />
        </Stack>
        <Stack direction="row" spacing={2} width="100%">
          <PatternFormat
            format="###########"
            customInput={TextField}
            name="phoneForMessage"
            label="메시지 발송 전화번호 (선택)"
            value={phoneForMessage}
            placeholder="연락처 최대 11자리"
            autoComplete="off"
            onChange={onMsgPhoneChange}
            InputLabelProps={{ shrink: true }}
            sx={{ flex: 1 }}
          />
          <PatternFormat
            format="###########"
            customInput={TextField}
            name="representativePhone"
            value={representativePhone}
            placeholder="00000000000"
            autoComplete="off"
            onChange={onPhoneChange}
            label="대표 연락처"
            InputLabelProps={{ shrink: true }}
            sx={{ flex: 1 }}
          />
        </Stack>
        <TextField
          name="officeAddress"
          label="사무실 주소"
          value={officeAddress}
          placeholder="서울특별시 강남구 개포동 185 지하 1층"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          onChange={onOfficeAddress}
          fullWidth
          sx={{ flex: 1 }}
        />
        {errMsg && <Typography color="error">{errMsg}</Typography>}
        <Button onClick={onCreateUnion} variant="contained" size="large">
          추가하기
        </Button>
        <SuccessErrorModal
          title="사업장 생성"
          description={
            requestStatus === RequestStatus.SUCCESS ? '사업장이 생성 되었습니다' : '사업장 생성중 서버 오류가 발생했습니다'
          }
          open={requestStatus === RequestStatus.SUCCESS || requestStatus === RequestStatus.FAIL}
          onClose={() => {
            setRequestStatus(RequestStatus.IDLE);
          }}
          isSuccess={requestStatus === RequestStatus.SUCCESS}
        />
      </Stack>
    </Paper>
  );
};

export default UnionCreation;
