import { Button, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridTreeNodeWithRender, gridClasses } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import { GeneralMeetingModel, GeneralMeetingStatus } from '../../../../../models/general-meeting.model';
import { convertDateFormat24H } from '../../../../../util/functions';
import GeneralMeetingStatusTag from './general-meeting-status-tag';

interface GeneralMeetingTableProps {
  rows: GeneralMeetingModel[];
  onDelete: (meeting: GeneralMeetingModel) => void;
  onRowClick: (selectedRowId: number) => void;
}

export default function GeneralMeetingTable({ rows, onDelete, onRowClick }: GeneralMeetingTableProps) {
  const theme = useTheme();

  const renderActionCell = useCallback(
    (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
      if (params.row.status === GeneralMeetingStatus.INIT) {
        return (
          <Button
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(params.row);
            }}
          >
            삭제하기
          </Button>
        );
      }
      return undefined;
    },
    [onDelete],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'title', headerName: '총회명', sortable: false, align: 'left', flex: 1 },
      {
        field: 'holdAt',
        headerName: '총회 일시',
        sortable: false,
        align: 'left',
        flex: 1,
        valueFormatter: (params) => (params.value ? convertDateFormat24H(params.value) : '-'),
      },
      {
        field: 'status',
        headerName: '상태',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        minWidth: 90,
        renderCell: (params) => <GeneralMeetingStatusTag generalMeetingStatus={params.value} />,
      },
      {
        field: 'action',
        headerName: '',
        sortable: false,
        align: 'center',
        flex: 0.6,
        renderCell: (params) => renderActionCell(params),
      },
    ],
    [renderActionCell],
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      onRowClick={(row) => onRowClick(Number(row.id))}
      disableColumnMenu
      hideFooterPagination={rows.length === 0}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10]}
      localeText={{ noRowsLabel: '총회 정보가 없습니다.' }}
      sx={{
        [`& .${gridClasses.columnHeader}`]: {
          backgroundColor: theme.palette.primary.light,
          color: 'black',
          typography: theme.typography.subtitle2,
        },
        [`& .${gridClasses.cellContent}`]: {
          typography: theme.typography.body2,
        },
        [`& .${gridClasses.cell}:focus`]: {
          outline: 'none',
        },
      }}
    />
  );
}
