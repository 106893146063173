import { Edit } from '@mui/icons-material';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { GeneralMeetingModel, GeneralMeetingQueryStringValueEnum, GeneralMeetingStatus } from '../../../../../models/general-meeting.model';
import { convertDateFormat12H } from '../../../../../util/functions';

interface MeetingInfoProps extends GeneralMeetingModel {
  changeMeetingStatusToInit: () => void;
  onPageMove?: (
    eventTypeValue: GeneralMeetingQueryStringValueEnum,
    eventActionValue: GeneralMeetingQueryStringValueEnum,
    targetIdValue?: number,
  ) => void;
}
export default function MeetingInfo(props: MeetingInfoProps) {
  const {
    changeMeetingStatusToInit,
    onPageMove,
    ...meetingInfo
  } = props;
  const theme = useTheme();

  const handleEditMeetingInfo = useCallback(() => {
    if (meetingInfo.status === GeneralMeetingStatus.CONFIRMED) {
      changeMeetingStatusToInit();
    }
    if (onPageMove) {
      onPageMove(GeneralMeetingQueryStringValueEnum.MEETING, GeneralMeetingQueryStringValueEnum.EDIT, meetingInfo.id);
    }
  }, [meetingInfo.status, meetingInfo.id, onPageMove, changeMeetingStatusToInit]);

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={2} sx={{ alignSelf: 'flex-start', alignItems: 'center' }}>
        <Typography variant="h2">총회 정보</Typography>
        {(meetingInfo.status === GeneralMeetingStatus.CONFIRMED ||
          meetingInfo.status === GeneralMeetingStatus.INIT) &&
          (
            <IconButton
              aria-label="edit"
              size="small"
              onClick={handleEditMeetingInfo}
            >
              <Edit fontSize="medium" />
            </IconButton>
          )}
      </Stack>
      <Stack direction="column" spacing={5} padding={5} sx={{ border: `1px solid ${theme.palette.grey[500]}` }}>
        <Stack direction="row" useFlexGap flexWrap="wrap">
          <Stack direction="column" spacing={0.5} alignItems="flex-start" sx={{ flex: 1 }}>
            <Typography variant="subtitle2" style={{ marginBottom: 12, color: theme.palette.primary.main }}>총회 장소</Typography>
            {(meetingInfo.locationName && meetingInfo.address) ?
              (
                <>
                  <Typography textAlign="start" variant="subtitle2">{meetingInfo.locationName}</Typography>
                  <Typography textAlign="start" variant="body2">{`${meetingInfo.address} ${meetingInfo.addressDetail ?? ''}`}</Typography>
                </>
              ) : (<Typography color={theme.palette.grey[500]} variant="subtitle2">장소명을 입력하지 않았습니다.</Typography>)}
          </Stack>
          <Stack direction="column" spacing={0.5} alignItems="flex-start" sx={{ flex: 1 }}>
            <Typography variant="subtitle2" style={{ marginBottom: 12, color: theme.palette.primary.main }}>총회 일시</Typography>
            {meetingInfo.holdAt ?
              (<Typography variant="subtitle2">{convertDateFormat12H(meetingInfo.holdAt)}</Typography>)
              : (<Typography color={theme.palette.grey[500]} variant="subtitle2">총회 일시를 설정하지 않았습니다.</Typography>)}
          </Stack>
        </Stack>

        <Stack direction="row" useFlexGap flexWrap="wrap">
          <Stack direction="column" spacing={0.5} alignItems="flex-start" sx={{ flex: 1 }}>
            <Typography variant="subtitle2" style={{ marginBottom: 12, color: theme.palette.primary.main }}>총회 화면 게시일</Typography>
            {meetingInfo.publishAt ?
              (<Typography variant="subtitle2">{convertDateFormat12H(meetingInfo.publishAt)}</Typography>)
              : (<Typography color={theme.palette.grey[500]} variant="subtitle2">게시일을 설정하지 않았습니다.</Typography>)}
          </Stack>
          <Stack direction="column" spacing={0.5} alignItems="flex-start" sx={{ flex: 1 }}>
            <Typography variant="subtitle2" style={{ marginBottom: 12, color: theme.palette.primary.main }}>총회 공고문 첨부 파일</Typography>
            {meetingInfo.announcementFile ?
              (<Typography variant="subtitle2">{meetingInfo.announcementFile?.originalName}</Typography>)
              : (<Typography color={theme.palette.grey[500]} variant="subtitle2">파일을 등록하지 않았습니다.</Typography>)}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
