import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { cloneDeep, find, isEmpty, parseInt, some } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { requestAgendaVoteResultSave } from '../../../../../../api/general-meeting.api';
import {
  AgendaVoteResultRequestBodyModel,
  QuestionOptionModel,
  ResultConverterDict,
  VoteOptionPresetType,
} from '../../../../../../models/agenda-vote-question.model';
import { AgendaModel } from '../../../../../../models/general-meeting.model';

interface VoteResultInputDialogProps {
  generalMeetingId: string | undefined;
  originAgenda: AgendaModel;
  open: boolean;
  onCancel: () => void;
  onSave: (newAgenda: AgendaModel) => void;
}

const unSelectId: number = -10;
const otherId: number = -1;
const replaceDicForAgainstResult: ResultConverterDict = { 찬성: '가결', 반대: '부결' };

const VoteResultInputDialog = ({
  generalMeetingId,
  originAgenda,
  open,
  onCancel,
  onSave,
}: VoteResultInputDialogProps) => {
  const theme = useTheme();

  const [agenda, setAgenda] = useState<AgendaModel>();
  const [selectedVoteResult, setSelectedVoteResult] = useState<QuestionOptionModel>({
    id: unSelectId,
    questionId: unSelectId,
    order: unSelectId,
    description: '선택 안함',
    result: {
      optionId: unSelectId,
      quantity: 0,
    },
  });
  const [otherReasonInput, setOtherReasonInput] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const cancelButtonText = useMemo(() => (originAgenda.question?.result ? '닫기' : '취소'), [originAgenda]);

  const saveButtonText = useMemo(() => {
    if (originAgenda.question?.result) {
      return isEditMode ? '변경 저장하기' : '수정하기';
    }
    return '저장';
  }, [isEditMode, originAgenda.question?.result]);

  const isSaveButtonClickable = useMemo(() => {
    if (
      agenda === undefined ||
      agenda.question === undefined ||
      agenda.question.options === undefined ||
      agenda.question.options.length === 0
    ) {
      return false;
    }

    const results = agenda.question.options.map((option) => option.result);
    const isNullValueExisted = some(results, (r) => r === null);
    if (results === null || results === undefined || results.length === 0 || isNullValueExisted) {
      return false;
    }

    if (selectedVoteResult.id === unSelectId) {
      return false;
    }

    if (selectedVoteResult.id === otherId && otherReasonInput.length === 0) {
      return false;
    }

    return true;
  }, [agenda, otherReasonInput.length, selectedVoteResult.id]);

  const voteResultDescription = useMemo(() => {
    if (selectedVoteResult.id === otherId) {
      return otherReasonInput;
    }

    return selectedVoteResult.description;
  }, [otherReasonInput, selectedVoteResult]);

  const voteResultMenuItem = useMemo(() => {
    let menuItemList: QuestionOptionModel[] = [
      {
        id: unSelectId,
        questionId: unSelectId,
        order: unSelectId,
        description: '선택 안함',
        result: {
          optionId: unSelectId,
          quantity: 0,
        },
      },
    ];

    const optionList = agenda?.question?.options;
    if (optionList) {
      menuItemList = menuItemList.concat(optionList);
    }

    if (agenda?.question?.preset === VoteOptionPresetType.CHOICE) {
      menuItemList = menuItemList.concat([
        {
          id: otherId,
          questionId: agenda.question.id,
          order: agenda.question.options[agenda.question.options.length - 1].order + 1,
          description: '기타',
          result: {
            optionId: otherId,
            quantity: 0,
          },
        },
      ]);
    }

    return menuItemList;
  }, [agenda]);

  useEffect(() => {
    const copiedAgenda = cloneDeep(originAgenda);
    const questionResult = copiedAgenda.question?.result;

    if (questionResult && copiedAgenda.question) {
      if (questionResult.selectedOptionId === otherId) {
        const otherResult: QuestionOptionModel = {
          id: questionResult.selectedOptionId,
          questionId: copiedAgenda.question.id,
          order: copiedAgenda.question.options[copiedAgenda.question.options.length - 1].order + 1,
          description: '기타',
          result: {
            optionId: questionResult.selectedOptionId,
            quantity: 0,
          },
        };
        setOtherReasonInput(questionResult.otherReason);
        setSelectedVoteResult(otherResult);
      } else {
        const optionResult = copiedAgenda.question.options.find(
          (option) => option.id === questionResult.selectedOptionId,
        );
        if (optionResult) {
          setSelectedVoteResult(optionResult);
        }
      }
    }

    setIsEditMode(!questionResult);
    setAgenda(copiedAgenda);
  }, [originAgenda]);

  const handleCountedVoteInputChanged = useCallback(
    (optionId: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const isNumeric = /^[0-9]*$/;
      if (!isNumeric.test(inputValue)) {
        return;
      }

      const newAgenda = cloneDeep(agenda);
      if (newAgenda === undefined) {
        return;
      }

      const newOptionList = newAgenda.question?.options.map((option) => {
        if (option.id === optionId) {
          const newOption = { ...option };
          if (newOption.result === null) {
            newOption.result = {
              optionId,
              quantity: Number(inputValue),
            };
          } else {
            newOption.result.quantity = Number(inputValue);
          }
          return newOption;
        }
        return option;
      });
      if (newAgenda.question !== undefined && newOptionList !== undefined) {
        newAgenda.question.options = newOptionList;
      }
      setAgenda(newAgenda);
    },
    [agenda],
  );

  const handleMenuItemChange = useCallback(
    (event: SelectChangeEvent) => {
      const { value } = event.target;
      const foundMenuItem = find(voteResultMenuItem, (item) => item.description === value);
      if (foundMenuItem === undefined) {
        return;
      }
      setSelectedVoteResult(foundMenuItem);
    },
    [voteResultMenuItem],
  );

  const handleOtherReasonChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtherReasonInput(value);
  }, []);

  const onSaveButtonClick = useCallback(async () => {
    if (agenda === undefined || agenda.question === undefined || generalMeetingId === undefined) {
      return;
    }

    if (agenda.question.result) {
      if (!isEditMode) {
        setIsEditMode(true);
        return;
      }
    }

    const selectedOptionId = selectedVoteResult.id;
    const otherReason = selectedOptionId === -1 ? otherReasonInput : selectedVoteResult.description;
    const optionResults = agenda.question.options
      .filter((option) => option.result !== null)
      .map((option) => option.result!!);
    if ((selectedOptionId === otherId && isEmpty(otherReason)) || isEmpty(optionResults) || optionResults === null) {
      return;
    }
    const voteResult: AgendaVoteResultRequestBodyModel = {
      selectedOptionId,
      otherReason,
      optionResults,
    };

    const meetingId = parseInt(generalMeetingId);
    const responseStatus = await requestAgendaVoteResultSave(meetingId, originAgenda.id, voteResult);
    if (responseStatus === 200) {
      agenda.question.result = {
        selectedOptionId,
        otherReason,
      };
      onSave(agenda);
    }
  }, [agenda, generalMeetingId, isEditMode, onSave, originAgenda.id, otherReasonInput, selectedVoteResult]);

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle variant="h2" sx={{ width: 603 }}>
        결과 입력
        <IconButton
          aria-label="close"
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          onClick={onCancel}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={2}>
            <Typography
              variant="body2"
              color={theme.palette.grey[900]}
              textAlign="center"
              bgcolor={theme.palette.grey[200]}
              borderRadius={999}
              padding="8px 16px"
              alignSelf="center"
            >
              {`제 ${agenda?.order}호`}
            </Typography>
            <Typography variant="subtitle2" color={theme.palette.grey[800]} textAlign="center">
              {agenda?.title}
            </Typography>
          </Stack>
          {agenda?.question?.options.map((option) => (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} key={option.id}>
              <Typography variant="body2" color={theme.palette.grey[900]}>
                {option.description}
              </Typography>
              <TextField
                style={{
                  minWidth: 64,
                  maxWidth: 64,
                  textAlign: 'center',
                  borderRadius: 8,
                  color: theme.palette.grey[800],
                  backgroundColor: isEditMode ? 'transparent' : theme.palette.grey[300],
                }}
                value={option.result?.quantity ?? 0}
                onChange={handleCountedVoteInputChanged(option.id)}
                disabled={!isEditMode}
              />
            </Stack>
          ))}
          <Stack alignSelf="center" width="100%">
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
              <Typography variant="subtitle2" color={theme.palette.grey[800]} minWidth="64px">
                투표 결과:
              </Typography>
              <Select
                style={{
                  minWidth: 148,
                  color: theme.palette.grey[800],
                  backgroundColor: isEditMode ? 'transparent' : theme.palette.grey[300],
                }}
                value={selectedVoteResult.description}
                onChange={handleMenuItemChange}
              >
                {voteResultMenuItem.map((menuItem) => (
                  <MenuItem
                    key={menuItem.id}
                    style={{
                      color: theme.palette.grey[800],
                      backgroundColor: isEditMode ? 'transparent' : theme.palette.grey[300],
                    }}
                    value={menuItem.description}
                    disabled={!isEditMode}
                  >
                    <Typography noWrap maxWidth="700px">
                      {replaceDicForAgainstResult[menuItem.description] || menuItem.description}
                    </Typography>
                  </MenuItem>

                ))}
              </Select>
            </Stack>
            {selectedVoteResult.id === otherId && (
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Typography variant="subtitle2" minWidth="64px" textAlign="end" color={theme.palette.grey[800]}>
                  기타
                </Typography>
                <TextField
                  style={{
                    width: 148,
                    marginTop: '12px',
                    marginBottom: '12px',
                    marginLeft: '16px',
                    borderRadius: 8,
                    color: theme.palette.grey[800],
                    backgroundColor: isEditMode ? 'transparent' : theme.palette.grey[300],
                  }}
                  placeholder="예) 과반수 미달"
                  value={otherReasonInput}
                  onChange={handleOtherReasonChange}
                  disabled={!isEditMode}
                />
              </Stack>
            )}
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            justifyContent="center"
            width="100%"
            border={`1px solid ${theme.palette.grey[500]}`}
            padding="10px 16px"
          >
            <Typography variant="body2" color={theme.palette.grey[900]} minWidth="32px">
              결과:
            </Typography>
            <Typography variant="subtitle2" color={theme.palette.grey[900]}>
              {replaceDicForAgainstResult[voteResultDescription] || voteResultDescription}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={2} marginTop={5}>
          <Button variant="contained" color="inherit" size="small" onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!isSaveButtonClickable}
            onClick={onSaveButtonClick}
          >
            {saveButtonText}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VoteResultInputDialog;
