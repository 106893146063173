import { Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { getMessageRequestHistoryList } from '../../../../../api/message.api';
import { getUnionCash } from '../../../../../api/union.api';
import { MessageSendingHistoryModel } from '../../../../../models/message-sending-history.model';
import MessageHistoryTable from './history-table';
import SendingResultDialog from './sending-result-dialog';

interface SendingHistoryPageProps {
  unionId: number
}

export default function SendingHistoryPage({ unionId }: SendingHistoryPageProps) {
  const theme = useTheme();
  const [messageRequestHistory, setMessageRequestHistory] = useState<MessageSendingHistoryModel[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [availableCash, setAvailableCash] = useState(0);

  const fetchAvailableCash = useCallback(async () => {
    try {
      const data = await getUnionCash(unionId);
      setAvailableCash(data);
    } catch (error) {
      // TODO: 에러 처리
    }
  }, [unionId]);

  useEffect(() => {
    fetchAvailableCash();
  }, [fetchAvailableCash]);

  const fetchSendingHistory = useCallback(async () => {
    try {
      const requestHistory = await getMessageRequestHistoryList(unionId);
      setMessageRequestHistory(requestHistory);
    } catch (err) {
      // 에러처리
    }
  }, [unionId]);

  useEffect(() => {
    fetchSendingHistory();
  }, [fetchSendingHistory]);

  return (
    <Stack width="100%" height="1030px" justifyContent="start" spacing={4} py={4}>
      <Typography
        textAlign="start"
        variant="h2"
        sx={{ '& .colored-text': { color: theme.palette.primary.main } }}
      >
        발송 가능 캐시&emsp;<span className="colored-text">{availableCash.toLocaleString('ko-KR')}원</span>
      </Typography>

      <MessageHistoryTable rows={messageRequestHistory} onDownloadClicked={(id) => { setSelectedId(id); }} />

      <SendingResultDialog
        unionId={unionId}
        selectedId={selectedId}
        messageSendingDate={
          messageRequestHistory.find((history) => history.id === selectedId)?.createdAt ?? dayjs().toDate()}
        onClose={() => setSelectedId(undefined)}
      />
    </Stack>
  );
}
