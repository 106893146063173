import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { requestUnionCashTransaction } from '../../../../../api/union.api';
import { UnionCashChargeType, UnionCashTransactionRequestBodyModel } from '../../../../../models/cash.model';

interface ManagementDialogProps {
  unionId: number;
  open: boolean;
  currentCash: number;
  onCancel: () => void;
  onComplete: (transactionType: UnionCashChargeType, balance: number) => void;
}

const ManagementDialog = ({ unionId, open, currentCash, onCancel, onComplete }: ManagementDialogProps) => {
  const theme = useTheme();

  const [transactionType, setTransactionType] = useState<UnionCashChargeType>(UnionCashChargeType.CHARGE);
  const [inputPrice, setInputPrice] = useState<number>();

  const isInputError = useMemo(() => {
    if (inputPrice === undefined || inputPrice < 0) {
      return false;
    }

    if (transactionType === UnionCashChargeType.CHARGE) {
      return false;
    }

    return inputPrice > currentCash;
  }, [currentCash, inputPrice, transactionType]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTransactionType(value as UnionCashChargeType);
  }, []);

  const onInputPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/,/gi, '');
    const isNumeric = /^[0-9]*$/;
    if (!isNumeric.test(inputValue)) {
      return;
    }

    setInputPrice(Number(inputValue));
  };

  const checkTransactionInput = useCallback(async () => {
    if (unionId === undefined) {
      return;
    }

    if (inputPrice === undefined || inputPrice < 0) {
      return;
    }

    if (transactionType === UnionCashChargeType.SUBTRACTION) {
      if (inputPrice > currentCash) {
        return;
      }
    }

    try {
      const requestBodyData: UnionCashTransactionRequestBodyModel = {
        type: transactionType,
        amount: inputPrice,
      };
      const responseData = await requestUnionCashTransaction(unionId, requestBodyData);
      onComplete(transactionType, responseData);
    } catch (error) {
      // TODO: 에러처리
    }
  }, [unionId, inputPrice, transactionType, currentCash, onComplete]);

  useEffect(() => {
    if (!open) {
      setTransactionType(UnionCashChargeType.CHARGE);
      setInputPrice(undefined);
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={false}>
      <DialogTitle sx={{ width: 656 }} variant="h2">
        캐시 관리
        <IconButton
          aria-label="close"
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          onClick={onCancel}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="subtitle1" color={theme.palette.grey[800]}>
            보유 캐시: {currentCash.toLocaleString('ko-KR')}원
          </Typography>
          <RadioGroup row value={transactionType} onChange={handleChange}>
            <FormControlLabel value={UnionCashChargeType.CHARGE} control={<Radio />} label="충전" />
            <FormControlLabel value={UnionCashChargeType.SUBTRACTION} control={<Radio />} label="차감" />
          </RadioGroup>
          <TextField
            error={isInputError}
            sx={{ input: { color: isInputError ? theme.palette.common.red[500] : theme.palette.grey[800] } }}
            InputProps={{
              startAdornment: <InputAdornment position="start">₩</InputAdornment>,
            }}
            variant="outlined"
            fullWidth
            placeholder="금액을 입력하세요."
            helperText={isInputError && '차감할 캐시가 보유 캐시보다 많습니다.'}
            value={inputPrice?.toLocaleString('ko-KR')}
            onChange={onInputPriceChange}
          />
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="contained" color="inherit" onClick={onCancel}>
              취소
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={inputPrice === undefined || inputPrice === 0 || isInputError}
              onClick={checkTransactionInput}
            >
              확인
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ManagementDialog;
