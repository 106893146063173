import { Button, Card, CardContent, CardHeader, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { object, string } from 'yup';
import AppContext from '../../AppContext';
import { login } from '../../api/staff';
import { ServerError } from '../../models';
import { MainLayout } from '../style';

function LoginView() {
  const { setToken } = useContext(AppContext);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [email, setEmail] = useState('');
  const theme = useTheme();
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const [password, setPassword] = useState('');
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const handleLogin = async () => {
    await object()
      .shape({
        email: string().email().required(),
        password: string().min(1).max(24).required(),
      })
      .validate({
        email,
        password,
      })
      .then(async ({ email: e, password: p }) => {
        const data = await login(e, p);
        if (data instanceof ServerError) {
          setShowErrorMessage(true);
        } else {
          setToken(data.token);
        }
      })
      .catch(() => {
        setShowErrorMessage(true);
      });
  };

  return (
    <MainLayout>
      <Card elevation={0} style={{ marginTop: 80, padding: 12, border: `1px solid ${theme.palette.grey[500]}` }}>
        <CardHeader
          title={
            <Stack spacing={3}>
              <Typography variant="h1" color="primary">
                더집
              </Typography>
              <Typography variant="h1">관리자 페이지</Typography>
            </Stack>
          }
        />
        <CardContent>
          <Stack spacing={2} width={{ sm: 400, xs: 320 }} marginTop={4}>
            <TextField
              fullWidth
              value={email}
              onChange={handleEmailChange}
              placeholder="이메일"
              name="aaa"
              autoComplete="off"
            />
            <TextField
              fullWidth
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="비밀번호"
              name="pp"
              autoComplete="off"
            />
            {showErrorMessage && (
              <Typography color="error" variant="body2">
                이메일 또는 비밀번호가 일치하지 않습니다
              </Typography>
            )}
            <Stack width="100%" alignItems="center">
              <Button variant="contained" fullWidth sx={{ maxWidth: '300px', marginTop: '24px' }} onClick={handleLogin}>
                로그인
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </MainLayout>
  );
}

export default LoginView;
