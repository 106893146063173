import { Box, Divider, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { getUnion, getUnionForExternal } from '../../../api/union.api';
import switchIcon from '../../../assets/switch-icon.svg';
import { ServerError, Union } from '../../../models';
import {
  GlobalMenuItems,
  GlobalMenuTabModel,
  getGlobalMenuItemIndexByKeyName,
} from '../../../navigation/global-menu.model';
import { isInternalStaff } from '../../../util/functions';
import TabPanel, { a11yProps } from '../../common/tab-panel';
import Splash from '../../splash';
import GeneralMeetingPage from './general-meeting';
import LibraryPage from './library';
import UnionMemberPage from './member';
import MessagePage from './message';
import NoticePage from './notice';
import UnionOperatorPage from './operator';
import PostPage from './post';
import UnionResidence from './residence';
import UnionDetailInformation from './union-information';
import UnionSwitchDialog from './union-switch-dialog';

const globalMenuItems: GlobalMenuTabModel[] = [
  GlobalMenuItems.INFORMATION,
  GlobalMenuItems.MEMBER,
  GlobalMenuItems.OPERATOR,
  GlobalMenuItems.NOTICE,
  GlobalMenuItems.POST,
  GlobalMenuItems.LIBRARY,
  GlobalMenuItems.GENERAL_MEETING,
  GlobalMenuItems.MESSAGE,
];

export default function UnionDetail() {
  const { staff, myUnions, selectedUnion } = useContext(AppContext);
  const { unionId } = useParams();
  const navigate = useNavigate();
  const [union, setUnion] = useState<Union>();
  const [switchDialogOpen, setSwitchDialogOpen] = useState(false);

  const isInternal = useMemo(() => isInternalStaff(staff!), [staff]);

  const fetchUnion = useCallback(async () => {
    const id = isInternal ? unionId : selectedUnion?.id;
    if (id) {
      const data = isInternal ? await getUnion(`${id}`) : await getUnionForExternal(`${id}`);
      if (data instanceof ServerError) {
        return;
      }
      setUnion(data.union);
    }
  }, [isInternal, selectedUnion?.id, unionId]);

  useEffect(() => {
    fetchUnion();
  }, [fetchUnion]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<number>(Number(searchParams.get('tab')) || 0);
  const theme = useTheme();

  const onRefresh = useCallback(() => {
    fetchUnion();
  }, [fetchUnion]);

  const onTabClick = (tabKeyName: string) => {
    setSearchParams({ tab: tabKeyName });
  };

  useEffect(() => {
    const tabQueryString = searchParams.get('tab');
    const newTabIndex = tabQueryString ? getGlobalMenuItemIndexByKeyName(tabQueryString) : 0;
    setTab(newTabIndex);
  }, [searchParams]);

  useEffect(() => {
    if (!selectedUnion) {
      return;
    }
    if (selectedUnion.id === Number(unionId)) {
      return;
    }
    navigate(`/unions/${selectedUnion.id}?tab=${GlobalMenuItems.INFORMATION.keyName}`, { replace: true });
  }, [navigate, selectedUnion, unionId]);

  return union ? (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Typography align="left" variant="h1" padding={3}>
          {union && union.name}
        </Typography>
        {!isInternal && myUnions && myUnions.length > 1 && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ cursor: 'pointer' }}
              onClick={() => setSwitchDialogOpen(true)}
            >
              <Typography fontSize={18} fontWeight={600} color={theme.palette.grey[600]}>
                사업장 변경
              </Typography>
              <img src={switchIcon} alt="" width={24} height={24} />
            </Stack>
            <UnionSwitchDialog open={switchDialogOpen} onClose={() => setSwitchDialogOpen(false)} />
          </>
        )}
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', background: theme.palette.grey[200] }}>
          <Tabs value={tab} aria-label="tab-label">
            {globalMenuItems.map((menuItem) => (
              <Tab
                key={menuItem.keyName}
                label={menuItem.displayName}
                onClick={() => onTabClick(menuItem.keyName)}
                {...a11yProps(menuItem.index)}
              />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={tab} index={GlobalMenuItems.INFORMATION.index}>
          <Stack spacing={4} p={4}>
            <Stack alignItems="start" spacing={4} maxWidth={1200}>
              <UnionDetailInformation union={union} onRefresh={onRefresh} />
            </Stack>
            <Divider sx={{ background: '#DDDDDD', width: '100%' }} />
            <Stack alignItems="start" spacing={4} maxWidth={1200}>
              <UnionResidence unionId={union.id} residences={union.residences || []} onRefresh={onRefresh} />
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel value={tab} index={GlobalMenuItems.MEMBER.index}>
          <Box p={4}>
            <UnionMemberPage unionId={union.id} unionName={union.name} />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={GlobalMenuItems.OPERATOR.index}>
          <Box p={4}>
            <UnionOperatorPage data={union.operators || []} unionId={union.id} onRefresh={onRefresh} />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={GlobalMenuItems.NOTICE.index}>
          <Box p={4}>
            <NoticePage unionId={union.id} />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={GlobalMenuItems.POST.index}>
          <Box p={4}>
            <PostPage unionId={union.id} />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={GlobalMenuItems.LIBRARY.index}>
          <Box p={4}>
            <LibraryPage unionId={union.id} />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={GlobalMenuItems.GENERAL_MEETING.index}>
          <Box px={4}>
            <GeneralMeetingPage />
          </Box>
        </TabPanel>
        <TabPanel value={tab} index={GlobalMenuItems.MESSAGE.index}>
          <Box px={4}>
            <MessagePage />
          </Box>
        </TabPanel>
      </Box>
    </Stack>
  ) : (
    <Splash />
  );
}
