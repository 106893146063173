import { Stack, TextField, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { MessageSendingType } from '../../../../../models/message-sending.model';
import { sliceWithinMaxBytes } from '../../../../../util/functions';

interface FreeMessageInputProps {
  title?: string;
  content: string;
  messageType: MessageSendingType;
  maxBytes: { title: number, content: number };
  onChangeTitle: (content: string) => void;
  onChangeContent: (content: string) => void;
}
export default function FreeMessageInput(props: FreeMessageInputProps) {
  const { title, content, messageType, maxBytes, onChangeTitle, onChangeContent } = props;

  const theme = useTheme();

  const slicedContents = useMemo(() => sliceWithinMaxBytes(content, maxBytes.content), [content, maxBytes.content]);
  const slicedTitle = useMemo(() => sliceWithinMaxBytes(title ?? '', maxBytes.title), [title, maxBytes.title]);

  return (
    <Stack spacing={3}>
      <Typography textAlign="start" variant="subtitle1">내용</Typography>
      <Stack spacing={1}>
        <TextField
          placeholder={'내용을 입력해주세요.\n90byte가 초과될 경우 LMS로 전환됩니다.'}
          autoComplete="off"
          multiline
          rows={8}
          value={slicedContents}
          onChange={(e) => {
            onChangeContent(e.target.value);
          }}

        />
        <Typography textAlign="start" variant="body3" color={theme.palette.grey[700]}>
          광고성 메시지가 아닌 정보성 메시지만 전송해 주시기 바랍니다. 또한, 부적절한 언어 사용은 서비스 이용에 제한을 받을 수 있습니다.
        </Typography>
      </Stack>
      {messageType === MessageSendingType.LMS && (
        <>
          <Typography textAlign="start" variant="subtitle1" pt={2}>제목</Typography>
          <Stack spacing={1}>
            <TextField
              placeholder="제목을 입력하세요. (선택)"
              autoComplete="off"
              value={slicedTitle}
              onChange={(e) => {
                onChangeTitle(e.target.value);
              }}
            />
            <Typography
              textAlign="start"
              variant="body3"
              color={theme.palette.grey[700]}
            >
              미입력 시 문자 내용 앞 일부가 표시됩니다. (최대 30byte)
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
}
