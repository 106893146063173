import { AgendaVoteResultRequestBodyModel } from '../models/agenda-vote-question.model';
import {
  AgendaModel,
  CreationOrUpdateAgendaModel,
  GeneralMeetingCreateRequestBodyModel,
  GeneralMeetingDetailModel,
  GeneralMeetingModel,
  GeneralMeetingRegisterVoteRequestModel,
  GeneralMeetingRegisterVoteResponseModel,
  GeneralMeetingStatus,
  GeneralMeetingVoteBoxResponseModel,
} from '../models/general-meeting.model';
import { ApiClient, handleError } from '../util/ApiClient';

export const getGeneralMeetings = async (unionId: number) => {
  try {
    const response = await ApiClient().get<GeneralMeetingModel[]>(`/unions/${unionId}/general-meetings`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getDetailGeneralMeeting = async (generalMeetingId: number) => {
  try {
    const response = await ApiClient().get<GeneralMeetingDetailModel>(`/general-meetings/${generalMeetingId}`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestGeneralMeetingCreate = async (requestBody: GeneralMeetingCreateRequestBodyModel) => {
  try {
    const response = await ApiClient().post<GeneralMeetingModel>('/general-meetings', requestBody);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const patchGeneralMeetingInfo = async (requestBody: GeneralMeetingCreateRequestBodyModel) => {
  try {
    const response = await ApiClient().patch<GeneralMeetingModel>(
      `/general-meetings/${requestBody.generalMeetingId}`,
      requestBody,
    );
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteGeneralMeeting = async (generalMeetingId: number) => {
  try {
    await ApiClient().delete(`/general-meetings/${generalMeetingId}`);
  } catch (error) {
    throw handleError(error);
  }
};

export const requestGeneralMeetingAnnounceFileUpload = async (
  meetingId: number,
  announcementFile: File,
  progressCallback: (progress: number) => void,
) => {
  try {
    const formData = new FormData();
    formData.append('file', announcementFile);
    const response = await ApiClient().postForm(`/general-meetings/${meetingId}/announcement-file`, formData, {
      headers: {
        'Content-Type': announcementFile.type,
      },
      onUploadProgress(progressEvent) {
        const progress = Number(Math.round((progressEvent.loaded * 100) / progressEvent.total!));
        progressCallback(progress);
      },
    });
    return response;
  } catch (error) {
    throw handleError(error);
  }
};

export const postGeneralMeetingAgenda = async (
  generalMeetingId: number,
  params: CreationOrUpdateAgendaModel,
  newAttachments: File[],
) => {
  try {
    const response = await ApiClient().postForm<AgendaModel>(`/general-meetings/${generalMeetingId}/agendas`, {
      ...params,
      attachFiles: newAttachments,
    });
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const patchGeneralMeetingAgenda = async (
  generalMeetingId: number,
  params: CreationOrUpdateAgendaModel,
  newAttachments: File[],
) => {
  try {
    const response = await ApiClient().patchForm<AgendaModel>(
      `/general-meetings/${generalMeetingId}/agendas/${params.id}`,
      {
        ...params,
        attachFiles: newAttachments,
      },
    );
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const patchGeneralMeetingStatus = async (generalMeetingId: number, newStatus: GeneralMeetingStatus) => {
  try {
    const response = await ApiClient().patch(`/general-meetings/${generalMeetingId}/status`, {
      value: newStatus,
    });
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const deleteGeneralMeetingAgenda = async (generalMeetingId: number, agendaId: number) => {
  try {
    await ApiClient().delete<void>(`/general-meetings/${generalMeetingId}/agendas/${agendaId}`);
  } catch (error) {
    throw handleError(error);
  }
};

export const getAvailableKevotings = async (generalMeetingId: number) => {
  try {
    const response = await ApiClient().get<{
      votes: GeneralMeetingVoteBoxResponseModel[];
      candidates: number[];
    }>(`/general-meetings/${generalMeetingId}/available-kevotings`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGeneralMeetingVoteBoxes = async (generalMeetingId: number) => {
  try {
    const response = await ApiClient().get<GeneralMeetingVoteBoxResponseModel[]>(
      `/general-meetings/${generalMeetingId}/vote-boxes`,
    );
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getGeneralMeetingVoteBox = async (generalMeetingId: number, voteBoxId: number) => {
  try {
    const response = await ApiClient().get<GeneralMeetingVoteBoxResponseModel>(
      `/general-meetings/${generalMeetingId}/vote-boxes/${voteBoxId}`,
    );
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestGeneralMeetingVoteBoxesRegister = async (
  generalMeetingId: number,
  selectedVotes: GeneralMeetingRegisterVoteRequestModel[],
) => {
  try {
    const response = await ApiClient().post<GeneralMeetingRegisterVoteResponseModel[]>(
      `/general-meetings/${generalMeetingId}/vote-boxes`,
      selectedVotes,
    );
    return response.status;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestAgendaVoteResultSave = async (
  generalMeetingId: number,
  agendaId: number,
  voteResult: AgendaVoteResultRequestBodyModel,
) => {
  try {
    const response = await ApiClient().post(
      `/general-meetings/${generalMeetingId}/agendas/${agendaId}/result`,
      voteResult,
    );
    return response.status;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestMeetingVoteResult = async (generalMeetingId: number) => {
  try {
    const response = await ApiClient().post(`/general-meetings/${generalMeetingId}/result/actions/publish`);
    return response.status;
  } catch (error) {
    throw handleError(error);
  }
};
