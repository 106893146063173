import {
  QuestionModel,
  QuestionOptionModel,
  VoteOptionPresetType,
  VoteQuestionType,
} from './agenda-vote-question.model';
import GeneralMeetingFileModel from './general-meeting-file.model';

export enum GeneralMeetingStatus {
  INIT = 'init', // 임시저장
  CONFIRMED = 'confirmed', // 게시전
  PUBLISHED = 'published', // 게시중
  FINISHED = 'finished', // 게시 종료
}

export enum GeneralMeetingAgendaType {
  REPORT = 'report',
  VOTE = 'vote',
}

export interface AgendaModel {
  id: number;
  generalMeetingId: number;
  type: GeneralMeetingAgendaType;
  title: string;
  order: number;
  files: GeneralMeetingFileModel[];
  question?: QuestionModel;
}

export interface GeneralMeetingModel {
  id: number;
  unionId: number;
  title: string;
  locationName?: string;
  address?: string;
  addressDetail?: string;
  holdAt?: Date;
  publishAt?: Date;
  finishAt?: Date;
  status: GeneralMeetingStatus;
  announcementFile?: GeneralMeetingFileModel;
}

export interface GeneralMeetingDetailModel extends GeneralMeetingModel {
  reportAgendas: AgendaModel[];
  voteAgendas: AgendaModel[];
}

export interface GeneralMeetingCreateRequestBodyModel {
  generalMeetingId?: number;
  unionId: number;
  title: string;
  locationName?: string;
  address?: string;
  addressDetail?: string;
  publishAt?: Date;
  holdAt?: Date;
}

export interface GeneralMeetingRegisterVoteRequestModel {
  refId: number;
}

export type CreationOrUpdateAgendaModel = OptionalProperty<Omit<AgendaModel, 'question'>, 'id'> & {
  question?: OptionalProperty<Omit<QuestionModel, 'options'>, 'id' | 'agendaId'> & {
    options: OptionalProperty<QuestionOptionModel, 'id' | 'questionId'>[];
  };
};

export interface GeneralMeetingRegisterVoteResponseModel extends GeneralMeetingCreateRequestBodyModel {
  id: number;
  generalMeetingId: number;
}

export enum VoteBoxType {
  ELECTRONIC = 'electronic',
  PAPER = 'paper',
}

export enum VotePurpose {
  RESOLUTION = 'resolution',
  BALLOT = 'ballot',
  COUNTING = 'counting',
}

export interface PostSuccessAlertModel {
  isShow: boolean;
  publishedAt: Date;
}

export interface PostFailureAlertModel {
  isShow: boolean;
  message: string;
}

export interface AgendaDeletionAlertModel {
  isShow: boolean;
  agenda: AgendaModel;
}

export interface GeneralMeetingVoteBoxQuestionOptionModel {
  id: number;
  questionId: number;
  order: number;
  title: string;
}

export interface GeneralMeetingVoteBoxQuestionResultModel {
  id: number;
  questionId: number;
  optionId: number;
  count: number;
  sum: number;
  average: number;
  ratio: number;
}

export interface GeneralMeetingVoteBoxQuestionModel {
  id: number;
  voteBoxId: number;
  order: number;
  title: string;
  preset: VoteOptionPresetType;
  type: VoteQuestionType;
  options: GeneralMeetingVoteBoxQuestionOptionModel[];
  result: GeneralMeetingVoteBoxQuestionResultModel[];
}

export interface GeneralMeetingVoteBoxParticipantChoiceModel {
  id: number;
  participantId: number;
  questionId: number;
  optionId: number;
  value: number;
}

export interface GeneralMeetingVoteBoxParticipantModel {
  id: number;
  voteBoxId: number;
  phoneNo: string;
  isParticipated: boolean;
  choices: GeneralMeetingVoteBoxParticipantChoiceModel[];
}

export enum KevotingElectionStatus {
  INIT = 'beforeStarting',
  IN_PROGRESS = 'inProgress',
  FINISHED = 'finished',
  COUNTED = 'counted',
}

export interface GeneralMeetingVoteBoxResponseModel {
  id: number;
  generalMeetingId: number;
  purpose: VoteBoxType;
  boxType: VotePurpose;
  refId: number | undefined;
  title: string | null;
  startDate: Date | null;
  endDate: Date | null;
  questions: GeneralMeetingVoteBoxQuestionModel[] | undefined;
  voters: GeneralMeetingVoteBoxParticipantModel[] | undefined;
  status: KevotingElectionStatus | undefined;
}

export interface GeneralMeetingVoteBoxSelectModel extends GeneralMeetingVoteBoxResponseModel {
  isChecked: boolean;
}

export enum GeneralMeetingQueryStringKeyEnum {
  EVENT_TYPE = 'eventType',
  EVENT_ACTION = 'eventAction',
  TARGET_ID = 'targetId',
}

export enum GeneralMeetingQueryStringValueEnum {
  MEETING = 'meeting',
  VOTE = 'vote',
  CREATE = 'create',
  EDIT = 'edit',
  DETAIL = 'detail',
  RESULT = 'result',
}
