/* eslint-disable import/prefer-default-export */
import { Tooltip, styled } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: '16px',
  },
}));
