import { AddOutlined, Close, Search } from '@mui/icons-material';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useContext, useMemo, useState } from 'react';
import AddLibraryFileDialog from './add-file-dialog';
import AppContext from '../../../../AppContext';
import { isInternalStaff } from '../../../../util/functions';

type LibraryHeaderViewProps = {
  searchText: string;
  setSearchText: (text: string) => void;
  unionId: number;
  onRefresh: () => void;
};
export default function LibraryHeaderView({ searchText, setSearchText, onRefresh, unionId }: LibraryHeaderViewProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { staff } = useContext(AppContext);
  const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const onClearClick = () => {
    setSearchText('');
  };
  const openAddDialog = () => {
    setDialogOpen(true);
  };
  const isInternal = useMemo(() => isInternalStaff(staff), [staff]);
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="start">
      <Typography variant="h2">자료실</Typography>
      <Divider orientation="vertical" sx={{ width: '1px', height: '32px' }} />
      <TextField
        placeholder="검색"
        size="small"
        value={searchText}
        onChange={onSearchTextChange}
        autoComplete="off"
        name="notRecognizable"
        sx={{
          width: '320px',
          background: 'white',
          '& fieldset': { border: 'none' },
          alignSelf: 'center',
        }}
        InputProps={{
          startAdornment: <Search sx={{ marginRight: '12px' }} />,
          endAdornment: (
            <Close
              sx={{
                display: searchText ? 'display' : 'none',
                cursor: 'pointer',
              }}
              onClick={onClearClick}
            />
          ),
          autoComplete: 'off',
        }}
      />
      <Typography flex={1} />
      {!isInternal && (
        <Button variant="contained" size="small" onClick={openAddDialog}>
          자료 추가
          <AddOutlined />
        </Button>
      )}

      <AddLibraryFileDialog
        unionId={unionId}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onRefresh={onRefresh}
      />
    </Stack>
  );
}
