export default interface LibraryCategoryModel {
  id: number;
  parentId: number | null;
  title: string;
  type: LibraryCategoryType;

  parentCategory?: LibraryCategoryModel;
  childCategories?: LibraryCategoryModel[];

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export enum LibraryCategoryType {
  UNION = '조합',
  COMMITTEE = '추진위원회',
}
