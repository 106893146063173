/* eslint-disable react/no-array-index-key */
import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import { ChangeEvent, MutableRefObject, createRef, useCallback, useEffect, useState } from 'react';
import { number, object, string } from 'yup';
import { getPostWalls, makeNotice } from '../../../../api/post.api';
import { PollCreateProps, PostWall, RequestStatus, ServerError } from '../../../../models';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';
import FileItem from '../../../common/file/file-item';
import PollView from './poll';

interface Props {
  open: boolean;
  onClose: () => void;
  unionId: number;
  onRefresh: () => void;
}
const NoticeCreationDialog = ({ open, onClose, unionId, onRefresh }: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const [postWalls, setPostWalls] = useState<PostWall[]>();
  const [selectedPostWall, setSelectedPostWall] = useState<PostWall>();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [addingPoll, setAddingPoll] = useState(false);
  const [postFiles, setPostFiles] = useState<File[]>([]);
  const fileRef: MutableRefObject<HTMLInputElement | null> = createRef();
  const [poll, setPoll] = useState<PollCreateProps & { pollOptionFiles: File[] }>();

  const onPostWallChange = (e: SelectChangeEvent) => {
    const wall = postWalls?.find((w) => w.id === Number(e.target.value));
    if (wall) {
      setSelectedPostWall(wall);
    } else {
      setSelectedPostWall(undefined);
    }
  };

  const exit = () => {
    setRequestStatus(RequestStatus.IDLE);
    setErrMsg('');
    setSelectedPostWall(undefined);
    setTitle('');
    setContent('');
    setAddingPoll(false);
    setPostFiles([]);
    setPoll(undefined);
    onClose();
  };

  const onMakeNotice = useCallback(async () => {
    if (requestStatus === RequestStatus.REQUESTED) {
      return;
    }
    setRequestStatus(RequestStatus.REQUESTED);
    await object({
      unionId: number().required(),
      postWallId: number().required(),
      title: string().required('제목은 필수값 입니다').min(1).max(30),
      content: string().required('내용은 최소 10자 이상 필요합니다').min(10).max(22560),
    })
      .validate({
        unionId,
        postWallId: selectedPostWall?.id,
        title,
        content,
      })
      .then(async (props) => {
        let pollOptionFiles;
        let pollProps;
        if (poll) {
          const { pollOptionFiles: f, ...p } = poll;
          pollOptionFiles = f;
          pollProps = p;
        }
        const result = await makeNotice({ ...props, pollProps }, postFiles, pollOptionFiles);
        if (result instanceof ServerError) {
          setRequestStatus(RequestStatus.FAIL);
          return;
        }
        onRefresh();
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .catch((err) => {
        setRequestStatus(RequestStatus.IDLE);
        setErrMsg(err.errors[0]);
      });
  }, [content, onRefresh, poll, postFiles, requestStatus, selectedPostWall?.id, title, unionId]);

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPostFiles([...postFiles, ...Array.from(e.target.files)]);
    }
  };
  const removeFile = useCallback(
    (idx: number) => {
      const newFiles = [...postFiles];
      newFiles.splice(idx, 1);
      setPostFiles(newFiles);
    },
    [postFiles],
  );

  useEffect(() => {
    const fetchWalls = async () => {
      const result = await getPostWalls(unionId);
      if (result instanceof ServerError) {
        return;
      }
      setPostWalls(result.walls);
    };
    fetchWalls();
  }, [unionId]);

  useEffect(() => {
    if (errMsg) {
      setErrMsg('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={exit}>
      <DialogTitle variant="h2">
        공지사항 작성
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: 600 }}>
        <Stack spacing={3}>
          {!addingPoll && (
            <Stack direction="row" spacing={3}>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel id="type-select-label">게시판</InputLabel>
                <Select
                  label="게시판"
                  value={`${selectedPostWall?.id}` || undefined}
                  onChange={onPostWallChange}
                  labelId="type-select-label"
                >
                  <MenuItem value={undefined}>
                    <b>게시판 선택</b>
                  </MenuItem>
                  {postWalls?.map((wall) => (
                    <MenuItem key={`post-wall-option-${wall.id}`} value={wall.id}>
                      {wall.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}

          {selectedPostWall && !addingPoll && (
            <>
              <TextField label="제목" autoComplete="off" value={title} inputProps={{ minLength: 1, maxLength: 30 }} onChange={(e) => setTitle(e.target.value)} />
              <TextField
                label="내용"
                autoComplete="off"
                multiline
                rows={8}
                value={content}
                inputProps={{ minLength: 10, maxLength: 22560 }}
                onChange={(e) => setContent(e.target.value)}
              />
              {postFiles.length > 0 && <Typography>첨부 파일</Typography>}
              <Stack spacing={2}>
                {postFiles?.map((file, idx) => (
                  <FileItem key={`post-file-${idx}`} fileName={file.name} fileSize={file.size} onDelete={() => removeFile(idx)} />
                ))}
              </Stack>
              {poll && (
                <Stack spacing={1}>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h2">투표 정보</Typography>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: 24, height: 24, width: '24', p: 0 }}
                      onClick={() => setPoll(undefined)}
                    >
                      X
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="body2">
                      <b>종료일:</b>
                      &nbsp;
                      {dayjs(poll.poll.endsAt).format('M월D일 HH:mm')}
                    </Typography>
                    <Typography variant="body2">
                      <b>항목 개수:</b>
                      &nbsp;
                      {poll.options.length}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              <Stack direction="row" spacing={3}>
                <Button variant="outlined" size="small" onClick={() => fileRef.current?.click()}>
                  파일 첨부
                </Button>
                <input
                  type="file"
                  multiple
                  onChange={onFileChange}
                  ref={fileRef}
                  style={{ display: 'none' }}
                  accept="image/*,video/*,.hwp,.hwpx,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.key,.pdf"
                />
                <Button variant="outlined" size="small" onClick={() => setAddingPoll(true)}>
                  투표 추가
                </Button>
              </Stack>
              {errMsg && <Typography color="error">{errMsg}</Typography>}
            </>
          )}
        </Stack>
        {addingPoll && <PollView onCancel={() => setAddingPoll(false)} setPoll={setPoll} />}
      </DialogContent>
      <DialogActions>
        {!addingPoll &&
          selectedPostWall &&
          (requestStatus === RequestStatus.REQUESTED ? (
            <Button>
              <CircularProgress />
            </Button>
          ) : (
            <Button variant="contained" onClick={onMakeNotice}>
              공지사항 생성
            </Button>
          ))}
      </DialogActions>
      <SuccessErrorModal
        isSuccess={requestStatus === RequestStatus.SUCCESS}
        title="공지사항 업로드"
        description={
          requestStatus === RequestStatus.SUCCESS
            ? '공지사항이 성공적으로 업로드 되었습니다.'
            : '공지사항 업로드 중 에러가 발생했습니다.'
        }
        open={requestStatus === RequestStatus.SUCCESS || requestStatus === RequestStatus.FAIL}
        onClose={() => {
          if (requestStatus === RequestStatus.SUCCESS) {
            exit();
          }
          setRequestStatus(RequestStatus.IDLE);
        }}
      />
    </Dialog>
  );
};

export default NoticeCreationDialog;
