import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useCallback, useContext, useState } from 'react';
import AppContext from '../../../../AppContext';
import { downloadFile } from '../../../../api/file.api';
import { deleteLibraryFile } from '../../../../api/library.api';
import postIcon from '../../../../assets/library-post-icon.svg';
import { LibraryFile, ServerError } from '../../../../models';
import { byteToHumanReadableSize, isInternalStaff } from '../../../../util/functions';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';
import { CommonTableCell, CommonTableRow } from '../../../common/table';

type LibraryListViewProps = {
  libraryList: LibraryFile[];
  unionId: number;
  onRefresh: () => void;
};

const columns = ['카테고리', '파일명', '용량', '작성자', '업로드 시간', ''];

export default function LibraryListView({ libraryList, unionId, onRefresh }: LibraryListViewProps) {
  const { staff } = useContext(AppContext);
  const [fileToDelete, setFileToDelete] = useState<LibraryFile>();
  const theme = useTheme();

  const fetchFile = useCallback(async (data: LibraryFile) => {
    const result = await downloadFile(data.url);
    if (result instanceof ServerError) {
      return;
    }
    saveAs(result, data.originalName);
  }, []);

  const onRemove = useCallback(() => {
    if (fileToDelete) {
      const performDelete = async () => {
        const result = await deleteLibraryFile({ id: fileToDelete.id, unionId });
        if (result instanceof ServerError) {
          return;
        }
        onRefresh();
      };
      performDelete();
    }
  }, [fileToDelete, onRefresh, unionId]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <CommonTableCell key={column}>{column}</CommonTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {libraryList.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography variant="subtitle1" color={theme.palette.grey[600]} textAlign="center">
                  내용이 없습니다.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {libraryList.map((row) => (
            <CommonTableRow key={row.id}>
              <CommonTableCell component="th" scope="row" width={200}>
                <Stack>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    {row.library?.category?.parentCategory?.title}
                  </Typography>
                  <Typography variant="body2" color={theme.palette.primary.main}>
                    {row.library?.category?.title}
                  </Typography>
                </Stack>
              </CommonTableCell>
              <CommonTableCell>
                <Stack>
                  <Typography variant="body2">{row.originalName}</Typography>
                  {row.post && (
                    <Stack direction="row">
                      <img src={postIcon} alt="" />
                      <Typography variant="body2" color={theme.palette.grey[600]}>
                        {row.post.title}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </CommonTableCell>
              <CommonTableCell width={100}>{byteToHumanReadableSize(row.size)}</CommonTableCell>
              <CommonTableCell width={100}>{row.staff.name}</CommonTableCell>
              <CommonTableCell width={180}>{dayjs(row.createdAt).format('YYYY-MM-DD HH:mm')}</CommonTableCell>
              <CommonTableCell width={200}>
                <Stack direction="row" spacing={2}>
                  <Button onClick={() => fetchFile(row)}>다운로드</Button>
                  {!isInternalStaff(staff) && (
                    <Button onClick={() => setFileToDelete(row)} color="error">
                      삭제
                    </Button>
                  )}
                </Stack>
              </CommonTableCell>
            </CommonTableRow>
          ))}
        </TableBody>
      </Table>
      <SuccessErrorModal
        isSuccess={false}
        title="자료 삭제"
        description="정말 자료를 삭제 하시겠습니까?"
        open={!!fileToDelete}
        onClose={() => setFileToDelete(undefined)}
        onConfirm={onRemove}
      />
    </TableContainer>
  );
}
