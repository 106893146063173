import { Dialog, Paper, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const CommonModalStyle = {
  position: 'absolute',
  top: '20%',
  left: '14%',
  width: {
    xs: '360px',
    sm: '600px',
    md: '820px',
    lg: '980px',
  },
  overflow: 'scroll',
};
function CommonModalView(props: { open: boolean; onClose: () => void; title?: string; content?: ReactNode }) {
  const { open, onClose, title, content } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="common-modal-title"
      aria-describedby="common-modal-description"
    >
      <Paper sx={{ p: 6 }}>
        <Stack spacing={3}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }} textAlign="center">
            {title}
          </Typography>
          <Stack>{content}</Stack>
        </Stack>
      </Paper>
    </Dialog>
  );
}

export default CommonModalView;
