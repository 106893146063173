import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { max } from 'lodash';
import { useCallback, useState } from 'react';
import { deleteGeneralMeetingAgenda } from '../../../../../api/general-meeting.api';
import { VoteOptionPresetType, VoteQuestionType } from '../../../../../models/agenda-vote-question.model';
import {
  AgendaDeletionAlertModel,
  AgendaModel,
  CreationOrUpdateAgendaModel,
  GeneralMeetingAgendaType,
  GeneralMeetingQueryStringValueEnum,
  GeneralMeetingStatus,
  GeneralMeetingVoteBoxResponseModel,
} from '../../../../../models/general-meeting.model';
import SuccessErrorModal from '../../../../common/dialog/success-error-dialog';
import VoteRegisteredAlert from '../vote/register/vote-registered-alert';
import VoteUnregisteredAlert from '../vote/register/vote-unregistered-alert';
import AddAgendaDialog from './add-agenda-dialog';
import AgendaTable from './agenda-table';

interface AgendaAddButtonProps {
  agendaName: string;
  handleClick: () => void;
}

function AgendaAddButton({ agendaName, handleClick }: AgendaAddButtonProps) {
  const theme = useTheme();
  return (
    <Button variant="text" onClick={handleClick} size="small" fullWidth sx={{ color: theme.palette.grey[800], py: 3 }}>
      <AddCircleOutlineOutlined color="primary" sx={{ marginRight: '8px' }} />
      {`${agendaName} 추가`}
    </Button>
  );
}

interface AgendaInfoProps {
  generalMeetingId: number;
  reportAgendas: AgendaModel[];
  voteAgendas: AgendaModel[];
  meetingStatus: GeneralMeetingStatus;
  selectedKevotings: GeneralMeetingVoteBoxResponseModel[];
  onRefresh: () => void;
  onVoteRegisterClick: () => void;
  changeMeetingStatus: () => void;
  onPageMove: (
    eventTypeValue: GeneralMeetingQueryStringValueEnum,
    eventActionValue: GeneralMeetingQueryStringValueEnum,
    targetIdValue?: number,
  ) => void;
}

export default function AgendaInfo(props: AgendaInfoProps) {
  const {
    generalMeetingId,
    reportAgendas,
    voteAgendas,
    meetingStatus,
    selectedKevotings,
    onRefresh,
    onVoteRegisterClick,
    changeMeetingStatus,
    onPageMove,
  } = props;

  const [updatedAgenda, setUpdatedAgenda] = useState<CreationOrUpdateAgendaModel>();
  const [showAgendaDeletionAlert, setShowAgendaDeletionAlert] = useState<AgendaDeletionAlertModel>();
  const [showAgendaModificationAlert, setShowAgendaModificationAlert] = useState(false);
  const theme = useTheme();

  const editAgenda = useCallback(
    async (agenda: AgendaModel) => {
      if (meetingStatus === GeneralMeetingStatus.CONFIRMED) {
        changeMeetingStatus();
      }
      setUpdatedAgenda(agenda);
    },
    [meetingStatus, changeMeetingStatus],
  );

  const deleteAgenda = useCallback(
    async (agenda: AgendaModel) => {
      await deleteGeneralMeetingAgenda(generalMeetingId, agenda.id);
      await onRefresh();
    },
    [generalMeetingId, onRefresh],
  );

  const presentAgendaDeletionModal = useCallback((agenda: AgendaModel) => {
    setShowAgendaDeletionAlert({ isShow: true, agenda });
  }, []);

  return (
    <Stack direction="column" spacing={3} alignItems="flex-start">
      <Typography variant="h2" paddingBottom="16px">
        상정 안건
      </Typography>
      <Typography variant="h3">보고 안건</Typography>
      <Stack justifyContent="center" width="100%" border={`1px solid ${theme.palette.grey[500]}`} borderRadius={0}>
        {reportAgendas.length > 0 && (
          <AgendaTable
            agendas={reportAgendas}
            isEditable={meetingStatus === GeneralMeetingStatus.INIT || meetingStatus === GeneralMeetingStatus.CONFIRMED}
            editAgenda={editAgenda}
            deleteAgenda={presentAgendaDeletionModal}
          />
        )}

        {(meetingStatus === GeneralMeetingStatus.INIT || meetingStatus === GeneralMeetingStatus.CONFIRMED) && (
          <>
            <Divider
              sx={{ border: `${reportAgendas.length === 0 ? '0px' : '0.1px'} solid ${theme.palette.grey[500]}` }}
            />
            <AgendaAddButton
              agendaName="보고 안건"
              handleClick={() => {
                const maxOrder = max(reportAgendas.map((v) => v.order)) ?? 0;
                setUpdatedAgenda({
                  generalMeetingId,
                  type: GeneralMeetingAgendaType.REPORT,
                  title: '',
                  order: maxOrder + 1,
                  files: [],
                  question: undefined,
                });
              }}
            />
          </>
        )}
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h3">의결 안건</Typography>
        <Stack direction="row" spacing={2} alignContent="center">
          <Button
            variant="contained"
            color="secondary"
            disabled={meetingStatus === GeneralMeetingStatus.INIT || meetingStatus === GeneralMeetingStatus.CONFIRMED}
            size="small"
            onClick={() => {
              onPageMove(
                GeneralMeetingQueryStringValueEnum.VOTE,
                GeneralMeetingQueryStringValueEnum.RESULT,
                generalMeetingId,
              );
            }}
          >
            투표 결과 입력하기
          </Button>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            disabled={meetingStatus === GeneralMeetingStatus.INIT}
            onClick={onVoteRegisterClick}
          >
            투표 등록하기
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onPageMove(GeneralMeetingQueryStringValueEnum.VOTE, GeneralMeetingQueryStringValueEnum.CREATE);
            }}
          >
            투표 만들기
          </Button>
        </Stack>
      </Stack>

      {selectedKevotings.length > 0 && (
        <VoteRegisteredAlert selectedKevotings={selectedKevotings} />
      )}

      {(meetingStatus !== GeneralMeetingStatus.INIT && selectedKevotings.length === 0) && (
        <VoteUnregisteredAlert onClick={onVoteRegisterClick} />
      )}

      <Stack justifyContent="center" width="100%" border={`1px solid ${theme.palette.grey[500]}`} borderRadius={0}>
        {voteAgendas.length > 0 && (
          <AgendaTable
            agendas={voteAgendas}
            isEditable={meetingStatus === GeneralMeetingStatus.INIT || meetingStatus === GeneralMeetingStatus.CONFIRMED}
            editAgenda={editAgenda}
            deleteAgenda={presentAgendaDeletionModal}
          />
        )}
        {(meetingStatus === GeneralMeetingStatus.INIT || meetingStatus === GeneralMeetingStatus.CONFIRMED) && (
          <>
            <Divider
              sx={{ border: `${voteAgendas.length === 0 ? '0px' : '0.1px'} solid ${theme.palette.grey[500]}` }}
            />
            <AgendaAddButton
              agendaName="의결 안건"
              handleClick={() => {
                const maxOrder = max(voteAgendas.map((v) => v.order)) ?? 0;

                setUpdatedAgenda({
                  generalMeetingId,
                  type: GeneralMeetingAgendaType.VOTE,
                  title: '',
                  order: maxOrder + 1,
                  files: [],
                  question: {
                    title: '',
                    type: VoteQuestionType.ELECTION,
                    preset: VoteOptionPresetType.FOR_OR_AGAINST,
                    options: [],
                    result: null,
                  },
                });
              }}
            />
          </>
        )}
      </Stack>

      <AddAgendaDialog
        generalMeetingId={Number(generalMeetingId)}
        agenda={updatedAgenda}
        registeredAgendaIds={
          updatedAgenda?.type === GeneralMeetingAgendaType.VOTE
            ? voteAgendas.map((agenda) => agenda.order)
            : reportAgendas.map((agenda) => agenda.order)
        }
        selectedAgendaOrder={updatedAgenda?.order}
        onClose={async (needRefresh) => {
          if (needRefresh) {
            await onRefresh();
            setShowAgendaModificationAlert(true);
          }

          setUpdatedAgenda(undefined);
        }}
      />

      <SuccessErrorModal
        isSuccess={false}
        title="안건 삭제"
        description="안건을 정말 삭제하시겠습니까?"
        open={!!showAgendaDeletionAlert}
        onClose={() => setShowAgendaDeletionAlert(undefined)}
        onConfirm={() => {
          if (!showAgendaDeletionAlert) {
            return;
          }
          deleteAgenda(showAgendaDeletionAlert?.agenda);
        }}
      />

      <SuccessErrorModal
        isSuccess
        title="수정 완료"
        description="지금 수정한 내용은 Kevoting에 자동으로 반영되지 않습니다. 전자 투표도 변경하려면 Kevoting에 접속해 다시 수정해 주세요."
        open={showAgendaModificationAlert && selectedKevotings.length > 0}
        onClose={() => {
          setShowAgendaModificationAlert(false);
        }}
      />
    </Stack>
  );
}
