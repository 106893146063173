/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Delete } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { find } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../AppContext';
import { getStaffList } from '../../api/staff';
import { ServerError, Staff, StaffRole } from '../../models';
import { StaffRoleType } from '../../models/staff-role.model';
import Splash from '../splash';
import StaffCreateModal from './staff-create-modal';
import StaffDeleteModal from './staff-delete-modal';

interface Column {
  name: string;
  minWidth?: number;
  align?: 'right';
}

const columns: readonly Column[] = [
  { name: '이름', minWidth: 100 },
  { name: '이메일', minWidth: 100 },
  { name: '역할', minWidth: 100 },
  {
    name: '',
    minWidth: 170,
  },
];

function DataView({ data, column }: { data: Staff; column: Column }) {
  // if (column.name === '이름') {
  //   return {data.name};
  // }
  // if (column.name === '이메일') {
  //   return data.email;
  // }
  // if (column.name === '역할') {
  //   return (
  //     <>
  //       {data.roles.map((role) => (
  //         <span key={`${data.id}-${role.id}`}>{role.role} </span>
  //       ))}
  //     </>
  //   );
  // }
  return '';
}

export default function StaffList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [staffList, setStaffList] = useState<Staff[]>();
  const [refreshStaffList, setRefreshStaffList] = useState(false);

  const [staffRoleList, setStaffRoleList] = useState<StaffRole[]>();

  const [isLoading, setIsLoading] = useState(true);

  const { staff } = useContext(AppContext);

  const [staffCreateModalOpen, setStaffCreateModalOpen] = useState(false);

  const [selectedDeleteStaff, setSelectedDeleteStaff] = useState<Staff>();

  const isRoot = useCallback(() => {
    if (!staff) return false;
    return !!find(staff.roles, (item) => item.role === StaffRoleType.INTERNAL);
  }, [staff]);

  const openCreateModal = useCallback(() => {
    setStaffCreateModalOpen(true);
  }, [setStaffCreateModalOpen]);

  const fetchStaffList = async () => {
    const data = await getStaffList();
    if (data instanceof ServerError) {
      return;
    }
    setStaffList(data.staffList);
  };
  // const fetchStaffRoleList = async () => {
  //   const data = await getStaffRoleList();
  //   if (data instanceof ServerError) {
  //   } else {
  //     setStaffRoleList(data.roleList);
  //   }
  // };

  useEffect(() => {
    if (isLoading) {
      fetchStaffList();
      // fetchStaffRoleList();
    }
  }, [isLoading]);

  useEffect(() => {
    if (refreshStaffList) {
      fetchStaffList();
      setRefreshStaffList(false);
    }
  }, [refreshStaffList]);

  useEffect(() => {
    if (staffList && staffRoleList) {
      setIsLoading(false);
    }
  }, [staffList, staffRoleList]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {isLoading ? (
        <Splash />
      ) : (
        <>
          <Stack direction="row" alignItems="center">
            <Stack alignItems="start" padding={4}>
              <Typography variant="h5">스태프 리스트</Typography>
            </Stack>
            {isRoot() && (
              <Button variant="contained" onClick={openCreateModal}>
                스태프 생성
              </Button>
            )}
          </Stack>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={`col-${column.name}`} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {staffList &&
                  staffList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        if (column.name === '') {
                          return (
                            <TableCell key={`tc-${column.name}-${row.name}`} align={column.align}>
                              {isRoot() && row.id !== staff?.id && (
                                <Box onClick={() => setSelectedDeleteStaff(row)}>
                                  <Delete cursor="pointer" />
                                </Box>
                              )}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={`tc-${column.name}-${row.name}`} align={column.align}>
                            {/* <DataView data={row} column={column} /> */}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {staffList && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={staffList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {staffRoleList && (
            <StaffCreateModal
              roles={staffRoleList}
              open={staffCreateModalOpen}
              handleClose={() => setStaffCreateModalOpen(false)}
              setRefreshList={() => setRefreshStaffList(true)}
            />
          )}
          {selectedDeleteStaff && (
            <StaffDeleteModal
              staff={selectedDeleteStaff}
              handleClose={() => setSelectedDeleteStaff(undefined)}
              setRefreshList={() => setRefreshStaffList(true)}
            />
          )}
        </>
      )}
    </Paper>
  );
}
