import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const removeCookie = (key: string) => cookies.remove(key, { path: '/' });

export const getCookie = (key: string) => cookies.get(key);

export const setCookie = (key: string, val: any) => {
  cookies.set(key, val, { maxAge: 12 * 60 * 1000, path: '/' });
};
