import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { ChangeEvent, useCallback, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { mixed, object, string } from 'yup';
import { addUnionMember } from '../../../../api/union.api';
import { RequestStatus, ServerError, UnionMemberGrade, UnionMemberType } from '../../../../models';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  unionId: number;
  onRefresh: () => void;
}
const UnionMemberInvitationDialog = ({ open, onClose, unionId, onRefresh }: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const [name, setName] = useState('');
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const [address, setAddress] = useState('');
  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };
  const [assetAddress, setAssetAddress] = useState('');
  const onAssetAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAssetAddress(event.target.value);
  };
  const [phone, setPhone] = useState('');
  const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const [serialNumber, setSerialNumber] = useState('');
  const onSerialNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value);
  };
  const [type, setType] = useState<UnionMemberType | ''>('');
  const onTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as UnionMemberType);
  };
  const [grade, setGrade] = useState<UnionMemberGrade | ''>('');
  const onGradeChange = (event: SelectChangeEvent) => {
    setGrade(event.target.value as UnionMemberGrade);
  };
  const [registrationNumberOrRrn, setRegistrationNumberOrRrn] = useState('');
  const onRegistrationNumberOrRrnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegistrationNumberOrRrn(event.target.value);
  };
  const [addRequestStatus, setAddRequestStatus] = useState(RequestStatus.IDLE);

  const onAdd = useCallback(async () => {
    await object()
      .shape({
        name: string().required('이름을 입력해주세요').min(1),
        address: string().required('주소를 입력해주세요').min(1),
        phone: string().required('연락처를 입력해주세요').trim().min(1),
        serialNumber: string().required('고유번호를 입력해주세요').min(1),
        type: mixed<UnionMemberType>().oneOf(Object.values(UnionMemberType), '구분을 선택해주세요').required(),
        grade: mixed<UnionMemberGrade>().oneOf(Object.values(UnionMemberGrade), '등급을 선택해주세요').required(),
        registrationNumberOrRrn: string().required(),
        assetAddress: string().required('소유부동산을 입력해주세요').min(1),
      })
      .validate({
        name,
        address,
        phone,
        serialNumber,
        type,
        grade,
        registrationNumberOrRrn,
        assetAddress,
      })
      .then(async (props) => {
        if (
          type === UnionMemberType.CORPORATION &&
          !registrationNumberOrRrn.match(new RegExp('[0-9]{3}[-][0-9]{2}[-][0-9]{5}', undefined))
        ) {
          setErrMsg('사업자 번호의 형태가 올바르지 않습니다. (예시: 476-15-76421)');
          return;
        }
        if (
          type !== UnionMemberType.CORPORATION &&
          !registrationNumberOrRrn.match(new RegExp('[0-9]{6}[-][1-9]{1}', undefined))
        ) {
          setErrMsg('생년월일의 형태가 올바르지 않습니다. (예시: 701212-1)');
          return;
        }
        setAddRequestStatus(RequestStatus.REQUESTED);
        const result = await addUnionMember({
          ...props,
          unionId,
          companyRegistrationNumber: type === UnionMemberType.CORPORATION ? registrationNumberOrRrn : null,
          partialRrn: type !== UnionMemberType.CORPORATION ? registrationNumberOrRrn : null,
        });
        if (result instanceof ServerError) {
          setAddRequestStatus(RequestStatus.FAIL);
          return;
        }
        setAddRequestStatus(RequestStatus.SUCCESS);
        onRefresh();
      })
      .catch((e) => {
        setErrMsg(e.errors[0]);
      });
  }, [name, address, phone, serialNumber, type, grade, registrationNumberOrRrn, assetAddress, unionId, onRefresh]);

  const exit = () => {
    setName('');
    setAddress('');
    setPhone('');
    setSerialNumber('');
    setType('');
    setGrade('');
    setRegistrationNumberOrRrn('');
    setErrMsg('');
    setAssetAddress('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={exit}>
      <DialogTitle variant="h2">
        구성원 추가
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField label="이름" value={name} onChange={onNameChange} required autoComplete="off" />
            <TextField
              label="고유번호"
              value={serialNumber}
              onChange={onSerialNumberChange}
              required
              autoComplete="off"
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ flex: 1 }} required>
              <InputLabel id="type-select-label">구분</InputLabel>
              <Select label="구분" value={type} onChange={onTypeChange} labelId="type-select-label">
                <MenuItem value="">
                  <em>선택안함</em>
                </MenuItem>
                {Object.values(UnionMemberType).map((val) => (
                  <MenuItem key={`member-type-option-${val}`} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ flex: 1 }} required>
              <InputLabel id="type-select-label">등급</InputLabel>
              <Select label="등급" value={grade} onChange={onGradeChange} labelId="type-select-label">
                <MenuItem value="">
                  <em>선택안함</em>
                </MenuItem>
                {Object.values(UnionMemberGrade).map((val) => (
                  <MenuItem key={`member-grade-option-${val}`} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <PatternFormat
            format="###########"
            customInput={TextField}
            name="phoneForMessage"
            label="연락처"
            value={phone}
            autoComplete="off"
            onChange={onPhoneChange}
            required
            sx={{ flex: 1 }}
          />
          <TextField
            label="생년월일/사업자번호"
            value={registrationNumberOrRrn}
            onChange={onRegistrationNumberOrRrnChange}
            required
            autoComplete="off"
            placeholder="생년월일: 000000-0 / 사업자등록번호: 000-00-00000"
          />
          <TextField label="주소" value={address} onChange={onAddressChange} required autoComplete="off" />
          <TextField
            label="권리내역 (소유 부동산)"
            value={assetAddress}
            onChange={onAssetAddressChange}
            required
            autoComplete="off"
          />
          {errMsg && <Typography color="error">{errMsg}</Typography>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" fullWidth onClick={onAdd}>
          추가하기
        </Button>
      </DialogActions>
      <SuccessErrorModal
        isSuccess={addRequestStatus === RequestStatus.SUCCESS}
        title="구성원 추가"
        description={
          addRequestStatus === RequestStatus.SUCCESS
            ? '구성원이 성공적으로 추가 되었습니다.'
            : '구성원 추가 중 에러가 발생했습니다.'
        }
        open={addRequestStatus === RequestStatus.SUCCESS || addRequestStatus === RequestStatus.FAIL}
        onClose={() => {
          setAddRequestStatus(RequestStatus.IDLE);
          exit();
        }}
      />
    </Dialog>
  );
};

export default UnionMemberInvitationDialog;
