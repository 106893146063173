import { Error } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography, useTheme } from '@mui/material';

export interface LeaveDialogProps {
  open: boolean;
  onLeave: () => void;
  onContinue: () => void;
}

export default function LeaveDialog(props: LeaveDialogProps) {
  const { open, onLeave, onContinue } = props;
  const theme = useTheme();

  return (
    <Dialog open={open} disableAutoFocus transitionDuration={{ enter: 0, exit: 0 }}>
      <DialogContent sx={{ width: '420px' }}>
        <Stack alignItems="center" spacing={3}>

          <Error color="error" sx={{ fontSize: '60px' }} />

          <Stack alignItems="center" spacing={2}>
            <Typography
              id="transition-modal-description"
              variant="body1"
              color={theme.palette.grey[800]}
              textAlign="center"
            >
              작성 중인 내용을 저장하지 않고 나가시겠습니까?
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            onContinue();
          }}
        >
          계속 작성하기
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            onLeave();
          }}
        >
          나가기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
