import { Close, Search } from '@mui/icons-material';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent } from 'react';

type CustomerHeaderViewProps = {
  searchText: string;
  setSearchText: (text: string) => void;
};
export default function CustomerHeaderView({ searchText, setSearchText }: CustomerHeaderViewProps) {
  const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const onClearClick = () => {
    setSearchText('');
  };
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="start">
      <Typography variant="h2">플랫폼 회원</Typography>
      <Divider orientation="vertical" sx={{ width: '1px', height: '32px' }} />
      <TextField
        placeholder="검색"
        size="small"
        value={searchText}
        onChange={onSearchTextChange}
        autoComplete="off"
        name="notRecognizable"
        sx={{
          width: '320px',
          background: 'white',
          '& fieldset': { border: 'none' },
          alignSelf: 'center',
        }}
        InputProps={{
          startAdornment: <Search sx={{ marginRight: '12px' }} />,
          endAdornment: (
            <Close
              sx={{
                display: searchText ? 'display' : 'none',
                cursor: 'pointer',
              }}
              onClick={onClearClick}
            />
          ),
          autoComplete: 'off',
        }}
      />
    </Stack>
  );
}
