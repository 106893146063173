import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContext from '../../../../AppContext';
import { isInternalStaff } from '../../../../util/functions';
import TabPanel, { a11yProps } from '../../../common/tab-panel';
import ManageCashPage from './manage-cash';
import SendingHistoryPage from './sending-history';

export default function MessagePage() {
  const { unionId } = useParams();
  const navigate = useNavigate();
  const { staff } = useContext(AppContext);
  const [tab, setTab] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const goToSendMessage = useCallback(() =>
    navigate(`/unions/${unionId}/send`), [navigate, unionId],
  );

  return (
    <Stack alignItems="start" spacing={4}>
      <Stack direction="row" alignItems="center" width="100%" py={4}>
        <Typography variant="h2" flex={1} textAlign="start">
          메시지
        </Typography>
        {!isInternalStaff(staff) && (
          <Button variant="contained" onClick={goToSendMessage} size="small">
            메시지 보내기
          </Button>
        )}
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleChange} aria-label="tab-label">
            <Tab label="발송 내역" {...a11yProps(0)} />
            <Tab label="충전 관리" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <SendingHistoryPage unionId={Number(unionId)} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ManageCashPage unionId={Number(unionId)} />
        </TabPanel>
      </Box>

    </Stack>
  );
}
