import { AddCircleOutlineOutlined, Close, Delete, InfoOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { max, min } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { string } from 'yup';
import { patchGeneralMeetingAgenda, postGeneralMeetingAgenda } from '../../../../../api/general-meeting.api';
import { QuestionOptionModel, VoteOptionPresetType, VoteQuestionType } from '../../../../../models/agenda-vote-question.model';
import { CreationOrUpdateAgendaModel, GeneralMeetingAgendaType } from '../../../../../models/general-meeting.model';

interface Props {
  generalMeetingId: number;
  agenda?: CreationOrUpdateAgendaModel;
  registeredAgendaIds: number[]
  selectedAgendaOrder?: number
  onClose: (needRefresh: boolean) => void;
}

export default function AddAgendaDialog(props: Props) {
  const { generalMeetingId, agenda, registeredAgendaIds, selectedAgendaOrder, onClose } = props;

  const [updatedAgenda, setUpdatedAgenda] = useState<CreationOrUpdateAgendaModel>();
  const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);

  const [unfilledOptIds, setUnfilledOptIds] = useState<number[]>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [wrongAgendaOrder, setWrongAgendaOrder] = useState<number>();
  const [wrongOptionIds, setWrongOptionIds] = useState<number[]>();

  useEffect(() => {
    if (agenda) {
      return;
    }

    setUpdatedAgenda(undefined);
    setAttachmentFiles([]);
    setErrorMessage(undefined);
  }, [agenda]);

  useEffect(() => {
    if (!agenda) {
      return;
    }

    setUpdatedAgenda(agenda);
  }, [agenda, generalMeetingId]);

  const theme = useTheme();

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        // accept이 적용이 안되는 경우를 위한 validation 추가
        const uploadableFileTypes = ['image/', 'pdf'];
        const targetFile = e.target.files[0];

        if (uploadableFileTypes.some((type) => targetFile.type.includes(type))) {
          setAttachmentFiles([...attachmentFiles, ...Array.from(e.target.files)]);
        } else {
          setErrorMessage('파일은 pdf 또는 이미지 파일만 첨부할 수 있습니다.확인 후 다시 시도해 주세요.');
        }
      }
    },
    [attachmentFiles],
  );

  const handleRemoveNewFiles = useCallback(
    (index: number) => {
      const newFiles = [...attachmentFiles];
      newFiles.splice(index, 1);
      setAttachmentFiles(newFiles);
    },
    [attachmentFiles],
  );

  const handleRemoveExistingFiles = useCallback(
    (id: number) => {
      if (!updatedAgenda) {
        return;
      }

      setUpdatedAgenda({
        ...updatedAgenda,
        files: updatedAgenda.files.filter((file) => file.id !== id),
      });
    },
    [updatedAgenda],
  );

  const handleRemoveOptions = useCallback(
    (id: number) => {
      if (!updatedAgenda) { return; }
      if (!updatedAgenda.question) { return; }
      const { question, ...rest } = updatedAgenda;
      const remainOptions = updatedAgenda.question.options.filter((opt) => opt.id !== id);
      setUpdatedAgenda({
        ...rest,
        question: {
          ...question!,
          options: remainOptions,
        },
      });
    }, [updatedAgenda],
  );

  const resetAllErrorStatus = useCallback(() => {
    setUnfilledOptIds(undefined);
    setErrorMessage(undefined);
    setWrongAgendaOrder(undefined);
    setWrongOptionIds(undefined);
  }, []);

  const handleSave = useCallback(async () => {
    if (!updatedAgenda) {
      return;
    }

    let isErrorStatus: boolean = false;
    resetAllErrorStatus();

    if (updatedAgenda.question?.preset === VoteOptionPresetType.CHOICE) {
      const unfilledOptionIds = updatedAgenda?.question?.options.filter((opt) => opt.description.length === 0);
      if (unfilledOptionIds.length > 0) {
        setUnfilledOptIds(unfilledOptionIds.map((opt) => opt.id ?? 0));
        isErrorStatus = true;
      }
      const optionIds = updatedAgenda.question?.options.map((opt) => opt.order);
      const duplicatedIds = optionIds.filter((optId, index) => optionIds.indexOf(optId) !== index);
      if (duplicatedIds.length > 0) {
        setWrongOptionIds(duplicatedIds);
        isErrorStatus = true;
      }

      const negativeIds = optionIds.filter((optId) => optId <= 0);
      if (negativeIds.length > 0) {
        setWrongOptionIds(negativeIds);
        isErrorStatus = true;
      }
    }

    if (selectedAgendaOrder !== updatedAgenda.order && registeredAgendaIds.includes(updatedAgenda.order)) {
      setWrongAgendaOrder(updatedAgenda.order);
      isErrorStatus = true;
    }

    if (updatedAgenda.order <= 0) {
      setWrongAgendaOrder(updatedAgenda.order);
      isErrorStatus = true;
    }

    if (isErrorStatus) {
      setErrorMessage('저장을 시도하는 중 실패했습니다.');
      return;
    }

    try {
      const title = await string().min(1).required().validate(updatedAgenda.title);
      updatedAgenda.title = title;
      if (updatedAgenda.id) {
        // update case
        await patchGeneralMeetingAgenda(generalMeetingId, updatedAgenda, attachmentFiles);
      } else {
        // add case
        const createParam = {
          ...updatedAgenda,
          question: updatedAgenda.question
            ? { ...updatedAgenda.question, options: updatedAgenda.question.options.map(({ id, ...rest }) => rest) }
            : undefined,
        };
        await postGeneralMeetingAgenda(generalMeetingId, createParam, attachmentFiles);
      }

      onClose(true);
    } catch (error) {
      setErrorMessage('저장을 시도하는 중 실패했습니다.');
    }
  }, [
    updatedAgenda,
    selectedAgendaOrder,
    registeredAgendaIds,
    generalMeetingId,
    attachmentFiles,
    onClose,
    resetAllErrorStatus,
  ]);

  const maxExistingAgendaOptionOrder: number = useMemo(() => {
    if (!updatedAgenda) {
      return 1;
    }
    return max(updatedAgenda.question?.options.map((opt) => opt.order)) ?? 1;
  }, [updatedAgenda]);

  const exit = () => {
    setUnfilledOptIds(undefined);
    onClose(false);
    resetAllErrorStatus();
  };

  if (!updatedAgenda) {
    return null;
  }

  return (
    <Dialog open={!!agenda} onClose={exit} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h2" flex={1} textAlign="left" component="span">
          {updatedAgenda && (updatedAgenda.type === GeneralMeetingAgendaType.REPORT ? '보고 안건' : '의결 안건')}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={(pTheme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: pTheme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: 600 }}>
        {errorMessage && <Alert severity="error" sx={{ mb: '8px' }}>{errorMessage}</Alert>}
        <Stack spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle1" color={theme.palette.grey[800]}>
              제
            </Typography>
            <TextField
              error={!!wrongAgendaOrder && wrongAgendaOrder === updatedAgenda.order}
              placeholder={`${updatedAgenda.order}`}
              autoComplete="off"
              defaultValue={updatedAgenda.order}
              inputProps={{ sx: { textAlign: 'center' } }}
              onChange={(e) => setUpdatedAgenda({ ...updatedAgenda, order: Number(e.target.value) })}
              sx={{ width: '58px' }}
            />
            <Stack direction="row">
              <Typography variant="subtitle1" color={theme.palette.grey[800]}>
                호&nbsp;
              </Typography>
              <sup style={{ color: theme.palette.common.red[500], marginRight: '8px' }}>*</sup>
            </Stack>
            <InfoOutlined sx={{ color: theme.palette.primary.main }} />
            <Typography variant="body2" color={theme.palette.primary.main}>
              안건은 낮은 숫자부터 차례대로 모바일 화면에 게시됩니다.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant="subtitle1" minWidth="100px" color={theme.palette.grey[800]}>
              안건 <sup style={{ color: theme.palette.common.red[500] }}>*</sup>
            </Typography>
            <TextField
              placeholder="예) 추진경과 보고의 건"
              error={!!errorMessage && updatedAgenda.title === ''}
              autoComplete="off"
              value={updatedAgenda.title}
              onChange={(e) => setUpdatedAgenda({ ...updatedAgenda, title: e.target.value })}
              sx={{ width: '100%' }}
            />
          </Stack>
          {updatedAgenda.type === GeneralMeetingAgendaType.VOTE && updatedAgenda.question && (
            <>
              <Stack direction="row" spacing={3} alignItems="center">
                <Typography variant="subtitle1" minWidth="100px" color={theme.palette.grey[800]}>
                  투표 종류
                </Typography>
                <RadioGroup
                  row
                  value={updatedAgenda.question.type}
                  onChange={(e) => {
                    resetAllErrorStatus();
                    if (unfilledOptIds) {
                      setUnfilledOptIds(undefined);
                    }
                    const { question, ...rest } = updatedAgenda;
                    setUpdatedAgenda({ ...rest, question: { ...question!, type: e.target.value as VoteQuestionType } });
                  }}
                >
                  <FormControlLabel value={VoteQuestionType.ELECTION} control={<Radio />} label="인명 투표" />
                  <FormControlLabel value={VoteQuestionType.AGENDA} control={<Radio />} label="안건 투표" />
                </RadioGroup>
              </Stack>
              <Stack direction="row" spacing={3} alignItems="center">
                <Typography variant="subtitle1" minWidth="100px" color={theme.palette.grey[800]}>
                  투표 방식
                </Typography>
                <RadioGroup
                  row
                  value={updatedAgenda.question.preset}
                  onChange={(e) => {
                    resetAllErrorStatus();
                    if (unfilledOptIds) {
                      setUnfilledOptIds(undefined);
                    }
                    const newPresetType = e.target.value as VoteOptionPresetType;
                    const { question, ...rest } = updatedAgenda;
                    let options: OptionalProperty<QuestionOptionModel, 'id' | 'questionId'>[];
                    if (newPresetType === VoteOptionPresetType.CHOICE) {
                      options = [{ id: -1, order: 1, description: '', result: null }, { id: -2, order: 2, description: '', result: null }];
                    } else {
                      options = [];
                    }
                    setUpdatedAgenda({
                      ...rest,
                      question: { ...question!, preset: newPresetType, options },
                    });
                  }}
                >
                  <FormControlLabel value={VoteOptionPresetType.FOR_OR_AGAINST} control={<Radio />} label="찬반 투표" />
                  <FormControlLabel value={VoteOptionPresetType.CHOICE} control={<Radio />} label="선택 투표" />
                </RadioGroup>
              </Stack>
              {updatedAgenda.question.preset === VoteOptionPresetType.CHOICE && (
                <Stack direction="row" spacing={3} alignItems="flex-start">
                  <Typography variant="subtitle1" minWidth="100px" color={theme.palette.grey[800]}>
                    보기 등록 <sup style={{ color: theme.palette.common.red[500] }}>*</sup>
                  </Typography>
                  <Stack spacing={2} width="100%">
                    {updatedAgenda.question.options.map((option, index) => (
                      <Stack key={option.id} direction="row" spacing={2} alignItems="center">
                        <Typography variant="body2" color={theme.palette.grey[800]}>
                          보기 <sup style={{ color: index < 2 ? theme.palette.common.red[500] : 'white' }}>*</sup>
                        </Typography>
                        <TextField
                          value={option.order}
                          error={wrongOptionIds?.includes(option.order)}
                          placeholder={`${index + 1}`}
                          autoComplete="off"
                          onChange={(e) => {
                            const { question, ...rest } = updatedAgenda;
                            setUpdatedAgenda({
                              ...rest,
                              question: {
                                ...question!,
                                options: question!.options.map((entry) => {
                                  if (entry.id !== option.id) {
                                    return entry;
                                  }

                                  return {
                                    ...entry,
                                    order: Number(e.target.value),
                                  };
                                }),
                              },
                            });
                          }}
                          inputProps={{ sx: { textAlign: 'center' } }}
                          sx={{
                            width: '58px',
                          }}
                        />
                        <TextField
                          value={option.description}
                          error={unfilledOptIds && unfilledOptIds.includes(option.id!)}
                          placeholder="보기"
                          autoComplete="off"
                          sx={{ flexGrow: 1 }}
                          onChange={(e) => {
                            const { question, ...rest } = updatedAgenda;
                            setUpdatedAgenda({
                              ...rest,
                              question: {
                                ...question!,
                                options: question!.options.map((entry) => {
                                  if (entry.id !== option.id) {
                                    return entry;
                                  }

                                  return {
                                    ...entry,
                                    description: e.target.value,
                                  };
                                }),
                              },
                            });
                          }}
                        />
                        {index > 1 && (
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => { handleRemoveOptions(option.id!); }}
                          ><Delete />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                    <Stack border={`1px solid ${theme.palette.grey[500]}`} borderRadius={1}>
                      <Button
                        variant="text"
                        fullWidth
                        sx={{ color: theme.palette.grey[800] }}
                        onClick={() => {
                          const { question, ...rest } = updatedAgenda;
                          const fakeId = min(
                            question!.options.filter((entry) => entry.id && entry.id < 0).map((entry) => entry.id),
                          ) ?? 0;
                          setUpdatedAgenda({
                            ...rest,
                            question: {
                              ...question!,
                              options: question!.options.concat({
                                id: fakeId - 1,
                                order: maxExistingAgendaOptionOrder + 1,
                                description: '',
                                result: null,
                              }),
                            },
                          });
                        }}
                      >
                        <AddCircleOutlineOutlined color="primary" sx={{ marginRight: '8px' }} />
                        보기 추가
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              {updatedAgenda.question.preset === VoteOptionPresetType.FOR_OR_AGAINST &&
                updatedAgenda.question.type === VoteQuestionType.ELECTION && (
                  <Stack direction="row" spacing={3} alignItems="center">
                    <Typography
                      variant="subtitle1"
                      minWidth="100px"
                      color={theme.palette.grey[800]}
                    >
                      후보자 이름{' '}
                      <sup style={{ color: theme.palette.common.red[500] }}>*</sup>
                    </Typography>
                    <TextField
                      placeholder="후보자 이름 입력"
                      error={!!errorMessage && updatedAgenda.question.title.length === 0}
                      autoComplete="off"
                      value={updatedAgenda.question.title}
                      onChange={(e) => {
                        const { question, ...rest } = updatedAgenda;
                        setUpdatedAgenda({
                          ...rest,
                          question: { ...question!, title: e.target.value },
                        });
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Stack>)}
            </>
          )}
          <Stack direction="row" spacing={3} alignItems="top">
            <Typography variant="subtitle1" minWidth="100px" color={theme.palette.grey[800]}>
              첨부파일
            </Typography>
            <Stack spacing={2} alignContent="center">
              {updatedAgenda.files.map((file) => (
                <Stack direction="row" spacing={1} key={`agenda-file-${file.id}`} alignItems="center">
                  <Typography variant="subtitle1" color={theme.palette.grey[800]}>
                    {file.originalName}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    sx={{ color: theme.palette.grey[800], alignSelf: 'start' }}
                    onClick={() => handleRemoveExistingFiles(file.id)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              ))}
              {attachmentFiles.map((file, index) => (
                <Stack direction="row" spacing={1} key={`${file.name}-${file.type}`} alignItems="center">
                  <Typography variant="subtitle1" color={theme.palette.grey[800]}>
                    {file.name}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    sx={{ color: theme.palette.grey[800], alignSelf: 'start' }}
                    onClick={() => handleRemoveNewFiles(index)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              ))}
              <Button variant="contained" color="inherit" component="label" sx={{ alignSelf: 'flex-start' }}>
                파일 선택
                <input type="file" onChange={onFileChange} multiple accept="image/*,.pdf" hidden />
              </Button>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <InfoOutlined sx={{ color: theme.palette.primary.main }} />
                <Typography variant="body2" color={theme.palette.primary.main}>
                  pdf 또는 이미지 파일만 첨부할 수 있습니다.
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="center" spacing={2} pt="16px">
            <Button variant="contained" color="inherit" sx={{ minWidth: '80px' }} onClick={exit}>
              취소
            </Button>
            <Button variant="contained" color="primary" sx={{ minWidth: '80px' }} onClick={handleSave}>
              저장
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
