import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import VoteBanner from '../../../../../../assets/bg_create_vote_banner.svg';
import constants from '../../../../../../config/constants';

export default function CreateVotePage() {
  const navigate = useNavigate();
  const theme = useTheme();

  const openKevotingLoginPage = useCallback(() => {
    const kevotingLoginUrl: string =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 'https://kevoting.polloud.com/loginAdminForm'
        : 'https://kevoting.co.kr/loginAdminForm';
    window.open(kevotingLoginUrl);
  }, []);

  return (
    <Stack width="100%">
      <Button
        variant="text"
        startIcon={<ArrowBackIcon sx={{ color: theme.palette.grey[800] }} />}
        sx={{ alignSelf: 'start', color: theme.palette.grey[800] }}
        onClick={() => {
          navigate(-1);
        }}
      >
        뒤로 돌아가기
      </Button>
      <Typography variant="h2" alignSelf="start" sx={{ marginTop: 5 }}>
        투표 만들기
      </Typography>
      <Stack
        sx={{ marginTop: 10 }}
        bgcolor={theme.palette.primary.main}
        padding={5}
        direction="row"
        justifyContent="space-between"
      >
        <Stack spacing={2}>
          <Typography variant="h2" color={theme.palette.common.white} alignSelf="start">
            사업장을 위한 전자투표 서비스
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.common.white} alignSelf="start" textAlign="start">
            더집은 국내외 다양한 선거에 채택된 Kevoting 서비스와 연계하고 있습니다.
            <br />
            아래 투표 생성하기 버튼을 눌러 투명하고 공정하며 편리한 전자투표서비스를 활용해 보세요.
          </Typography>
          <Typography variant="subtitle3" color={theme.palette.common.white} alignSelf="start" textAlign="start">
            사업장 생성 당시 제공된 아이디로 Kevoting에 로그인하여 투표를 만들어주세요.
            <br />
            {`(계정 문의: ${constants.csMailAddress})`}
          </Typography>
          <Button
            variant="contained"
            color="inherit"
            sx={{
              marginTop: 3,
              alignSelf: 'start',
              color: theme.palette.primary.main,
              bgcolor: theme.palette.common.white,
            }}
            onClick={openKevotingLoginPage}
          >
            투표 생성하기
          </Button>
        </Stack>
        <img src={VoteBanner} alt="" />
      </Stack>
    </Stack>
  );
}
