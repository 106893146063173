import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { GeneralMeetingVoteBoxResponseModel, VotePurpose } from '../../../../../../models/general-meeting.model';
import { convertDateFormat24H } from '../../../../../../util/functions';

interface VoteRegisterKindSelectComponentProps {
  votePurpose: VotePurpose;
  selectableVotes: GeneralMeetingVoteBoxResponseModel[];
  disabledId?: string;
  onChanged: (selectedId: string, votePurpose: VotePurpose) => void;
}

export default function VoteRegisterKindSelectionComponent(props: VoteRegisterKindSelectComponentProps) {
  const { votePurpose, selectableVotes, disabledId, onChanged } = props;
  const [selectedId, setSelectedId] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    const selectedIdToString = event.target.value as string;
    setSelectedId(selectedIdToString);
    onChanged(selectedIdToString, votePurpose);
  };

  const checkDisabled = useCallback(
    (compareId: number): boolean => {
      if (disabledId === '-1') { return false; }
      return disabledId === String(compareId);
    }, [disabledId]);

  const translatePurposeToKo = useCallback((purpose: VotePurpose): string => {
    switch (purpose) {
      case VotePurpose.BALLOT:
        return '당일 투표';
      case VotePurpose.RESOLUTION:
        return '전자서면결의';
      default:
        return '';
    }
  }, []);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="body1" minWidth="100px">{`${translatePurposeToKo(votePurpose)}:`}</Typography>
      <FormControl sx={{ flex: 1 }}>
        <Select
          value={selectedId}
          onChange={handleChange}
          displayEmpty
          renderValue={selectedId ? undefined : () => '투표를 선택해주세요'}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ minHeight: '80px' }}
        >
          <MenuItem value="-1">
            <Typography variant="body2" py="12px">선택안함</Typography>
          </MenuItem>
          {selectableVotes.map((vote) => (
            <MenuItem key={vote.id} value={vote.id} disabled={checkDisabled(vote.id)}>
              <Stack>
                <Typography variant="subtitle2">{vote.title}</Typography>
                {vote.startDate && (
                <Typography variant="body2">{`${convertDateFormat24H(vote.startDate)} ~ ${convertDateFormat24H(vote.startDate)}`}</Typography>
                )}
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
