import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getLibraries } from '../../../../api/library.api';
import { LibraryFile, ServerError } from '../../../../models';
import LibraryHeaderView from './header';
import LibraryListView from './list';

type LibraryPageProps = {
  unionId: number;
};
export default function LibraryPage({ unionId }: LibraryPageProps) {
  const [libraries, setLibraries] = useState<LibraryFile[]>([]);
  const [filteredLibraries, setFilteredLibraries] = useState<LibraryFile[]>([]);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [searchText, setSearchText] = useState('');

  const fetchLibraries = useCallback(async () => {
    const result = await getLibraries(unionId);
    if (result instanceof ServerError) {
      return;
    }
    setLibraries(result.libraryFiles);
  }, [unionId]);

  useEffect(() => {
    fetchLibraries();
  }, [fetchLibraries]);

  useEffect(() => {
    if (needsRefresh) {
      fetchLibraries();
      setNeedsRefresh(false);
    }
  }, [fetchLibraries, needsRefresh, unionId]);

  const onRefresh = () => {
    setNeedsRefresh(true);
  };

  useEffect(() => {
    setFilteredLibraries(libraries);
  }, [libraries]);

  useEffect(() => {
    if (!searchText) {
      setFilteredLibraries(libraries);
    } else {
      const d = libraries.filter(({ originalName }: LibraryFile) =>
        [originalName].some((v) => v?.toLowerCase().includes(searchText.toLowerCase().trim())),
      );
      setFilteredLibraries(d);
    }
  }, [libraries, searchText]);

  return (
    <Stack spacing={4}>
      <LibraryHeaderView
        unionId={unionId}
        searchText={searchText}
        setSearchText={setSearchText}
        onRefresh={onRefresh}
      />
      <LibraryListView libraryList={filteredLibraries} onRefresh={onRefresh} unionId={unionId} />
    </Stack>
  );
}
