import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import MeetingMobilePreview from '../../../../../assets/preview_meeting_mobile.svg';

interface MeetingMobilePreviewDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function MeetingMobilePreviewDialog({ open, onClose }: MeetingMobilePreviewDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 320, marginTop: 2 }}>
        <Typography variant="subtitle2" textAlign="center">
          입력한 정보는 다음과 같이 보입니다.
        </Typography>
        <img src={MeetingMobilePreview} alt="" style={{ marginTop: 16 }} />
      </DialogContent>
    </Dialog>
  );
}
