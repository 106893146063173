import { MessageSendingHistoryModel, MessageSendingResultModel } from '../models/message-sending-history.model';
import { SendingMessageForm } from '../models/message-sending.model';
import { MessageTemplateModel } from '../models/message-template.model';
import { ApiClient, handleError } from '../util/ApiClient';

export const getMessageRequestHistoryList = async (unionId: number) => {
  try {
    const response = await ApiClient().get<MessageSendingHistoryModel[]>(`/unions/${unionId}/message-requests`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

interface MessageRequestHistoryParams {
  unionId: number;
  requestId: number;
}
export const getMessageResultHistory = async ({ unionId, requestId }: MessageRequestHistoryParams) => {
  try {
    const response = await ApiClient().get<MessageSendingResultModel[]>(
      `/unions/${unionId}/message-requests/${requestId}/messages`,
    );
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMessageTemplate = async (templateCode: string) => {
  try {
    const response = await ApiClient().get<MessageTemplateModel>(`/templates/${templateCode}`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

interface RequestToSendMessageParams {
  unionId: number;
  params: SendingMessageForm;
}
export const requestToSendMessage = async ({ unionId, params }: RequestToSendMessageParams) => {
  try {
    const response = await ApiClient().post(`unions/${unionId}/message-requests`, JSON.stringify(params), {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.status;
  } catch (error) {
    throw handleError(error);
  }
};
