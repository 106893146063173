import CustomerModel from './customer.model';

export default interface UnionMemberModel {
  id: number;
  serialNumber: string;
  name: string;
  phone: string | null;
  address: string;
  type: UnionMemberType;
  grade: UnionMemberGrade;
  partialRrn: string | null;
  companyRegistrationNumber: string | null;
  assetAddress: string;

  unionId: number;
  customers?: CustomerModel[];
}

export enum UnionMemberType {
  CITIZEN_DOMESTIC = '내국인',
  CITIZEN_FOREIGN = '내국인(해외거주)',
  FOREIGNER = '외국인',
  CORPORATION = '사업자',
}

export enum UnionMemberGrade {
  GENERAL = '일반조합원',
  LEADER = '조합장',
  DELEGATE = '대의원',
  DIRECTOR = '이사',
  AUDITOR = '감사',
}

export interface UnionMemberInformationUpdateData {
  name: string;
  phone: string;
  address: string;
}
