/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { AddCircleOutlineSharp } from '@mui/icons-material';
import { Button, Stack, Theme, Typography, styled, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import AppContext from '../../../../AppContext';
import { deleteNotice, getNoticeList } from '../../../../api/post.api';
import { Post, ServerError } from '../../../../models';
import StaffRoleModel from '../../../../models/staff-role.model';
import { isInternalStaff } from '../../../../util/functions';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';
import NoticeCreationDialog from './notice-creation-dialog';
import NoticeViewerDialog from './viewer-dialog';

type DataKey = 'location' | 'createdAt' | 'title' | 'viewCount' | 'replyCount' | 'upvoteCount' | 'action' | 'writer';
interface ColumnData {
  dataKey: DataKey;
  label: string;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 80,
    label: '위치',
    dataKey: 'location',
  },
  {
    width: 200,
    label: '제목',
    dataKey: 'title',
  },
  { width: 100, label: '작성자', dataKey: 'writer' },
  {
    width: 100,
    label: '게시일',
    dataKey: 'createdAt',
  },
  {
    width: 80,
    label: '조회',
    dataKey: 'viewCount',
  },
  {
    width: 80,
    label: '댓글',
    dataKey: 'replyCount',
  },
  {
    width: 80,
    label: '좋아요',
    dataKey: 'upvoteCount',
  },
  {
    width: 120,
    label: '',
    dataKey: 'action',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
  // hide last border
  '&:last-child td, &:last-child th': { border: 0 },
}));

const authorizedColumns = (isInternal: StaffRoleModel | undefined) => {
  if (isInternal) {
    return columns.filter((column) => column.dataKey !== 'action');
  }

  return columns;
};

function fixedHeaderContent(theme: Theme, isInternal: StaffRoleModel | undefined) {
  return (
    <TableRow sx={{ background: theme.palette.primary.light }}>
      {authorizedColumns(isInternal).map((column) => (
        <StyledTableCell key={column.dataKey} variant="head" align="left" style={{ width: column.width }}>
          <Typography variant="subtitle2">{column.label}</Typography>
        </StyledTableCell>
      ))}
    </TableRow>
  );
}

function rowContent(
  _index: number,
  row: Post,
  onDelete: (d: Post) => Promise<void>,
  onNavigate: () => void,
  onViewer: () => void,
  isInternal: StaffRoleModel | undefined,
) {
  const content = (key: DataKey) => {
    if (key === 'location') {
      return row.postWall?.title;
    }
    if (key === 'viewCount') {
      return (
        <Typography color="#0073EA" sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onViewer}>
          {row.viewCount}
        </Typography>
      );
    }
    if (key === 'action') {
      return (
        <Stack direction="row" spacing={3}>
          <Button variant="outlined" onClick={onNavigate} size="small">
            수정
          </Button>
          <Button color="error" onClick={() => onDelete(row)}>
            삭제
          </Button>
        </Stack>
      );
    }
    if (key === 'createdAt') {
      return dayjs(row.createdAt).format('YYYY.MM.DD HH:mm');
    }
    if (key === 'writer') {
      return row.staff?.name;
    }
    return (
      <Typography
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
        }}
      >
        {row[key]}
      </Typography>
    );
  };
  return (
    <>
      {authorizedColumns(isInternal).map((column) => (
        <TableCell key={`${row.id}-${column.dataKey}`} align="left">
          {content(column.dataKey)}
        </TableCell>
      ))}
    </>
  );
}

interface Props {
  unionId: number;
}

export default function NoticePage({ unionId }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [noticeList, setNoticeList] = useState<Post[]>([]);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [noticeToRemove, setNoticeToRemove] = useState<Post>();
  const [selectedForViewer, setSelectedForViewer] = useState<Post>();
  const { staff } = useContext(AppContext);

  const isInternal = useMemo(() => isInternalStaff(staff), [staff]);

  const onViewerClick = (post: Post) => {
    setSelectedForViewer(post);
  };
  const onEditClick = (post: Post) => {
    navigate({
      pathname: `/unions/${unionId}/notices/${post.id}/edit`,
    });
  };
  const fetchData = useCallback(async () => {
    const result = await getNoticeList(unionId);
    if (result instanceof ServerError) {
      return;
    }
    setNoticeList(result.postList);
  }, [unionId]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (needsRefresh) {
      fetchData();
      setNeedsRefresh(false);
    }
  }, [fetchData, needsRefresh]);
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);
  const VirtuosoTableComponents: TableComponents<Post> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
    Table: (props) => <Table {...props} sx={{ tableLayout: 'fixed' }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => <StyledTableRow {...props} sx={{ borderBottom: '1px solid #DDDDDD' }} />,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
    EmptyPlaceholder: () => (
      <TableBody>
        <TableRow>
          <TableCell colSpan={authorizedColumns(isInternal).length}>
            <Typography variant="subtitle1" textAlign="center">
              내용이 없습니다.
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    ),
  };

  const selectForRemove = async (post: Post) => {
    setNoticeToRemove(post);
  };
  const onRefresh = async () => {
    setNeedsRefresh(true);
  };

  const onRemove = useCallback(async () => {
    if (noticeToRemove) {
      const result = await deleteNotice(noticeToRemove.id, unionId);
      if (result instanceof ServerError) {
        return;
      }
      onRefresh();
    }
  }, [noticeToRemove, unionId]);

  return (
    <Stack alignItems="start" height="800px" spacing={4}>
      <Stack direction="row" width="100%" alignItems="center" justifyContent="start">
        <Typography flex={1} textAlign="start" variant="h2">
          공지사항
        </Typography>
        {!isInternal && (
          <Button variant="contained" onClick={() => setCreationDialogOpen(true)} size="small">
            공지사항 추가
            <AddCircleOutlineSharp sx={{ marginLeft: '4px' }} />
          </Button>
        )}
      </Stack>

      <TableVirtuoso
        data={noticeList}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => fixedHeaderContent(theme, isInternal)}
        itemContent={(idx, rowData) =>
          rowContent(
            idx,
            rowData,
            selectForRemove,
            () => onEditClick(rowData),
            () => onViewerClick(rowData),
            isInternal,
          )
        }
      />
      <NoticeCreationDialog
        open={creationDialogOpen}
        onClose={() => setCreationDialogOpen(false)}
        unionId={unionId}
        onRefresh={onRefresh}
      />
      <SuccessErrorModal
        isSuccess={false}
        title="공지사항 삭제"
        description="정말 공지사항을 삭제 하시겠습니까?"
        open={!!noticeToRemove}
        onClose={() => setNoticeToRemove(undefined)}
        onConfirm={onRemove}
      />
      <NoticeViewerDialog
        viewers={selectedForViewer?.viewers}
        open={!!selectedForViewer}
        onClose={() => setSelectedForViewer(undefined)}
      />
    </Stack>
  );
}
