import { AddCircleOutlineSharp, DownloadOutlined, Search, UploadFileOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  ClickAwayListener,
  Divider,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueGetterParams, gridClasses } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { parseInt } from 'lodash';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import AppContext from '../../../../AppContext';
import { getUnionMembers, removeMemberFromUnion } from '../../../../api/union.api';
import { TransitionUp, useTooltip } from '../../../../hooks';
import { ServerError, UnionMember } from '../../../../models';
import { isInternalStaff } from '../../../../util/functions';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';
import TemplateSendingDialog from '../message/send/template-sending-dialog';
import UnionMemberExcelUpload from './excel-upload';
import UnionMemberInvitationDialog from './invitation-dialog';
import MemberGradeModificationDialog from './member-grade-modification-dialog';
import MemberModificationDialog from './member-modification-dialog';

interface Props {
  unionId: number;
  unionName: string;
}

const appInvitationTemplateCode = 'bizp_2023113014591994618786535';

const UploadButtonTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(97, 97, 97, 0.9)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#616161',
    color: 'rgba(97, 97, 97, 0.9)',
    borderRadius: '4px',
  },
}));

export default function UnionMemberPage({ unionId, unionName }: Props) {
  const theme = useTheme();
  const { selectedUnion, staff } = useContext(AppContext);
  const [unionMembers, setUnionMembers] = useState<UnionMember[]>([]);
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);
  const [excelUploadMode, setExcelUploadMode] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<UnionMember>();
  const [memberToModify, setMemberToModify] = useState<UnionMember>();
  const [memberGradeToModify, setMemberGradeToModify] = useState<UnionMember>();
  const [searchText, setSearchText] = useState('');
  const [searchedData, setSearchedData] = useState<UnionMember[]>(unionMembers);
  const [selectedTemplateCode, setSelectedTemplateCode] = useState<string>();
  const [isSuccessRequest, setIsSuccessRequest] = useState(false);
  const [errMsg, setErrMsg] = useState<string>();
  const [toastMessage, setToastMessage] = useState('');
  const isToastOpen = useMemo(() => {
    if (toastMessage === '') {
      return false;
    }
    return true;
  }, [toastMessage]);
  const [lightTooltip, setTooltip] = useTooltip({
    content: (
      <Typography variant="subtitle2" color={theme.palette.grey[800]} textAlign="start">
        <li>앱 초대 메시지는 카카오톡 알림톡을 통해 발송되며, 메시지는 수정이 불가능합니다.</li>
        <li>명부에 등록되어 있으나 앱을 설치하지 않은 구성원에게만 발송됩니다.</li>
      </Typography>
    ),
    maxWidth: 600,
    placement: 'bottom-start',
  });

  const unregisteredMemberCount: number = useMemo(
    () => unionMembers.filter((member) => member.customers?.length === 0).length,
    [unionMembers],
  );

  const isUploadButtonDisabled = useMemo(() => unionMembers.length > 0, [unionMembers]);

  const uploadTooltipText = useMemo(
    () => (
      <Typography
        variant="body3"
        color={theme.palette.common.white}
        style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
      >
        구성원 명부 엑셀 파일 업로드는 최초 1회만 가능합니다. 고객 센터로 문의해 주세요.
      </Typography>
    ),
    [theme.palette.common.white],
  );

  const uploadButton = useMemo(() => {
    const button = (
      <Button
        variant="outlined"
        onClick={() => setExcelUploadMode(true)}
        size="small"
        disabled={isUploadButtonDisabled}
        sx={(pTheme) => ({
          ':disabled': { backgroundColor: pTheme.palette.grey[200], borderColor: pTheme.palette.grey[200] },
        })}
      >
        명부 업로드
        <UploadFileOutlined sx={{ marginLeft: '4px' }} />
      </Button>
    );

    if (isUploadButtonDisabled) {
      return (
        <UploadButtonTooltip title={uploadTooltipText} arrow>
          <span>{button}</span>
        </UploadButtonTooltip>
      );
    }
    return button;
  }, [isUploadButtonDisabled, uploadTooltipText]);

  const getOXDashLabel = useCallback((isDashNeeded: boolean, length?: number, isAccepted?: boolean) => {
    if (isDashNeeded) {
      if (length === undefined || length === 0 || isAccepted === undefined) {
        return '-';
      }

      if (isAccepted) {
        return 'O';
      }

      return 'X';
    }

    if (length === undefined || length === 0) {
      return 'X';
    }

    return 'O';
  }, []);

  const fetchUnionMembers = useCallback(async () => {
    try {
      const members = await getUnionMembers(unionId);
      setUnionMembers(members);
    } catch (error) {
      // error 처리 필요
    }
  }, [unionId]);

  const onUpload = useCallback(() => {
    fetchUnionMembers();
    setExcelUploadMode(false);
  }, [fetchUnionMembers]);

  const onRemove = useCallback(async () => {
    if (memberToRemove) {
      const result = await removeMemberFromUnion({ unionMemberId: memberToRemove.id });
      if (result instanceof ServerError) {
        return;
      }
      fetchUnionMembers();
    }
  }, [fetchUnionMembers, memberToRemove]);

  const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchedData(unionMembers);
    } else {
      const d = unionMembers.filter(
        ({ name, phone, address, grade, type, partialRrn, companyRegistrationNumber, serialNumber }: UnionMember) =>
          [name, phone, address, grade, type, partialRrn, companyRegistrationNumber, serialNumber].some((v) =>
            v?.toLowerCase().includes(searchText.toLowerCase().trim()),
          ),
      );
      setSearchedData(d);
    }
  }, [unionMembers, searchText]);

  const downloadMembers = useCallback(() => {
    const formattedData = unionMembers.map(
      ({
        serialNumber,
        name,
        phone,
        address,
        assetAddress,
        type,
        grade,
        partialRrn,
        companyRegistrationNumber,
        customers,
      }) => ({
        조합원번호: serialNumber,
        성명: name,
        연락처: phone,
        주소: address,
        소유부동산: assetAddress,
        구분: type,
        등급: grade,
        '생년월일/사업자번호': partialRrn || companyRegistrationNumber,
        '커뮤니티 가입': getOXDashLabel(false, customers?.length),
        '전자적 방식 동의': getOXDashLabel(true, customers?.length, customers?.at(0)?.acceptedElectronicNotice),
      }),
    );
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [['조합원번호', '성명', '연락처', '주소', '소유부동산', '구분', '등급', '생년월일/사업자번호']],
      { origin: 'A1' },
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '명부');
    XLSX.writeFile(workbook, '명부.xlsx');
  }, [unionMembers, getOXDashLabel]);

  const getGenderFromRrn = (rrn: string): string => {
    const genderNum = parseInt(rrn.slice(-1));
    if (genderNum % 2 === 1) {
      return 'M';
    }
    return 'F';
  };

  const getFormattedRrnForVote = (rrn: string): string => {
    const genderNum = parseInt(rrn.slice(-1));
    const basisRrn = rrn.slice(0, 6);
    let yearPrefix: string;
    if (genderNum < 3 || (genderNum >= 5 && genderNum < 7)) {
      yearPrefix = '19';
    } else {
      yearPrefix = '20';
    }
    return yearPrefix + basisRrn;
  };

  const downloadKevMembersForVote = useCallback(() => {
    const memberHasPartialRrn = unionMembers
      .filter((member) => !!member.partialRrn)
      .map(({ name, phone, grade, partialRrn, assetAddress }) => ({
        이름: name,
        '휴대전화 번호': phone,
        선거구: grade,
        성별: getGenderFromRrn(partialRrn!),
        생년월일: getFormattedRrnForVote(partialRrn!),
        소유지번주소: assetAddress,
      }));
    const worksheet = XLSX.utils.json_to_sheet(memberHasPartialRrn);
    XLSX.utils.sheet_add_aoa(worksheet, [['이름', '휴대전화 번호', '선거구', '성별', '생년월일', '소유지번주소']], {
      origin: 'A1',
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '투표용 명부');
    XLSX.writeFile(workbook, `${unionName}_${dayjs().format('YYYYMMDD')}.xlsx`);
  }, [unionMembers, unionName]);

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'serialNumber', headerName: '고유번호', align: 'left', disableColumnMenu: true },
      { field: 'name', headerName: '이름', align: 'left', disableColumnMenu: true },
      { field: 'type', headerName: '구분', align: 'left', disableColumnMenu: true },
      { field: 'grade', headerName: '등급', align: 'left', disableColumnMenu: true },
      { field: 'phone', headerName: '연락처', align: 'left', disableColumnMenu: true, minWidth: 120 },
      {
        field: 'rrnOrRegist',
        headerName: '생년월일/사업자번호',
        align: 'left',
        valueGetter: (params: GridValueGetterParams) => params.row.partialRrn || params.row.companyRegistrationNumber,
        minWidth: 160,
        disableColumnMenu: true,
      },
      {
        field: 'address',
        headerName: '주소',
        align: 'left',
        disableColumnMenu: true,
        minWidth: 240,
        renderCell: (params) => (
          <Typography variant="body2" textAlign="start">
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'assetAddress',
        minWidth: 240,
        disableColumnMenu: true,
        align: 'left',
        renderHeader: () => (
          <Typography textAlign="start" variant="subtitle2">
            권리 내역
            <br />
            (소유 부동산)
          </Typography>
        ),
        renderCell: (params) => (
          <Typography variant="body2" textAlign="start">
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'registered',
        renderHeader: () => (
          <Typography variant="subtitle2">
            커뮤니티
            <br />
            가입여부
          </Typography>
        ),
        align: 'center',
        valueGetter: (params: GridValueGetterParams) => getOXDashLabel(false, params.row.customers.length),
        disableColumnMenu: true,
      },
      {
        field: 'acceptedElectronicNotice',
        renderHeader: () => (
          <Typography variant="subtitle2">
            전자적 방식
            <br />
            동의
          </Typography>
        ),
        align: 'center',
        valueGetter: (params: GridValueGetterParams) =>
          getOXDashLabel(true, params.row.customers.length, params.row.customers?.at(0)?.acceptedElectronicNotice),
        disableColumnMenu: true,
      },
      {
        field: 'modifyGrade',
        headerName: '',
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        renderCell: (params) => (
          <Button variant="outlined" color="primary" onClick={() => setMemberGradeToModify(params.row)}>
            등급
          </Button>
        ),
      },
      {
        field: 'modifyInformation',
        headerName: '',
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        renderCell: (params) => (
          <Button variant="outlined" color="primary" onClick={() => setMemberToModify(params.row)}>
            수정
          </Button>
        ),
      },
      {
        field: 'remove',
        headerName: '',
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        renderCell: (params) => (
          <Button color="error" onClick={() => setMemberToRemove(params.row)}>
            삭제
          </Button>
        ),
      },
    ],
    [getOXDashLabel],
  );

  const clearToastMessage = useCallback(() => {
    setToastMessage('');
  }, []);

  const clearMemberToModify = useCallback(() => {
    setMemberToModify(undefined);
  }, []);

  const handleMemberModificationSuccess = useCallback(() => {
    fetchUnionMembers();
    clearMemberToModify();
    setToastMessage('구성원 정보가 수정되었습니다.');
  }, [clearMemberToModify, fetchUnionMembers]);

  const clearMemberGradeToModify = useCallback(() => {
    setMemberGradeToModify(undefined);
  }, []);

  const handleSuccessToModifyMemberGrade = useCallback(() => {
    fetchUnionMembers();
    clearMemberGradeToModify();
    setToastMessage('구성원의 등급이 변경되었습니다.');
  }, [clearMemberGradeToModify, fetchUnionMembers]);

  useEffect(() => {
    fetchUnionMembers();
  }, [fetchUnionMembers]);

  return (
    <Stack alignItems="start" height="800px" spacing={4}>
      {!excelUploadMode && (
        <Stack spacing={2}>
          <Stack direction="row" spacing={3} alignItems="center" width="100%">
            <Typography variant="h2">구성원</Typography>
            <Divider orientation="vertical" />
            <TextField
              size="small"
              placeholder="검색"
              InputProps={{ startAdornment: <Search /> }}
              value={searchText}
              onChange={onSearchTextChange}
              autoComplete="off"
            />
            <Typography flex={1} />
            <Button variant="outlined" size="small" onClick={downloadKevMembersForVote}>
              전자투표용 명부 다운로드
              <DownloadOutlined sx={{ marginLeft: '4px' }} />
            </Button>
            <Button variant="outlined" size="small" onClick={downloadMembers}>
              명부 다운로드
              <DownloadOutlined sx={{ marginLeft: '4px' }} />
            </Button>
            {uploadButton}
            <Button variant="contained" onClick={() => setCreationDialogOpen(true)} size="small">
              구성원 추가
              <AddCircleOutlineSharp sx={{ marginLeft: '4px' }} />
            </Button>
          </Stack>
          {!isInternalStaff(staff) && (
            <ClickAwayListener onClickAway={() => setTooltip(false)}>
              <Stack direction="row">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedTemplateCode(appInvitationTemplateCode);
                  }}
                  sx={{
                    alignSelf: 'start',
                    border: `1px solid ${theme.palette.grey[500]}`,
                    borderRadius: '4px',
                    py: '4px',
                    px: '8px',
                  }}
                >
                  <Typography variant="subtitle2" color={theme.palette.grey[800]}>
                    더집 앱 초대 메시지 보내기
                  </Typography>
                </Button>
                {lightTooltip}
              </Stack>
            </ClickAwayListener>
          )}
        </Stack>
      )}
      {excelUploadMode ? (
        <UnionMemberExcelUpload unionId={unionId} onRefresh={onUpload} onCancel={() => setExcelUploadMode(false)} />
      ) : (
        <div style={{ height: '100%', width: '100%' }}>
          <DataGrid
            rows={searchedData}
            columns={columns}
            hideFooterPagination={searchedData.length === 0}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25]}
            localeText={{ noRowsLabel: searchText ? '검색된 결과가 없습니다' : '내용이 없습니다.' }}
            rowSelection={false}
            getRowHeight={() => 'auto'}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 2,
              },
              [`& .${gridClasses.columnHeaders}`]: {
                width: '100%',
                background: '#CCE5FF',
              },
              [`& .${gridClasses.columnHeaderTitle}`]: {
                font: theme.typography.subtitle2,
              },
              [`& .${gridClasses.cell}:focus`]: {
                outline: 'none',
              },
            }}
          />
        </div>
      )}
      <UnionMemberInvitationDialog
        open={creationDialogOpen}
        onClose={() => setCreationDialogOpen(false)}
        unionId={unionId}
        onRefresh={fetchUnionMembers}
      />
      <SuccessErrorModal
        isSuccess={false}
        title=""
        description="초대할 대상이 없습니다."
        open={!!selectedTemplateCode && unregisteredMemberCount === 0}
        onClose={() => setSelectedTemplateCode(undefined)}
      />
      <SuccessErrorModal
        isSuccess={false}
        title=""
        description="정말 구성원을 삭제 하시겠습니까?"
        open={!!memberToRemove}
        onClose={() => setMemberToRemove(undefined)}
        onConfirm={onRemove}
      />
      <TemplateSendingDialog
        unionId={unionId}
        templateCode={selectedTemplateCode}
        totalReceivers={unregisteredMemberCount}
        messageVariables={{ 사업자명: selectedUnion?.name ?? '' }}
        onClose={() => setSelectedTemplateCode(undefined)}
        onSuccess={() => {
          setSelectedTemplateCode(undefined);
          setIsSuccessRequest(true);
        }}
        onError={(msg) => setErrMsg(msg)}
      />
      <SuccessErrorModal
        isSuccess={!errMsg}
        title={errMsg ? '발송 실패' : '발송 완료'}
        description={errMsg || '발송이 완료되었습니다.'}
        open={!!errMsg || isSuccessRequest}
        onClose={() => {
          setErrMsg(undefined);
          setIsSuccessRequest(false);
        }}
      />
      <MemberModificationDialog
        open={!!memberToModify}
        unionId={unionId}
        selectedMember={memberToModify}
        onClose={clearMemberToModify}
        onSuccess={handleMemberModificationSuccess}
      />

      <MemberGradeModificationDialog
        open={!!memberGradeToModify}
        selectedMember={memberGradeToModify}
        onClose={clearMemberGradeToModify}
        onSuccess={handleSuccessToModifyMemberGrade}
      />

      <Snackbar
        open={isToastOpen}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={TransitionUp}
        onClose={clearToastMessage}
      >
        <Alert
          icon={false}
          sx={{
            width: '342px',
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.common.white,
            borderRadius: 1,
          }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
