import { CheckCircle } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, Typography, useTheme } from '@mui/material';

interface VoteResultPostConfirmDialogProps {
  open: boolean;
  onInputContinue: () => void;
  onPublish: () => void;
}

const VoteResultPostConfirmDialog = ({ open, onInputContinue, onPublish }: VoteResultPostConfirmDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: 417 }}>
        <Stack spacing={3} alignItems="center">
          <CheckCircle style={{ fontSize: 80, color: theme.palette.common.green[500] }} />
          <Typography variant="body1" color={theme.palette.grey[900]} textAlign="center">
            투표 결과를 모바일 화면에 게시 하시겠습니까?
          </Typography>
          <Stack direction="row" spacing={2} width="100%">
            <Button variant="outlined" color="primary" sx={{ flex: 1 }} onClick={onInputContinue}>
              취소
            </Button>
            <Button variant="contained" color="primary" sx={{ flex: 1 }} onClick={onPublish}>
              게시하기
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VoteResultPostConfirmDialog;
