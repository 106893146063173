import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { Customer } from '../../models';
import { CommonTableCell, CommonTableRow } from '../common/table';

type CustomerListViewProps = {
  customers: Customer[];
};

export default function CustomerListView({ customers }: CustomerListViewProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <CommonTableCell>닉네임</CommonTableCell>
            <CommonTableCell>이름</CommonTableCell>
            <CommonTableCell>이메일</CommonTableCell>
            <CommonTableCell>생년월일</CommonTableCell>
            <CommonTableCell>로그인 수단</CommonTableCell>
            <CommonTableCell>가입일</CommonTableCell>
            <CommonTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((row) => (
            <CommonTableRow key={row.id}>
              <CommonTableCell component="th" scope="row">
                {row.nickname}
              </CommonTableCell>
              <CommonTableCell>{row.name}</CommonTableCell>
              <CommonTableCell>{row.email || '-'}</CommonTableCell>
              <CommonTableCell>{}</CommonTableCell>
              <CommonTableCell>{row.customerSocial?.serviceProvider || 'IDPW'}</CommonTableCell>
              <CommonTableCell>{dayjs(row.createdAt).format('YYYY.MM.DD')}</CommonTableCell>
              <CommonTableCell>{}</CommonTableCell>
            </CommonTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
