/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { AddCircleOutlineSharp } from '@mui/icons-material';
import { Button, Stack, Theme, Typography, styled, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import AppContext from '../../../../AppContext';
import { removeOperatorFromUnion } from '../../../../api/union.api';
import { ServerError, Staff, UnionOperator, UnionOperatorType } from '../../../../models';
import { isInternalStaff } from '../../../../util/functions';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';
import UnionOperatorCreationDialog from './union-operator-creation';

type DataKey = 'name' | 'phone' | 'email' | 'role' | 'action';
interface ColumnData {
  dataKey: DataKey;
  label: string;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 120,
    label: '이름',
    dataKey: 'name',
  },
  {
    width: 120,
    label: '직책',
    dataKey: 'role',
  },
  {
    width: 120,
    label: '연락처',
    dataKey: 'phone',
  },
  {
    width: 120,
    label: '이메일',
    dataKey: 'email',
  },
  {
    width: 120,
    label: '',
    dataKey: 'action',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
  // hide last border
  '&:last-child td, &:last-child th': { border: 0 },
}));
function fixedHeaderContent(theme: Theme) {
  return (
    <TableRow sx={{ background: theme.palette.primary.light }}>
      {columns.map((column) => (
        <StyledTableCell key={column.dataKey} variant="head" align="center" style={{ width: column.width }}>
          <Typography variant="subtitle2">
            {column.label}
          </Typography>
        </StyledTableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index: number, row: Staff, onDelete?: (d: UnionOperator) => Promise<void>) {
  const content = (key: DataKey) => {
    if (key === 'name' || key === 'phone' || key === 'email') {
      return row[key];
    }
    if (key === 'role') {
      return row.unionOperator.type;
    }
    if (key === 'action' && onDelete) {
      return (
        <Button color="error" onClick={() => onDelete(row.unionOperator)}>
          삭제하기
        </Button>
      );
    }
    return '';
  };
  return (
    <>
      {columns.map((column) => (
        <TableCell key={`${row.id}-${column.dataKey}`} align="center">
          {content(column.dataKey)}
        </TableCell>
      ))}
    </>
  );
}
interface Props {
  data: Staff[];
  unionId: number;
  onRefresh: () => void;
}

export default function UnionOperatorPage({ data, unionId, onRefresh }: Props) {
  const { selectedUnion, staff } = useContext(AppContext);
  const theme = useTheme();
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);
  const [staffToRemove, setStaffToRemove] = useState<UnionOperator>();
  const VirtuosoTableComponents: TableComponents<Staff> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
    Table: (props) => <Table {...props} sx={{ tableLayout: 'fixed' }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => <StyledTableRow {...props} sx={{ borderBottom: '1px solid #DDDDDD' }} />,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
    EmptyPlaceholder: () => (
      <TableBody>
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Typography variant="subtitle1" color={theme.palette.grey[600]} textAlign="center">
              내용이 없습니다.
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    ),
  };
  const selectForRemove = async (s: UnionOperator) => {
    setStaffToRemove(s);
  };

  const isUnionLead = useMemo(
    () => selectedUnion?.unionOperator?.type === UnionOperatorType.UNION_LEAD,
    [selectedUnion],
  );

  const isInternal = useMemo(() => isInternalStaff(staff), [staff]);

  const onRemove = useCallback(async () => {
    if (staffToRemove) {
      const result = await removeOperatorFromUnion({ unionOperatorId: staffToRemove.id });
      if (result instanceof ServerError) {
        return;
      }
      onRefresh();
    }
  }, [staffToRemove, onRefresh]);

  return (
    <Stack alignItems="start" height="800px" spacing={4}>
      <Stack direction="row" alignItems="center" width="100%">
        <Typography variant="h2" flex={1} textAlign="start">
          관리자
        </Typography>
        {(isUnionLead || isInternal) && (
          <Button variant="contained" onClick={() => setCreationDialogOpen(true)} size="small">
            관리자 추가
            <AddCircleOutlineSharp sx={{ marginLeft: '4px' }} />
          </Button>
        )}
      </Stack>

      <TableVirtuoso
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() => fixedHeaderContent(theme)}
        itemContent={(idx, rowData) =>
          rowContent(
            idx,
            rowData,
            isInternal || (isUnionLead && rowData.unionOperator.type !== UnionOperatorType.UNION_LEAD)
              ? selectForRemove
              : undefined,
          )
        }
      />
      <UnionOperatorCreationDialog
        open={creationDialogOpen}
        onClose={() => setCreationDialogOpen(false)}
        unionId={unionId}
        onRefresh={onRefresh}
      />
      <SuccessErrorModal
        isSuccess={false}
        title="운영 / 관리자 삭제"
        description="정말 운영 / 관리자를 삭제 하시겠습니까?"
        open={!!staffToRemove}
        onClose={() => setStaffToRemove(undefined)}
        onConfirm={onRemove}
      />
    </Stack>
  );
}
