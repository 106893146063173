import { Check, Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { addOperatorToUnion, getOperatorByEmail, inviteOperatorandAddToUnion } from '../../../../api/union.api';
import { RequestStatus, ServerError, Staff, UnionOperatorType } from '../../../../models';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';

interface Props {
  open: boolean;
  onClose: () => void;
  unionId: number;
  onRefresh: () => void;
}
const UnionOperatorCreationDialog = ({ open, onClose, unionId, onRefresh }: Props) => {
  const [errMsg, setErrMsg] = useState('');
  const [name, setName] = useState('');
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const [email, setEmail] = useState('');
  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const [phone, setPhone] = useState('');
  const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const onlyNumbers = event.target.value.replace(/[^0-9]/g, '');
    setPhone(onlyNumbers);
  };
  const [searchedStaff, setSearchedStaff] = useState<Staff>();
  const [emailSearched, setEmailSearched] = useState(false);
  const [operatorType, setOperatorType] = useState<UnionOperatorType | ''>('');
  const onOperatorTypeChange = (event: SelectChangeEvent) => {
    setOperatorType(event.target.value as UnionOperatorType);
  };
  const [searchRequestStatus, setSearchRequestStatus] = useState(RequestStatus.IDLE);
  const searchEmail = useCallback(async () => {
    setSearchRequestStatus(RequestStatus.REQUESTED);
    const data = await getOperatorByEmail({ email, unionId });
    if (data instanceof ServerError) {
      setSearchRequestStatus(RequestStatus.FAIL);
      return;
    }
    setSearchRequestStatus(RequestStatus.SUCCESS);
    setEmailSearched(true);
    if (data.operator) {
      setSearchedStaff(data.operator);
    }
  }, [email, unionId]);
  const [addRequestStatus, setAddRequestStatus] = useState(RequestStatus.IDLE);

  const exit = () => {
    setSearchRequestStatus(RequestStatus.IDLE);
    setName('');
    setEmail('');
    setPhone('');
    setSearchedStaff(undefined);
    setAddRequestStatus(RequestStatus.IDLE);
    setEmailSearched(false);
    setErrMsg('');
    onClose();
  };

  useEffect(() => {
    if (searchRequestStatus === RequestStatus.FAIL) {
      setSearchRequestStatus(RequestStatus.IDLE);
    } else if (searchRequestStatus === RequestStatus.SUCCESS) {
      setSearchRequestStatus(RequestStatus.IDLE);
    }
  }, [searchRequestStatus]);

  const onAdd = useCallback(async () => {
    if (!emailSearched || addRequestStatus === RequestStatus.REQUESTED) {
      return;
    }
    if (!operatorType) {
      setErrMsg('직책을 설정해주세요');
      return;
    }
    setAddRequestStatus(RequestStatus.REQUESTED);
    if (searchedStaff) {
      const result = await addOperatorToUnion({ staffId: searchedStaff.id, unionId, type: operatorType });
      if (result instanceof ServerError) {
        setErrMsg('이미 존재하는 관리자 입니다.');
        setAddRequestStatus(RequestStatus.IDLE);
        return;
      }
      onRefresh();
      setAddRequestStatus(RequestStatus.SUCCESS);
      return;
    }
    await object()
      .shape({
        email: string().email().required(),
        name: string().min(1).required(),
        phone: string().min(1).required(),
      })
      .validate({
        email,
        name,
        phone,
      })
      .then(async (props) => {
        const result = await inviteOperatorandAddToUnion({ ...props, type: operatorType, unionId });
        if (result instanceof ServerError) {
          setErrMsg('이미 존재하는 관리자 입니다');
          setAddRequestStatus(RequestStatus.IDLE);
          return;
        }
        onRefresh();
        setAddRequestStatus(RequestStatus.SUCCESS);
      })
      .catch((err) => {
        setErrMsg(err.errors[0]);
        setAddRequestStatus(RequestStatus.IDLE);
      });
  }, [emailSearched, addRequestStatus, operatorType, searchedStaff, email, name, phone, unionId, onRefresh]);

  useEffect(() => {
    if (errMsg) {
      setErrMsg('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorType, email, name, phone]);

  return (
    <Dialog open={open} onClose={exit}>
      <DialogTitle variant="h2">
        운영 / 관리자 추가
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            <TextField
              label="이메일"
              autoComplete="off"
              value={email}
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1, minWidth: '260px' }}
              onChange={onEmailChange}
              InputProps={{ readOnly: !!emailSearched }}
            />
            <Button
              variant="contained"
              onClick={searchRequestStatus === RequestStatus.IDLE && !searchedStaff ? searchEmail : undefined}
              size="small"
            >
              {searchRequestStatus === RequestStatus.REQUESTED && <CircularProgress size="24px" />}
              {searchedStaff ? <Check /> : '검색'}
            </Button>
          </Stack>
          {emailSearched && searchedStaff && (
            <>
              <Stack direction="row" spacing={3} paddingTop={1}>
                <TextField
                  label="이름"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={searchedStaff.name}
                  sx={{ width: 140 }}
                  InputProps={{ readOnly: true }}
                />
                <TextField
                  label="연락처"
                  autoComplete="off"
                  value={searchedStaff.phone}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Stack>
              <Stack direction="row" spacing={3} paddingTop={1}>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id="type-select-label">직책</InputLabel>
                  <Select label="직책" value={operatorType} onChange={onOperatorTypeChange} labelId="type-select-label">
                    <MenuItem value="">
                      <em>선택안함</em>
                    </MenuItem>
                    {Object.values(UnionOperatorType).map((val) => (
                      <MenuItem key={`operator-type-option-${val}`} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </>
          )}
          {emailSearched && !searchedStaff && (
            <>
              <Stack direction="row" spacing={3} paddingTop={1}>
                <TextField
                  label="이름"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={name}
                  sx={{ width: 140 }}
                  onChange={onNameChange}
                />
                <TextField
                  label="연락처"
                  autoComplete="off"
                  value={phone}
                  InputLabelProps={{ shrink: true }}
                  onChange={onPhoneChange}
                />
              </Stack>
              <Stack direction="row" spacing={3} paddingTop={1}>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id="type-select-label">직책</InputLabel>
                  <Select label="직책" value={operatorType} onChange={onOperatorTypeChange} labelId="type-select-label">
                    <MenuItem value="">
                      <em>선택안함</em>
                    </MenuItem>
                    {Object.values(UnionOperatorType).map((val) => (
                      <MenuItem key={`operator-type-option-${val}`} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </>
          )}
          {errMsg && <Typography color="error">{errMsg}</Typography>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color={emailSearched ? 'primary' : 'inherit'} variant="contained" fullWidth onClick={onAdd}>
          {addRequestStatus === RequestStatus.IDLE ? (
            <>
              {!emailSearched && '이메일을 검색해 주세요'}
              {searchedStaff && '존재하는 계정 관리자로 추가하기'}
              {emailSearched && !searchedStaff && '계정 생성/초대 및 추가하기'}
            </>
          ) : (
            <CircularProgress size="24px" sx={{ color: 'white' }} />
          )}
        </Button>
      </DialogActions>
      <SuccessErrorModal
        isSuccess={addRequestStatus === RequestStatus.SUCCESS}
        title="운영 / 관리자 추가"
        description={
          addRequestStatus === RequestStatus.SUCCESS
            ? '운영 / 관리자가 성공적으로 추가 되었습니다.'
            : '운영 / 관리자가 추가 중 에러가 발생했습니다.'
        }
        open={addRequestStatus === RequestStatus.SUCCESS || addRequestStatus === RequestStatus.FAIL}
        onClose={() => {
          if (addRequestStatus === RequestStatus.SUCCESS) {
            exit();
          }
          setAddRequestStatus(RequestStatus.IDLE);
        }}
      />
    </Dialog>
  );
};

export default UnionOperatorCreationDialog;
