import { PollCreateProps, Post, PostWall } from '../models';
import { ApiClient, handleError } from '../util/ApiClient';

export const getPostList = async (unionId: number) => {
  try {
    const response = await ApiClient().get<{
      postList: Post[];
    }>('/posts', { params: { unionId, isNotice: false } });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getNoticeList = async (unionId: number) => {
  try {
    const response = await ApiClient().get<{
      postList: Post[];
    }>('/posts', { params: { unionId, isNotice: true } });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getPost = async (postId: number) => {
  try {
    const response = await ApiClient().get<{
      post: Post;
    }>(`/posts/${postId}`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

type MakeNoticeProps = {
  unionId: number;
  postWallId: number;
  title: string;
  content: string;
  pollProps?: PollCreateProps;
};
export const makeNotice = async (props: MakeNoticeProps, postFiles?: File[], pollOptionFiles?: File[]) => {
  try {
    const formdata = new FormData();
    Object.entries(props).forEach(([key, val]) => {
      if (val) {
        if (key !== 'pollProps') {
          formdata.append(key, val as string);
        } else {
          formdata.append(key, JSON.stringify(val));
        }
      }
    });
    postFiles?.forEach((file) => {
      formdata.append('postFiles', file);
    });
    pollOptionFiles?.forEach((file) => {
      formdata.append('pollOptionFiles', file);
    });
    const response = await ApiClient().post('/posts', formdata, { headers: { 'Content-Type': 'multipart/form-data' } });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getPostWalls = async (unionId: number) => {
  try {
    const response = await ApiClient().get<{
      walls: PostWall[];
    }>('/posts/walls', { params: { unionId } });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteNotice = async (postId: number, unionId: number) => {
  try {
    return await ApiClient().delete('/posts', { data: { postId, unionId } });
  } catch (err) {
    return handleError(err);
  }
};

export const hideOrShowPost = async (postId: number) => {
  try {
    return await ApiClient().patch('/posts/hidden', { postId });
  } catch (err) {
    return handleError(err);
  }
};

export const hideOrShowPostReply = async (postReplyId: number) => {
  try {
    return await ApiClient().patch('/posts/replies/hidden', { postReplyId });
  } catch (err) {
    return handleError(err);
  }
};

type UpdateNoticeProps = {
  postId: number;
  title: string;
  content: string;
  deleteFileIds?: number[];
  postFiles?: File[];
  postWallId?: number;
};

export const updateNotice = async ({ postFiles, deleteFileIds, ...props }: UpdateNoticeProps) => {
  try {
    const formdata = new FormData();
    Object.entries(props).forEach(([key, val]) => {
      if (val) {
        formdata.append(key, val as string);
      }
    });
    postFiles?.forEach((file) => {
      formdata.append('postFiles', file);
    });
    if (deleteFileIds && deleteFileIds.length > 0) {
      formdata.append('deleteFileIds', JSON.stringify(deleteFileIds));
    }
    const response = await ApiClient().patch('/posts', formdata, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getImageFromAuthorization = async (imageUrl: string) => {
  try {
    const response = await ApiClient().get(imageUrl, { responseType: 'blob' });
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};
