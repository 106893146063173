import { Info } from '@mui/icons-material';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { parseInt } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { deleteGeneralMeeting, getGeneralMeetings } from '../../../../../api/general-meeting.api';
import {
  GeneralMeetingModel,
  GeneralMeetingQueryStringValueEnum,
  GeneralMeetingStatus,
} from '../../../../../models/general-meeting.model';
import SuccessErrorModal from '../../../../common/dialog/success-error-dialog';
import GeneralMeetingTable from './general-meeting-table';

interface GeneralMeetingPageProps {
  unionId: string | undefined;
  onPageMove: (
    eventTypeValue: GeneralMeetingQueryStringValueEnum,
    eventActionValue: GeneralMeetingQueryStringValueEnum,
    targetIdValue?: number,
  ) => void;
}

const GeneralMeetingList = ({ unionId, onPageMove }: GeneralMeetingPageProps) => {
  const theme = useTheme();
  const [meetingList, setMeetingList] = useState<GeneralMeetingModel[]>([]);
  const [meetingDetailToDelete, setMeetingDetailToDelete] = useState<GeneralMeetingModel>();
  const [needRefresh, setNeedRefresh] = useState(false);

  const fetchMeetings = useCallback(async () => {
    if (unionId === undefined) {
      return;
    }

    try {
      const parsedUnionId = parseInt(unionId);
      const meetings = await getGeneralMeetings(parsedUnionId);
      setMeetingList(meetings);
    } catch (error) {
      // 오류처리 필요
    }
  }, [unionId]);

  useEffect(() => {
    fetchMeetings();
  }, [fetchMeetings]);

  useEffect(() => {
    if (!needRefresh) {
      return;
    }

    fetchMeetings();
    setNeedRefresh(false);
  }, [fetchMeetings, needRefresh]);

  const onDeleteMeetingDetail = useCallback(async () => {
    try {
      if (!meetingDetailToDelete) {
        return;
      }

      await deleteGeneralMeeting(meetingDetailToDelete.id);
      setNeedRefresh(true);
    } catch (error) {
      // 에러처리
    }
  }, [meetingDetailToDelete]);

  const recentHoldingGeneralMeeting = useMemo(() => {
    const today = dayjs();
    return meetingList.find(
      (meeting) => today.isSame(meeting.holdAt, 'day') && meeting.status === GeneralMeetingStatus.PUBLISHED,
    );
  }, [meetingList]);

  return (
    <Stack width="100%" height="800px" spacing={4} py={4}>
      <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
        <Typography flex={1} textAlign="start" variant="h2">
          총회
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              onPageMove(GeneralMeetingQueryStringValueEnum.VOTE, GeneralMeetingQueryStringValueEnum.CREATE);
            }}
          >
            투표 만들기
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              onPageMove(GeneralMeetingQueryStringValueEnum.MEETING, GeneralMeetingQueryStringValueEnum.CREATE);
            }}
          >
            총회 만들기
          </Button>
        </Stack>
      </Stack>
      {recentHoldingGeneralMeeting && (
        <Stack
          direction="row"
          sx={{ background: theme.palette.secondary.light }}
          p="40px 16px"
          spacing={2}
          alignItems="center"
        >
          <Info color="primary" />
          <Stack direction="row" spacing={0} alignItems="center">
            <Button
              variant="text"
              onClick={() =>
                onPageMove(
                  GeneralMeetingQueryStringValueEnum.MEETING,
                  GeneralMeetingQueryStringValueEnum.DETAIL,
                  recentHoldingGeneralMeeting.id,
                )
              }
              sx={{ paddingY: 0.5, paddingX: 1 }}
            >
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
                sx={{ textDecoration: 'underline' }}
                display="inline"
              >
                {recentHoldingGeneralMeeting.title}
              </Typography>
            </Button>
            <Typography variant="subtitle1">가 진행 중입니다. 투표가 종료되었다면</Typography>
            <Button
              variant="text"
              onClick={() =>
                onPageMove(
                  GeneralMeetingQueryStringValueEnum.VOTE,
                  GeneralMeetingQueryStringValueEnum.RESULT,
                  recentHoldingGeneralMeeting.id,
                )
              }
              sx={{ paddingY: 0.5, paddingX: 1, minWidth: '32px' }}
            >
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
                sx={{ textDecoration: 'underline' }}
                display="inline"
              >
                여기
              </Typography>
            </Button>
            <Typography variant="subtitle1">를 눌러 결과를 입력하세요.</Typography>
          </Stack>
        </Stack>
      )}

      <GeneralMeetingTable
        rows={meetingList}
        onDelete={(selectedGeneralMeeting) => {
          setMeetingDetailToDelete(selectedGeneralMeeting);
        }}
        onRowClick={(selectedRowId) =>
          onPageMove(
            GeneralMeetingQueryStringValueEnum.MEETING,
            GeneralMeetingQueryStringValueEnum.DETAIL,
            selectedRowId,
          )
        }
      />
      <SuccessErrorModal
        isSuccess={false}
        title="총회 삭제"
        description="정말 총회를 삭제하시겠습니까?"
        open={!!meetingDetailToDelete}
        onClose={() => setMeetingDetailToDelete(undefined)}
        onConfirm={onDeleteMeetingDetail}
      />
    </Stack>
  );
};

export default GeneralMeetingList;
