import { Button, Card, Divider, Modal, Stack, TextField, Typography } from '@mui/material';
import { find } from 'lodash';
import { useState } from 'react';
import { object, ref, string } from 'yup';
import { makeStaff } from '../../api/staff';
import { ServerError, StaffRole } from '../../models';
import { StaffRoleType } from '../../models/staff-role.model';
import { CommonModalStyle } from '../common/dialog/common-modal';

interface Props {
  roles: StaffRole[];
  open: boolean;
  handleClose: () => void;
  setRefreshList: () => void;
}

function StaffCreateModal(props: Props) {
  const { roles, open, handleClose, setRefreshList } = props;
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [name, setName] = useState('');
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const [email, setEmail] = useState('');
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const [password, setPassword] = useState('');
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const handlePasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(event.target.value);
    if (showErrorMessage) {
      setShowErrorMessage(false);
    }
  };

  const handleStaffCreation = async () => {
    await object()
      .shape({
        name: string().required(),
        email: string().email().required(),
        password: string().min(1).max(24).required(),
        passwordConfirmation: string()
          .oneOf([ref('password'), null])
          .required(),
      })
      .validate({
        name,
        email,
        password,
        passwordConfirmation,
      })
      .then(async ({ name: n, email: e, password: p }) => {
        const data = await makeStaff(n, e, p, [find(roles, (item) => item.role === StaffRoleType.EXTERNAL)!.id]);
        if (data instanceof ServerError) {
          setShowErrorMessage(true);
        } else {
          setRefreshList();
          handleClose();
        }
      })
      .catch(() => {
        setShowErrorMessage(true);
      });
  };

  const clearData = () => {
    setEmail('');
    setPassword('');
    setName('');
    setPasswordConfirmation('');
    setShowErrorMessage(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        clearData();
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={CommonModalStyle}
    >
      <Card sx={{ p: 6 }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          스태프 생성
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <TextField onChange={handleNameChange} fullWidth size="small" label="이름" variant="outlined" margin="normal" />
        <TextField
          autoComplete="off"
          fullWidth
          size="small"
          inputProps={{ autoComplete: 'off' }}
          onChange={handleEmailChange}
          label="이메일"
          variant="outlined"
          margin="normal"
        />
        <TextField
          fullWidth
          type="password"
          size="small"
          value={password}
          onChange={handlePasswordChange}
          autoComplete="off"
          inputProps={{ autoComplete: 'new-password' }}
          label="비밀번호"
          variant="outlined"
          margin="normal"
        />
        <TextField
          fullWidth
          type="password"
          size="small"
          value={passwordConfirmation}
          onChange={handlePasswordConfirmationChange}
          autoComplete="off"
          inputProps={{ autoComplete: 'new-password' }}
          label="비밀번호 확인"
          variant="outlined"
          margin="normal"
        />

        {showErrorMessage && (
          <Typography color="error" variant="body2">
            입력값이 올바르지 않거나 같은 이메일이 존재합니다
          </Typography>
        )}
        <Stack direction="row" style={{ marginTop: 24 }}>
          <Button variant="contained" fullWidth onClick={handleStaffCreation}>
            생성하기
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
}

export default StaffCreateModal;
