import { Staff, Union, UnionConstructionType, UnionMember, UnionOperatorType, UnionStage } from '../models';
import { UnionCashLogResponseDataModel, UnionCashTransactionRequestBodyModel } from '../models/cash.model';
import UnionMemberModel, { UnionMemberGrade, UnionMemberInformationUpdateData } from '../models/union-member.model';
import { ApiClient, handleError } from '../util/ApiClient';

export const getUnionListForInternalStaff = async () => {
  try {
    const response = await ApiClient().get<{
      unionList: Union[];
    }>('/unions');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getJoinedUnionList = async () => {
  try {
    const response = await ApiClient().get<{
      unionList: Union[];
    }>('/unions/joined');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

interface MakeUnionProps {
  name: string;
  representativeAddress: string;
  constructionType: UnionConstructionType;
  stage: UnionStage;
  serialNumber: string;
  businessRegistrationNumber: string;
  phoneForMessage?: string;
  representativePhone: string;
  officeAddress: string;
}
export const makeUnion = async (props: MakeUnionProps) => {
  try {
    const response = await ApiClient().post('/unions', props);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getUnion = async (id: string) => {
  try {
    const response = await ApiClient().get<{
      union: Union;
    }>(`/unions/${id}`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getUnionMembers = async (id: number) => {
  try {
    const response = await ApiClient().get<UnionMemberModel[]>(`/unions/${id}/members`);
    return response.data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getUnionForExternal = async (id: string) => {
  try {
    const response = await ApiClient().get<{
      union: Union;
    }>(`/unions/${id}`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getUnionOperators = async (id: string) => {
  try {
    const response = await ApiClient().get<{
      operators: Staff[];
    }>(`/unions/${id}/operators`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getOperatorByEmail = async (params: { email: string; unionId: number }) => {
  try {
    const response = await ApiClient().get<{
      operator: Staff | null;
    }>('/unions/operators/search-email', { params });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

interface AddOperatorToUnionProps {
  staffId: number;
  unionId: number;
  type: UnionOperatorType;
}
export const addOperatorToUnion = async (props: AddOperatorToUnionProps) => {
  try {
    const response = await ApiClient().post('/unions/operators', props);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

interface InviteOperatorandAddToUnionProps {
  email: string;
  name: string;
  phone: string;
  unionId: number;
  type: UnionOperatorType;
}
export const inviteOperatorandAddToUnion = async (props: InviteOperatorandAddToUnionProps) => {
  try {
    const response = await ApiClient().post('/unions/operators/invitations', props);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};
export const removeOperatorFromUnion = async (data: { unionOperatorId: number }) => {
  try {
    const response = await ApiClient().delete('/unions/operators', { data });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const addResidenceToUnion = async (props: { unionId: number; residenceId: number }) => {
  try {
    const response = await ApiClient().post('/unions/residences', props);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const removeResidenceFromUnion = async (data: { unionId: number; residenceId: number }) => {
  try {
    const response = await ApiClient().delete('/unions/residences', { data });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

interface UpdateUnionBasicInformationProps {
  unionId: number;
  name: string;
  representativeAddress: string;
  constructionType: UnionConstructionType;
  serialNumber: string;
  representativePhone: string;
  stage: UnionStage;
}
export const updateUnionBasicInformation = async (props: UpdateUnionBasicInformationProps) => {
  try {
    const response = await ApiClient().patch('/unions', props);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const addUnionMember = async (props: Omit<UnionMember, 'id'> & { unionId: number }) => {
  try {
    const response = await ApiClient().post('/unions/members', props);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const addUnionMembers = async (data: { members: Omit<UnionMember, 'id' | 'unionId'>[]; unionId: number }) => {
  try {
    const response = await ApiClient().post('/unions/members/multiple', data);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const removeMemberFromUnion = async (data: { unionMemberId: number }) => {
  try {
    const response = await ApiClient().delete('/unions/members', { data });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getSignUpForKevotingUrl = async (unionId: number) => {
  try {
    const response = await ApiClient().get<string>(`/unions/${unionId}/sign-up-for-kevoting`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUnionCash = async (unionId: number) => {
  try {
    const response = await ApiClient().get<number>(`/unions/${unionId}/cash`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const requestUnionCashTransaction = async (
  unionId: number,
  requestBodyData: UnionCashTransactionRequestBodyModel,
) => {
  try {
    const response = await ApiClient().post<number>(`/unions/${unionId}/cash`, requestBodyData);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getUnionCashLogs = async (unionId: number) => {
  try {
    const response = await ApiClient().get<UnionCashLogResponseDataModel[]>(`/unions/${unionId}/cash-logs`);
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateUnionMemberInformation = async (
  unionId: number,
  unionMemberId: number,
  requestBodyData: UnionMemberInformationUpdateData,
) => {
  try {
    const response = await ApiClient().patch<UnionMemberInformationUpdateData>(
      `/unions/${unionId}/members/${unionMemberId}`,
      requestBodyData,
    );
    return response.status;
  } catch (error) {
    throw handleError(error);
  }
};

export const updateUnionMemberGrade = async (unionId: number, unionMemberId: number, newGrade: UnionMemberGrade) => {
  try {
    const response = await ApiClient().patch(`/unions/${unionId}/members/${unionMemberId}/grade`, { value: newGrade });
    return response.status;
  } catch (error) {
    throw handleError(error);
  }
};
