import { ArrowRight, Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import AppContext from '../../AppContext';
import { getTerms, registerWithInvitation, validateRegistrationCode } from '../../api/staff';
import Logo from '../../assets/logo.svg';
import { ServerError, Terms } from '../../models';
import theme from '../../theme';
import { MainLayout } from '../style';

const RegistrationInvitation = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<boolean>();
  const [errMsg, setErrMsg] = useState('');
  const [terms, setTerms] = useState<Terms[]>();
  const [agreedTermsIds, setAgreedTermsIds] = useState<number[]>([]);
  const [termContent, setTermContent] = useState('');

  const onAgreeAll = () => {
    if (terms) {
      if (terms.length !== agreedTermsIds.length) {
        setAgreedTermsIds(terms.map((item) => item.id));
      } else {
        setAgreedTermsIds([]);
      }
    }
  };

  const onAgreeChange = (id: number) => {
    if (agreedTermsIds.includes(id)) {
      const newData = agreedTermsIds.filter((item) => item !== id);
      setAgreedTermsIds(newData);
    } else {
      setAgreedTermsIds([...agreedTermsIds, id]);
    }
  };

  useEffect(() => {
    const fetchTerms = async () => {
      const result = await getTerms();
      if (result instanceof ServerError) {
        setIsValid(false);
        return;
      }
      setTerms(result.terms);
    };
    fetchTerms();
  }, []);

  useEffect(() => {
    if (!code) {
      setIsValid(false);
      return;
    }
    const onValidate = async () => {
      const result = await validateRegistrationCode(code);
      if (result instanceof ServerError) {
        setIsValid(false);
        return;
      }
      setIsValid(result.isValid);
    };
    onValidate();
  }, [code]);

  useEffect(() => {
    if (isValid === false) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { setStaff, setToken } = useContext(AppContext);

  const onRegister = useCallback(async () => {
    if (agreedTermsIds.length !== terms?.length) {
      setErrMsg('약관 동의가 필요합니다.');
      return;
    }
    await object()
      .shape({
        password: string().required().min(8, '비밀번호는 최소 8자리 입니다.').max(20),
        passwordConfirmation: string().required(),
      })
      .validate({ password, passwordConfirmation })
      .then(async (data) => {
        if (data.password !== data.passwordConfirmation) {
          setErrMsg('비밀번호와 비밀번호 확인값이 일치하지 않습니다');
          return;
        }
        const result = await registerWithInvitation(code!, password);
        if (result instanceof ServerError) {
          setErrMsg('서버에러가 발생했습니다');
          return;
        }
        setStaff(result.staff);
        setToken(result.token);
      })
      .catch((e) => {
        setErrMsg(e.errors[0]);
      });
  }, [code, password, passwordConfirmation, setStaff, setToken, terms, agreedTermsIds]);

  useEffect(() => {
    if (errMsg) {
      setErrMsg('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, passwordConfirmation, agreedTermsIds]);

  return (
    <MainLayout>
      <Paper sx={{ padding: '24px', maxWidth: '1200px' }}>
        {isValid && (
          <Stack spacing={4} minWidth="360px">
            <Stack spacing={3} alignItems="center">
              <img src={Logo} alt="" width={70} />
              <Typography fontWeight={700} fontSize={24}>
                계정 만들기
              </Typography>
            </Stack>
            <Stack spacing={2}>
              <TextField
                label="비밀번호"
                autoComplete="off"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="영문/숫자/특수문자 혼합 8~20자"
              />
              <TextField
                label="비밀번호 확인"
                autoComplete="off"
                type="password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </Stack>
            {terms && (
              <Stack spacing={1}>
                <Stack direction="row">
                  <Checkbox
                    checked={terms.length === agreedTermsIds.length}
                    onChange={onAgreeAll}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  />
                  <Typography fontSize={18} fontWeight={600}>
                    약관 전체 동의
                  </Typography>
                </Stack>
                <Divider />
                {terms?.map((term) => (
                  <Stack direction="row" key={`term-item-${term.id}`} alignItems="center">
                    <Checkbox onChange={() => onAgreeChange(term.id)} checked={agreedTermsIds.includes(term.id)} />
                    <Typography flex={1} textAlign="start">
                      [필수] {term.title}
                    </Typography>
                    <Button size="small" color="inherit" onClick={() => setTermContent(term.content)}>
                      <ArrowRight />
                    </Button>
                  </Stack>
                ))}
              </Stack>
            )}
            {errMsg && (
              <Typography color="error" variant="body1">
                {errMsg}
              </Typography>
            )}
            <Button variant="contained" onClick={onRegister}>
              가입하기
            </Button>
            <Dialog open={!!termContent} onClose={() => setTermContent('')} fullWidth maxWidth="sm">
              <DialogTitle variant="h2">
                약관
                <IconButton
                  aria-label="close"
                  onClick={() => setTermContent('')}
                  sx={() => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  })}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <div dangerouslySetInnerHTML={{ __html: termContent }} />
              </DialogContent>
            </Dialog>
          </Stack>
        )}
      </Paper>
    </MainLayout>
  );
};

export default RegistrationInvitation;
