import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { SendingMessageForm } from '../../../../../models/message-sending.model';

interface SendingConfirmDialogProps {
  messageForm?: SendingMessageForm;
  predictedCash: number;
  totalReceivers: number;
  handleSend: () => void;
  onClose: () => void;
}
export default function SendingConfirmDialog(props: SendingConfirmDialogProps) {
  const theme = useTheme();
  const { messageForm, predictedCash, totalReceivers, handleSend, onClose } = props;

  const exit = () => {
    onClose();
  };

  return (
    <Dialog open={!!messageForm} onClose={exit} fullWidth maxWidth="sm">
      <DialogTitle variant="h2">
        메시지 보내기
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {messageForm && (
          <Stack spacing={3}>
            <Stack direction="row" spacing={3}>
              <Typography variant="subtitle1" color={theme.palette.grey[800]} minWidth="102px">메시지 유형</Typography>
              <Typography variant="subtitle1" color={theme.palette.grey[800]}>{messageForm.type.toUpperCase()}</Typography>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Typography variant="subtitle1" color={theme.palette.grey[800]} minWidth="102px">발송 대상</Typography>
              <Typography variant="subtitle1" color={theme.palette.grey[800]}>
                {`${totalReceivers.toLocaleString('ko-KR')}명`}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={3}>
              <Typography variant="subtitle1" color={theme.palette.grey[800]} minWidth="102px">차감 캐시</Typography>
              <Typography variant="subtitle1" color={theme.palette.primary.main}>
                {`${predictedCash.toLocaleString('ko-KR')}원`}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={2} pt="16px">
              <Button variant="contained" color="inherit" sx={{ minWidth: '80px' }} onClick={exit}>
                취소
              </Button>
              <Button variant="contained" color="primary" sx={{ minWidth: '80px' }} onClick={handleSend}>
                보내기
              </Button>
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
