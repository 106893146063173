import { ArrowBack, InfoOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Container, Stack, Typography, useTheme } from '@mui/material';
import { parseInt } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteGeneralMeeting,
  getAvailableKevotings,
  getDetailGeneralMeeting,
  getGeneralMeetingVoteBoxes,
  patchGeneralMeetingStatus,
} from '../../../../../api/general-meeting.api';
import {
  GeneralMeetingDetailModel,
  GeneralMeetingQueryStringValueEnum,
  GeneralMeetingStatus,
  GeneralMeetingVoteBoxSelectModel,
  PostFailureAlertModel,
  PostSuccessAlertModel,
} from '../../../../../models/general-meeting.model';
import { GlobalMenuItems } from '../../../../../navigation/global-menu.model';
import { convertDateFormat24H } from '../../../../../util/functions';
import SuccessErrorModal from '../../../../common/dialog/success-error-dialog';
import GeneralMeetingStatusTag from '../home/general-meeting-status-tag';
import VoteRegisterDialog from '../vote/register/vote-register-dialog';
import VoteRegisterDoneDialog from '../vote/register/vote-register-done-dialog';
import AgendaInfo from './agenda-info';
import MeetingInfo from './meeting-info';

interface GeneralMeetingDetailPageProps {
  generalMeetingId: string;
  onPageMove: (
    eventTypeValue: GeneralMeetingQueryStringValueEnum,
    eventActionValue: GeneralMeetingQueryStringValueEnum,
    targetIdValue?: number,
  ) => void;
}

export default function GeneralMeetingDetailPage({ generalMeetingId, onPageMove }: GeneralMeetingDetailPageProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [meetingDetail, setMeetingDetail] = useState<GeneralMeetingDetailModel>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showFinishMeetingConfirm, setShowFinishMeetingConfirm] = useState(false);
  const [showPostFailureAlert, setShowPostFailureAlert] = useState<PostFailureAlertModel>();
  const [showPostSuccessAlert, setShowPostSuccessAlert] = useState<PostSuccessAlertModel>();
  const [availableKevotings, setAvailableKevotings] = useState<GeneralMeetingVoteBoxSelectModel[]>([]);
  const [isVoteRegisterDialogVisible, setIsVoteRegisterDialogVisible] = useState<boolean>(false);
  const [isVoteRegisterDoneDialogVisible, setIsVoteRegisterDoneDialogVisible] = useState<boolean>(false);

  const registerModalDescription = useMemo(() => {
    if (showPostSuccessAlert === undefined) {
      return '';
    }

    return `${convertDateFormat24H(
      showPostSuccessAlert.publishedAt,
    )} 부터 앱에 총회 페이지가 게시됩니다. 모든 안건이 빠짐없이 입력되었는지 확인해 주세요.`;
  }, [showPostSuccessAlert]);

  const fetchMeetingInfo = useCallback(async (meetingId: number) => {
    try {
      const responseData = await getDetailGeneralMeeting(meetingId);
      setMeetingDetail(responseData);
    } catch (error) {
      // TODO: 에러처리
    }
  }, []);

  const fetchMeetingVotes = useCallback(async (meetingId: number) => {
    try {
      const availableKevotingData = await getAvailableKevotings(meetingId);
      const registeredVotesData = await getGeneralMeetingVoteBoxes(meetingId);
      const allKevotings: GeneralMeetingVoteBoxSelectModel[] = availableKevotingData.votes.map((kevoting) => {
        const isRegistered = registeredVotesData.find((registeredVote) => registeredVote.refId === kevoting.id);
        const voteBox: GeneralMeetingVoteBoxSelectModel = { ...kevoting, isChecked: !!isRegistered };
        return voteBox;
      });
      setAvailableKevotings(allKevotings);
    } catch (error) {
      // TODO: 에러처리
    }
  }, []);

  useEffect(() => {
    if (!generalMeetingId) {
      return;
    }

    const meetingId = parseInt(generalMeetingId);
    fetchMeetingInfo(meetingId);
    fetchMeetingVotes(meetingId);
  }, [fetchMeetingVotes, fetchMeetingInfo, generalMeetingId]);

  const onDeleteMeetingDetail = useCallback(async () => {
    try {
      if (!meetingDetail) {
        return;
      }

      await deleteGeneralMeeting(meetingDetail.id);

      navigate(`?tab=${GlobalMenuItems.GENERAL_MEETING.keyName}`, { replace: true });
    } catch (error) {
      // 에러처리
    }
  }, [meetingDetail, navigate]);

  const changeGeneralMeetingStatus = useCallback(
    async (status: GeneralMeetingStatus) => {
      if (!generalMeetingId) {
        return;
      }
      try {
        await patchGeneralMeetingStatus(Number(generalMeetingId), status);
      } catch (error) {
        // 오류 처리
      }
    },
    [generalMeetingId],
  );

  const handlePostGeneralMeetingClicked = useCallback(() => {
    if (!meetingDetail) {
      return;
    }
    const { locationName, address, holdAt, publishAt, announcementFile, reportAgendas, voteAgendas } = meetingDetail;
    const isAgendaExist = reportAgendas.length > 0 || voteAgendas.length > 0;
    if (!(locationName && address && holdAt && publishAt && announcementFile)) {
      const objectForSuccessAlert: PostFailureAlertModel = {
        isShow: true,
        message: '총회 정보를 모두 입력해 주세요.',
      };
      setShowPostFailureAlert(objectForSuccessAlert);
    } else if (!isAgendaExist) {
      const objectForSuccessAlert: PostFailureAlertModel = {
        isShow: true,
        message: '보고 안건과 의결 안건 중 최소 1개의 안건을 입력해 주세요.',
      };
      setShowPostFailureAlert(objectForSuccessAlert);
    } else {
      const objectForSuccessAlert: PostSuccessAlertModel = {
        isShow: true,
        publishedAt: publishAt,
      };
      setShowPostSuccessAlert(objectForSuccessAlert);
    }
  }, [meetingDetail]);

  const handleGeneralMeetingStatus = useCallback(() => {
    if (!meetingDetail) {
      return;
    }
    if (meetingDetail.status === GeneralMeetingStatus.PUBLISHED) {
      setShowFinishMeetingConfirm(true);
    } else {
      setShowDeleteConfirm(true);
    }
  }, [meetingDetail]);

  const navigateBackToGeneralMeetingList = useCallback(() => {
    if (!meetingDetail) {
      return;
    }

    navigate(`/unions/${meetingDetail.unionId}?tab=${GlobalMenuItems.GENERAL_MEETING.keyName}`, { replace: true });
  }, [meetingDetail, navigate]);

  if (!generalMeetingId || !meetingDetail) {
    return (
      <Container sx={{ py: 6 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Stack direction="column" spacing={5}>
      <Button
        variant="text"
        startIcon={<ArrowBack sx={{ color: theme.palette.grey[800] }} />}
        onClick={() => {
          navigate(`/unions/${meetingDetail.unionId}?tab=${GlobalMenuItems.GENERAL_MEETING.keyName}`);
        }}
        sx={{ color: theme.palette.grey[800], alignSelf: 'flex-start' }}
      >
        리스트로 돌아가기
      </Button>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2}>
          <Typography variant="h1">{meetingDetail.title}</Typography>
          <GeneralMeetingStatusTag generalMeetingStatus={meetingDetail.status} />
        </Stack>
        <Button
          onClick={handleGeneralMeetingStatus}
          size="small"
          color="error"
          sx={{ backgroundColor: theme.palette.common.red[100] }}
        >
          {meetingDetail.status === GeneralMeetingStatus.PUBLISHED ? '게시 종료' : '총회 삭제'}
        </Button>
      </Stack>

      <MeetingInfo
        {...meetingDetail}
        changeMeetingStatusToInit={() => changeGeneralMeetingStatus(GeneralMeetingStatus.INIT)}
        onPageMove={(
          eventTypeValue: GeneralMeetingQueryStringValueEnum,
          eventActionValue: GeneralMeetingQueryStringValueEnum,
          targetIdValue?: number,
        ) => onPageMove(eventTypeValue, eventActionValue, targetIdValue)}
      />

      <AgendaInfo
        generalMeetingId={meetingDetail.id}
        reportAgendas={meetingDetail.reportAgendas}
        voteAgendas={meetingDetail.voteAgendas}
        meetingStatus={meetingDetail.status}
        selectedKevotings={availableKevotings.filter((vote) => vote.isChecked)}
        onRefresh={() => fetchMeetingInfo(meetingDetail.id)}
        onVoteRegisterClick={() => {
          setIsVoteRegisterDialogVisible(true);
        }}
        changeMeetingStatus={() => changeGeneralMeetingStatus(GeneralMeetingStatus.INIT)}
        onPageMove={(
          eventTypeValue: GeneralMeetingQueryStringValueEnum,
          eventActionValue: GeneralMeetingQueryStringValueEnum,
          targetIdValue?: number,
        ) => onPageMove(eventTypeValue, eventActionValue, targetIdValue)}
      />

      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" paddingTop="40px">
        <InfoOutlined sx={{ color: theme.palette.grey[700] }} />
        <Typography variant="body2" color={theme.palette.grey[700]}>
          총회 정보와 상정 안건을 모두 입력하였으면 아래 버튼을 클릭해 주세요. 게시 시작 날짜부터 총회 화면이 자동으로
          게시됩니다.
        </Typography>
      </Stack>
      <Button
        variant="contained"
        size="small"
        sx={{ alignSelf: 'center', px: '40px' }}
        disabled={meetingDetail.status !== GeneralMeetingStatus.INIT}
        onClick={handlePostGeneralMeetingClicked}
      >
        게시 등록
      </Button>

      <SuccessErrorModal
        isSuccess={false}
        title="총회 삭제"
        description={`정말 총회를 삭제하시겠습니까?
        삭제된 총회는 복구할 수 없습니다.`}
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={onDeleteMeetingDetail}
      />

      <SuccessErrorModal
        isSuccess={false}
        title="게시 등록 실패"
        description={showPostFailureAlert ? showPostFailureAlert?.message : ''}
        open={!!showPostFailureAlert}
        onClose={() => {
          setShowPostFailureAlert(undefined);
        }}
      />

      <SuccessErrorModal
        isSuccess
        title="게시 등록"
        description={registerModalDescription}
        open={!!showPostSuccessAlert}
        onConfirm={async () => {
          await changeGeneralMeetingStatus(GeneralMeetingStatus.CONFIRMED);
          navigateBackToGeneralMeetingList();
        }}
        confirmTitle="등록하기"
        onClose={() => setShowPostSuccessAlert(undefined)}
      />

      <SuccessErrorModal
        isSuccess={false}
        title="게시 종료"
        description="정말로 총회 게시를 종료하시겠습니까? 종료하면 모바일 앱에서 총회 화면이 삭제됩니다."
        open={showFinishMeetingConfirm}
        onConfirm={async () => {
          await changeGeneralMeetingStatus(GeneralMeetingStatus.FINISHED);
          navigateBackToGeneralMeetingList();
        }}
        confirmTitle="종료하기"
        onClose={() => setShowFinishMeetingConfirm(false)}
      />

      <VoteRegisterDialog
        generalMeetingId={Number(generalMeetingId)}
        title={meetingDetail?.title ?? ''}
        availableKevotings={availableKevotings}
        open={isVoteRegisterDialogVisible}
        onCancel={(originAvailableKevotings) => {
          setAvailableKevotings(originAvailableKevotings);
          setIsVoteRegisterDialogVisible(false);
        }}
        onRegister={(newAvailableKevotings) => {
          setAvailableKevotings(newAvailableKevotings);
          setIsVoteRegisterDialogVisible(false);
          setIsVoteRegisterDoneDialogVisible(true);
        }}
      />

      <VoteRegisterDoneDialog
        open={isVoteRegisterDoneDialogVisible}
        onClose={() => {
          setIsVoteRegisterDoneDialogVisible(false);
        }}
      />
    </Stack>
  );
}
