import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../AppContext';
import { MainLayout } from '../style';

export default function ExternalHome() {
  const navigate = useNavigate();
  const { selectedUnion } = useContext(AppContext);

  useEffect(() => {
    if (selectedUnion) {
      navigate(`/unions/${selectedUnion.id}`);
    }
  }, [navigate, selectedUnion]);

  return <MainLayout>사이트에 접근할 수 없습니다.</MainLayout>;
}
