export default interface UnionOperatorModel {
  id: number;
  type: UnionOperatorType;
  unionId: number;
  staffId: number;
}

export enum UnionOperatorType {
  UNION_LEAD = '조합장',
  UNION_DIRECTOR = '이사',
  UNION_AUDITOR = '감사',
  UNION_OPERATOR = '사무직원',
  SPECIALIST = '전문관리업체 직원',
}
