import { CheckCircle } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, Typography, useTheme } from '@mui/material';

interface VoteRegisterDoneDialogProps {
  open: boolean;
  onClose: () => void;
}

const VoteRegisterDoneDialog = ({ open, onClose }: VoteRegisterDoneDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: 385 }}>
        <Stack spacing={3} alignItems="center">
          <CheckCircle style={{ fontSize: 80, color: theme.palette.common.green[500] }} />
          <Typography variant="h2" color={theme.palette.grey[900]}>
            투표 등록 완료
          </Typography>
          <Typography variant="body1" color={theme.palette.grey[900]}>
            투표 등록이 완료되었습니다.
          </Typography>
          <Button variant="contained" color="primary" onClick={onClose} fullWidth>
            확인
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VoteRegisterDoneDialog;
