import { styled } from '@mui/system';

// sm, md, lg
const breakpoints = [768, 992, 1200];
export const minQuery = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
export const maxQuery = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const MainLayout = styled('div')({
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const ContentLayout = styled('div')({
  width: '100%',
  [minQuery[2]]: { width: '1200px' },
});
