import { Box } from '@mui/material';
import StaffList from './staff-list';

function StaffView() {
  return (
    <Box sx={{ width: '100%' }}>
      <StaffList />
    </Box>
  );
}
export default StaffView;
