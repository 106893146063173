import { AddCircleOutlineSharp, Close, Search } from '@mui/icons-material';
import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  searchText: string;
  setSearchText: (text: string) => void;
}

const UnionHeader = ({ searchText, setSearchText }: Props) => {
  const navigate = useNavigate();
  const onAdd = () => {
    navigate('/unions/create');
  };
  const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const onClearClick = () => {
    setSearchText('');
  };
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="start">
      <Typography variant="h2">사업장</Typography>
      <Stack direction="row">
        <Divider orientation="vertical" sx={{ width: '1px', height: '32px' }} />
        <TextField
          placeholder="검색"
          size="small"
          value={searchText}
          onChange={onSearchTextChange}
          autoComplete="off"
          name="notRecognizable"
          sx={{
            width: '320px',
            background: 'white',
            '& fieldset': { border: 'none' },
            alignSelf: 'center',
          }}
          InputProps={{
            startAdornment: <Search sx={{ marginRight: '12px' }} />,
            endAdornment: (
              <Close
                sx={{
                  display: searchText ? 'display' : 'none',
                  cursor: 'pointer',
                }}
                onClick={onClearClick}
              />
            ),
            autoComplete: 'off',
          }}
        />
      </Stack>
      <Typography flex={1} />
      <Button variant="contained" onClick={onAdd} size="small">
        사업장 추가
        <AddCircleOutlineSharp sx={{ marginLeft: '4px' }} />
      </Button>
    </Stack>
  );
};

export default UnionHeader;
