import { Error } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, Typography, useTheme } from '@mui/material';

interface VoteResultVoteBoxFetchErrorDialogProps {
  open: boolean;
  onClose: () => void;
}

const VoteResultVoteBoxFetchErrorDialog = ({ open, onClose }: VoteResultVoteBoxFetchErrorDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: 417 }}>
        <Stack spacing={3} alignItems="center">
          <Error color="error" style={{ fontSize: 80 }} />
          <Typography variant="body1" color={theme.palette.grey[900]} textAlign="center">
            투표 결과를 가져오지 못했습니다. Kevoting 홈페이지에서 확인해 주세요.
            <br />
            <br />
            문의: 1899-1433
          </Typography>
          <Button variant="contained" color="primary" onClick={onClose} fullWidth>
            확인
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VoteResultVoteBoxFetchErrorDialog;
