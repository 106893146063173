/* eslint-disable @typescript-eslint/indent */
import { Close, HelpOutline } from '@mui/icons-material';
import { IconButton, Slide, SlideProps, Snackbar, Stack, tooltipClasses, useTheme } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import history from '../util/history';
import { LightTooltip } from '../views/common/tooltip/light-tooltip';

export interface Feedback {
  // TODO: 만약 토스트 색상이 오류/ 성공에 따라 달라진다면 추가 필요
  // level: 'error' | 'success' | 'info';
  message: string;
}

export function TransitionUp(props: SlideProps) {
  return <Slide {...props} direction="up" exit={false} />;
}

export function useCommonSnackbar(): [JSX.Element, React.Dispatch<React.SetStateAction<Feedback | undefined>>] {
  const [systemMessage, setFeedback] = useState<Feedback>();

  const snackbar = useMemo(
    () => (
      <Snackbar
        open={!!systemMessage}
        TransitionComponent={TransitionUp}
        onClose={() => setFeedback(undefined)}
        autoHideDuration={2000}
        message={systemMessage?.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    ),
    [systemMessage],
  );

  return [snackbar, setFeedback];
}

export interface TooltipProps {
  content: ReactNode;
  maxWidth: number;
  placement?:
    | 'bottom'
    | 'top'
    | 'left'
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}
export function useTooltip({
  content,
  maxWidth,
  placement,
}: TooltipProps): [JSX.Element, React.Dispatch<React.SetStateAction<boolean>>] {
  const theme = useTheme();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const lightTooltip = useMemo(
    () => (
      <LightTooltip
        open={tooltipOpen}
        placement={placement ?? 'bottom'}
        PopperProps={{
          disablePortal: true,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -16],
              },
            },
          ],
        }}
        sx={{
          [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: `${maxWidth}px`,
          },
        }}
        onClose={() => setTooltipOpen(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <Stack direction="row" alignItems="flex-start">
            {content}
            <IconButton
              aria-label="help"
              sx={{
                color: theme.palette.grey[800],
                right: '-12px',
                top: '-12px',
              }}
              onClick={() => setTooltipOpen(false)}
            >
              <Close />
            </IconButton>
          </Stack>
        }
      >
        <IconButton aria-label="help" sx={{ color: theme.palette.grey[700] }} onClick={() => setTooltipOpen(true)}>
          <HelpOutline />
        </IconButton>
      </LightTooltip>
    ),
    [content, maxWidth, placement, theme, tooltipOpen],
  );

  return [lightTooltip, setTooltipOpen];
}

export const usePreventLeave = (global = false) => {
  const handlePreventLeave = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = ''; // chrome requires returnValue
  };

  const onPreventLeave = () => {
    window.addEventListener('beforeunload', handlePreventLeave);
  };
  const offPreventLeave = () => {
    window.removeEventListener('beforeunload', handlePreventLeave);
  };

  useEffect(() => {
    if (!global) return () => {};

    window.addEventListener('beforeunload', handlePreventLeave);
    return () => {
      window.removeEventListener('beforeunload', handlePreventLeave);
    };
  }, [global]);

  return {
    onPreventLeave,
    offPreventLeave,
  };
};

export const usePreventGoBack = (global = false) => {
  const [needAlert, setNeedAlert] = useState(false);

  const preventGoBack = useCallback(() => {
    history.push(window.location.href);
    setNeedAlert(true);
  }, []);

  const onPreventGoBack = () => {
    history.push(window.location.href);
    window.addEventListener('popstate', preventGoBack);
  };

  const offPreventGoBack = () => {
    window.removeEventListener('popstate', preventGoBack);
  };

  useEffect(() => {
    if (!global) return () => {};

    history.push(window.location.href);
    window.addEventListener('popstate', preventGoBack);
    return () => {
      // clean up
      window.removeEventListener('popstate', preventGoBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global]);

  return {
    needAlert,
    setNeedAlert,
    onPreventGoBack,
    offPreventGoBack,
  };
};
