import { Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { mixed, object, string } from 'yup';
import { updateUnionMemberInformation } from '../../../../api/union.api';
import { TransitionUp } from '../../../../hooks';
import { RequestStatus, UnionMember, UnionMemberGrade, UnionMemberType } from '../../../../models';

interface MemberModificationDialogProps {
  open: boolean;
  unionId: number;
  selectedMember?: UnionMember;
  onClose: () => void;
  onSuccess: () => void;
}

const MemberModificationDisabledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderColor: theme.palette.grey[500],
}));

const MemberModificationDialog = ({
  open,
  unionId,
  selectedMember,
  onClose,
  onSuccess,
}: MemberModificationDialogProps) => {
  const theme = useTheme();

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [errorMessage, setErrorMessage] = useState('');

  const memberId = useMemo(() => selectedMember?.id, [selectedMember?.id]);
  const serialNumber = useMemo(() => selectedMember?.serialNumber, [selectedMember?.serialNumber]);
  const type = useMemo(() => selectedMember?.type, [selectedMember?.type]);
  const grade = useMemo(() => selectedMember?.grade, [selectedMember?.grade]);
  const registrationNumberOrRrn = useMemo(() => {
    if (selectedMember === undefined || selectedMember.partialRrn === null) {
      return '';
    }

    return selectedMember.partialRrn;
  }, [selectedMember]);
  const assetAddress = useMemo(() => selectedMember?.assetAddress, [selectedMember?.assetAddress]);

  const isModifyButtonDisabled = useMemo(() => {
    if (requestStatus === RequestStatus.REQUESTED) {
      return true;
    }
    return false;
  }, [requestStatus]);

  const isToastOpen = useMemo(() => {
    if (requestStatus === RequestStatus.FAIL) {
      return true;
    }
    return false;
  }, [requestStatus]);

  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const onAddressChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  }, []);

  const onPhoneChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  }, []);

  const initializeMemberData = useCallback(() => {
    if (selectedMember === undefined) {
      return;
    }

    setName(selectedMember.name);
    setAddress(selectedMember.address);
    let selectedMemberPhone = selectedMember.phone;
    if (selectedMemberPhone === null) {
      selectedMemberPhone = '';
    } else {
      selectedMemberPhone = selectedMemberPhone.replace(/-/g, '');
    }
    setPhone(selectedMemberPhone);
    setErrorMessage('');
  }, [selectedMember]);

  const validateInputData = useCallback(() => {
    const validatedData = object()
      .shape({
        name: string().required('이름을 입력해주세요.').min(1),
        address: string().required('주소를 입력해주세요.').min(1),
        phone: string().required('연락처를 입력해주세요.').trim().min(1),
        serialNumber: string().required('고유번호를 입력해주세요.').min(1),
        type: mixed<UnionMemberType>().oneOf(Object.values(UnionMemberType), '구분을 선택해주세요.').required(),
        grade: mixed<UnionMemberGrade>().oneOf(Object.values(UnionMemberGrade), '등급을 선택해주세요.').required(),
        registrationNumberOrRrn: string().required('생년월일 또는 사업자 번호를 입력해주세요.'),
        assetAddress: string().required('소유부동산을 입력해주세요.').min(1),
      })
      .validate({
        name,
        address,
        phone,
        serialNumber,
        type,
        grade,
        registrationNumberOrRrn,
        assetAddress,
      });

    validatedData.catch((e) => {
      setErrorMessage(e.message);
      return {
        isValid: false,
      };
    });

    if (
      type === UnionMemberType.CORPORATION &&
      !registrationNumberOrRrn.match(new RegExp('[0-9]{3}[-][0-9]{2}[-][0-9]{5}', undefined))
    ) {
      setErrorMessage('사업자 번호의 형태가 올바르지 않습니다. (예시: 476-15-76421)');
      return {
        isValid: false,
      };
    }

    if (
      type !== UnionMemberType.CORPORATION &&
      !registrationNumberOrRrn.match(new RegExp('[0-9]{6}[-][1-9]{1}', undefined))
    ) {
      setErrorMessage('생년월일의 형태가 올바르지 않습니다. (예시: 701212-1)');
      return {
        isValid: false,
      };
    }

    return {
      isValid: true,
      name,
      phone,
      address,
    };
  }, [address, assetAddress, grade, name, phone, registrationNumberOrRrn, serialNumber, type]);

  const modifyMemberData = useCallback(async () => {
    if (memberId === undefined) {
      setRequestStatus(RequestStatus.FAIL);
      return;
    }

    const { isValid, name: validatedName, phone: validatedPhone, address: validatedAddress } = validateInputData();
    if (!isValid || !validatedName || !validatedPhone || !validatedAddress) {
      return;
    }

    setRequestStatus(RequestStatus.REQUESTED);
    try {
      const requestBodyData = {
        name: validatedName,
        phone: validatedPhone,
        address: validatedAddress,
      };
      const responseStatus = await updateUnionMemberInformation(unionId, memberId, requestBodyData);
      if (responseStatus === 200) {
        setRequestStatus(RequestStatus.SUCCESS);
        onSuccess();
      }
    } catch (error) {
      setRequestStatus(RequestStatus.FAIL);
    }
  }, [memberId, onSuccess, unionId, validateInputData]);

  useEffect(() => {
    initializeMemberData();
  }, [initializeMemberData]);

  return (
    <Dialog open={open}>
      <DialogTitle variant="h2">
        구성원 추가
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField label="이름" value={name} onChange={onNameChange} required autoComplete="off" />
            <MemberModificationDisabledTextField
              label="고유번호"
              value={serialNumber}
              required
              autoComplete="off"
              disabled
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ flex: 1 }} required>
              <InputLabel id="type-select-label">구분</InputLabel>
              <Select
                label="구분"
                value={type}
                labelId="type-select-label"
                disabled
                sx={{ backgroundColor: theme.palette.grey[200] }}
              >
                <MenuItem value="">
                  <em>선택안함</em>
                </MenuItem>
                {Object.values(UnionMemberType).map((val) => (
                  <MenuItem key={`member-type-option-${val}`} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <PatternFormat
            format="###########"
            customInput={TextField}
            name="phoneForMessage"
            label="연락처"
            value={phone}
            autoComplete="off"
            onChange={onPhoneChange}
            required
            sx={{ flex: 1 }}
          />
          <MemberModificationDisabledTextField
            label="생년월일/사업자번호"
            value={registrationNumberOrRrn}
            required
            autoComplete="off"
            placeholder="생년월일: 000000-0 / 사업자등록번호: 000-00-00000"
            disabled
          />
          <TextField label="주소" value={address} onChange={onAddressChange} required autoComplete="off" />
          <MemberModificationDisabledTextField
            label="권리내역 (소유 부동산)"
            value={assetAddress}
            required
            autoComplete="off"
            disabled
          />
          <Typography color="error">{errorMessage}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={modifyMemberData}
          disabled={isModifyButtonDisabled}
        >
          수정하기
        </Button>
      </DialogActions>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={TransitionUp}
        onClose={() => {
          setRequestStatus(RequestStatus.IDLE);
        }}
      >
        <Alert
          icon={false}
          sx={{
            width: '342px',
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.common.white,
            borderRadius: 1,
          }}
        >
          저장을 시도하는 중 실패했습니다.
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default memo(MemberModificationDialog);
