import { useContext, useEffect } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import { CenteredInFullPage, LogoImageContainer } from './style';

import AppContext from '../../AppContext';
import { getSelf } from '../../api/staff';
import { ServerError } from '../../models';
import { getCookie } from '../../util/Cookie';

function Splash() {
  const { initialized, setToken, setStaff, setInitialized, token, setMyUnions, setSelectedUnion } =
    useContext(AppContext);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const t = getCookie('token');
        if (!t) {
          return;
        }
        const data = await getSelf();
        if (data instanceof ServerError) {
          return;
        }
        setToken(t);
        setStaff(data.staff);
      } finally {
        setInitialized(true);
      }
    };
    if (!initialized) {
      initialize();
    }
  }, [initialized, setInitialized, setMyUnions, setSelectedUnion, setStaff, setToken, token]);

  return (
    <CenteredInFullPage>
      <Backdrop open>
        <div>
          <LogoImageContainer>
            <CircularProgress size="5rem" color="primary" />
          </LogoImageContainer>
        </div>
      </Backdrop>
    </CenteredInFullPage>
  );
}

export default Splash;
