/* eslint-disable eqeqeq */
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import hwpFileIcon from '../assets/file-hwp.svg';
import imgFileIcon from '../assets/file-img.svg';
import pdfFileIcon from '../assets/file-pdf.svg';
import pptFileIcon from '../assets/file-powerpoint.svg';
import excelFileIcon from '../assets/file-spreadsheet.svg';
import videoFileIcon from '../assets/file-video.svg';
import wordFileIcon from '../assets/file-word.svg';
import { GenderType, Staff } from '../models';
import { StaffRoleType } from '../models/staff-role.model';
import { getCookie, setCookie } from './Cookie';

export const convertToPriceString = (val: number) => {
  if (val == 0) {
    return '0원';
  }
  return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`;
};

export const convertGenderTypeToString = (genderType: GenderType) => {
  if (genderType == GenderType.MALE) {
    return '남성';
  }
  return '여성';
};

export const byteToHumanReadableSize = (bytes: number) => {
  const thresh = 1000;
  let result = bytes;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  while (Math.round(Math.abs(result) * 10) / 10 >= thresh && u < units.length - 1) {
    result /= thresh;
    u += 1;
  }
  return `${result.toFixed(1)} ${units[u]}`;
};

export const getFileIcon = (name: string) => {
  const imageExtensions = ['png', 'jpg', 'jpeg', 'bmp'];
  const hwpExtensions = ['hwp', 'hwpx'];
  const wordExtensions = ['doc', 'docx'];
  const pptExtensions = ['ppt', 'pptx'];
  const excelExtensions = ['xls', 'xlsx'];
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv'];
  const extension = name.split('.').pop();
  if (!extension) {
    return pdfFileIcon;
  }
  if (imageExtensions.includes(extension)) {
    return imgFileIcon;
  }
  if (videoExtensions.includes(extension)) {
    return videoFileIcon;
  }
  if (hwpExtensions.includes(extension)) {
    return hwpFileIcon;
  }
  if (wordExtensions.includes(extension)) {
    return wordFileIcon;
  }
  if (pptExtensions.includes(extension)) {
    return pptFileIcon;
  }
  if (excelExtensions.includes(extension)) {
    return excelFileIcon;
  }
  return pdfFileIcon;
};

export const isInternalStaff = (staff: Staff | undefined) =>
  staff && staff.roles.find((item) => item.role === StaffRoleType.INTERNAL);

export const getCurrentUnionId = () => {
  const unionId = getCookie('union-id');
  if (unionId) {
    return Number(unionId);
  }
  return undefined;
};

export const setCurrentUnionId = (id: number) => {
  setCookie('union-id', id);
};

export const convertDateFormatYearMonthDay = (date: Date) => {
  const dateTime = dayjs(date);
  return dateTime.locale('ko').format('YYYYMMDD');
};

export const convertDateFormat24HWithoutDay = (date: Date) => {
  const dateTime = dayjs(date);
  return dateTime.locale('ko').format('YYYY.MM.DD HH:mm');
};

export const convertDateFormat24H = (date: Date) => {
  const dateTime = dayjs(date);
  return dateTime.locale('ko').format('YYYY년 MM월 DD일 (ddd요일) HH:mm');
};

export const convertDateFormat12H = (date: Date) => {
  const dateTime = dayjs(date);
  return dateTime.locale('ko').format('YYYY년 MM월 DD일 (ddd요일) A hh:mm');
};

export const convertDateFormat12HWithoutDaySplitByDash = (date: Date) => {
  const dateTime = dayjs(date);
  return dateTime.locale('ko').format('YYYY-MM-DD hh:mm A');
};

export const getVoteDateText = (startDate: Date | null, endDate: Date | null) => {
  if (startDate === null || endDate === null) {
    return '';
  }

  return `${convertDateFormat24H(startDate)} ~ ${convertDateFormat24H(endDate)}`;
};

export const formatPhoneNumHyphen = (phoneNum: string) => {
  let formattedNum: string = '';
  if (phoneNum.length === 11) {
    formattedNum = phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phoneNum.length === 10) {
    formattedNum = phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  } else {
    formattedNum = phoneNum;
  }
  return formattedNum;
};

export const getByteWithEucKr = (str: string): number =>
  str
    .split('')
    .map((s) => s.charCodeAt(0))
    .reduce((prev, c) => {
      let byteCount: number;
      if (c === 10) {
        // \u000A (줄바꿈인경우)
        byteCount = 2;
      } else {
        // 7bit 초과시 2bytes로
        // eslint-disable-next-line no-bitwise
        byteCount = c >> 7 ? 2 : 1;
      }
      return prev + byteCount;
    }, 0);

export const sliceWithinMaxBytes = (originStr: string, maxByte: number): string => {
  let byteCnt = 0;
  let endIdx = 0;

  originStr.split('').forEach((char, idx) => {
    // eslint-disable-next-line no-bitwise
    byteCnt += char.charCodeAt(0) >> 7 ? 2 : 1;

    if (byteCnt > maxByte && endIdx === 0) {
      endIdx = idx;
    }
  });

  return originStr.substring(0, endIdx || originStr.length);
};

export const isDevEnv: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
