/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { DownloadOutlined, InfoOutlined, SaveOutlined } from '@mui/icons-material';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { mixed, object, string } from 'yup';
import { addUnionMembers } from '../../../../api/union.api';
import { RequestStatus, ServerError, UnionMember, UnionMemberGrade, UnionMemberType } from '../../../../models';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';

type MemberElement = Omit<UnionMember, 'id' | 'unionId'>;
type UnionMemberExcelUploadProps = {
  unionId: number;
  onRefresh: () => void;
  onCancel: () => void;
};
const UnionMemberExcelUpload = ({ unionId, onRefresh, onCancel }: UnionMemberExcelUploadProps) => {
  const [errMsg, setErrMsg] = useState('');
  const [file, setFile] = useState<File>();
  const onFileDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps: rootProps, getInputProps: inputProps } = useDropzone({
    onDrop: onFileDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xslx'],
    },
  });
  const [validationStatus, setValidationStatus] = useState(RequestStatus.IDLE);
  const [membersToUpload, setMembersToUpload] = useState<MemberElement[]>();
  const [uploadStatus, setUploadStatus] = useState(RequestStatus.IDLE);

  const validator = object().shape({
    조합원번호: string().required('조합원번호'),
    성명: string().required('성명'),
    연락처: string().optional(),
    주소: string().required('주소'),
    소유부동산: string().required('소유부동산'),
    구분: mixed<UnionMemberType>().oneOf(Object.values(UnionMemberType), '구분').required('구분'),
    등급: mixed<UnionMemberGrade>().oneOf(Object.values(UnionMemberGrade), '등급').required('등급'),
    '생년월일/사업자번호': string()
      .required('생년월일/사업자번호')
      .matches(new RegExp('[0-9]{6}[-][1-8]{1}|[0-9]{3}[-][0-9]{2}[-][0-9]{5}', undefined)),
  });
  const parseElement = (data: unknown[]): { isValid: boolean; msg: string; result: MemberElement[] } => {
    let msg = '';
    let isValid = true;
    const result: MemberElement[] = [];
    const serialNumberSet = new Set();
    data.every((item, idx) => {
      try {
        const {
          조합원번호: serialNumber,
          성명: name,
          연락처: phone,
          주소: address,
          소유부동산: assetAddress,
          구분: type,
          등급: grade,
          '생년월일/사업자번호': rrnOrRegistrationNumber,
        } = validator.validateSync(item, { stripUnknown: true });

        if (serialNumberSet.has(serialNumber)) {
          msg = `${idx + 1}번 데이터 중복된 조합원번호 ${serialNumber} 가 있습니다.`;
          isValid = false;
          return false;
        }

        result.push({
          serialNumber,
          name,
          phone: phone?.replace(/-/g, '') || null,
          address,
          assetAddress,
          type,
          grade,
          partialRrn: type === UnionMemberType.CORPORATION ? null : rrnOrRegistrationNumber,
          companyRegistrationNumber: type === UnionMemberType.CORPORATION ? rrnOrRegistrationNumber : null,
        });
        serialNumberSet.add(serialNumber);
        return true;
      } catch (e) {
        msg = `${idx + 1}번 데이터 형식에 오류(${(e as { errors: string[] }).errors[0]})가 있습니다.`;
        isValid = false;
        return false;
      }
    });
    return { isValid, msg, result };
  };

  const onSave = useCallback(async () => {
    if (membersToUpload) {
      setUploadStatus(RequestStatus.REQUESTED);
      const result = await addUnionMembers({ members: membersToUpload, unionId });
      if (result instanceof ServerError) {
        setUploadStatus(RequestStatus.FAIL);
        return;
      }
      setUploadStatus(RequestStatus.SUCCESS);
    }
  }, [membersToUpload, unionId]);

  useEffect(() => {
    if (uploadStatus === RequestStatus.SUCCESS) {
      onRefresh();
    }
  }, [onRefresh, uploadStatus]);

  useEffect(() => {
    if (file) {
      const handleFileTask = async () => {
        setValidationStatus(RequestStatus.REQUESTED);
        try {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = (e) => {
            const wb = XLSX.read(e.target?.result, { type: 'buffer' });
            const ws = wb.Sheets[wb.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(ws);
            const result = parseElement(data);
            if (!result.isValid) {
              setErrMsg(result.msg);
              setValidationStatus(RequestStatus.FAIL);
            } else {
              setValidationStatus(RequestStatus.SUCCESS);
              setMembersToUpload(result.result);
            }
          };
        } catch (e) {
          setErrMsg('처리중 오류가 발생했습니다.');
          setValidationStatus(RequestStatus.FAIL);
        }
      };
      handleFileTask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);
  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <Stack alignItems="start" spacing={3}>
          <Stack direction="row" alignItems="center" spacing={14}>
            <Typography variant="h2">명부 업로드 가이드라인</Typography>
            <Button
              variant="outlined"
              size="small"
              href={`${process.env.PUBLIC_URL}/member-upload-sample.xlsx`}
              download="명부양식.xlsx"
            >
              명부 양식 다운로드
              <DownloadOutlined sx={{ marginLeft: '4px' }} />
            </Button>
          </Stack>
          <Stack alignItems="start" spacing={1}>
            <Stack direction="row" spacing={1}>
              <InfoOutlined />
              <Typography variant="body1" gutterBottom fontWeight={600} fontSize={16}>
                모든 항목은 반드시 입력해야 하며, 각 항목별 입력 규칙에 맞춰 작성해주세요.
              </Typography>
            </Stack>
            <Typography align="left">
              <b>조합원번호:</b> 조합원 또는 추진위원 번호는 <b>중복될 수 없습니다.</b>
            </Typography>
            <Typography align="left">
              <b>성명:</b> 구성원의 이름
            </Typography>
            <Typography align="left">
              <b>연락처:</b> 휴대폰 번호는 하이픈(-)을 제외하고 숫자만 입력합니다. 소식지, 정보 전달, 총회 투표 및
              어플리케이션을 사용하는데 필요하니 정확히 입력해 주세요.
            </Typography>
            <Typography align="left">
              <b>주소:</b> 구성원이 등기를 수신할 수 있는 현재 거주중인 주소
            </Typography>
            <Typography align="left">
              <b>소유부동산:</b> 구성원이 권리를 소유한 토지등 부동산 내역(주소)
            </Typography>
            <Typography align="left">
              <b>구분:</b> 구성원에 따라 내국인/내국인(해외거주)/외국인/사업자 중 1개를 필수로 입력합니다.
            </Typography>
            <Typography align="left">
              <b>등급:</b> 구성원에 따라 일반조합원/조합장/대의원/이사/감사/공동소유자 중 1개를 필수로 입력합니다.
            </Typography>
            <Typography align="left">
              <b>생년월일/사업자번호:</b> 개인일 경우 주민등록번호 앞 7자리를 하이픈(-)을 포함하여 ‘000000-0’ 형태로
              작성. 법인일 경우 사업자 번호 10자리를 하이픈(-)을 포함하여 ‘000-00-00000’ 형태로 작성.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {!file && validationStatus !== RequestStatus.REQUESTED && (
        <Stack alignItems="start" {...rootProps()} width={160}>
          <Button variant="contained" sx={{ width: 160 }}>
            파일선택
            <input {...inputProps()} multiple={false} />
          </Button>
        </Stack>
      )}
      <Paper>
        <Stack direction="row" spacing={4} p={4} alignItems="center">
          <Stack spacing={1} alignItems="start">
            <Typography fontWeight={600}>처리상태</Typography>
            {validationStatus === RequestStatus.IDLE && <Typography>업로드 대기중</Typography>}
            {validationStatus === RequestStatus.REQUESTED && <Typography>업로드 중...</Typography>}
            {validationStatus === RequestStatus.SUCCESS && (
              <Typography>{`업로드 성공(${membersToUpload?.length}명)`}</Typography>
            )}
            {errMsg && <Typography color="error">{errMsg}</Typography>}
          </Stack>
        </Stack>
      </Paper>
      <Stack direction="row" spacing={4}>
        <Button variant="outlined" onClick={onCancel} size="small" color="inherit" sx={{ width: 240 }}>
          취소
        </Button>
        {validationStatus === RequestStatus.SUCCESS && (
          <Button variant="contained" onClick={onSave} sx={{ width: 240 }}>
            저장
            <SaveOutlined sx={{ marginLeft: '4px' }} />
          </Button>
        )}
      </Stack>
      <SuccessErrorModal
        isSuccess={uploadStatus === RequestStatus.SUCCESS}
        open={uploadStatus === RequestStatus.SUCCESS || uploadStatus === RequestStatus.FAIL}
        title="조합원 엑셀 업로드"
        description={
          uploadStatus === RequestStatus.SUCCESS
            ? '성공적으로 엑셀파일을 통한 조합원 추가를 완료했습니다.'
            : '엑셀파일을 통한 조합원 추가 중 오류가 발생했습니다.'
        }
        onClose={() => setUploadStatus(RequestStatus.IDLE)}
      />
    </Stack>
  );
};

export default UnionMemberExcelUpload;
