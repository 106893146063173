import { ArrowRight, Close } from '@mui/icons-material';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext } from 'react';
import AppContext from '../../../AppContext';
import { Union } from '../../../models';

type UnionSwitchDialogProps = {
  open: boolean;
  onClose: () => void;
};
export default function UnionSwitchDialog({ open, onClose }: UnionSwitchDialogProps) {
  const theme = useTheme();
  const { myUnions, setSelectedUnion } = useContext(AppContext);

  const onUnionSelect = (union: Union) => {
    setSelectedUnion(union);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle variant="h2">
        사업장 변경
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          {myUnions?.map((union) => (
            <Stack
              key={`union-item-${union.id}`}
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ cursor: 'pointer' }}
              onClick={() => onUnionSelect(union)}
            >
              <Chip
                label={union.unionOperator?.type}
                sx={{ width: 80, backgroundColor: theme.palette.primary.light }}
              />
              <Typography flex={1}>{union.name}</Typography>
              <ArrowRight />
            </Stack>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
