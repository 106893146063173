import { useTheme } from '@mui/material';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { MessageSendingResultModel } from '../../../../../models/message-sending-history.model';

interface SendingResultTableProps {
  results: MessageSendingResultModel[]
}

export default function SendingResultTable({ results }: SendingResultTableProps) {
  const theme = useTheme();

  const columns: GridColDef[] = useMemo(() => [
    {
      field: 'destPhone',
      headerName: '전화번호',
      sortable: false,
      align: 'left',
      flex: 2,
      valueFormatter: (params) => (params.value ?? '-'),
    },
    {
      field: 'status',
      headerName: '결과',
      sortable: true,
      align: 'left',
      flex: 1,
      renderCell: (params) =>
        (params.row.errorCode ? `${params.value}(${params.row.errorCode})` : params.value),
    },
  ], []);

  return (
    <DataGrid
      autoHeight
      columns={columns}
      rows={results}
      hideFooterPagination={results.length === 0}
      getRowId={(row) => row.destPhone}
      disableColumnMenu
      rowSelection={false}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10]}
      localeText={{ noRowsLabel: '내용이 없습니다.' }}
      sx={{
        [`& .${gridClasses.columnHeader}`]: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.grey[900],
          typography: theme.typography.subtitle2,
        },
        [`& .${gridClasses.cellContent}`]: {
          typography: theme.typography.body2,
        },
        [`& .${gridClasses.cell}:focus`]: {
          outline: 'none',
        },
      }}
    />
  );
}
