import { createTheme, responsiveFontSizes } from '@mui/material';
import { colors, grey } from './theme/reference';

const white = grey[50];
const black = grey[900];

const themeBase = {
  palette: {
    primary: {
      main: colors.blue[500],
      dark: colors.blue[600],
      light: colors.blue[100],
      contrastText: white,
    },
    secondary: {
      main: colors.blue[100],
      dark: '#B3D8FF',
      light: colors.blue[50],
      contrastText: colors.blue[500],
    },
    common: {
      black,
      white,
      ...colors,
    },
    grey,
    error: {
      main: '#ff0032',
      dark: '#f70032',
      light: '#ff224e',
    },
  },
  typography: {
    fontFamily: 'Pretendard',
    allVariants: { color: grey[900] },
    title: {
      fontSize: '2rem',
      fontWeight: 700,
      letterSpacing: '-0.5px',
    },
    subtitle1: {
      fontSize: '1.125rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
      lineHeight: '24px',
    },
    subtitle3: {
      fontSize: '0.875rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    subtitle4: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    h1: {
      fontSize: '1.75rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    body1: {
      fontSize: '1.125rem',
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '-0.5px',
      lineHeight: '24px',
    },
    body3: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
    caption1: {
      fontSize: '0.75rem',
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
    caption2: {
      fontSize: '0.625rem',
      fontWeight: 400,
      letterSpacing: '-0.5px',
    },
  },
};

export default responsiveFontSizes(
  createTheme({
    ...themeBase,
    components: {
      MuiCard: { defaultProps: { elevation: 0 }, styleOverrides: { root: { borderRadius: 0 } } },
      MuiCheckbox: {
        styleOverrides: { root: { padding: '0 4px 0 0' } },
      },
      MuiTextField: { styleOverrides: { root: { borderRadius: '0' } } },
      MuiOutlinedInput: { styleOverrides: { root: { borderRadius: '8px' } } },
      MuiButton: {
        styleOverrides: {
          root: { boxShadow: 'none', borderRadius: '16px' },
          sizeSmall: () => ({
            fontSize: '16px',
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '12px',
            paddingRight: '12px',
            borderRadius: '8px',
            fontWeight: 600,
          }),
          sizeMedium: ({ theme }) => ({
            fontSize: theme.typography.body2.fontSize,
            paddingTop: '12px',
            paddingBottom: '12px',
            borderRadius: '16px',
            fontWeight: 600,
          }),
          sizeLarge: ({ theme }) => ({ fontSize: theme.typography.h2.fontSize }),
        },
      },
      MuiTableContainer: { styleOverrides: { root: { boxShadow: 'none' } } },
      MuiTableCell: { styleOverrides: { root: { border: 'none' } } },
      MuiChip: {
        styleOverrides: {
          filledPrimary: ({ theme }) => ({
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
          }),
        },
      },
      MuiDialogContent: { styleOverrides: { root: { padding: '32px' } } },
      MuiDialogActions: { styleOverrides: { root: { padding: '0 32px 32px' } } },
    },
  }),
);
