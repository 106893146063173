import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { getCustomerList } from '../../api/customer.api';
import { Customer, ServerError } from '../../models';
import CustomerHeaderView from './header';
import CustomerListView from './list';

const CustomerPage = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [needsRefresh, setNeedsRefresh] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (needsRefresh) {
      const fetchCustomers = async () => {
        const result = await getCustomerList();
        if (result instanceof ServerError) {
          return;
        }
        setCustomers(result.customerList);
      };
      fetchCustomers();
      setNeedsRefresh(false);
    }
  }, [needsRefresh]);

  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);

  useEffect(() => {
    if (!searchText) {
      setFilteredCustomers(customers);
    } else {
      const d = customers.filter(({ name, phone, nickname, email }: Customer) =>
        [name, phone, nickname, email].some((v) => v?.toLowerCase().includes(searchText.toLowerCase().trim())),
      );
      setFilteredCustomers(d);
    }
  }, [customers, searchText]);

  return (
    <Stack spacing={3} p={5}>
      <CustomerHeaderView searchText={searchText} setSearchText={setSearchText} />
      <CustomerListView customers={filteredCustomers} />
    </Stack>
  );
};

export default CustomerPage;
