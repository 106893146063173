import axios, { AxiosError, AxiosInstance } from 'axios';

import config from '../config';
import { ServerError } from '../models';
import { getCookie } from './Cookie';

const axiosInstances: { [key: string]: AxiosInstance } = {};

export const ApiClient = (): AxiosInstance => {
  const token = getCookie('token');

  let client = axiosInstances.base;
  if (!client) {
    client = axios.create({
      baseURL: `${config.baseApiUrl}`,
      responseType: 'json',
      timeout: 10000,
      headers: { Authorization: `bearer ${token}` },
    });
    axiosInstances.base = client;
  } else {
    client.defaults.headers.Authorization = `bearer ${token}`;
  }

  return client;
};

export const handleError = (error: unknown) => {
  let code = 500;
  if (axios.isAxiosError(error) && (error as AxiosError).response?.status) {
    code = (error as AxiosError).response!.status;
  }
  return new ServerError({ code, msg: '서버에러' });
};

export default ApiClient;
