import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getUnionMembers } from '../../../../../api/union.api';
import { AppJoinedStatus } from '../../../../../models/message-sending.model';
import { MessageTargetGrade } from '../../../../../models/message-type';
import UnionMemberModel, { UnionMemberGrade } from '../../../../../models/union-member.model';

export interface SendingFormProps {
  isCommittee: boolean;
  unionId: number;
  getJoinedStatus: (status: AppJoinedStatus[]) => void;
  getReceivers: (receivers: MessageTargetGrade[], totalMemberCnt: number) => void;
}

interface GradeWithCount {
  targetGrade: MessageTargetGrade,
  count: number
}

interface GroupedByGrade {
  [key: string]: UnionMemberModel[];
}

export default function SendingForm(props: SendingFormProps) {
  const { isCommittee, unionId, getJoinedStatus, getReceivers } = props;

  const [targetGradeMembers, setTargetGradeMembers] = useState<GroupedByGrade>();
  const [groupedAllReceivers, setGroupedAllReceivers] = useState<GradeWithCount[]>([]);

  const [checkJoinedStatus, setCheckJoinedStatus] =
    useState<AppJoinedStatus[]>([AppJoinedStatus.REGISTERED, AppJoinedStatus.UNREGISTERED]);
  const [checkedReceiversGrade, setCheckedReceiversGrade] = useState<GradeWithCount[]>([]);

  const executives = useMemo(() => [UnionMemberGrade.AUDITOR, UnionMemberGrade.DIRECTOR, UnionMemberGrade.LEADER], []);

  const convertToMessageTargetGrade = useCallback((origin: GroupedByGrade) => {
    const newData: GroupedByGrade = {};
    Object.keys(origin).forEach((key) => {
      if (executives.includes(key as UnionMemberGrade)) {
        const executiveKey = MessageTargetGrade.EXECUTIVE;
        if (!newData[executiveKey]) {
          newData[executiveKey] = [];
        }
        newData[executiveKey].push(...origin[key]);
      } else {
        newData[key as MessageTargetGrade] = origin[key];
      }
    });
    return newData;
  }, [executives]);

  const fetchUnionMembers = useCallback(async () => {
    if (!unionId) {
      return;
    }
    try {
      const data = await getUnionMembers(Number(unionId));
      const groupedMember = groupBy(data, ({ grade }) => grade);
      setTargetGradeMembers(convertToMessageTargetGrade(groupedMember));
    } catch (error) {
      // error 처리 필요
    }
  }, [convertToMessageTargetGrade, unionId]);

  useEffect(() => {
    fetchUnionMembers();
  }, [fetchUnionMembers]);

  const countByGradeArr: number[] = useMemo(() => {
    if (!targetGradeMembers || checkJoinedStatus.length === 0) {
      return [0, 0, 0];
    }

    const allExecutive = (targetGradeMembers.executive ?? []).length;
    const allDelegate = (targetGradeMembers[UnionMemberGrade.DELEGATE] ?? []).length;
    const allGeneral = (targetGradeMembers[UnionMemberGrade.GENERAL] ?? []).length;

    if (checkJoinedStatus.length === 2) {
      // 앱 가입 전체 인 경우
      return [allExecutive, allDelegate, allGeneral];
    }
    const unregisteredExecutive =
      (targetGradeMembers.executive ?? []).filter((member) => member.customers?.length === 0).length;
    const unregisteredDelegate =
      (targetGradeMembers[UnionMemberGrade.DELEGATE] ?? []).filter((member) => member.customers?.length === 0).length;
    const unregisteredGeneral =
      (targetGradeMembers[UnionMemberGrade.GENERAL] ?? []).filter((member) => member.customers?.length === 0).length;
    if (checkJoinedStatus.includes(AppJoinedStatus.UNREGISTERED)) {
      return [unregisteredExecutive, unregisteredDelegate, unregisteredGeneral];
    }
    return [
      allExecutive - unregisteredExecutive,
      allDelegate - unregisteredDelegate,
      allGeneral - unregisteredGeneral,
    ];
  }, [checkJoinedStatus, targetGradeMembers]);

  useEffect(() => {
    if (isCommittee) {
      return;
    }
    const initialGradeWithCount: GradeWithCount[] = [];
    Object.values(MessageTargetGrade).forEach((grade, index) => {
      initialGradeWithCount.push({ targetGrade: grade, count: countByGradeArr[index] });
    });
    setGroupedAllReceivers(initialGradeWithCount);
  }, [countByGradeArr, isCommittee]);

  const convertedGradeToKo = useCallback((grade: MessageTargetGrade): string => {
    switch (grade) {
      case MessageTargetGrade.DELEGATE:
        return '대의원';
      case MessageTargetGrade.EXECUTIVE:
        return '임원';
      case MessageTargetGrade.GENERAL:
        return '일반 조합원';
      default:
        return '';
    }
  }, []);

  const onCheckAllJoinedStatus = useCallback(() => {
    // 2 == 가입자, 미가입자
    setCheckedReceiversGrade([]);
    if (checkJoinedStatus.length !== 2) {
      setCheckJoinedStatus([AppJoinedStatus.REGISTERED, AppJoinedStatus.UNREGISTERED]);
    } else {
      setCheckJoinedStatus([]);
    }
  }, [checkJoinedStatus.length]);

  const onCheckJoinedChange = useCallback((status: AppJoinedStatus) => {
    setCheckedReceiversGrade([]);
    if (checkJoinedStatus.includes(status)) {
      setCheckJoinedStatus(checkJoinedStatus.filter((item) => item !== status));
    } else {
      setCheckJoinedStatus((prev) => [...prev, status]);
    }
  }, [checkJoinedStatus]);

  const onCheckAllReceiversGrade = useCallback(() => {
    if (!groupedAllReceivers) {
      return;
    }
    if (checkedReceiversGrade.length !== groupedAllReceivers.length) {
      setCheckedReceiversGrade(groupedAllReceivers);
    } else {
      setCheckedReceiversGrade([]);
    }
  }, [checkedReceiversGrade, groupedAllReceivers]);

  const onCheckReceiverChangeGrade = useCallback((selectedMember: GradeWithCount) => {
    if (checkedReceiversGrade.includes(selectedMember)) {
      setCheckedReceiversGrade(checkedReceiversGrade.filter((item) => item !== selectedMember));
    } else {
      setCheckedReceiversGrade((prev) => [...prev, selectedMember]);
    }
  }, [checkedReceiversGrade]);

  useEffect(() => {
    getJoinedStatus(checkJoinedStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkJoinedStatus]);

  useEffect(() => {
    if (isCommittee) {
      return;
    }
    const totalReceiverCount = checkedReceiversGrade.reduce((acc, crr) => acc + crr.count, 0);
    const allReceiverGrade = checkedReceiversGrade.map((receiver) => receiver.targetGrade);
    getReceivers(allReceiverGrade, totalReceiverCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedReceiversGrade, isCommittee]);

  const committeeTotalReceiverCount = useMemo(
    () => countByGradeArr.reduce((acc, crr) => acc + crr, 0), [countByGradeArr]);

  useEffect(() => {
    if (!isCommittee) {
      return;
    }
    getReceivers(Object.values(MessageTargetGrade), committeeTotalReceiverCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [committeeTotalReceiverCount, isCommittee]);

  return (
    <Stack alignItems="start" spacing={3}>
      <Typography variant="subtitle1">수신 대상</Typography>
      <FormControl>
        <FormLabel id="check-box-app-hasJoined">
          <Typography textAlign="start" variant="body2" mb={1}>앱 가입 여부</Typography>
        </FormLabel>
        <FormGroup row sx={{ marginLeft: '8px', columnGap: '40px' }}>
          <FormControlLabel
            value={AppJoinedStatus.ALL}
            control={<Checkbox />}
            label={<Typography variant="body2">전체</Typography>}
            checked={checkJoinedStatus.length === 2}
            onChange={onCheckAllJoinedStatus}
          />
          <FormControlLabel
            value={AppJoinedStatus.UNREGISTERED}
            control={<Checkbox />}
            label={<Typography variant="body2">가입자</Typography>}
            onChange={() => onCheckJoinedChange(AppJoinedStatus.REGISTERED)}
            checked={checkJoinedStatus.includes(AppJoinedStatus.REGISTERED)}
          />
          <FormControlLabel
            value={AppJoinedStatus.REGISTERED}
            control={<Checkbox />}
            label={<Typography variant="body2">미가입자</Typography>}
            onChange={() => onCheckJoinedChange(AppJoinedStatus.UNREGISTERED)}
            checked={checkJoinedStatus.includes(AppJoinedStatus.UNREGISTERED)}
          />
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="check-box-member-grade">
          <Typography textAlign="start" variant="body2" mb={1}>구분</Typography>
        </FormLabel>
        {!isCommittee && (
          <FormGroup row sx={{ marginLeft: '8px', columnGap: '40px' }}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body2">전체</Typography>}
              checked={checkedReceiversGrade.length === groupedAllReceivers?.length}
              onChange={onCheckAllReceiversGrade}
            />
            {groupedAllReceivers.map((receiver) => (
              <FormControlLabel
                key={receiver.targetGrade}
                control={<Checkbox />}
                label={<Typography variant="body2">{`${convertedGradeToKo(receiver.targetGrade)}(${receiver.count}명)`}</Typography>}
                onChange={() => onCheckReceiverChangeGrade(receiver)}
                checked={checkedReceiversGrade.includes(receiver)}
              />
            ))}
          </FormGroup>
        )}
        {isCommittee && (
          <FormGroup row sx={{ marginLeft: '8px', columnGap: '40px' }}>
            <FormControlLabel
              control={<Checkbox />}
              label={<Typography variant="body2">{`전체 (${committeeTotalReceiverCount}명)`}</Typography>}
              checked
              disabled
            />
          </FormGroup>
        )}
      </FormControl>
    </Stack>
  );
}
