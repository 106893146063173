import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { koKR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { useEffect, useMemo, useState } from 'react';
import './App.css';
import AppContext from './AppContext';
import { getSelf } from './api/staff';
import { getJoinedUnionList } from './api/union.api';
import { ServerError, Staff, Union } from './models';
import theme from './theme';
import { getCookie, setCookie } from './util/Cookie';
import { getCurrentUnionId, isInternalStaff, setCurrentUnionId } from './util/functions';
import ResponsiveAppBar from './views/app-bar';
import Splash from './views/splash';

dayjs.locale('ko');

function App() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [staff, setStaff] = useState<Staff>();
  const [showNavigation, setShowNavigation] = useState(true);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [myUnions, setMyUnions] = useState<Union[]>();
  const [selectedUnion, setSelectedUnion] = useState<Union>();

  useEffect(() => {
    const initStaff = async () => {
      if (token && token !== getCookie('token')) {
        setCookie('token', token);
        const data = await getSelf();
        if (data instanceof ServerError) {
          return;
        }
        setStaff(data.staff);
      }
    };
    initStaff();
  }, [token]);

  useEffect(() => {
    const setUnion = async () => {
      if (staff && !isInternalStaff(staff)) {
        const unionData = await getJoinedUnionList();
        if (unionData instanceof ServerError) {
          return;
        }
        setMyUnions(unionData.unionList);
        if (unionData.unionList.length > 0) {
          const currentUnionId = getCurrentUnionId();
          if (currentUnionId) {
            const union = unionData.unionList.find((item) => item.id === currentUnionId);
            if (union) {
              setSelectedUnion(union);
              return;
            }
          }
          setSelectedUnion(unionData.unionList[0]);
        }
      }
    };
    setUnion();
  }, [staff]);

  useEffect(() => {
    if (selectedUnion) {
      const currentUnionId = getCurrentUnionId();
      if (selectedUnion.id !== currentUnionId) {
        setCurrentUnionId(selectedUnion.id);
      }
    }
  }, [selectedUnion]);

  const contextValues = useMemo(
    () => ({
      token,
      setToken,
      initialized,
      setInitialized,
      staff,
      setStaff,
      showNavigation,
      setShowNavigation,
      needsRefresh,
      setNeedsRefresh,
      selectedUnion,
      setSelectedUnion,
      myUnions,
      setMyUnions,
    }),
    [
      token,
      setToken,
      initialized,
      setInitialized,
      staff,
      setStaff,
      showNavigation,
      setShowNavigation,
      needsRefresh,
      setNeedsRefresh,
      selectedUnion,
      setSelectedUnion,
      myUnions,
      setMyUnions,
    ],
  );

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={contextValues}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="ko"
            localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            {!initialized ? <Splash /> : <ResponsiveAppBar />}
          </LocalizationProvider>
        </AppContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
