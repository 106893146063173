import { CheckCircleOutlineOutlined } from '@mui/icons-material';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { mixed, object, string } from 'yup';
import AppContext from '../../../AppContext';
import { getSignUpForKevotingUrl, updateUnionBasicInformation } from '../../../api/union.api';
import { ServerError, Union, UnionConstructionType, UnionStage } from '../../../models';
import { isInternalStaff } from '../../../util/functions';

const UnionDetailInformation = ({ union, onRefresh }: { union: Union; onRefresh: () => void }) => {
  const theme = useTheme();
  const { staff } = useContext(AppContext);
  const [name, setName] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const [serialNumber, setSerialNumber] = useState('');
  const onSerialNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(e.target.value);
  };
  const [representativePhone, setRepresentativePhone] = useState('');
  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRepresentativePhone(e.target.value);
  };
  const [representativeAddress, setRepresentativeAddress] = useState('');
  const onRepresentativeAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRepresentativeAddress(e.target.value);
  };
  const [constructionType, setConstructionType] = useState<UnionConstructionType | ''>('');
  const onConstructionTypeChange = (event: SelectChangeEvent) => {
    setConstructionType(event.target.value as UnionConstructionType);
  };
  const [stage, setStage] = useState<UnionStage | ''>('');
  const onStageChange = (event: SelectChangeEvent) => {
    setStage(event.target.value as UnionStage);
  };
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('');
  const onRegisterNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBusinessRegistrationNumber(e.target.value);
  };
  const [phoneForMessage, setPhoneForeMessage] = useState('');
  const onMsgPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneForeMessage(e.target.value);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [officeAddress, setOfficeAddress] = useState('');
  const onOfficeAddress = (e: ChangeEvent<HTMLInputElement>) => {
    setOfficeAddress(e.target.value);
  };

  useEffect(() => {
    if (union) {
      setName(union.name);
      setSerialNumber(union.serialNumber);
      setRepresentativePhone(union.representativePhone);
      setRepresentativeAddress(union.representativeAddress);
      setConstructionType(union.constructionType);
      setStage(union.stage);
      setPhoneForeMessage(union.phoneForMessage ?? '');
      setOfficeAddress(union.officeAddress);
      setBusinessRegistrationNumber(union.businessRegistrationNumber);
    }
  }, [union]);

  const isInternal = useMemo(() => isInternalStaff(staff), [staff]);

  const hyphenRemovedSerialNumber = useMemo(() => (serialNumber ?? '').replace('/-/g', ''), [serialNumber]);
  const hyphenRemovedBusinessRegistrationNumber = useMemo(() => (businessRegistrationNumber ?? '').replace('/-/g', ''), [businessRegistrationNumber]);

  const updateInfo = useCallback(async () => {
    await object()
      .shape({
        name: string().min(1, '사업장명을 입력해주세요').required('사업장명을 입력해주세요'),
        representativePhone: string().required('대표 연락처를 입력해주세요'),
        serialNumber: string().min(10, '고유번호는 10자리여야 합니다').required('고유번호를 입력해주세요'),
        representativeAddress: string().min(1).required('대표단지를 입력해주세요'),
        phoneForMessage: string().max(11).optional(),
        businessRegistrationNumber: string().min(10, '사업자등록번호는 10자리여야 합니다').required('사업자등록번호를 입력해주세요'),
        officeAddress: string().required('사무실 주소를 입력해주세요'),
        constructionType: mixed<UnionConstructionType>()
          .oneOf(Object.values(UnionConstructionType), '사업형태를 선택해주세요')
          .typeError('사업형태를 선택해주세요')
          .required('사업형태를 선택해주세요'),
        stage: mixed<UnionStage>()
          .oneOf(Object.values(UnionStage), '사업단계를 선택해주세요')
          .typeError('사업단계를 선택해주세요')
          .required('사업단계를 선택해주세요'),
      })
      .validate({
        name,
        representativePhone,
        serialNumber: hyphenRemovedSerialNumber,
        representativeAddress,
        phoneForMessage,
        businessRegistrationNumber: hyphenRemovedBusinessRegistrationNumber,
        officeAddress,
        constructionType,
        stage,
      })
      .then(async (props) => {
        const result = await updateUnionBasicInformation({ ...props, unionId: union.id });
        if (result instanceof ServerError) {
          return;
        }
        onRefresh();
        setIsEditing(false);
        setErrMsg('');
      })
      .catch((e) => {
        setErrMsg(e.errors[0]);
      });
  }, [
    name,
    representativePhone,
    hyphenRemovedSerialNumber,
    representativeAddress,
    phoneForMessage,
    hyphenRemovedBusinessRegistrationNumber,
    officeAddress,
    constructionType,
    stage,
    union.id,
    onRefresh]);

  const openKevotingSignUpPage = useCallback(async () => {
    try {
      const responseData = await getSignUpForKevotingUrl(union.id);
      window.open(responseData);
    } catch (error) {
      // TODO: 에러처리
    }
  }, [union.id]);

  const kevotingSignedUpText = useMemo(
    () => (union.kevotingRegistrationDate === null ? 'kevoting 계정 미등록' : 'kevoting 계정 생성 완료'),
    [union.kevotingRegistrationDate],
  );

  const kevotingSignedUpColor = useMemo(
    () => (union.kevotingRegistrationDate === null ? theme.palette.common.yellow[500] : theme.palette.primary.main),
    [theme.palette, union.kevotingRegistrationDate],
  );

  return (
    <>
      <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h2">기본 정보</Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={0.5}>
              <CheckCircleOutlineOutlined style={{ fontSize: 24, color: kevotingSignedUpColor }} />
              <Typography variant="body3" color={kevotingSignedUpColor}>
                {kevotingSignedUpText}
              </Typography>
            </Stack>
            {isInternal && (
              <Button
                variant="outlined"
                size="small"
                sx={{ color: theme.palette.grey[800], borderColor: theme.palette.grey[500], padding: '4px 8px' }}
                onClick={openKevotingSignUpPage}
              >
                <Typography variant="subtitle2" color={theme.palette.grey[800]}>
                  kevoting 계정 생성
                </Typography>
              </Button>
            )}
          </Stack>
        </Stack>
        {isInternal && (
          <Button variant="contained" onClick={isEditing ? updateInfo : () => setIsEditing(true)} size="small">
            {isEditing ? '변경사항 저장하기' : '기본정보 변경하기'}
          </Button>
        )}
      </Stack>
      <Stack direction="row" spacing={2} width="100%">
        <TextField
          name="orgName"
          label="사업장명"
          value={name}
          placeholder="개포주공6,7단지아파트 재건축정비사업조합"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          onChange={onNameChange}
          sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
          InputProps={{ readOnly: !isEditing }}
          focused={false}
        />
        <TextField
          name="orgRepresentativeAddress"
          label="대표단지"
          value={representativeAddress}
          placeholder="개포동 185"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          onChange={onRepresentativeAddressChange}
          sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
          InputProps={{ readOnly: !isEditing }}
          focused={isEditing ? undefined : false}
        />
      </Stack>
      <Stack direction="row" spacing={2} width="100%">
        <FormControl
          sx={{
            display: 'flex',
            flex: 1,
            '.MuiOutlinedInput-root': {
              background: isEditing ? '#FFFFFF' : '#F8F8F8',
              width: '100%',
              alignItems: 'start',
              justifyContent: 'start',
            },
            alignItems: 'start',
            '.MuiSelect-outlined': {
              display: 'flex',
              alignItems: 'start',
            },
          }}
          focused={isEditing ? undefined : false}
        >
          <InputLabel id="construction-type-select-label">사업형태</InputLabel>
          <Select
            label="사업형태"
            value={constructionType}
            onChange={onConstructionTypeChange}
            labelId="construction-type-select-label"
            readOnly={!isEditing}
            sx={{ alignItems: 'start' }}
          >
            <MenuItem value="">
              <em>선택안함</em>
            </MenuItem>
            {Object.values(UnionConstructionType).map((val) => (
              <MenuItem key={`construction-option-${val}`} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
          focused={isEditing ? undefined : false}
        >
          <InputLabel id="stage-select-label">사업단계</InputLabel>
          <Select
            label="사업단계"
            value={stage}
            onChange={onStageChange}
            labelId="stage-select-label"
            readOnly={!isEditing}
          >
            <MenuItem value="">
              <em>선택안함</em>
            </MenuItem>
            {Object.values(UnionStage).map((val) => (
              <MenuItem key={`stage-option-${val}`} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2} width="100%">
        <PatternFormat
          format="###-##-#####"
          customInput={TextField}
          name="serialNumber"
          label="고유번호"
          value={serialNumber}
          placeholder="824-80-19313"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          onChange={onSerialNumberChange}
          sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
          focused={isEditing ? undefined : false}
          InputProps={{ readOnly: !isEditing }}
        />
        <PatternFormat
          format="###-##-#####"
          customInput={TextField}
          name="businessRegistrationNumber"
          label="사업자등록번호"
          value={businessRegistrationNumber || ''}
          placeholder="사업자등록번호 10자리"
          InputLabelProps={{ shrink: true }}
          autoComplete="off"
          onChange={onRegisterNumberChange}
          sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
          focused={isEditing ? undefined : false}
          inputProps={{ readOnly: !isEditing }}
        />
      </Stack>
      <Stack direction="row" spacing={2} width="100%">
        <PatternFormat
          format="###########"
          customInput={TextField}
          name="phoneForMessage"
          label="메시지 발신 번호 (선택)"
          value={phoneForMessage}
          placeholder="연락처 최대 11자리"
          autoComplete="off"
          onChange={onMsgPhoneChange}
          InputLabelProps={{ shrink: true }}
          sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
          focused={isEditing ? undefined : false}
          inputProps={{ readOnly: !isEditing }}
        />
        <PatternFormat
          format="###########"
          customInput={TextField}
          name="representativePhone"
          value={representativePhone}
          placeholder="00000000000"
          autoComplete="off"
          onChange={onPhoneChange}
          label="대표 연락처"
          InputLabelProps={{ shrink: true }}
          sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
          focused={isEditing ? undefined : false}
          InputProps={{ readOnly: !isEditing }}
        />
      </Stack>
      <TextField
        name="officeAddress"
        label="사무실 주소"
        value={officeAddress || ''}
        placeholder="서울특별시 강남구 개포동 185 지하 1층"
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        onChange={onOfficeAddress}
        fullWidth
        sx={{ flex: 1, '.MuiOutlinedInput-root': { background: isEditing ? '#FFFFFF' : '#F8F8F8' } }}
        focused={isEditing ? undefined : false}
        InputProps={{ readOnly: !isEditing }}
      />
      {errMsg && <Typography color="error">{errMsg}</Typography>}
    </>
  );
};

export default UnionDetailInformation;
