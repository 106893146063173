import { Close, Search } from '@mui/icons-material';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Post } from '../../../../models';
import { CommonTableCell, CommonTableRow } from '../../../common/table';

type PostListProps = {
  posts: Post[];
  unionId: number;
};

const columns = ['위치', '제목', '작성자', '작성일', '조회', '댓글', '신고수', '상태', ''];

export default function PostList({ posts, unionId }: PostListProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([]);
  const [searchText, setSearchText] = useState('');

  const onSearchTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);

  const onClearClick = useCallback(() => {
    setSearchText('');
  }, []);

  const onDetailClick = useCallback(
    (post: Post) => {
      navigate({
        pathname: `/unions/${unionId}/posts/detail`,
        search: `?${createSearchParams({ id: `${post.id}`, isNotice: `${post.isNotice}` })}`,
      });
    },
    [navigate, unionId],
  );

  useEffect(() => {
    setFilteredPosts(posts);
  }, [posts]);

  useEffect(() => {
    if (searchText) {
      const d = posts.filter(({ title, customer }: Post) =>
        [title, customer?.nickname].some((v) => v?.toLowerCase().includes(searchText.toLowerCase().trim())),
      );
      setFilteredPosts(d);
    } else {
      setFilteredPosts(posts);
    }
  }, [posts, searchText]);

  return (
    <Stack alignItems="start" justifyContent="start" spacing={4}>
      <Stack direction="row">
        <TextField
          placeholder="제목, 작성자 검색"
          size="small"
          value={searchText}
          onChange={onSearchTextChange}
          autoComplete="off"
          name="notRecognizable"
          sx={{
            width: '320px',
            background: 'white',
            '& fieldset': { border: 'none' },
            alignSelf: 'center',
          }}
          InputProps={{
            startAdornment: <Search sx={{ marginRight: '12px' }} />,
            endAdornment: (
              <Close
                sx={{
                  display: searchText ? 'display' : 'none',
                  cursor: 'pointer',
                }}
                onClick={onClearClick}
              />
            ),
            autoComplete: 'off',
          }}
        />
      </Stack>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <CommonTableCell key={column}>{column}</CommonTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPosts.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Typography variant="subtitle1" color={theme.palette.grey[600]} textAlign="center">
                    내용이 없습니다.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {filteredPosts.map((row) => (
              <CommonTableRow key={row.id}>
                <CommonTableCell component="th" scope="row">
                  {row.postWall?.title}
                </CommonTableCell>
                <CommonTableCell>
                  <b>{row.title}</b>
                </CommonTableCell>
                <CommonTableCell>{row.customer?.nickname}</CommonTableCell>
                <CommonTableCell>{dayjs(row.createdAt).format('YYYY-MM-DD HH:mm')}</CommonTableCell>
                <CommonTableCell>{row.viewCount}</CommonTableCell>
                <CommonTableCell>{row.replyCount}</CommonTableCell>
                <CommonTableCell>{row.accuseCount}</CommonTableCell>
                <CommonTableCell>
                  {row.deletedAt && <Typography color="error">삭제</Typography>}
                  {!row.deletedAt && (row.isHidden ? '숨김' : '게시')}
                </CommonTableCell>
                <CommonTableCell>
                  <Button variant="outlined" size="small" onClick={() => onDetailClick(row)}>
                    상세
                  </Button>
                </CommonTableCell>
              </CommonTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
