import { TableCell, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';

export const CommonTableCell = styled(TableCell)(({ theme }) => ({
  alignItems: 'left',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.grey[800],
    fontSize: 16,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 400,
  },
}));

export const CommonTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  '&:nth-of-type(odd)': {},
  // hide last border
  '&:last-child': {
    borderBottom: 0,
  },
}));

export const BottomBorderTableHead = styled(TableHead)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
}));

export const VerticalTopAlignTableCell = styled(TableCell)(() => ({
  alignItems: 'left',
  verticalAlign: 'top',
}));

export const CommonTableRowBottomBorder = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  '& td': {
    border: 'inherit',
  },
}));
