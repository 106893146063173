import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  GeneralMeetingQueryStringKeyEnum,
  GeneralMeetingQueryStringValueEnum,
} from '../../../../models/general-meeting.model';
import { GlobalMenuItems } from '../../../../navigation/global-menu.model';
import CreateMeetingPage from './create-meeting';
import GeneralMeetingDetailPage from './detail';
import GeneralMeetingList from './home/general-meeting-page';
import CreateVotePage from './vote/create-vote';
import MeetingVoteResultPage from './vote/result';

export default function GeneralMeetingPage() {
  const { unionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const onSubPageMove = useCallback(
    (
      eventTypeValue: GeneralMeetingQueryStringValueEnum,
      eventActionValue: GeneralMeetingQueryStringValueEnum,
      targetIdValue?: number,
    ) => {
      const meetingQueryString = {
        tab: GlobalMenuItems.GENERAL_MEETING.keyName,
        [GeneralMeetingQueryStringKeyEnum.EVENT_TYPE]: eventTypeValue,
        [GeneralMeetingQueryStringKeyEnum.EVENT_ACTION]: eventActionValue,
        [GeneralMeetingQueryStringKeyEnum.TARGET_ID]: targetIdValue ? String(targetIdValue) : '',
      };
      setSearchParams(meetingQueryString);
    },
    [setSearchParams],
  );

  const generalMeetingSubPage = useMemo(() => {
    const eventTypeQueryString = searchParams.get(GeneralMeetingQueryStringKeyEnum.EVENT_TYPE);
    const eventActionQueryString = searchParams.get(GeneralMeetingQueryStringKeyEnum.EVENT_ACTION);
    const targetIdQueryString = searchParams.get(GeneralMeetingQueryStringKeyEnum.TARGET_ID);

    if (eventTypeQueryString === null || eventActionQueryString === null) {
      return (
        <GeneralMeetingList
          unionId={unionId}
          onPageMove={(
            eventTypeValue: GeneralMeetingQueryStringValueEnum,
            eventActionValue: GeneralMeetingQueryStringValueEnum,
            targetIdValue?: number,
          ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
        />
      );
    }

    switch (eventTypeQueryString) {
      case GeneralMeetingQueryStringValueEnum.MEETING:
        switch (eventActionQueryString) {
          case GeneralMeetingQueryStringValueEnum.CREATE:
            return <CreateMeetingPage />;
          case GeneralMeetingQueryStringValueEnum.EDIT:
            return targetIdQueryString ? (
              <CreateMeetingPage />
            ) : (
              <GeneralMeetingList
                unionId={unionId}
                onPageMove={(
                  eventTypeValue: GeneralMeetingQueryStringValueEnum,
                  eventActionValue: GeneralMeetingQueryStringValueEnum,
                  targetIdValue?: number,
                ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
              />
            );
          case GeneralMeetingQueryStringValueEnum.DETAIL:
            return targetIdQueryString ? (
              <GeneralMeetingDetailPage
                generalMeetingId={targetIdQueryString}
                onPageMove={(
                  eventTypeValue: GeneralMeetingQueryStringValueEnum,
                  eventActionValue: GeneralMeetingQueryStringValueEnum,
                  targetIdValue?: number,
                ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
              />
            ) : (
              <GeneralMeetingList
                unionId={unionId}
                onPageMove={(
                  eventTypeValue: GeneralMeetingQueryStringValueEnum,
                  eventActionValue: GeneralMeetingQueryStringValueEnum,
                  targetIdValue?: number,
                ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
              />
            );
          default:
            return (
              <GeneralMeetingList
                unionId={unionId}
                onPageMove={(
                  eventTypeValue: GeneralMeetingQueryStringValueEnum,
                  eventActionValue: GeneralMeetingQueryStringValueEnum,
                  targetIdValue?: number,
                ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
              />
            );
        }
      case GeneralMeetingQueryStringValueEnum.VOTE:
        switch (eventActionQueryString) {
          case GeneralMeetingQueryStringValueEnum.CREATE:
            return <CreateVotePage />;
          case GeneralMeetingQueryStringValueEnum.RESULT:
            return targetIdQueryString ? (
              <MeetingVoteResultPage />
            ) : (
              <GeneralMeetingList
                unionId={unionId}
                onPageMove={(
                  eventTypeValue: GeneralMeetingQueryStringValueEnum,
                  eventActionValue: GeneralMeetingQueryStringValueEnum,
                  targetIdValue?: number,
                ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
              />
            );
          default:
            return (
              <GeneralMeetingList
                unionId={unionId}
                onPageMove={(
                  eventTypeValue: GeneralMeetingQueryStringValueEnum,
                  eventActionValue: GeneralMeetingQueryStringValueEnum,
                  targetIdValue?: number,
                ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
              />
            );
        }
      default:
        return (
          <GeneralMeetingList
            unionId={unionId}
            onPageMove={(
              eventTypeValue: GeneralMeetingQueryStringValueEnum,
              eventActionValue: GeneralMeetingQueryStringValueEnum,
              targetIdValue?: number,
            ) => onSubPageMove(eventTypeValue, eventActionValue, targetIdValue)}
          />
        );
    }
  }, [onSubPageMove, searchParams, unionId]);

  return (
    <Stack width="100%">
      {generalMeetingSubPage}
    </Stack>
  );
}
