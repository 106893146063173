import CustomerProfileModel from './customer-profile.model';
import CustomerSocialModel from './customer-social.model';
import PostWallModel from './post-wall.model';
import ResidenceModel from './residence.model';
import UnionMemberModel from './union-member.model';

export default interface CustomerModel {
  id: number;
  name: string;
  phone: string;
  email: string | null;
  address: string;
  nickname: string;
  acceptedPush: boolean;
  acceptedElectronicNotice: boolean;
  residences?: ResidenceModel[];
  unionMembers?: UnionMemberModel[];
  postWalls?: PostWallModel[];
  customerProfile?: CustomerProfileModel;
  customerSocial?: CustomerSocialModel;
  createdAt: Date;
}

export enum GenderType {
  MALE = 1,
  FEMALE = 2,
}
