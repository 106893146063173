/* eslint-disable react/no-array-index-key */

import { ArrowBack, Delete } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, MutableRefObject, createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { array, number, object, string } from 'yup';
import AppContext from '../../../../AppContext';
import { getPost, getPostWalls, updateNotice } from '../../../../api/post.api';
import pollIcon from '../../../../assets/poll-icon.svg';
import { Poll, Post, PostFile, PostWall, RequestStatus, ServerError } from '../../../../models';
import { GlobalMenuItems } from '../../../../navigation/global-menu.model';
import { getFileIcon } from '../../../../util/functions';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';
import Splash from '../../../splash';

const VoteView = ({ poll, theme }: { poll: Poll; theme: Theme }) => (
  <Paper sx={{ p: 2, width: 420 }}>
    <Stack spacing={1} alignItems="start">
      <Stack direction="row" spacing={1}>
        <img src={pollIcon} alt="" />
        <Typography>투표</Typography>
      </Stack>
      <Typography fontSize={14} fontWeight={600} color={theme.palette.grey[600]}>
        {poll.voterCount}명 투표 {poll.isMultiSelect && ' · 복수 선택 가능'}
      </Typography>
      {poll.pollOptions?.map((option) => (
        <Stack
          sx={{ background: '#F5F5F5', p: 1, width: '100%', borderRadius: '4px' }}
          direction="row"
          key={`poll-${poll.id}-option-${option.id}`}
        >
          <Typography fontWeight={400} fontSize={14} flex={1} textAlign="start">
            {option.content}
          </Typography>
          <Typography fontWeight={400} fontSize={14}>
            {poll.voterCount ? ((option.voteCount / poll.voterCount) * 100).toFixed(0) : '0'}%
          </Typography>
        </Stack>
      ))}
      <Typography fontSize={14} fontWeight={400} color={theme.palette.grey[600]}>
        종료일 {dayjs(poll.endsAt).format('YYYY-MM-DD HH:mm')}
      </Typography>
    </Stack>
  </Paper>
);

export default function NoticeEditPage() {
  const navigate = useNavigate();
  const { unionId, id } = useParams();
  const theme = useTheme();
  if (!id) {
    navigate(-1);
  }
  const [post, setPost] = useState<Post>();
  const [postWalls, setPostWalls] = useState<PostWall[]>();
  const [selectedPostWall, setSelectedPostWall] = useState<PostWall>();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [existingFiles, setExistingFiles] = useState<PostFile[]>([]);
  const fileRef: MutableRefObject<HTMLInputElement | null> = createRef();
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [deleteFileIds, setDeleteFileIds] = useState<number[]>([]);
  const { selectedUnion } = useContext(AppContext);

  useEffect(() => {
    const newExistingFiles = existingFiles.filter((item) => !deleteFileIds.includes(item.id));
    if (newExistingFiles.length !== existingFiles.length) {
      setExistingFiles(newExistingFiles);
    }
  }, [deleteFileIds, existingFiles]);

  const removeExistingFile = useCallback(
    (fileId: number) => {
      setDeleteFileIds([...deleteFileIds, fileId]);
    },
    [deleteFileIds],
  );

  const removeNewFile = useCallback(
    (idx: number) => {
      setNewFiles(newFiles.filter((_, index) => index !== idx));
    },
    [newFiles],
  );

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setNewFiles([...newFiles, ...Array.from(e.target.files)]);
    }
  };

  useEffect(() => {
    const fetchWalls = async () => {
      if (selectedUnion) {
        const result = await getPostWalls(selectedUnion.id);
        if (result instanceof ServerError) {
          return;
        }
        setPostWalls(result.walls);
      }
    };

    fetchWalls();
  }, [selectedUnion]);

  const onPostWallChange = (e: SelectChangeEvent) => {
    const wall = postWalls?.find((w) => w.id === Number(e.target.value));
    if (wall) {
      setSelectedPostWall(wall);
    } else {
      setSelectedPostWall(undefined);
    }
  };

  const fetchPost = useCallback(async () => {
    if (Number.isInteger(Number(id))) {
      const result = await getPost(Number(id));
      if (result instanceof ServerError) {
        return;
      }
      setPost(result.post);
      setExistingFiles(result.post.postFiles || []);
      setNewFiles([]);
    }
  }, [id]);

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  useEffect(() => {
    if (post && postWalls) {
      const wall = postWalls?.find((item) => item.id === post.postWallId);
      if (wall) {
        setSelectedPostWall(wall);
        setTitle(post.title);
        setContent(post.content);
      }
    }
  }, [post, postWalls]);

  const onUpdate = useCallback(async () => {
    await object({
      postId: number().required(),
      postWallId: number().required(),
      title: string().required('제목은 필수값 입니다').min(1).max(30),
      content: string().required('내용은 최소 10자 이상 필요합니다').min(10).max(22560),
      deleteFileIds: array(number().required()).optional(),
    })
      .validate({
        postId: id,
        postWallId: selectedPostWall?.id,
        title,
        content,
        deleteFileIds,
      })
      .then(async ({ postWallId, ...rest }) => {
        setRequestStatus(RequestStatus.REQUESTED);
        const result = await updateNotice({
          postWallId: post?.postWallId !== selectedPostWall?.id ? postWallId : undefined,
          postFiles: newFiles,
          ...rest,
        });
        if (result instanceof ServerError) {
          setRequestStatus(RequestStatus.FAIL);
          return;
        }
        fetchPost();
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .catch(() => {
        setRequestStatus(RequestStatus.IDLE);
      });
  }, [content, deleteFileIds, fetchPost, id, newFiles, post?.postWallId, selectedPostWall?.id, title]);

  return post ? (
    <Stack spacing={4} padding={6} alignItems="start">
      <Button
        variant="text"
        startIcon={<ArrowBack sx={{ color: theme.palette.grey[800] }} />}
        onClick={() => {
          navigate(`/unions/${unionId}?tab=${GlobalMenuItems.NOTICE.keyName}`, { replace: true });
        }}
        sx={{ color: theme.palette.grey[800], alignSelf: 'flex-start' }}
      >
        리스트로 돌아가기
      </Button>
      <Typography variant="h2">공지사항 수정</Typography>
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="type-select-label">게시판</InputLabel>
        <Select
          label="게시판"
          value={`${selectedPostWall?.id}` || undefined}
          onChange={onPostWallChange}
          labelId="type-select-label"
        >
          <MenuItem value={undefined}>
            <b>게시판 선택</b>
          </MenuItem>
          {postWalls?.map((wall) => (
            <MenuItem key={`post-wall-option-${wall.id}`} value={wall.id}>
              {wall.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="제목"
        autoComplete="off"
        inputProps={{ minLength: 1, maxLength: 30 }}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
      />
      <TextField
        label="내용"
        autoComplete="off"
        inputProps={{ minLength: 10, maxLength: 22560 }}
        multiline
        rows={8}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        fullWidth
      />
      {post.polls && post.polls[0] && <VoteView poll={post.polls[0]} theme={theme} />}
      <Stack spacing={2}>
        {existingFiles.map((file) => (
          <Stack
            direction="row"
            sx={{ background: theme.palette.grey[200], border: `1px solid ${theme.palette.grey[500]}` }}
            p="16px"
            spacing={2}
            alignItems="center"
            key={`post-file-${file.id}`}
          >
            {['.png', '.jpg', '.jpeg', '.bmp'].includes(file.extension) ? (
              <img src={file.url} alt={file.originalName} width="240px" />
            ) : (
              <>
                <img src={getFileIcon(file.originalName)} alt="" />
                <Typography>{file.originalName}</Typography>
                <Typography flex={1} />
              </>
            )}
            <Delete sx={{ cursor: 'pointer' }} onClick={() => removeExistingFile(file.id)} />
          </Stack>
        ))}
        {newFiles.map((file, idx) => (
          <Stack
            direction="row"
            sx={{ background: theme.palette.grey[200], border: `1px solid ${theme.palette.grey[500]}` }}
            p="16px"
            spacing={2}
            alignItems="center"
            key={`post-file-new-${idx}`}
          >
            <img src={getFileIcon(file.name)} alt="" />
            <Typography>{file.name}</Typography>
            <Typography flex={1} />
            <Delete sx={{ cursor: 'pointer' }} onClick={() => removeNewFile(idx)} />
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" spacing={3}>
        <Button variant="outlined" size="small" onClick={() => fileRef.current?.click()}>
          파일 첨부
        </Button>
        <input
          type="file"
          multiple
          onChange={onFileChange}
          ref={fileRef}
          style={{ display: 'none' }}
          accept="image/*,video/*,.hwp,.hwpx,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.key,.pdf"
        />
      </Stack>
      <Divider sx={{ width: '100%' }} />
      <Stack direction="row" spacing={3}>
        {requestStatus === RequestStatus.REQUESTED ? (
          <Button>
            <CircularProgress />
          </Button>
        ) : (
          <>
            <Button variant="contained" color="inherit" sx={{ width: 240 }} onClick={() => navigate(-1)}>
              취소
            </Button>
            <Button variant="contained" sx={{ width: 240 }} onClick={onUpdate}>
              저장
            </Button>
          </>
        )}
      </Stack>
      <SuccessErrorModal
        isSuccess={requestStatus === RequestStatus.SUCCESS}
        title="공지사항 수정"
        description={
          requestStatus === RequestStatus.SUCCESS
            ? '공지사항이 성공적으로 수정 되었습니다.'
            : '공지사항 수정 중 에러가 발생했습니다.'
        }
        open={requestStatus === RequestStatus.SUCCESS || requestStatus === RequestStatus.FAIL}
        onClose={() => {
          setRequestStatus(RequestStatus.IDLE);
        }}
      />
    </Stack>
  ) : (
    <Splash />
  );
}
