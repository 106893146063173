import { Close } from '@mui/icons-material';
import { Button, ClickAwayListener, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getMessageTemplate, requestToSendMessage } from '../../../../../api/message.api';
import { getUnionCash } from '../../../../../api/union.api';
import { useTooltip } from '../../../../../hooks';
import { AppJoinedStatus, MessageSendingType, SendingMessageForm } from '../../../../../models/message-sending.model';
import { MessageTemplateModel } from '../../../../../models/message-template.model';
import { MessageTargetGrade, MessageVariables, TemplateRequest } from '../../../../../models/message-type';
import TemplateExample from './template-example-component';

interface TemplateSendingDialogProps {
  unionId: number;
  templateCode?: string;
  totalReceivers: number;
  messageVariables: MessageVariables;
  onClose: () => void;
  onSuccess: () => void;
  onError: (msg: string) => void;
}

export default function TemplateSendingDialog(props: TemplateSendingDialogProps) {
  const theme = useTheme();
  const { unionId, templateCode, totalReceivers, messageVariables, onClose, onSuccess, onError } = props;
  const [template, setTemplate] = useState<MessageTemplateModel>();
  const [availableCash, setAvailableCash] = useState(0);
  const [lightTooltip, setTooltip] = useTooltip({
    content: (
      <Typography
        variant="subtitle2"
        color={theme.palette.grey[800]}
        textAlign="start"
      >
        캐시 충전이 필요하신 경우 아래 번호로 연락 바랍니다.<br />
        Kevoting: 1899-1433
      </Typography>
    ),
    maxWidth: 410,
    placement: 'bottom-end',
  });
  const messageBasisPrice = 20;

  const exit = () => {
    onClose();
  };

  const predictedCash = useMemo(() => totalReceivers * messageBasisPrice, [totalReceivers]);

  const fetchTemplate = useCallback(async () => {
    if (!templateCode) {
      return;
    }
    try {
      const data = await getMessageTemplate(templateCode);
      setTemplate(data);
    } catch (error) {
      // 에러 처리
    }
  }, [templateCode]);

  const fetchAvailableCash = useCallback(async () => {
    try {
      const data = await getUnionCash(unionId);
      setAvailableCash(data);
    } catch (error) {
      // TODO: 에러 처리
    }
  }, [unionId]);

  useEffect(() => {
    fetchAvailableCash();
    fetchTemplate();
  }, [fetchAvailableCash, fetchTemplate]);

  const requestToSend = useCallback(async () => {
    if (!templateCode) {
      return;
    }
    const sendingMessageForm: SendingMessageForm = {
      isTemplate: true,
      type: MessageSendingType.ALIM_TALK,
      contents: { templateCode, variables: {} } as TemplateRequest,
      registrationStatus: AppJoinedStatus.UNREGISTERED,
      targetGrades: Object.values(MessageTargetGrade),
    };
    try {
      await requestToSendMessage({ unionId: Number(unionId), params: sendingMessageForm });
      onSuccess();
    } catch (error) {
      onError('메시지 전송 요청 중 실패했습니다.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateCode, unionId]);

  return (
    <Dialog open={!!templateCode && totalReceivers > 0} onClose={exit} fullWidth maxWidth="md">
      <DialogTitle variant="h2">
        메시지 보내기
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <ClickAwayListener onClickAway={() => setTooltip(false)}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color={theme.palette.grey[800]}>
                메시지 전송 시 건 당 20원이 차감됩니다.
              </Typography>
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle1" color={theme.palette.grey[800]}>
                  보유 캐시&emsp;{`${availableCash.toLocaleString('ko-kr')}원`}
                </Typography>
                {lightTooltip}
              </Stack>
            </Stack>
          </ClickAwayListener>

          <Stack direction="row" spacing={3}>

            {template && (
              <Stack spacing={1}>
                <TemplateExample template={template} messageVariables={messageVariables} />
                <Typography variant="body2" color={theme.palette.grey[800]} alignSelf="center">
                  {'#{변수}안의 내용은 개인화되어 발송됩니다.'}
                </Typography>
              </Stack>
            )}

            <Stack justifyContent="space-between">
              <Stack spacing={3}>
                <Stack direction="row" spacing={3}>
                  <Typography variant="subtitle1" color={theme.palette.grey[800]} minWidth="102px">발송 대상</Typography>
                  <Typography variant="subtitle1" color={theme.palette.grey[800]}>
                    {`${totalReceivers.toLocaleString('ko-KR')}명`}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <Typography variant="subtitle1" color={theme.palette.grey[800]} minWidth="102px">차감 캐시</Typography>
                  <Typography variant="subtitle1" color={theme.palette.primary.main}>
                    {`${predictedCash.toLocaleString('ko-KR')}원`}
                  </Typography>
                </Stack>
              </Stack>

              {availableCash < predictedCash && (
                <Stack spacing={1}>
                  <Typography variant="body1" color={theme.palette.error.main}>
                    캐시가 부족합니다. 아래 번호로 연락하여 충전을 요청하세요.
                  </Typography>
                  <Typography variant="subtitle1" color={theme.palette.error.main}>
                    Kevoting: 1899-1433
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="center" spacing={2} pt="16px">
            <Button variant="contained" color="inherit" sx={{ minWidth: '80px' }} onClick={exit}>
              취소
            </Button>
            <Button variant="contained" color="primary" sx={{ minWidth: '80px' }} disabled={availableCash < predictedCash} onClick={requestToSend}>
              보내기
            </Button>
          </Stack>

        </Stack>
      </DialogContent>
    </Dialog>
  );
}
