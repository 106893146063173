// 투표 종류
export enum VoteQuestionType {
  AGENDA = 'agenda', // 안건투표
  ELECTION = 'election', // 인명투표
}

// 투표방식
export enum VoteOptionPresetType {
  FOR_OR_AGAINST = 'forOrAgainst', // 찬반 투표
  CHOICE = 'choice', // 선택 투표
}

export interface QuestionOptionResultModel {
  optionId: number;
  quantity: number;
}

export interface QuestionOptionModel {
  id: number;
  questionId: number;
  order: number;
  description: string;
  result: QuestionOptionResultModel | null;
}

export interface QuestionResultModel {
  selectedOptionId: number;
  otherReason: string;
}

export interface QuestionModel {
  id: number;
  agendaId: number;
  type: VoteQuestionType;
  preset: VoteOptionPresetType;
  result: QuestionResultModel | null;
  title: string;
  options: QuestionOptionModel[];
}

export interface AgendaVoteResultRequestBodyModel {
  selectedOptionId: number;
  otherReason: string;
  optionResults: QuestionOptionResultModel[];
}

export interface ResultConverterDict {
  [key: string]: string;
}
