import { Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { requestGeneralMeetingVoteBoxesRegister } from '../../../../../../api/general-meeting.api';
import {
  GeneralMeetingRegisterVoteRequestModel,
  GeneralMeetingVoteBoxSelectModel,
  VotePurpose,
} from '../../../../../../models/general-meeting.model';
import VoteRegisterConfirmComponent from './vote-register-confirm-component';
import VoteRegisterSelectComponent from './vote-register-select-component';

interface VoteResultRegisterDialogProps {
  generalMeetingId: number;
  title: string;
  availableKevotings: GeneralMeetingVoteBoxSelectModel[];
  open: boolean;
  onCancel: (originAvailableKevotings: GeneralMeetingVoteBoxSelectModel[]) => void;
  onRegister: (newAvailableKevotings: GeneralMeetingVoteBoxSelectModel[]) => void;
}

enum VoteRegisterDialogStatus {
  SELECT,
  REGISTER,
}

export default function VoteRegisterDialog({
  generalMeetingId,
  title,
  availableKevotings: originAvailableKevotings,
  open,
  onCancel,
  onRegister,
}: VoteResultRegisterDialogProps) {
  const theme = useTheme();

  const [dialogStatus, setDialogStatus] = useState<VoteRegisterDialogStatus>(VoteRegisterDialogStatus.SELECT);
  const [availableKevotings, setAvailableKevotings] = useState<GeneralMeetingVoteBoxSelectModel[]>([]);
  const [selectedBallotPurposeId, setSelectedBallotPurposeId] = useState<number>();
  const [selectedResolutionVoteId, setSelectedResolutionVoteId] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [missingRequiredField, setMissingRequiredField] = useState(false);

  const dialogContentMessage = useMemo(() => {
    if (dialogStatus === VoteRegisterDialogStatus.SELECT) {
      return `아래 목록에서 이번 총회에 진행하는 투표를 모두 선택해주세요.
      투표를 등록하셔야 앱에서 구성원이 투표에 참여하고 결과를 확인 할 수 있습니다.`;
    }
    return '등록하려는 투표 정보가 맞는지 다시 한번 확인해주세요.';
  }, [dialogStatus]);

  const cancelButtonLabel = useMemo(
    () => (dialogStatus === VoteRegisterDialogStatus.SELECT ? '취소' : '뒤로'),
    [dialogStatus],
  );

  const okButtonLabel = useMemo(
    () => (dialogStatus === VoteRegisterDialogStatus.SELECT ? '다음' : '등록'),
    [dialogStatus],
  );

  const isOKButtonClickable = useMemo(() => {
    if (originAvailableKevotings.length === 0) {
      return false;
    }

    return true;
  }, [originAvailableKevotings]);

  const enrolledVotes = useMemo(() => availableKevotings.filter((vote) => vote.isChecked), [availableKevotings]);

  const resetSelectedValue = useCallback(() => {
    setSelectedBallotPurposeId(undefined);
    setSelectedResolutionVoteId(undefined);
  }, []);

  const onCancelButtonClick = useCallback(() => {
    if (dialogStatus === VoteRegisterDialogStatus.REGISTER) {
      resetSelectedValue();
      setDialogStatus(VoteRegisterDialogStatus.SELECT);
      return;
    }

    onCancel(originAvailableKevotings);
  }, [dialogStatus, originAvailableKevotings, resetSelectedValue, onCancel]);

  const onOKButtonClick = useCallback(async () => {
    if (dialogStatus === VoteRegisterDialogStatus.SELECT) {
      setDialogStatus(VoteRegisterDialogStatus.REGISTER);
      return;
    }

    if (!generalMeetingId) {
      return;
    }

    try {
      const selectedVotes = availableKevotings.filter((vote) => vote.isChecked);

      if (selectedVotes.length > 0) {
        const allMissing: boolean = !selectedBallotPurposeId && !selectedResolutionVoteId;
        if (allMissing) {
          setMissingRequiredField(true);
          return;
        }

        if (!selectedBallotPurposeId || !selectedResolutionVoteId) {
          if (selectedVotes.length === 2) {
            setMissingRequiredField(true);
            return;
          }
          setMissingRequiredField(false);
        }
      }

      const requestDataList: GeneralMeetingRegisterVoteRequestModel[] =
        selectedVotes.length === 0 ? [] :
          selectedVotes.map((vote) => (
            {
              refId: vote.id,
              purpose: (selectedBallotPurposeId && selectedBallotPurposeId === Number(vote.id))
                ? VotePurpose.BALLOT : VotePurpose.RESOLUTION,
            }));

      const responseStatus = await requestGeneralMeetingVoteBoxesRegister(generalMeetingId, requestDataList);
      if (responseStatus === 201) {
        resetSelectedValue();
        onRegister(availableKevotings);
      }
    } catch (error) {
      // TODO: 에러처리
    }
  }, [
    dialogStatus,
    generalMeetingId,
    availableKevotings,
    selectedBallotPurposeId,
    selectedResolutionVoteId,
    resetSelectedValue,
    onRegister,
  ]);

  const updateMeetingVoteList = useCallback(
    (selectedVote: GeneralMeetingVoteBoxSelectModel) => {
      const newMeetingVoteList = availableKevotings.map((vote) => {
        if (vote.id === selectedVote.id) {
          const newVote = { ...vote };
          newVote.isChecked = !newVote.isChecked;
          return newVote;
        }
        return vote;
      });
      setAvailableKevotings(newMeetingVoteList);
    },
    [availableKevotings],
  );

  useEffect(() => {
    if (!open) {
      setDialogStatus(VoteRegisterDialogStatus.SELECT);
      setAvailableKevotings([]);
      setErrorMessage(undefined);
    } else {
      setAvailableKevotings(cloneDeep(originAvailableKevotings));
    }
  }, [open, originAvailableKevotings]);

  useEffect(() => {
    if (dialogStatus === VoteRegisterDialogStatus.REGISTER) {
      setMissingRequiredField(false);
    }
  }, [dialogStatus]);

  useEffect(() => {
    if (!missingRequiredField) {
      return;
    }
    if (selectedBallotPurposeId && selectedResolutionVoteId) {
      setMissingRequiredField(false);
      return;
    }

    if (enrolledVotes.length === 1 && (!!selectedBallotPurposeId || !!selectedResolutionVoteId)) {
      setMissingRequiredField(false);
    }
  }, [enrolledVotes, missingRequiredField, selectedBallotPurposeId, selectedResolutionVoteId]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={false}>
      <DialogTitle variant="h2" sx={{ minWidth: 1170 }}>
        투표 등록
        <IconButton
          aria-label="close"
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
          onClick={() => {
            onCancel(originAvailableKevotings);
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      {dialogStatus === VoteRegisterDialogStatus.SELECT && errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <DialogContent>
        <Stack spacing={5}>
          <Typography variant="subtitle1" color={theme.palette.grey[900]} style={{ whiteSpace: 'pre-line' }}>
            {dialogContentMessage}
          </Typography>

          {dialogStatus === VoteRegisterDialogStatus.SELECT ? (
            <VoteRegisterSelectComponent
              availableKevotings={cloneDeep(availableKevotings)}
              onExceedMaxSelect={(isExceed) => {
                setErrorMessage(isExceed ? '투표는 2개까지 선택 가능합니다.' : undefined);
              }}
              onSelect={(selectedKevotingElection) => {
                updateMeetingVoteList(selectedKevotingElection);
              }}

            />
          ) : (
            <VoteRegisterConfirmComponent
              title={title}
              selectedVotes={enrolledVotes}
              hasMissingRequiredField={missingRequiredField}
              onSelectBallotVotePurpose={(selectedBoxId) => {
                setSelectedBallotPurposeId(selectedBoxId !== -1 ? selectedBoxId : undefined);
              }}
              onSelectResolutionVotePurpose={(selectedBoxId) => {
                setSelectedResolutionVoteId(selectedBoxId !== -1 ? selectedBoxId : undefined);
              }}
            />
          )}

          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="contained" color="inherit" onClick={onCancelButtonClick}>
              {cancelButtonLabel}
            </Button>
            <Button variant="contained" color="primary" disabled={!isOKButtonClickable} onClick={onOKButtonClick}>
              {okButtonLabel}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
