import { Box } from '@mui/material';
import UnionPage from '../union';

function InternalHome() {
  return (
    <Box p={5} sx={{ width: '100%' }}>
      <UnionPage />
    </Box>
  );
}

export default InternalHome;
