import { Customer } from '../models';
import { ApiClient, handleError } from '../util/ApiClient';

export const getCustomerList = async () => {
  try {
    const response = await ApiClient().get<{
      customerList: Customer[];
    }>('/customers');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getCustomer = async (id: number) => {
  try {
    const response = await ApiClient().get<{
      customer: Customer;
    }>(`/customers/${id}`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

interface RegisterProps {
  name: string;
  serialNumber: string;
  phone?: string;
  email?: string;
  password: string;
  address?: string;
  rrn?: string;
}
export const registerCustomer = async (props: RegisterProps) => {
  try {
    const response = await ApiClient().post('/customers', props);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

interface UpdateCustomerProps {
  customerId: number;
  name: string | null;
  phone: string | null;
  email: string | null;
  address: string | null;
  rrn: string | null;
}
export const updateCustomer = async (props: UpdateCustomerProps) => {
  try {
    const response = await ApiClient().patch('/customers', props);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};
