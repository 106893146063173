export enum MessageRegistrationStatus {
  ALL = 'all',
  REGISTERED = 'registered',
  UNREGISTERED = 'unregistered',
}

export enum MessageTargetGrade {
  EXECUTIVE = 'executive',
  DELEGATE = 'delegate',
  GENERAL = 'general',
}

export interface MessageVariables {
  [key: string]: string;
}
export interface SMSRequest {
  message: string;
}

export interface LMSRequest {
  subject: string | null;
  message: string;
}

export interface TemplateRequest {
  templateCode: string;
  variables: MessageVariables;
}
