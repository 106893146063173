/* eslint-disable import/prefer-default-export */
import { ApiClient, handleError } from '../util/ApiClient';

export const downloadFile = async (url: string) => {
  try {
    const response = await ApiClient().get<Blob>(url, { responseType: 'blob', baseURL: '' });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};
