import styled from '@emotion/styled';

export const CenteredInFullPage = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoImageContainer = styled.figure`
  text-align: center;
`;

export const LogoImage = styled.img`
  height: 10vh;
`;
