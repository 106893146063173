import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppContext from '../AppContext';
import { isInternalStaff } from '../util/functions';
import LoginView from '../views/auth/login';
import RegistrationInvitation from '../views/auth/registration-invitation';
import CustomerPage from '../views/customer';
import ExternalHome from '../views/external-home';
import InternalHome from '../views/internal-home';
import StaffView from '../views/staff';
import UnionDetail from '../views/union/detail';
import SendMessagePage from '../views/union/detail/message/send';
import NoticeEditPage from '../views/union/detail/notice/notice-edit';
import PostDetailPage from '../views/union/detail/post/detail';
import UnionCreation from '../views/union/union-creation';

function Navigation() {
  const { staff, token } = useContext(AppContext);

  return (
    <BrowserRouter>
      <Routes>
        {!staff && <Route path="/registration/invitations/:code" element={<RegistrationInvitation />} />}
        {staff && token ? (
          <>
            <Route path="/unions/:unionId" element={<UnionDetail />} />
            <Route path="/unions/:unionId/posts/detail" element={<PostDetailPage />} />
            <Route path="/staff" element={<StaffView />} />
            <Route path="/unions/:unionId/notices/:id/edit" element={<NoticeEditPage />} />
            <Route path="/unions/:unionId/send" element={<SendMessagePage />} />
            {isInternalStaff(staff) ? (
              <>
                <Route path="/*" element={<InternalHome />} />
                <Route path="/unions/create" element={<UnionCreation />} />
                <Route path="/internal/home" element={<InternalHome />} />
                <Route path="/customers" element={<CustomerPage />} />
                <Route path="/" element={<InternalHome />} />
              </>
            ) : (
              <>
                <Route path="/*" element={<ExternalHome />} />
                <Route path="/" element={<ExternalHome />} />
              </>
            )}
          </>
        ) : (
          <Route path="/*" element={<LoginView />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
