import { Check, ExpandMore, Info } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { GeneralMeetingVoteBoxResponseModel } from '../../../../../../models/general-meeting.model';
import { getVoteDateText } from '../../../../../../util/functions';

interface VoteRegisteredAlertProps {
  selectedKevotings: GeneralMeetingVoteBoxResponseModel[];
}

const VoteRegisteredAlert = ({ selectedKevotings }: VoteRegisteredAlertProps) => {
  const theme = useTheme();

  const accordionSummaryText = useMemo(
    () => (selectedKevotings.length > 0 ? `${selectedKevotings.length}개의 투표가 등록되어 있습니다.` : ''),
    [selectedKevotings],
  );

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Accordion sx={{ bgcolor: theme.palette.common.blue[50] }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle1" color={theme.palette.grey[800]}>
            {accordionSummaryText}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {selectedKevotings.map((vote) => (
            <Stack key={vote.id} direction="row" spacing={1} alignItems="center">
              <Check sx={{ color: theme.palette.common.black }} />
              <Typography variant="subtitle1" color={theme.palette.grey[800]}>
                {vote.title}
              </Typography>
              <Typography variant="body2" color={theme.palette.grey[800]}>
                {getVoteDateText(vote.startDate, vote.endDate)}
              </Typography>
            </Stack>
          ))}
        </AccordionDetails>
      </Accordion>
      <Alert
        severity="warning"
        iconMapping={{
          warning: <Info fontSize="inherit" sx={{ color: theme.palette.common.yellow[500] }} />,
        }}
        sx={{ alignItems: 'center' }}
      >
        <Typography variant="subtitle1" color={theme.palette.grey[800]} textAlign="start">
          수정된 안건은 kevoting에 자동으로 반영되지 않습니다. 전자 투표도 변경하려면 Kevoting에 접속해 수정해 주세요.
        </Typography>
      </Alert>
    </Stack>
  );
};

export default VoteRegisteredAlert;
