import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useCallback } from 'react';
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode';

interface MeetingAddressSearchDialogProps {
  open: boolean;
  onCancel: () => void;
  onComplete: (address: string) => void;
}

export default function MeetingAddressSearchDialog({ open, onCancel, onComplete }: MeetingAddressSearchDialogProps) {
  const handleComplete = useCallback(
    (address: Address) => {
      onComplete(address.address);
    },
    [onComplete],
  );

  return (
    <Dialog open={open}>
      <DialogTitle>
        <IconButton
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onCancel}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: 600, padding: 0, marginTop: 3 }}>
        <DaumPostcodeEmbed onComplete={handleComplete} />
      </DialogContent>
    </Dialog>
  );
}
