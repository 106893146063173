import { Error } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, Typography, useTheme } from '@mui/material';

interface VoteResultEmptyBoxDialogProps {
  open: boolean;
  onClose: () => void;
}

const VoteResultEmptyBoxDialog = ({ open, onClose }: VoteResultEmptyBoxDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: 385 }}>
        <Stack spacing={3} alignItems="center">
          <Error color="error" style={{ fontSize: 80 }} />
          <Typography variant="body1" color={theme.palette.grey[900]}>
            투표 결과가 존재하지 않습니다.
          </Typography>
          <Button variant="contained" color="primary" onClick={onClose} fullWidth>
            확인
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default VoteResultEmptyBoxDialog;
