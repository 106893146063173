import { ArrowBack } from '@mui/icons-material';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContext from '../../../../../AppContext';
import { requestToSendMessage } from '../../../../../api/message.api';
import { getUnionCash } from '../../../../../api/union.api';
import { usePreventGoBack, usePreventLeave } from '../../../../../hooks';
import { UnionStage } from '../../../../../models';
import { AppJoinedStatus, MessageSendingType, SendingMessageForm } from '../../../../../models/message-sending.model';
import { LMSRequest, MessageTargetGrade, SMSRequest } from '../../../../../models/message-type';
import { GlobalMenuItems } from '../../../../../navigation/global-menu.model';
import { getByteWithEucKr, sliceWithinMaxBytes } from '../../../../../util/functions';
import SuccessErrorModal from '../../../../common/dialog/success-error-dialog';
import CashChargingDialog from './cash-charging-dialog';
import FreeMessageInput from './free-message-input';
import LeaveDialog from './leave-dialog';
import PhonePreview from './phone-preview';
import SendingConfirmDialog from './sending-confirm-dialog';
import SendingForm from './sending-form';

interface MessageSendingAlertData {
  isOpen: boolean;
  isSuccess: boolean;
  title: string;
  description: string;
}

export default function SendMessagePage() {
  const theme = useTheme();
  const { selectedUnion } = useContext(AppContext);
  const { unionId } = useParams();
  const maxBytes = { title: 30, content: 2000, boundary: 90 };
  const navigate = useNavigate();
  const [freeMsgTitle, setFreeMsgTitle] = useState('');
  const [freeMsgContent, setFreeMsgContent] = useState('');
  const [selectedAppJoinedStatus, setSelectedAppJoinedStatus] = useState<AppJoinedStatus[]>();
  const [selectedReceivers, setSelectedReceivers] = useState<MessageTargetGrade[]>();
  const [selectedReceiversCount, setSelectedReceiversCount] = useState<number>(0);
  const [sendingMessageForm, setSendingMessageForm] = useState<SendingMessageForm>();
  const [openChargingCash, setOpenChargingCash] = useState(false);
  const [availableCash, setAvailableCash] = useState(0);
  const [messageSendingAlertData, setMessageSendingAlertData] = useState<MessageSendingAlertData>({
    isOpen: false,
    isSuccess: false,
    title: '',
    description: '',
  });

  const { onPreventLeave, offPreventLeave } = usePreventLeave();
  const { needAlert, setNeedAlert, onPreventGoBack, offPreventGoBack } = usePreventGoBack();

  const confirmNavigation = useCallback(() => {
    setNeedAlert(false);
    offPreventGoBack();
    setTimeout(() => {
      navigate(-2);
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const cancelNavigation = useCallback(() => {
    setNeedAlert(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasChanged: boolean = useMemo(
    () => freeMsgContent.length > 0 || freeMsgTitle.length > 0,
    [freeMsgContent.length, freeMsgTitle.length],
  );

  useEffect(() => {
    const handleBeforeunload = hasChanged ? onPreventLeave : offPreventLeave;
    const handlePopstate = hasChanged ? onPreventGoBack : offPreventGoBack;
    handleBeforeunload();
    handlePopstate();
    return () => {
      offPreventLeave();
      offPreventGoBack();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged]);

  const fetchAvailableCash = useCallback(async () => {
    try {
      const data = await getUnionCash(Number(unionId));
      setAvailableCash(data);
    } catch (error) {
      // TODO: 에러 처리
    }
  }, [unionId]);

  useEffect(() => {
    fetchAvailableCash();
  }, [fetchAvailableCash]);

  const inputMsgToByte = useMemo(() => getByteWithEucKr(freeMsgContent ?? ''), [freeMsgContent]);

  const { msgService, maxMsgLengthByte } = useMemo(() => {
    let messageService = MessageSendingType.SMS;
    let maxMessageLength = maxBytes.boundary;
    if (inputMsgToByte > maxBytes.boundary) {
      messageService = MessageSendingType.LMS;
      maxMessageLength = maxBytes.content;
    }
    return { msgService: messageService, maxMsgLengthByte: maxMessageLength };
  }, [inputMsgToByte, maxBytes.boundary, maxBytes.content]);

  const titleFromContent: string = useMemo(
    () => sliceWithinMaxBytes(freeMsgContent, maxBytes.title),
    [freeMsgContent, maxBytes.title],
  );

  const finalLmsTitle: string = useMemo(() => {
    if (msgService === MessageSendingType.SMS) {
      return '';
    }
    return freeMsgTitle.length > 0 ? freeMsgTitle : titleFromContent;
  }, [freeMsgTitle, msgService, titleFromContent]);

  const isOverflow: boolean = useMemo(() => inputMsgToByte > maxBytes.content, [inputMsgToByte, maxBytes.content]);

  const isCommittee: boolean = useMemo(() => {
    if (selectedUnion) {
      return selectedUnion.stage === UnionStage.PROMOTION_COMMITTEE;
    }
    return false;
  }, [selectedUnion]);

  const predictedCash: number = useMemo(() => {
    let amountPerCase = 0;
    amountPerCase = msgService === MessageSendingType.LMS ? 60 : 20;
    return amountPerCase * selectedReceiversCount;
  }, [msgService, selectedReceiversCount]);

  const isInsufficient: boolean = useMemo(() => availableCash < predictedCash, [availableCash, predictedCash]);

  const sendingContents: SMSRequest | LMSRequest = useMemo(() => {
    if (msgService === MessageSendingType.SMS) {
      return { message: freeMsgContent } as SMSRequest;
    }
    return { message: freeMsgContent, subject: finalLmsTitle } as LMSRequest;
  }, [msgService, freeMsgContent, finalLmsTitle]);

  const convertedRegistrationStatusToForm: AppJoinedStatus = useMemo(() => {
    if (selectedAppJoinedStatus?.length === 2) {
      return AppJoinedStatus.ALL;
    }
    if (selectedAppJoinedStatus?.includes(AppJoinedStatus.REGISTERED)) {
      return AppJoinedStatus.REGISTERED;
    }
    return AppJoinedStatus.UNREGISTERED;
  }, [selectedAppJoinedStatus]);

  const combineMessageForm = useCallback(() => {
    if (!selectedAppJoinedStatus || !selectedReceivers) {
      return;
    }

    if (freeMsgContent.length === 0) {
      setMessageSendingAlertData({
        isOpen: true,
        isSuccess: false,
        title: '',
        description: '내용은 필수 입력 항목입니다.',
      });
      return;
    }
    if (selectedAppJoinedStatus?.length === 0 || selectedReceivers?.length === 0) {
      setMessageSendingAlertData({
        isOpen: true,
        isSuccess: false,
        title: '',
        description: '수신 대상을 선택해주세요.',
      });
      return;
    }

    if (selectedReceiversCount === 0) {
      setMessageSendingAlertData({
        isOpen: true,
        isSuccess: false,
        title: '',
        description: '메시지를 보낼 대상이 없습니다.',
      });
      return;
    }

    if (isInsufficient) {
      setMessageSendingAlertData({
        isOpen: true,
        isSuccess: false,
        title: '발송 실패',
        description: '캐시가 부족합니다.',
      });
      return;
    }

    const sendingForm: SendingMessageForm = {
      isTemplate: false,
      type: msgService,
      contents: sendingContents,
      registrationStatus: convertedRegistrationStatusToForm,
      targetGrades: selectedReceivers,
    };
    setSendingMessageForm(sendingForm);
  }, [
    freeMsgContent.length,
    isInsufficient,
    msgService,
    sendingContents,
    selectedAppJoinedStatus,
    selectedReceivers,
    selectedReceiversCount,
    convertedRegistrationStatusToForm,
  ]);

  const requestToSend = useCallback(async () => {
    if (!sendingMessageForm) {
      return;
    }

    try {
      const responseStatus = await requestToSendMessage({ unionId: Number(unionId), params: sendingMessageForm });
      if (responseStatus === 200) {
        setMessageSendingAlertData({
          isOpen: true,
          isSuccess: true,
          title: '발송 완료',
          description: '발송이 완료되었습니다.',
        });
      }
    } catch (error) {
      setMessageSendingAlertData({
        isOpen: true,
        isSuccess: false,
        title: '',
        description: '메시지 전송 요청 중 실패했습니다.',
      });
    }
  }, [sendingMessageForm, unionId]);

  const clearMessageSendingAlertData = useCallback(() => {
    setMessageSendingAlertData({
      isOpen: false,
      isSuccess: false,
      title: '',
      description: '',
    });
  }, []);

  const handleMessageSendingAlertModalClose = useCallback(() => {
    const { isSuccess } = cloneDeep(messageSendingAlertData);
    clearMessageSendingAlertData();
    if (isSuccess) {
      navigate(`/unions/${unionId}?tab=${GlobalMenuItems.MESSAGE.keyName}`, { replace: true });
    }
  }, [clearMessageSendingAlertData, messageSendingAlertData, navigate, unionId]);

  return (
    <Stack alignItems="start" direction="column" spacing={3} p={4}>
      <Button
        variant="text"
        startIcon={<ArrowBack sx={{ color: theme.palette.grey[800] }} />}
        onClick={() => {
          navigate(-1);
        }}
        sx={{ color: theme.palette.grey[800], alignSelf: 'flex-start' }}
      >
        뒤로 돌아가기
      </Button>
      <Typography variant="h2">메시지 보내기</Typography>

      <Typography variant="body2" textAlign="start">
        메시지 전송 시 건 당 SMS는 20원, LMS는 60원이 차감됩니다.
      </Typography>

      <Stack direction="row" spacing={5} alignItems="flex-start" pt={2}>
        <PhonePreview
          inputMsg={freeMsgContent}
          inputTitle={finalLmsTitle}
          inputMsgToByte={inputMsgToByte}
          msgService={msgService}
          maxMsgLengthByte={maxMsgLengthByte}
          isOverflow={isOverflow}
        />

        <Stack spacing={7}>
          <FreeMessageInput
            title={freeMsgTitle}
            content={freeMsgContent}
            messageType={msgService}
            maxBytes={{ title: maxBytes.title, content: maxBytes.content }}
            onChangeTitle={(inputtedTitle) => setFreeMsgTitle(inputtedTitle)}
            onChangeContent={(inputtedContent) => setFreeMsgContent(inputtedContent)}
          />

          <SendingForm
            unionId={Number(unionId)}
            isCommittee={isCommittee}
            getJoinedStatus={(joinedStatus) => {
              setSelectedAppJoinedStatus(joinedStatus);
            }}
            getReceivers={(receiver, totalReceiversCount) => {
              setSelectedReceivers(receiver);
              setSelectedReceiversCount(totalReceiversCount);
            }}
          />
          <Stack spacing={2} alignItems="flex-start">
            <Typography variant="subtitle1">보유 캐시</Typography>
            <Stack direction="row" alignItems="center" spacing={3} pt={1}>
              <Typography variant="subtitle1" sx={{ '& .colored-text': { color: theme.palette.primary.main } }}>
                <span className="colored-text">{availableCash.toLocaleString('ko-KR')}원</span>
              </Typography>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  setOpenChargingCash(true);
                }}
              >
                충전하기
              </Button>
            </Stack>
            <Stack alignItems="flex-start">
              <Typography
                variant="subtitle1"
                color={isInsufficient ? theme.palette.error.main : theme.palette.common.green[500]}
              >
                {`차감 예정 캐시: ${predictedCash.toLocaleString('ko-KR')}원`}
              </Typography>
              {isInsufficient && (
                <Typography variant="subtitle1" color={theme.palette.error.main}>
                  캐시가 부족합니다.
                </Typography>
              )}
            </Stack>
          </Stack>
          <Button variant="contained" onClick={combineMessageForm} sx={{ alignSelf: 'flex-start', px: '40px' }}>
            보내기
          </Button>
        </Stack>
      </Stack>

      <LeaveDialog open={needAlert} onContinue={cancelNavigation} onLeave={confirmNavigation} />

      <SendingConfirmDialog
        messageForm={sendingMessageForm}
        predictedCash={predictedCash}
        totalReceivers={selectedReceiversCount}
        onClose={() => {
          setSendingMessageForm(undefined);
        }}
        handleSend={requestToSend}
      />

      <CashChargingDialog open={openChargingCash} onClose={() => setOpenChargingCash(false)} />

      <SuccessErrorModal
        isSuccess={messageSendingAlertData.isSuccess}
        title={messageSendingAlertData.title}
        description={messageSendingAlertData.description}
        open={messageSendingAlertData.isOpen}
        onClose={handleMessageSendingAlertModalClose}
      />
    </Stack>
  );
}
