import { Stack, Typography, useTheme } from '@mui/material';
import { parseInt } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { GeneralMeetingVoteBoxResponseModel, VotePurpose } from '../../../../../../models/general-meeting.model';
import { getVoteDateText } from '../../../../../../util/functions';
import VoteRegisterKindSelectionComponent from './vote-register-kind-select-component';

interface VoteRegisterConfirmComponentProps {
  title: string;
  selectedVotes: GeneralMeetingVoteBoxResponseModel[];
  hasMissingRequiredField: boolean
  onSelectBallotVotePurpose: (selectedId: number) => void;
  onSelectResolutionVotePurpose: (selectedId: number) => void;
}

interface RegisteredVoteItemProps {
  selectedVotes: GeneralMeetingVoteBoxResponseModel[];
}

const RegisteredVoteEmptyItem = () => {
  const theme = useTheme();
  return (
    <Stack spacing={2} border={`1px solid ${theme.palette.grey[500]}`} borderRadius={1} padding={2}>
      <Typography variant="subtitle1" color={theme.palette.grey[500]}>
        등록된 투표가 없습니다.
      </Typography>
    </Stack>
  );
};

const RegisteredVoteItem = ({ selectedVotes }: RegisteredVoteItemProps) => {
  const theme = useTheme();

  return (
    <Stack spacing={2} border={`1px solid ${theme.palette.grey[500]}`} borderRadius={1} padding={2}>
      {selectedVotes.map((vote) => (
        <Stack key={vote.id}>
          <Typography variant="subtitle1" color={theme.palette.grey[800]}>
            {vote.title}
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[800]}>
            {getVoteDateText(vote.startDate, vote.endDate)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

const VoteRegisterConfirmComponent = (props: VoteRegisterConfirmComponentProps) => {
  const theme = useTheme();
  const {
    title,
    selectedVotes,
    hasMissingRequiredField,
    onSelectBallotVotePurpose,
    onSelectResolutionVotePurpose,
  } = props;
  const [selectedResolutionId, setSelectedResolutionId] = useState<string>();
  const [selectedBallotId, setSelectedBallotId] = useState<string>();

  const selectedVoteComponent = useMemo(() => {
    if (selectedVotes.length === 0) {
      return <RegisteredVoteEmptyItem />;
    }

    return <RegisteredVoteItem selectedVotes={selectedVotes} />;
  }, [selectedVotes]);

  const resetAllSelectedIds = useCallback(() => {
    setSelectedResolutionId(undefined);
    setSelectedBallotId(undefined);
  }, []);

  const handleChanged = useCallback((selectedId: string, votePurpose: VotePurpose) => {
    switch (votePurpose) {
      case VotePurpose.RESOLUTION:
        setSelectedResolutionId(selectedId);
        break;
      case VotePurpose.BALLOT:
        setSelectedBallotId(selectedId);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!selectedBallotId || !selectedResolutionId) { return; }
    if (parseInt(selectedBallotId) * parseInt(selectedResolutionId) === 1) {
      resetAllSelectedIds();
    }
  }, [resetAllSelectedIds, selectedBallotId, selectedResolutionId]);

  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <Typography variant="h3" color={theme.palette.grey[800]}>
          총회명
        </Typography>
        <Typography
          variant="subtitle1"
          color={theme.palette.grey[800]}
          borderRadius={1}
          border={`1px solid ${theme.palette.grey[500]}`}
          padding={2}
        >
          {title}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" color={theme.palette.grey[800]}>
          등록 투표
        </Typography>
        {selectedVoteComponent}
      </Stack>
      {selectedVotes.length > 0 && (
        <Stack spacing={2}>
          <Typography variant="h3" color={theme.palette.grey[800]}>
            투표 시기
          </Typography>
          <VoteRegisterKindSelectionComponent
            votePurpose={VotePurpose.RESOLUTION}
            selectableVotes={selectedVotes}
            disabledId={selectedBallotId}
            onChanged={(selectedVoteId, votePurpose) => {
              handleChanged(selectedVoteId, votePurpose);
              onSelectResolutionVotePurpose(parseInt(selectedVoteId));
            }}
          />
          <VoteRegisterKindSelectionComponent
            votePurpose={VotePurpose.BALLOT}
            selectableVotes={selectedVotes}
            disabledId={selectedResolutionId}
            onChanged={(selectedVoteId, votePurpose) => {
              handleChanged(selectedVoteId, votePurpose);
              onSelectBallotVotePurpose(parseInt(selectedVoteId));
            }}
          />
          {hasMissingRequiredField && (
          <Typography variant="subtitle1" color={theme.palette.error.main}>
            투표 시기를 지정하지 않은 투표가 있습니다.
          </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default VoteRegisterConfirmComponent;
