import { AddCircleOutlineSharp } from '@mui/icons-material';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { removeResidenceFromUnion } from '../../../../api/union.api';
import { Residence, ServerError } from '../../../../models';
import AddResidenceDialog from './add-residence-dialog';
import { isInternalStaff } from '../../../../util/functions';
import AppContext from '../../../../AppContext';

interface Props {
  residences: Residence[];
  unionId: number;
  onRefresh: () => void;
}
const UnionResidence = ({ residences, unionId, onRefresh }: Props) => {
  const { staff } = useContext(AppContext);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const theme = useTheme();

  const removeResdience = async (residence: Residence) => {
    const result = await removeResidenceFromUnion({ unionId, residenceId: residence.id });
    if (result instanceof ServerError) {
      return;
    }
    onRefresh();
  };

  const isInternal = useMemo(() => isInternalStaff(staff), [staff]);
  return (
    <Stack spacing={4} width="100%">
      <Stack direction="row" alignItems="center" spacing={4}>
        <Typography variant="h6">단지 정보</Typography>
        {isInternal && (
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setAddDialogOpen(true);
            }}
          >
            단지 추가
            <AddCircleOutlineSharp sx={{ marginLeft: '4px' }} />
          </Button>
        )}
      </Stack>
      {residences.length === 0 ? (
        <Typography variant="h6">사업장에 등록된 단지가 없습니다</Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ background: theme.palette.primary.light }}>
                <TableCell align="center">
                  <b>No</b>
                </TableCell>
                <TableCell align="center">
                  <b>도로명주소</b>
                </TableCell>
                <TableCell align="center" width="120px">
                  <b>우편번호</b>
                </TableCell>
                <TableCell align="center" width="100px" />
              </TableRow>
            </TableHead>
            <TableBody>
              {residences.map((item, idx) => (
                <TableRow key={`sr-item-${item.id}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center" component="th" scope="row">
                    {idx + 1}
                  </TableCell>
                  <TableCell>
                    <Stack>
                      <Typography>{item.fullRoadAddress}</Typography>
                      <Typography>{`(지번) ${item.jibunAddress}`}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">{item.zipCode}</TableCell>
                  <TableCell align="center">
                    <Button color="error" onClick={() => removeResdience(item)}>
                      제거하기
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AddResidenceDialog
        unionId={unionId}
        onClose={() => {
          setAddDialogOpen(false);
        }}
        open={addDialogOpen}
        onRefresh={onRefresh}
      />
    </Stack>
  );
};

export default UnionResidence;
