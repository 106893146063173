import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import {
  GeneralMeetingVoteBoxSelectModel,
  KevotingElectionStatus,
} from '../../../../../../models/general-meeting.model';
import { getVoteDateText } from '../../../../../../util/functions';
import { CommonTableRowBottomBorder } from '../../../../../common/table';

interface VoteRegisterSelectComponentProps {
  availableKevotings: GeneralMeetingVoteBoxSelectModel[];
  onExceedMaxSelect: (isExceed: boolean) => void;
  onSelect: (selectedKevotingElection: GeneralMeetingVoteBoxSelectModel) => void;
}

const VoteRegisterSelectComponent = (props: VoteRegisterSelectComponentProps) => {
  const { availableKevotings, onExceedMaxSelect, onSelect } = props;
  const theme = useTheme();
  const voteResultRegisterSelectTableColumns: string[] = ['투표명', '투표 일시', '상태'];
  const [page, setPage] = useState(0);
  const rowsPerPage: number = 5;

  const getVoteStatusColor = useCallback(
    (voteStatus: KevotingElectionStatus) => {
      switch (voteStatus) {
        case KevotingElectionStatus.INIT:
          return theme.palette.common.yellow[100];
        case KevotingElectionStatus.IN_PROGRESS:
          return theme.palette.common.green[100];
        case KevotingElectionStatus.FINISHED:
          return theme.palette.grey[200];
        case KevotingElectionStatus.COUNTED:
          return theme.palette.common.blue[50];
        default:
          return theme.palette.common.yellow[100];
      }
    },
    [theme.palette],
  );

  const getVoteStatusText = useCallback((voteStatus: KevotingElectionStatus) => {
    switch (voteStatus) {
      case KevotingElectionStatus.INIT:
        return '시작 전';
      case KevotingElectionStatus.IN_PROGRESS:
        return '진행 중';
      case KevotingElectionStatus.FINISHED:
        return '투표 종료';
      case KevotingElectionStatus.COUNTED:
        return '개표 완료';
      default:
        return '';
    }
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const hasSelectedMaxVotesCount: boolean = useMemo(
    () => availableKevotings.filter((vote) => vote.isChecked).length === 2,
    [availableKevotings]);

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: theme.palette.primary.light }} padding="checkbox" />
              {voteResultRegisterSelectTableColumns.map((column) => (
                <TableCell
                  key={column}
                  sx={{ bgcolor: theme.palette.primary.light, width: column === '상태' ? '15%' : '40%' }}
                  align={column === '상태' ? 'center' : 'inherit'}
                >
                  <Typography variant="subtitle2" color={theme.palette.grey[900]}>
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {availableKevotings.length === 0 && (
              <TableRow>
                <TableCell colSpan={voteResultRegisterSelectTableColumns.length + 1}>
                  <Typography variant="subtitle1" color={theme.palette.grey[600]} textAlign="center">
                    Kevoting에 생성된 투표가 없습니다.
                    <br />
                    투표 만들기 후 다시 등록해 주세요.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {availableKevotings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vote) => (
              <CommonTableRowBottomBorder
                key={vote.id}
                onClick={() => {
                  if (hasSelectedMaxVotesCount && !vote.isChecked) {
                    onExceedMaxSelect(true);
                    return;
                  }
                  onExceedMaxSelect(false);
                  onSelect(vote);
                }}
              >
                <TableCell>
                  <Checkbox color="primary" checked={vote.isChecked} />
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    {vote.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={theme.palette.grey[800]}>
                    {getVoteDateText(vote.startDate, vote.endDate)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {vote.status && (
                    <Typography
                      variant="body2"
                      color={theme.palette.grey[800]}
                      borderRadius={999}
                      bgcolor={getVoteStatusColor(vote.status)}
                      padding="8px 16px"
                      marginX="16px"
                    >
                      {getVoteStatusText(vote.status)}
                    </Typography>
                  )}
                </TableCell>
              </CommonTableRowBottomBorder>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={availableKevotings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  );
};

export default VoteRegisterSelectComponent;
