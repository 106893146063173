export interface GlobalMenuTabModel {
  index: number;
  keyName: string;
  displayName: string;
}

export const GlobalMenuItems = {
  INFORMATION: {
    index: 0,
    keyName: 'information',
    displayName: '정보',
  },
  MEMBER: {
    index: 1,
    keyName: 'member',
    displayName: '구성원',
  },
  OPERATOR: {
    index: 2,
    keyName: 'operator',
    displayName: '관리자',
  },
  NOTICE: {
    index: 3,
    keyName: 'notice',
    displayName: '공지사항',
  },
  POST: {
    index: 4,
    keyName: 'post',
    displayName: '게시물',
  },
  LIBRARY: {
    index: 5,
    keyName: 'library',
    displayName: '자료실',
  },
  GENERAL_MEETING: {
    index: 6,
    keyName: 'generalMeeting',
    displayName: '총회',
  },
  MESSAGE: {
    index: 7,
    keyName: 'message',
    displayName: '메시지',
  },
};

export const getGlobalMenuItemKeyNameByIndex = (targetIndex: number) => {
  const foundMenuItem: GlobalMenuTabModel | undefined = Object.values(GlobalMenuItems).find(
    (menuItem) => menuItem.index === targetIndex,
  );

  if (foundMenuItem === undefined) {
    return GlobalMenuItems.INFORMATION.keyName;
  }

  return foundMenuItem.keyName;
};

export const getGlobalMenuItemIndexByKeyName = (targetKeyName: string) => {
  const foundMenuItem: GlobalMenuTabModel | undefined = Object.values(GlobalMenuItems).find(
    (menuItem) => menuItem.keyName === targetKeyName,
  );

  if (foundMenuItem === undefined) {
    return GlobalMenuItems.INFORMATION.index;
  }

  return foundMenuItem.index;
};
