import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { UnionCashChangeType, UnionCashLogResponseDataModel } from '../../../../../models/cash.model';
import { convertDateFormat24HWithoutDay } from '../../../../../util/functions';
import { CommonTableRowBottomBorder } from '../../../../common/table';

interface UnionCashLogTableProps {
  unionCashLogs: UnionCashLogResponseDataModel[];
}

const UnionCashLogTable = ({ unionCashLogs }: UnionCashLogTableProps) => {
  const theme = useTheme();
  const unionCashLogTableColumns: string[] = ['구분', '일자', '내역', '잔여 캐시', '비고'];

  const getTransactionTypeKorean = useCallback((transactionValue: number) => {
    const translated: string = transactionValue > 0 ? '충전' : '차감';
    return translated;
  }, []);

  const getNumberPlusMinusSign = useCallback((transactionValue: number) => {
    const numberSign: string = transactionValue > 0 ? '+' : '-';
    return numberSign;
  }, []);

  const getTransactionMemoKorean = useCallback((changeType: UnionCashChangeType, transactionValue: number) => {
    switch (changeType) {
      case UnionCashChangeType.MESSAGE:
        return '발송';
      case UnionCashChangeType.REFUND:
        return '관리자';
      case UnionCashChangeType.CHARGE:
        return transactionValue > 0 ? '' : '관리자';
      default:
        return '';
    }
  }, []);

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {unionCashLogTableColumns.map((column) => (
              <TableCell key={column} sx={{ bgcolor: theme.palette.primary.light }}>
                <Typography variant="subtitle2" color={theme.palette.grey[800]}>
                  {column}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {unionCashLogs.length === 0 && (
            <TableRow>
              <TableCell colSpan={unionCashLogTableColumns.length}>
                <Typography variant="subtitle1" color={theme.palette.grey[600]} textAlign="center">
                  내용이 없습니다.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {unionCashLogs.map((cashLog) => (
            <CommonTableRowBottomBorder key={cashLog.id}>
              <TableCell>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  {getTransactionTypeKorean(cashLog.diff)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  {convertDateFormat24HWithoutDay(cashLog.createdAt)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  {getNumberPlusMinusSign(cashLog.diff)}
                  {Math.abs(cashLog.diff).toLocaleString('ko-KR')}원
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  {cashLog.remaining.toLocaleString('ko-KR')}원
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  {getTransactionMemoKorean(cashLog.changeType, cashLog.diff)}
                </Typography>
              </TableCell>
            </CommonTableRowBottomBorder>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UnionCashLogTable;
