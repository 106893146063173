import { CheckCircle } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { UnionCashChargeType } from '../../../../../models/cash.model';

interface ManagementTransactionDoneDialogProps {
  open: boolean;
  transactionType: UnionCashChargeType;
  onClose: () => void;
}

const ManagementTransactionDoneDialog = ({ open, transactionType, onClose }: ManagementTransactionDoneDialogProps) => {
  const theme = useTheme();

  const getTranslatedTransaction = useMemo(
    () => (transactionType === UnionCashChargeType.CHARGE ? '캐시 충전' : '캐시 차감'),
    [transactionType],
  );

  return (
    <Dialog open={open}>
      <DialogContent sx={{ width: 385 }}>
        <Stack spacing={3} alignItems="center">
          <CheckCircle style={{ fontSize: 80, color: theme.palette.common.green[500] }} />
          <Typography variant="h2" color={theme.palette.grey[900]}>
            {getTranslatedTransaction}
          </Typography>
          <Typography variant="body1" color={theme.palette.grey[900]}>
            {getTranslatedTransaction}이 완료되었습니다.
          </Typography>
          <Button variant="contained" color="primary" onClick={onClose} fullWidth>
            확인
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ManagementTransactionDoneDialog;
