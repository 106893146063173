import { ArrowBackIos, Menu, Search } from '@mui/icons-material';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { MessageTemplateModel } from '../../../../../models/message-template.model';
import { MessageVariables } from '../../../../../models/message-type';

interface TemplateExampleProps {
  template: MessageTemplateModel;
  messageVariables: MessageVariables;
}
export default function TemplateExample(props: TemplateExampleProps) {
  const { template, messageVariables } = props;
  const theme = useTheme();

  const replaceInitVariable = useCallback((originVal: string) => {
    let replacedValue: string = originVal;
    Object.entries(messageVariables).forEach(([key, value]) => {
      const templateDefaultValue = new RegExp(`#{${key}}`, 'g');
      replacedValue = replacedValue.replace(templateDefaultValue, value);
    });
    return replacedValue;
  }, [messageVariables]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: '#B3C9D8',
        alignSelf: 'start',
        borderRadius: '16px',
      }}
    >
      <Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" pt={2} px={3}>
          {/* 24px : mui defaul icon size */}
          <ArrowBackIos fontSize="small" sx={{ mr: '24px' }} />
          <Typography variant="subtitle2">
            더집
          </Typography>
          <Stack direction="row" spacing={1}>
            <Search />
            <Menu />
          </Stack>
        </Stack>
        <Stack spacing={2} alignItems="center" mx={3} my={4}>
          <Box
            maxWidth="256px"
            borderRadius="8px"
            overflow="clip"
            bgcolor="white"
          >
            <Typography
              textAlign="start"
              width="100%"
              variant="subtitle2"
              p={2}
              sx={{ backgroundColor: '#F9DD4A' }}
            >알림톡 도착
            </Typography>
            <Box p={2}>
              <Stack spacing={1}>
                <Typography textAlign="start" variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                  {replaceInitVariable(template.message)}
                </Typography>

                {template.button.length > 0 && (
                  <Typography
                    textAlign="center"
                    variant="subtitle3"
                    color={theme.palette.grey[800]}
                    p="12px"
                    borderRadius="4px"
                    sx={{ backgroundColor: theme.palette.grey[300] }}
                  >
                    {template.button[0].name}
                  </Typography>
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
}
