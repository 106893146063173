import { DeleteOutline } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import { byteToHumanReadableSize, getFileIcon } from '../../../util/functions';

interface FileItemProps {
  fileName: string;
  fileSize: number;
  onDelete: () => void;

}
export default function FileItem(props: FileItemProps) {
  const { fileName, fileSize, onDelete } = props;
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      sx={{ background: theme.palette.grey[200], border: `1px solid ${theme.palette.grey[500]}` }}
      p="16px"
      spacing={2}
      alignItems="center"
    >
      <img src={getFileIcon(fileName)} alt="" />
      <Typography>{fileName}</Typography>
      <Typography minWidth={100}>{byteToHumanReadableSize(fileSize)}</Typography>
      <Typography flex={1} />
      <DeleteOutline sx={{ cursor: 'pointer' }} onClick={onDelete} />
    </Stack>
  );
}
