import { ArrowBack, InfoOutlined } from '@mui/icons-material';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { parseInt, some } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
  getDetailGeneralMeeting,
  getGeneralMeetingVoteBox,
  getGeneralMeetingVoteBoxes,
  requestMeetingVoteResult,
} from '../../../../../../api/general-meeting.api';
import {
  AgendaModel,
  GeneralMeetingQueryStringKeyEnum,
  GeneralMeetingVoteBoxResponseModel,
} from '../../../../../../models/general-meeting.model';
import { GlobalMenuItems } from '../../../../../../navigation/global-menu.model';
import {
  convertDateFormat12HWithoutDaySplitByDash,
  convertDateFormatYearMonthDay,
} from '../../../../../../util/functions';
import VoteResultAgendaTable from './vote-result-agenda-table';
import VoteResultEmptyBoxDialog from './vote-result-empty-vote-box-dialog';
import VoteResultInputDialog from './vote-result-input-dialog';
import VoteResultPostAlertDialog from './vote-result-post-alert-dialog';
import VoteResultPostConfirmDialog from './vote-result-post-confirm-dialog';
import VoteResultVoteBoxFetchErrorDialog from './vote-result-vote-box-fetch-error-dialog';

export default function MeetingVoteResultPage() {
  const navigate = useNavigate();
  const { unionId } = useParams();
  const [searchParam] = useSearchParams();
  const theme = useTheme();

  const [meetingTitle, setMeetingTitle] = useState<string>();
  const [agendaList, setAgendaList] = useState<AgendaModel[]>([]);
  const [isPostAlertDialogVisible, setIsPostAlertDialogVisible] = useState<boolean>(false);
  const [isPostConfirmDialogVisible, setIsPostConfirmDialogVisible] = useState<boolean>(false);
  const [isInputDialogVisible, setIsInputDialogVisible] = useState<boolean>(false);
  const [selectedAgenda, setSelectedAgenda] = useState<AgendaModel>();
  const [isPublishButtonClickable, setIsPublishButtonClickable] = useState<boolean>(true);
  const [meetingVoteBoxIds, setMeetingVoteBoxIds] = useState<number[]>([]);
  const [isEmptyVoteBoxes, setIsEmptyVoteBoxes] = useState<boolean>(false);
  const [isVoteBoxFetchError, setIsVoteBoxFetchError] = useState<boolean>(false);

  const generalMeetingId = useMemo(() => {
    const meetingId = searchParam.get(GeneralMeetingQueryStringKeyEnum.TARGET_ID);
    return meetingId ?? undefined;
  }, [searchParam]);

  const fetchMeetingInfo = useCallback(async (meetingId: number) => {
    try {
      const meetingData = await getDetailGeneralMeeting(meetingId);
      const meetingVoteBoxesData = await getGeneralMeetingVoteBoxes(meetingId);
      const meetingVoteBoxIdList = meetingVoteBoxesData.map((voteBox) => voteBox.id);

      setMeetingTitle(meetingData.title);
      setAgendaList(meetingData.voteAgendas);
      setMeetingVoteBoxIds(meetingVoteBoxIdList);
    } catch (error) {
      // TODO: 에러처리
    }
  }, []);

  useEffect(() => {
    if (!generalMeetingId) {
      return;
    }

    const meetingId = parseInt(generalMeetingId);
    fetchMeetingInfo(meetingId);
  }, [fetchMeetingInfo, generalMeetingId]);

  const updateAgendaResult = useCallback(
    (newAgenda: AgendaModel) => {
      const newAgendaList = agendaList.map((agendaResult) => {
        if (agendaResult.id === newAgenda.id) {
          return newAgenda;
        }
        return agendaResult;
      });
      setAgendaList(newAgendaList);
    },
    [agendaList],
  );

  const publishVoteResult = useCallback(async () => {
    if (agendaList.length === 0 || !generalMeetingId) {
      return;
    }

    try {
      const responseStatus = await requestMeetingVoteResult(parseInt(generalMeetingId));
      if (responseStatus === 201) {
        setIsPublishButtonClickable(false);
        navigate(`/unions/${unionId}?tab=${GlobalMenuItems.GENERAL_MEETING.keyName}`, { replace: true });
      }
    } catch (error) {
      // TODO: 에러 처리
    }
  }, [agendaList.length, generalMeetingId, navigate, unionId]);

  const checkVoteResultInputStatus = useCallback(() => {
    if (agendaList.length === 0) {
      setIsEmptyVoteBoxes(true);
      return;
    }

    const agendaQuestionResultList = agendaList.map((agenda) => agenda.question).map((question) => question?.result);
    const isMissingInputExisted = some(agendaQuestionResultList, (questionResult) => questionResult === null);
    if (!isMissingInputExisted) {
      setIsPostConfirmDialogVisible(true);
    } else {
      setIsPostAlertDialogVisible(true);
    }
  }, [agendaList]);

  const createExcelFile = useCallback(
    async (voteResultData: GeneralMeetingVoteBoxResponseModel) => {
      if (!voteResultData.voters || !voteResultData.startDate || !voteResultData.endDate || !voteResultData.questions) {
        setIsVoteBoxFetchError(true);
        return;
      }

      const summaryWorksheetColumns: string[] = ['투표 개요', ''];
      const totalVoterCount: number = voteResultData.voters.length;
      const particiaptedVoterCount: number = voteResultData.voters.filter((voter) => voter.isParticipated).length;
      const voterTournout = totalVoterCount === 0 ? 0 : (particiaptedVoterCount * 100) / totalVoterCount;
      const summaryWorksheetData = [
        { '투표 개요': '투표명', '': voteResultData.title ?? '' },
        {
          '투표 개요': '투표기간',
          '': `투표 시작: ${convertDateFormat12HWithoutDaySplitByDash(voteResultData.startDate)}`,
        },
        {
          '투표 개요': '투표기간',
          '': `투표 종료: ${convertDateFormat12HWithoutDaySplitByDash(voteResultData.endDate)}`,
        },
        {
          '투표 개요': '선거인 수',
          '': totalVoterCount,
        },
        {
          '투표 개요': '투표 참여자 수',
          '': particiaptedVoterCount,
        },
        {
          '투표 개요': '투표율',
          '': `${voterTournout.toFixed(2)}%`,
        },
        { '투표 개요': '주의 사항' },
        { '투표 개요': ' * 위 내용은 전자 투표의 결과만을 포함합니다.' },
        {
          '투표 개요':
            ' * 웹페이지에 전체 총회 결과를 입력 시, 전자 투표, 현장 투표 등을 포함한 전체 총회 결과를 입력해주세요.',
        },
      ];
      const summaryWorksheet = XLSX.utils.json_to_sheet(summaryWorksheetData);
      const summaryWorksheetMergeRange = [{ s: { r: 2, c: 0 }, e: { r: 3, c: 0 } }];
      summaryWorksheet['!merges'] = summaryWorksheetMergeRange;
      XLSX.utils.sheet_add_aoa(summaryWorksheet, [summaryWorksheetColumns], { origin: 'A1' });

      let voteResultWorksheetColumns: string[] = ['휴대 전화 번호', '투표 참여 여부'];
      const agendaColumns: string[] = voteResultData.questions.map((question) => `안건번호 ${question.order}`);
      voteResultWorksheetColumns = voteResultWorksheetColumns.concat(agendaColumns);

      const voteResultParticipants = voteResultData.voters.map((participant) => {
        const reducedChoices = voteResultData.questions?.reduce((result, question) => {
          const questionColumn: string = `안건번호 ${question.order}`;
          const questionChoice = participant.choices.find((choice) => choice.questionId === question.id);
          const choosedOption = question.options.find((option) => option.id === questionChoice?.optionId);
          return { ...result, [questionColumn]: choosedOption?.title ?? '' };
        }, {});

        return {
          '휴대 전화 번호': participant.phoneNo,
          '투표 참여 여부': participant.isParticipated ? 'Y' : 'N',
          ...reducedChoices,
        };
      });
      const voteResultWorksheet = XLSX.utils.json_to_sheet(voteResultParticipants);
      XLSX.utils.sheet_add_aoa(voteResultWorksheet, [voteResultWorksheetColumns], { origin: 'A1' });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, summaryWorksheet, '개요');
      XLSX.utils.book_append_sheet(workbook, voteResultWorksheet, '투표 결과');
      XLSX.writeFile(workbook, `${meetingTitle}_${convertDateFormatYearMonthDay(voteResultData.startDate)}.xlsx`);
    },
    [meetingTitle],
  );

  const fetchVoteBoxData = useCallback(
    async (meetingId: number, voteBoxId: number) => {
      try {
        const responseData = await getGeneralMeetingVoteBox(meetingId, voteBoxId);
        await createExcelFile(responseData);
      } catch (error) {
        setIsVoteBoxFetchError(true);
      }
    },
    [createExcelFile],
  );

  const downloadVoteResultData = useCallback(async () => {
    if (meetingVoteBoxIds.length === 0 || !generalMeetingId) {
      setIsEmptyVoteBoxes(true);
      return;
    }

    const meetingId = parseInt(generalMeetingId);
    // eslint-disable-next-line no-restricted-syntax
    for await (const meetingVoteBoxId of meetingVoteBoxIds) {
      await fetchVoteBoxData(meetingId, meetingVoteBoxId);
    }
  }, [fetchVoteBoxData, generalMeetingId, meetingVoteBoxIds]);

  return (
    <Stack width="100%" spacing={5}>
      <Button
        variant="text"
        startIcon={<ArrowBack sx={{ color: grey[800] }} />}
        sx={{ alignSelf: 'start', color: grey[800] }}
        onClick={() => {
          navigate(-1);
        }}
      >
        {meetingTitle}
      </Button>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h2">투표 결과</Typography>
        <Button variant="contained" color="primary" onClick={downloadVoteResultData} sx={{ padding: '12px 24px' }}>
          전자 투표 결과 확인하기
        </Button>
      </Stack>
      <VoteResultAgendaTable
        agendas={agendaList}
        onClick={(clickedAgenda) => {
          setSelectedAgenda(clickedAgenda);
          setIsInputDialogVisible(true);
        }}
      />
      <Stack alignItems="center" spacing={2}>
        <Stack direction="row" spacing={1}>
          <InfoOutlined sx={{ color: theme.palette.grey[700] }} />
          <Typography variant="body2" color={theme.palette.grey[700]} textAlign="start">
            투표 결과를 모두 입력했다면 결과 게시하기를 누르세요. 앱에 결과가 게시되고 알림이 보내집니다.
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          onClick={checkVoteResultInputStatus}
          sx={{ padding: '12px 40px' }}
          disabled={!isPublishButtonClickable}
        >
          결과 게시하기
        </Button>
      </Stack>

      <VoteResultPostAlertDialog
        open={isPostAlertDialogVisible}
        onInputContinue={() => {
          setIsPostAlertDialogVisible(false);
        }}
        onPublish={() => {
          setIsPostAlertDialogVisible(false);
          publishVoteResult();
        }}
      />

      <VoteResultPostConfirmDialog
        open={isPostConfirmDialogVisible}
        onInputContinue={() => {
          setIsPostConfirmDialogVisible(false);
        }}
        onPublish={() => {
          setIsPostConfirmDialogVisible(false);
          publishVoteResult();
        }}
      />

      <VoteResultEmptyBoxDialog
        open={isEmptyVoteBoxes}
        onClose={() => {
          setIsEmptyVoteBoxes(false);
        }}
      />

      <VoteResultVoteBoxFetchErrorDialog
        open={isVoteBoxFetchError}
        onClose={() => {
          setIsVoteBoxFetchError(false);
        }}
      />

      {selectedAgenda && (
        <VoteResultInputDialog
          generalMeetingId={generalMeetingId}
          originAgenda={selectedAgenda}
          open={isInputDialogVisible}
          onCancel={() => {
            setSelectedAgenda(undefined);
            setIsInputDialogVisible(false);
          }}
          onSave={(newAgenda) => {
            setSelectedAgenda(undefined);
            updateAgendaResult(newAgenda);
            setIsInputDialogVisible(false);
          }}
        />
      )}
    </Stack>
  );
}
