export default interface CustomerSocialModel {
  id: number;
  customerId: number;
  serviceProvider: OAuthServiceProvider;
  oAuthId: string;

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

export enum OAuthServiceProvider {
  KAKAO = 'kakao',
  NAVER = 'naver',
  APPLE = 'apple',
}
