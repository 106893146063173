export enum UnionCashChangeType {
  CHARGE = 'charge',
  MESSAGE = 'message',
  REFUND = 'refund',
}

export enum UnionCashChargeType {
  CHARGE = 'charge',
  SUBTRACTION = 'subtraction',
}

export interface UnionCashLogResponseDataModel {
  id: number;
  cashId: number;
  changeType: UnionCashChangeType;
  refId: number;
  diff: number;
  remaining: number;
  createdAt: Date;
}

export interface UnionCashTransactionRequestBodyModel {
  type: UnionCashChargeType;
  amount: number;
}
