import PostModel from './post.model';

export default interface PostWallModel {
  id: number;
  title: string;
  type: PostWallType;

  communityId: number;

  posts?: PostModel[];
  notices?: PostModel[];

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export enum PostWallType {
  FREE = '자유게시판',
  EXECUTIVE = '임원',
  DELEGATE = '대의원',
}
