import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Customer } from '../../../../models';

type NoticeViewerDialogPorps = {
  viewers?: Customer[];
  open: boolean;
  onClose: () => void;
};
export default function NoticeViewerDialog({ viewers, open, onClose }: NoticeViewerDialogPorps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h2">
        <Typography fontWeight={600} fontSize={16}>
          조회한 회원 ({viewers?.length})
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: 540 }}>
        <Stack spacing={1}>
          <Stack direction="row">
            <Typography fontWeight={600} width={140}>
              이름
            </Typography>
            <Typography fontWeight={600} flex={1}>
              ID
            </Typography>
          </Stack>
          {viewers?.map(({ id, name, nickname }) => (
            <Stack direction="row" key={`viewer-item-${id}`}>
              <Typography variant="body2" color="#3D3D3D" width={140}>
                {name}
              </Typography>
              <Typography variant="body2" color="#3D3D3D" flex={1}>
                {nickname}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
