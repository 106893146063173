import { LMSRequest, MessageTargetGrade, SMSRequest, TemplateRequest } from './message-type';

export enum MessageSendingType {
  SMS = 'sms',
  LMS = 'lms',
  ALIM_TALK = 'alimTalk',
}

export enum AppJoinedStatus {
  ALL = 'all',
  UNREGISTERED = 'unregistered',
  REGISTERED = 'registered',
}

export interface MemberCount {
  grade: MessageTargetGrade;
  count: number;
}

export interface SendingMessageForm {
  isTemplate: boolean;
  type: MessageSendingType;
  contents: SMSRequest | LMSRequest | TemplateRequest;
  registrationStatus: AppJoinedStatus;
  targetGrades: MessageTargetGrade[];
}
