/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { createContext } from 'react';
import { Staff, Union } from './models';

interface ContextInterface {
  initialized: boolean;
  setInitialized(initialized: boolean): void;
  token: string | undefined;
  setToken(token: string | undefined): void;
  staff: Staff | undefined;
  setStaff(user: Staff | undefined): void;
  showNavigation: boolean | undefined;
  setShowNavigation: (b: boolean) => void;
  needsRefresh: boolean | undefined;
  setNeedsRefresh: (b: boolean) => void;
  myUnions: Union[] | undefined;
  setMyUnions: (b: Union[] | undefined) => void;
  selectedUnion: Union | undefined;
  setSelectedUnion: (b: Union | undefined) => void;
}

export default createContext<ContextInterface>({
  initialized: false,
  setInitialized: (initialized: boolean) => {},
  token: undefined,
  setToken: (t) => {},
  staff: undefined,
  setStaff: (u) => {},
  showNavigation: undefined,
  setShowNavigation: (b) => {},
  needsRefresh: undefined,
  setNeedsRefresh: (b) => {},
  myUnions: undefined,
  setMyUnions: (b) => {},
  selectedUnion: undefined,
  setSelectedUnion: (b) => {},
});
