import { Assignment, ExpandLess, ExpandMore, PeopleAltOutlined } from '@mui/icons-material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useContext, useMemo, useState } from 'react';

import { Menu, MenuItem, Stack, Typography, styled, useTheme } from '@mui/material';
import AppContext from '../../AppContext';
import Logo from '../../logo.svg';
import Navigation from '../../navigation';
import { removeCookie } from '../../util/Cookie';
import { isDevEnv, isInternalStaff } from '../../util/functions';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const { setStaff, setToken, staff, token } = useContext(AppContext);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleLogout = () => {
    handleCloseUserMenu();
    removeCookie('token');
    setToken(undefined);
    setStaff(undefined);
  };
  const isInternal = useMemo(() => isInternalStaff(staff!), [staff]);
  const color = theme.palette.grey[100];

  const AppBar = useMemo(
    () =>
      styled(MuiAppBar)(() => ({
        position: 'fixed',
        backgroundColor: theme.palette.grey[50],
        width: isInternal ? `calc(100% - ${drawerWidth}px)` : '100%',
      })),
    [isInternal, theme.palette.grey],
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {staff && token && (
        <>
          <AppBar sx={{ height: '64px' }}>
            <Stack sx={{ width: '100%', height: '100%', alignItems: 'center', paddingX: 4 }} direction="row">
              <img src={Logo} alt="" />
              {isDevEnv && (
              <Typography variant="h1" pl={2}>개발환경</Typography>
              )}
              <Typography flex={1} />
              <Stack
                id="setting-button"
                onClick={handleOpenUserMenu}
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ cursor: 'pointer' }}
                aria-controls={anchorElUser ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorElUser ? 'true' : undefined}
              >
                <Stack
                  sx={{
                    width: 42,
                    height: 42,
                    background: theme.palette.primary.dark,
                    borderRadius: 999,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography color="white">{staff.name.substring(0, 1)}</Typography>
                </Stack>
                <Typography color={theme.palette.grey[600]} variant="subtitle2">{staff.name} 님</Typography>
                {anchorElUser ? <ExpandLess sx={{ color: '#444444' }} /> : <ExpandMore sx={{ color: '#444444' }} />}
              </Stack>
              <Menu
                sx={{ mt: '48px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                MenuListProps={{
                  'aria-labelledby': 'setting-button',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Stack p={2} minWidth={300}>
                  <Typography color="#949494">계정</Typography>
                  <Typography>{staff.email}</Typography>
                </Stack>
                <Divider />
                <MenuItem sx={{ alignItems: 'center', justifyContent: 'center' }} onClick={handleLogout}>
                  <Typography>로그아웃</Typography>
                </MenuItem>
              </Menu>
            </Stack>
          </AppBar>
          {isInternal && (
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                  background: theme.palette.grey[800],
                },
              }}
              variant="persistent"
              anchor="left"
              open
            >
              <DrawerHeader />
              <List>
                <ListItem disablePadding>
                  <ListItemButton href="/customers">
                    <ListItemIcon>
                      <PeopleAltOutlined sx={{ color }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="subtitle2" color={color}>플랫폼 회원</Typography>}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton href="/internal/home">
                    <ListItemIcon>
                      <Assignment color="secondary" sx={{ color }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography variant="subtitle2" color={color}>사업장 목록</Typography>}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Drawer>
          )}
        </>
      )}
      <main style={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Navigation />
      </main>
    </Box>
  );
}
