import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
} from '@mui/material';
import { groupBy } from 'lodash';
import { ChangeEvent, MutableRefObject, createRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AppContext from '../../../../AppContext';
import { getLibraryCategories, uploadLibraryFile } from '../../../../api/library.api';
import { LibraryCategory, LibraryCategoryType, RequestStatus, ServerError, UnionStage } from '../../../../models';
import SuccessErrorModal from '../../../common/dialog/success-error-dialog';
import FileItem from '../../../common/file/file-item';

type AddLibraryFileDialogProps = {
  open: boolean;
  onClose: () => void;
  unionId: number;
  onRefresh: () => void;
};
export default function AddLibraryFileDialog({ open, onClose, onRefresh, unionId }: AddLibraryFileDialogProps) {
  const [addRequestStatus, setAddRequestStatus] = useState(RequestStatus.IDLE);
  const [categories, setCategories] = useState<LibraryCategory[]>();
  const [selectedParentCategory, setSelectedParentCategory] = useState<LibraryCategory>();
  const [selectedChildCategory, setSelectedChildCategory] = useState<LibraryCategory>();
  const fileRef: MutableRefObject<HTMLInputElement | null> = createRef();
  const [file, setFile] = useState<File>();
  const theme = useTheme();
  const { selectedUnion } = useContext(AppContext);

  const onParentCategoryChange = useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        setSelectedParentCategory(undefined);
      } else {
        const cat = categories?.find((item) => item.id === Number(event.target.value));
        setSelectedParentCategory(cat);
      }
    },
    [categories],
  );

  const onChildCategoryChange = useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        setSelectedChildCategory(undefined);
      } else {
        const cat = selectedParentCategory?.childCategories?.find((item) => item.id === Number(event.target.value));
        setSelectedChildCategory(cat);
      }
    },
    [selectedParentCategory],
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const result = await getLibraryCategories();
      if (result instanceof ServerError) {
        return;
      }

      const unionStage = selectedUnion?.stage;
      if (!unionStage) {
        return;
      }

      const groupedCategories = groupBy(result.categories, (category) => category.type);
      const targetCategories =
        groupedCategories[unionStage === UnionStage.PROMOTION_COMMITTEE ?
          LibraryCategoryType.COMMITTEE : LibraryCategoryType.UNION];
      setCategories(targetCategories);
    };

    fetchCategories();
  }, [selectedUnion?.stage]);

  useEffect(() => {
    setSelectedChildCategory(undefined);
    setFile(undefined);
  }, [selectedParentCategory]);

  const exit = () => {
    setSelectedChildCategory(undefined);
    setSelectedParentCategory(undefined);
    setFile(undefined);
    onClose();
  };

  const onAdd = useCallback(() => {
    const uploadFile = async () => {
      if (selectedChildCategory && file) {
        setAddRequestStatus(RequestStatus.REQUESTED);
        const result = await uploadLibraryFile({
          unionId,
          categoryId: selectedChildCategory.id,
          libraryFile: file,
        });
        if (result instanceof ServerError) {
          setAddRequestStatus(RequestStatus.FAIL);
          return;
        }
        setAddRequestStatus(RequestStatus.SUCCESS);
      }
    };
    uploadFile();
  }, [file, selectedChildCategory, unionId]);

  const isReady = useMemo(() => !!categories, [categories]);

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <Dialog open={open} onClose={exit} fullWidth maxWidth="sm">
      <DialogTitle variant="h2">
        자료 추가
        <IconButton
          aria-label="close"
          onClick={exit}
          sx={() => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      {isReady && (
        <>
          <DialogContent>
            <Stack spacing={3}>
              <FormControl sx={{ flex: 1 }} required>
                <InputLabel id="type-select-label">상위 카테고리 선택</InputLabel>
                <Select
                  label="상위 카테고리 선택"
                  value={selectedParentCategory ? `${selectedParentCategory.id}` : ''}
                  onChange={onParentCategoryChange}
                  labelId="type-select-label"
                >
                  <MenuItem value="">
                    <em>상위 카테고리 선택</em>
                  </MenuItem>
                  {categories?.map((val) => (
                    <MenuItem key={`high-cat-option-${val.id}`} value={val.id}>
                      {val.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedParentCategory && (
                <FormControl sx={{ flex: 1 }} required>
                  <InputLabel id="type-select-label">하위 카테고리 선택</InputLabel>
                  <Select
                    label="하위 카테고리 선택"
                    value={selectedChildCategory ? `${selectedChildCategory.id}` : ''}
                    onChange={onChildCategoryChange}
                    labelId="type-select-label"
                  >
                    <MenuItem value="">
                      <em>하위 카테고리 선택</em>
                    </MenuItem>
                    {selectedParentCategory.childCategories?.map((val) => (
                      <MenuItem key={`low-cat-option-${val.id}`} value={val.id}>
                        {val.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {selectedChildCategory &&
                (file ? (
                  <FileItem fileName={file.name} fileSize={file.size} onDelete={() => setFile(undefined)} />
                ) : (
                  <Stack direction="row">
                    <Button variant="outlined" size="small" onClick={() => fileRef.current?.click()}>
                      파일 선택
                    </Button>
                    <input
                      type="file"
                      onChange={onFileChange}
                      ref={fileRef}
                      style={{ display: 'none' }}
                      accept="image/*,video/*,.hwp,.hwpx,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.key,.pdf"
                    />
                  </Stack>
                ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" width="100%" spacing={2}>
              {addRequestStatus === RequestStatus.REQUESTED ? (
                <CircularProgress />
              ) : (
                <>
                  <Button onClick={exit} sx={{ flex: 1 }} variant="contained" color="inherit">
                    취소
                  </Button>
                  <Button onClick={onAdd} sx={{ flex: 1 }} variant="contained">
                    저장
                  </Button>
                </>
              )}
            </Stack>
          </DialogActions>
          <SuccessErrorModal
            isSuccess={addRequestStatus === RequestStatus.SUCCESS}
            title="자료 추가"
            description={
              addRequestStatus === RequestStatus.SUCCESS
                ? '자료가 성공적으로 추가 되었습니다.'
                : '자료 추가 중 에러가 발생했습니다.'
            }
            open={addRequestStatus === RequestStatus.SUCCESS || addRequestStatus === RequestStatus.FAIL}
            onClose={() => {
              if (addRequestStatus === RequestStatus.SUCCESS) {
                onRefresh();
                exit();
              }
              setAddRequestStatus(RequestStatus.IDLE);
            }}
          />
        </>
      )}
    </Dialog>
  );
}
