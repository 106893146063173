export default interface TermsModel {
  id: number;
  type: TermsType;

  title: string;
  content: string;

  order: number;
  version: Date;
  isRequired: boolean;

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export enum TermsType {
  PRIVACY = 'privacy',
  SERVICE = 'service',
  MARKETING = 'marketing',
}
