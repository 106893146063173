import { Button, Card, Divider, Modal, Stack, Typography } from '@mui/material';
import { deleteStaff } from '../../api/staff';
import { ServerError } from '../../models';
import StaffModel from '../../models/staff.model';
import { CommonModalStyle } from '../common/dialog/common-modal';

interface Props {
  staff: StaffModel;
  handleClose: () => void;
  setRefreshList: () => void;
}

const StaffDeleteModal = (props: Props) => {
  const { staff, setRefreshList, handleClose } = props;
  const handleStaffDelete = async () => {
    const data = await deleteStaff(staff.id);
    if (data instanceof ServerError) {
      return;
    }
    setRefreshList();
    handleClose();
  };

  return (
    <Modal
      open
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={CommonModalStyle}
    >
      <Card sx={{ p: 6 }}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          스태프 삭제
        </Typography>
        <Divider style={{ marginTop: 24, marginBottom: 24 }} />
        <Typography>
          정말
          {staff.email}을 삭제 하시겠습니까?
        </Typography>
        <Stack direction="row" style={{ marginTop: 24 }}>
          <Button variant="outlined" color="info" fullWidth onClick={handleClose} sx={{ marginRight: '24px' }}>
            취소
          </Button>
          <Button variant="contained" fullWidth onClick={handleStaffDelete}>
            삭제
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
};

export default StaffDeleteModal;
