import { CheckCircle, Error } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography, useTheme } from '@mui/material';

export interface SuccessErrorModalProps {
  isSuccess: boolean;
  title: string;
  description: string;
  open: boolean;
  confirmTitle?: string;
  onConfirm?: () => void;
  onClose: () => void;
}

export default function SuccessErrorModal(props: SuccessErrorModalProps) {
  const { isSuccess, title, description, open, confirmTitle, onClose, onConfirm } = props;

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} disableAutoFocus transitionDuration={{ enter: 0, exit: 0 }}>
      <DialogContent sx={{ width: '420px' }}>
        <Stack alignItems="center" spacing={3}>
          {isSuccess ? (
            <CheckCircle sx={{ fontSize: '60px', color: theme.palette.common.green[500] }} />
          ) : (
            <Error color="error" sx={{ fontSize: '60px' }} />
          )}
          <Stack alignItems="center" spacing={2}>
            <Typography id="transition-modal-title" variant="h2" component="h2">
              {title}
            </Typography>
            <Typography
              id="transition-modal-description"
              variant="body1"
              color={theme.palette.grey[800]}
              textAlign="center"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {description}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {onConfirm && (
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              onClose();
            }}
          >
            취소
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            if (onConfirm) {
              onConfirm();
            }
            onClose();
          }}
        >
          {confirmTitle || '확인'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
