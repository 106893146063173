import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef } from 'react';
import AppContext from '../../../../../AppContext';
import { MessageSendingType } from '../../../../../models/message-sending.model';

interface PhonePreviewProps {
  inputMsg?: string;
  inputTitle?: string;
  inputMsgToByte: number;
  msgService: MessageSendingType;
  maxMsgLengthByte: number;
  isOverflow: boolean;
}
export default function PhonePreview(props: PhonePreviewProps) {
  const { inputMsg, inputTitle, inputMsgToByte, msgService, maxMsgLengthByte, isOverflow } = props;
  const { selectedUnion } = useContext(AppContext);
  const theme = useTheme();
  const boxRef = useRef<HTMLUListElement>();
  const thezibDefaultPhoneNum: string = '1899-0605';

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [inputMsg]);

  const senderPhoneNum: string = useMemo(() => {
    if (selectedUnion?.phoneForMessage) {
      return selectedUnion.phoneForMessage.length > 0 ? selectedUnion.phoneForMessage : thezibDefaultPhoneNum;
    }
    return thezibDefaultPhoneNum;
  }, [selectedUnion?.phoneForMessage]);

  return (
    <Box
      width="312px"
      height="460px"
      border={`1px solid ${theme.palette.grey[300]}`}
      borderRadius="24px"
      p={2}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent="center"
      >
        <Box width="8px" height="8px" borderRadius="50%" sx={{ backgroundColor: theme.palette.grey[200] }} />
        <Box width="32px" height="8px" borderRadius="6px" sx={{ backgroundColor: theme.palette.grey[200] }} />
      </Stack>
      <Typography variant="subtitle2" py={2} color={theme.palette.grey[700]}>
        {senderPhoneNum}
      </Typography>
      <Box
        ref={boxRef}
        maxWidth="280px"
        height="324px"
        border={`1px solid ${theme.palette.grey[300]}`}
        borderRadius="8px"
        p={2}
        sx={{ backgroundColor: theme.palette.grey[100], overflow: 'scroll' }}
      >
        <Stack spacing={1}>
          {inputTitle && (
            <Typography
              textAlign="start"
              variant="subtitle2"
              color={theme.palette.grey[800]}
              sx={{ whiteSpace: 'pre-line' }}
            >
              {inputTitle}
            </Typography>
          )}

          <Typography
            textAlign="start"
            variant="body2"
            color={inputMsg ? theme.palette.grey[800] : theme.palette.grey[500]}
            sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}
          >
            {inputMsg ?? ''}
          </Typography>
        </Stack>
      </Box>

      <Typography
        textAlign="end"
        variant="body2"
        mt={2}
        sx={{ '& .current-msg-byte': { color: isOverflow ? theme.palette.error.main : theme.palette.primary.main } }}
      >
        {msgService.toUpperCase()} (<span className="current-msg-byte">{inputMsgToByte}</span>/{maxMsgLengthByte}bytes)
      </Typography>

    </Box>
  );
}
