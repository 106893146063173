import { Button, Stack } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../../../../AppContext';
import { getUnionCash, getUnionCashLogs } from '../../../../../api/union.api';
import { UnionCashChargeType, UnionCashLogResponseDataModel } from '../../../../../models/cash.model';
import { isInternalStaff } from '../../../../../util/functions';
import ManagementDialog from './management-dialog';
import ManagementTransactionDoneDialog from './management-transaction-done-dialog';
import UnionCashLogTable from './union-cash-log-table';

interface ManageCashPageProps {
  unionId: number;
}

export default function ManageCashPage({ unionId }: ManageCashPageProps) {
  const { staff } = useContext(AppContext);

  const [currentCash, setCurrentCash] = useState<number>(0);
  const [cashLogs, setCashLogs] = useState<UnionCashLogResponseDataModel[]>([]);
  const [isManagementVisible, setIsManagementVisible] = useState<boolean>(false);
  const [isTransactionDoneVisible, setIsTransactionDoneVisible] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<UnionCashChargeType>();

  const fetchUnionCash = useCallback(async (id: number) => {
    try {
      const responseData = await getUnionCash(id);
      setCurrentCash(responseData);
    } catch (error) {
      // TODO: 에러 처리
    }
  }, []);

  const fetchUnionCashLog = useCallback(async (id: number) => {
    try {
      const responseData = await getUnionCashLogs(id);
      setCashLogs(responseData);
    } catch (error) {
      // TODO: 에러 처리
    }
  }, []);

  const fetchUnionCashData = useCallback(() => {
    if (unionId === undefined) {
      return;
    }

    fetchUnionCash(unionId);
    fetchUnionCashLog(unionId);
  }, [fetchUnionCash, fetchUnionCashLog, unionId]);

  useEffect(() => {
    fetchUnionCashData();
  }, [fetchUnionCashData]);

  return (
    <Stack spacing={3}>
      {isInternalStaff(staff) && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ alignSelf: 'end' }}
          onClick={() => {
            setIsManagementVisible(true);
          }}
        >
          캐시 관리
        </Button>
      )}
      <UnionCashLogTable unionCashLogs={cashLogs} />

      <ManagementDialog
        unionId={unionId}
        open={isManagementVisible}
        currentCash={currentCash}
        onCancel={() => {
          setIsManagementVisible(false);
        }}
        onComplete={(completedTransactionType, balance) => {
          setIsManagementVisible(false);
          setTransactionType(completedTransactionType);
          setCurrentCash(balance);
          setIsTransactionDoneVisible(true);
          fetchUnionCashData();
        }}
      />

      {transactionType && (
        <ManagementTransactionDoneDialog
          open={isTransactionDoneVisible}
          transactionType={transactionType}
          onClose={() => {
            setTransactionType(undefined);
            setIsTransactionDoneVisible(false);
          }}
        />
      )}
    </Stack>
  );
}
