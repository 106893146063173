import { Staff, Terms } from '../models';
import { ApiClient, handleError } from '../util/ApiClient';

export const login = async (email: string, password: string) => {
  try {
    const response = await ApiClient().post<{
      staff: Staff;
      token: string;
    }>('/staff/login', {
      email,
      password,
    });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getSelf = async () => {
  try {
    const response = await ApiClient().post<{
      staff: Staff;
    }>('/staff/login-by-jwt');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getStaffList = async () => {
  try {
    const response = await ApiClient().get<{
      staffList: Staff[];
    }>('/staff');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const makeStaff = async (name: string, email: string, password: string, roleIds: number[]) => {
  try {
    const response = await ApiClient().post('/staff', {
      name,
      email,
      password,
      roleIds,
    });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteStaff = async (id: number) => {
  try {
    const response = await ApiClient().delete(`/staff/${id}`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getExternalStaffList = async () => {
  try {
    const response = await ApiClient().get<{
      operators: Staff[];
    }>('/staff/external');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const validateRegistrationCode = async (code: string) => {
  try {
    const response = await ApiClient().get<{
      isValid: boolean;
    }>(`/staff/invitations/validation/${code}`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const registerWithInvitation = async (code: string, password: string) => {
  try {
    const response = await ApiClient().post<{
      staff: Staff;
      token: string;
    }>('/staff/invitations/registration', {
      code,
      password,
    });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getTerms = async () => {
  try {
    const response = await ApiClient().get<{
      terms: Terms[];
    }>('/staff/terms');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};
