import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { last } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ResultConverterDict } from '../../../../../../models/agenda-vote-question.model';
import { AgendaModel } from '../../../../../../models/general-meeting.model';
import { CommonTableRowBottomBorder } from '../../../../../common/table';

interface VoteResultAgendaTableProps {
  agendas: AgendaModel[];
  onClick: (selectedAgenda: AgendaModel) => void;
}

interface VoteResultAgendaTableCollapseRowProps {
  agenda: AgendaModel;
  colSpan: number;
  onClick: (selectedAgenda: AgendaModel) => void;
}

const VoteResultAgendaTableCollapseRow = ({ agenda, colSpan, onClick }: VoteResultAgendaTableCollapseRowProps) => {
  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const replaceDicForAgainstResult = useMemo<ResultConverterDict>(() => ({ 찬성: '가결', 반대: '부결' }), []);

  const replacedForOrAgainstResult = useMemo(() => {
    if (!agenda.question?.result) {
      return '-';
    }
    return replaceDicForAgainstResult[agenda.question?.result?.otherReason] || agenda.question?.result?.otherReason;
  }, [agenda.question?.result, replaceDicForAgainstResult]);

  return (
    <>
      <CommonTableRowBottomBorder>
        <TableCell sx={{ bgcolor: isCollapsed ? theme.palette.grey[100] : 'transparent' }}>
          <Typography variant="body2" color={theme.palette.grey[800]}>
            {`제 ${agenda.order}호`}
          </Typography>
        </TableCell>
        <TableCell sx={{ bgcolor: isCollapsed ? theme.palette.grey[100] : 'transparent' }}>
          <Typography variant="body2" color={theme.palette.grey[800]}>
            {agenda.title}
          </Typography>
        </TableCell>
        <TableCell sx={{ bgcolor: isCollapsed ? theme.palette.grey[100] : 'transparent' }}>
          <Typography
            variant="subtitle2"
            color={agenda.question?.result?.otherReason ? theme.palette.primary.main : theme.palette.grey[800]}
          >
            {replacedForOrAgainstResult}
          </Typography>
        </TableCell>
        <TableCell sx={{ bgcolor: isCollapsed ? theme.palette.grey[100] : 'transparent' }}>
          <Button
            variant={agenda.question?.result ? 'contained' : 'outlined'}
            color={agenda.question?.result ? 'secondary' : 'primary'}
            fullWidth
            onClick={() => {
              onClick(agenda);
            }}
          >
            {agenda.question?.result ? '수정' : '결과 입력'}
          </Button>
        </TableCell>
        <TableCell sx={{ bgcolor: isCollapsed ? theme.palette.grey[100] : 'transparent' }}>
          <IconButton
            size="small"
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            {isCollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </CommonTableRowBottomBorder>
      <CommonTableRowBottomBorder>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Collapse in={isCollapsed}>
            {agenda.question?.options.map((option) => (
              <Typography
                key={option.id}
                variant="subtitle1"
                color={theme.palette.grey[800]}
                style={{ margin: '16px 10%' }}
              >
                {option.description} : {option.result?.quantity ?? '-'}
              </Typography>
            ))}
          </Collapse>
        </TableCell>
      </CommonTableRowBottomBorder>
    </>
  );
};

const VoteResultAgendaTable = ({ agendas, onClick: onAgendaClick }: VoteResultAgendaTableProps) => {
  const theme = useTheme();
  const voteResultAgendaTableColumns: string[] = useMemo(() => ['번호', '안건', '결과', '상태', ''], []);

  const getTableColumnWidth = useCallback(
    (column: string) => {
      switch (column) {
        case voteResultAgendaTableColumns[0]:
          return '10%';
        case voteResultAgendaTableColumns[1]:
          return '50%';
        case last(voteResultAgendaTableColumns):
          return '5%';
        default:
          return '15%';
      }
    },
    [voteResultAgendaTableColumns],
  );

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {voteResultAgendaTableColumns.map((column) => {
              if (column === last(voteResultAgendaTableColumns)) {
                return (
                  <TableCell
                    key={column}
                    sx={{
                      bgcolor: theme.palette.primary.light,
                      width: getTableColumnWidth(column),
                    }}
                  />
                );
              }
              return (
                <TableCell
                  key={column}
                  sx={{
                    bgcolor: theme.palette.primary.light,
                    width: getTableColumnWidth(column),
                  }}
                >
                  <Typography variant="subtitle2" color={theme.palette.grey[900]}>
                    {column}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {agendas.length === 0 && (
            <TableRow>
              <TableCell colSpan={voteResultAgendaTableColumns.length}>
                <Typography variant="subtitle1" color={theme.palette.grey[600]} textAlign="center">
                  내용이 없습니다.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {agendas.map((agenda) => (
            <VoteResultAgendaTableCollapseRow
              key={agenda.id}
              agenda={agenda}
              colSpan={voteResultAgendaTableColumns.length}
              onClick={(selectedAgenda) => {
                onAgendaClick(selectedAgenda);
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VoteResultAgendaTable;
