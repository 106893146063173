/* eslint-disable import/prefer-default-export */
import { Residence } from '../models';
import { ApiClient, handleError } from '../util/ApiClient';

export const searchResidences = async (address: string) => {
  try {
    const response = await ApiClient().get<{
      residences: Residence[];
    }>(`/residences/search/${address}`);
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};
