import { Button, Dialog, DialogActions, DialogContent, Stack, Typography, useTheme } from '@mui/material';

interface CashChargingDialogProps {
  open: boolean;
  onClose: () => void;
}
export default function CashChargingDialog({ open, onClose }: CashChargingDialogProps) {
  const theme = useTheme();
  return (
    <Dialog open={open} disableAutoFocus transitionDuration={{ enter: 0, exit: 0 }}>
      <DialogContent sx={{ width: '420px' }}>
        <Stack alignItems="center" spacing={3}>

          <Typography
            id="transition-modal-description"
            variant="body1"
            color={theme.palette.grey[800]}
            textAlign="center"
          >
            캐시 충전이 필요하신 경우 아래 번호로 연락 바랍니다.
          </Typography>

          <Typography
            id="transition-modal-description"
            variant="h2"
            color={theme.palette.grey[800]}
            textAlign="center"
          >
            Kevoting: 1899-1433
          </Typography>

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            onClose();
          }}
        >
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}
