/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import { Typography, styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { Union } from '../../models';

type DataKey = 'name' | 'representativePhone' | 'serialNumber' | 'representativeAddress' | 'stage' | 'constructionType';
interface ColumnData {
  dataKey: DataKey;
  label: string;
  width: number;
}

const columns: ColumnData[] = [
  {
    width: 100,
    label: '사업장 고유번호',
    dataKey: 'serialNumber',
  },
  {
    width: 120,
    label: '사업구분',
    dataKey: 'constructionType',
  },
  {
    width: 120,
    label: '사업장명',
    dataKey: 'name',
  },
  {
    width: 120,
    label: '대표지번',
    dataKey: 'representativeAddress',
  },
  {
    width: 80,
    label: '사업단계',
    dataKey: 'stage',
  },
  {
    width: 120,
    label: '전화번호',
    dataKey: 'representativePhone',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    padding: '20px',
  },
  [`&.${tableCellClasses.body}`]: { borderBottom: `1px solid ${theme.palette.grey[400]}`, padding: '20px' },
}));

const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  // hide last border
  '&:last-child td, &:last-child th': { border: 0 },
}));

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <StyledTableCell key={column.dataKey} variant="head" align="left" style={{ width: column.width }}>
          <Typography variant="subtitle2">{column.label}</Typography>
        </StyledTableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index: number, row: Union) {
  const content = (key: DataKey) => row[key];
  return (
    <>
      {columns.map((column) => (
        <StyledTableCell key={`${row.id}-${column.dataKey}`} align="left">
          <Typography variant="body2">{content(column.dataKey)}</Typography>
        </StyledTableCell>
      ))}
    </>
  );
}
interface Props {
  data: Union[];
}

export default function UnionList({ data }: Props) {
  const navigate = useNavigate();
  const VirtuosoTableComponents: TableComponents<Union> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer component="div" {...props} ref={ref} />),
    Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => (
      <StyledTableRow onClick={() => navigate(`/unions/${_item.id}`)} {...props} />
    ),
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
  };

  return (
    <Paper style={{ height: 800, width: '100%' }}>
      <TableVirtuoso
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Paper>
  );
}
