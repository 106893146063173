/* eslint-disable import/prefer-default-export */
import { LibraryCategory, LibraryFile } from '../models';
import { ApiClient, handleError } from '../util/ApiClient';

export const getLibraries = async (unionId: number) => {
  try {
    const response = await ApiClient().get<{
      libraryFiles: LibraryFile[];
    }>('/libraries', { params: { unionId } });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getLibraryCategories = async () => {
  try {
    const response = await ApiClient().get<{
      categories: LibraryCategory[];
    }>('/libraries/categories');
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};

type UploadLibraryFileProps = {
  unionId: number;
  categoryId: number;
  libraryFile: File;
};
export const uploadLibraryFile = async ({ unionId, categoryId, libraryFile }: UploadLibraryFileProps) => {
  try {
    const formdata = new FormData();
    formdata.append('unionId', `${unionId}`);
    formdata.append('categoryId', `${categoryId}`);
    formdata.append('libraryFile', libraryFile);
    const response = await ApiClient().post('/libraries/files', formdata, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (err) {
    return handleError(err);
  }
};
type DeleteLibraryFileProps = {
  id: number;
  unionId: number;
};
export const deleteLibraryFile = async ({ id, unionId }: DeleteLibraryFileProps) => {
  try {
    return await ApiClient().delete('/libraries/files', { data: { id, unionId } });
  } catch (err) {
    return handleError(err);
  }
};
