import { Delete, Edit, ExpandMoreOutlined } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useCallback, useState } from 'react';
import { QuestionModel, VoteOptionPresetType } from '../../../../../models/agenda-vote-question.model';
import { AgendaModel } from '../../../../../models/general-meeting.model';
import { BottomBorderTableHead, CommonTableRow, VerticalTopAlignTableCell } from '../../../../common/table';

interface VoteExpandComponentProps {
  question: QuestionModel;
}

function VoteExpandComponent(props: VoteExpandComponentProps) {
  const { question } = props;
  const theme = useTheme();
  const [isVoteExpand, setIsVoteExpand] = useState(false);
  const toggleVoteExpendButton = useCallback(() => {
    setIsVoteExpand(!isVoteExpand);
  }, [isVoteExpand]);
  return (
    <>
      {isVoteExpand && (
        <TableContainer
          sx={{
            backgroundColor: theme.palette.grey[100],
            borderLeft: `5px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Table>
            <TableBody>
              {question.options.map((option) => (
                <TableRow key={option.id} sx={{ display: 'flex', p: 2, columnGap: 2 }}>
                  {question.preset === VoteOptionPresetType.CHOICE && (
                    <VerticalTopAlignTableCell sx={{ p: 0 }}>
                      <Typography variant="body2" textAlign="left">
                        보기&nbsp;
                        {option.order}
                      </Typography>
                    </VerticalTopAlignTableCell>
                  )}
                  <VerticalTopAlignTableCell sx={{ p: 0, flex: 1 }}>
                    <Typography variant="body2">{option.description}</Typography>
                  </VerticalTopAlignTableCell>
                  <VerticalTopAlignTableCell sx={{ p: 0 }}>
                    <Typography variant="body2">-</Typography>
                  </VerticalTopAlignTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Button variant="text" sx={{ alignSelf: 'start', color: theme.palette.grey[600] }} onClick={toggleVoteExpendButton}>
        투표 보기
        <ExpandMoreOutlined
          sx={{ transform: isVoteExpand ? 'rotate(180deg)' : '', transition: 'transform 150ms ease' }}
        />
      </Button>
    </>
  );
}
interface AgendaTableProps {
  agendas: AgendaModel[];
  isEditable: boolean;
  editAgenda: (agenda: AgendaModel) => void;
  deleteAgenda: (agenda: AgendaModel) => void;
}

export default function AgendaTable(props: AgendaTableProps) {
  const { agendas, isEditable, editAgenda, deleteAgenda } = props;

  const theme = useTheme();

  const getFileNames = ((agenda: AgendaModel) => agenda.files.map((file) => file.originalName));

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <BottomBorderTableHead>
          <TableRow>
            <VerticalTopAlignTableCell sx={{ minWidth: '140px' }}>번호</VerticalTopAlignTableCell>
            <VerticalTopAlignTableCell sx={{ width: '50%' }}>안건</VerticalTopAlignTableCell>
            <VerticalTopAlignTableCell sx={{ width: '50%' }}>첨부 자료</VerticalTopAlignTableCell>
            <VerticalTopAlignTableCell sx={{ minWidth: '140px' }} />
          </TableRow>
        </BottomBorderTableHead>
        <TableBody>
          {agendas.map((row) => (
            <CommonTableRow key={row.id}>
              <VerticalTopAlignTableCell>
                <Typography variant="subtitle2" color={theme.palette.grey[800]}>{`제 ${row.order}호`}</Typography>
              </VerticalTopAlignTableCell>
              <VerticalTopAlignTableCell>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle2" color={theme.palette.grey[800]}>
                    {row.title}
                  </Typography>
                  {row.question && <VoteExpandComponent question={row.question} />}
                </Stack>
              </VerticalTopAlignTableCell>
              <VerticalTopAlignTableCell>
                <Typography variant="body2" color={theme.palette.grey[700]} sx={{ whiteSpace: 'pre-line' }}>
                  {getFileNames(row).join('\n')}
                </Typography>
              </VerticalTopAlignTableCell>
              {isEditable && (
                <VerticalTopAlignTableCell>
                  <Stack direction="row" spacing={2}>
                    <IconButton
                      size="small"
                      onClick={() => editAgenda(row)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => deleteAgenda(row)}
                    ><Delete />
                    </IconButton>
                  </Stack>
                </VerticalTopAlignTableCell>
              )}
            </CommonTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
