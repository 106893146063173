import { Alert, Button, Stack, Typography, useTheme } from '@mui/material';

interface VoteUnregisteredAlertProps {
  onClick: () => void
}
const VoteUnregisteredAlert = ({ onClick }: VoteUnregisteredAlertProps) => {
  const theme = useTheme();

  return (
    <Alert severity="error" sx={{ width: '100%', alignItems: 'center' }}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="subtitle1" color={theme.palette.grey[800]}>
          투표가 등록되지 않았습니다.
        </Typography>
        <Button variant="text" sx={{ p: 0.5 }} onClick={onClick}>
          <Typography
            variant="subtitle1"
            color={theme.palette.grey[800]}
            sx={{ textDecoration: 'underline' }}
            display="inline"
          >
            투표 등록하기
          </Typography>
        </Button>
        <Typography variant="subtitle1" color={theme.palette.grey[800]}>
          버튼을 클릭하여 총회에 등록하세요.
        </Typography>
      </Stack>
    </Alert>
  );
};

export default VoteUnregisteredAlert;
