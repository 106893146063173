import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPostList } from '../../../../api/post.api';
import { Post, ServerError } from '../../../../models';
import { GlobalMenuItems } from '../../../../navigation/global-menu.model';
import TabPanel, { a11yProps } from '../../../common/tab-panel';
import PostList from './list';

export default function PostPage({ unionId }: { unionId: number }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<number>(Number(searchParams.get('subtab')) || 0);
  const [posts, setPosts] = useState<Post[]>([]);
  const [examinePosts, setExaminePosts] = useState<Post[]>([]);

  const onTabChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setSearchParams({ tab: GlobalMenuItems.POST.keyName, subtab: `${newValue}` });
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPostList(unionId);
      if (data instanceof ServerError) {
        return;
      }
      setPosts(data.postList);
    };

    fetchData();
  }, [unionId]);

  useEffect(() => {
    setExaminePosts(
      posts.filter((post) => post.accuseCount > 9 && !post.isHidden && post.accuseCount / post.viewCount > 0.1),
    );
  }, [posts]);

  return (
    <Stack alignItems="start" spacing={4}>
      <Typography variant="h2">게시글</Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={onTabChange} aria-label="tab-label">
            <Tab label="전체" {...a11yProps(0)} />
            <Tab label="신고 누적" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <PostList posts={posts} unionId={unionId} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <PostList posts={examinePosts} unionId={unionId} />
        </TabPanel>
      </Box>
    </Stack>
  );
}
