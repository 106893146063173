import { Close, Download } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { getMessageResultHistory } from '../../../../../api/message.api';
import { MessageSendingResultModel } from '../../../../../models/message-sending-history.model';
import SendingResultTable from './sending-result-table';

interface SendingResultDialogProps {
  unionId: number,
  selectedId?: number,
  messageSendingDate: Date,
  onClose: () => void;
}
export default function SendingResultDialog(props: SendingResultDialogProps) {
  const { unionId, selectedId, messageSendingDate, onClose } = props;
  const theme = useTheme();

  const [messageResult, setMessageResult] = useState<MessageSendingResultModel[]>([]);
  const fetchSendingHistory = useCallback(async () => {
    if (!selectedId) {
      return;
    }
    try {
      const requestHistory = await getMessageResultHistory({ unionId, requestId: selectedId });
      setMessageResult(requestHistory);
    } catch (err) {
      setMessageResult([]);
    }
  }, [selectedId, unionId]);

  useEffect(() => {
    fetchSendingHistory();
  }, [fetchSendingHistory]);

  const { success, failure, waiting } = useMemo(() => {
    let successCnt: number = 0;
    let failureCnt: number = 0;
    messageResult.forEach((result) => {
      if (result.status === '성공') {
        successCnt += 1;
      } else if (result.status === '실패') {
        failureCnt += 1;
      }
    });
    return {
      success: successCnt,
      failure: failureCnt,
      waiting: messageResult.length - (successCnt + failureCnt),
    };
  }, [messageResult]);

  const downloadSendingResult = useCallback(() => {
    const formattedData = messageResult.map(
      ({ destPhone, status, errorCode }) => ({
        전화번호: destPhone,
        결과: errorCode ? `${status} (${errorCode})` : status,
      }),
    );
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [['전화번호', '결과']],
      { origin: 'A1' },
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '명부');
    XLSX.writeFile(workbook, `발송결과_${dayjs(messageSendingDate).format('YYMMDD')}.xlsx`);
  }, [messageResult, messageSendingDate]);

  return (
    <Dialog open={!!selectedId} maxWidth="lg" fullWidth={false}>
      <DialogTitle variant="h2" sx={{ minWidth: '656px' }}>
        발송 결과
        <IconButton
          aria-label="close"
          sx={{ position: 'absolute', right: '8px', top: '8px', color: theme.palette.grey[500] }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minHeight: '790px' }}>
        <Stack spacing={1} alignContent="start">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            endIcon={<Download />}
            sx={{ alignSelf: 'start' }}
            onClick={downloadSendingResult}
          >
            내려받기
          </Button>
          <Typography variant="caption1" color={theme.palette.grey[600]} pb={2}>
            수신자 사정에 따라 수신이 실패하거나, 발송 후 수신까지 최대 49시간이 지연될 수 있습니다.
          </Typography>
          <Typography variant="subtitle3" color={theme.palette.grey[800]}>
            {`성공: ${success}`}&emsp;{`실패: ${failure}`}&emsp;{`대기중: ${waiting}`}&emsp;
          </Typography>
          <SendingResultTable results={messageResult} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
